const EyeClosed = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2195_15479)">
      <path
        d="M12.0001 19.4999C11.1581 19.4999 10.3151 19.3219 9.49609 19.0049"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.882 12.968C18.99 16.467 15.495 19.5 12 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0791 9.4209C19.7701 10.2299 20.3841 11.1119 20.8821 12.0329C21.0391 12.3239 21.0391 12.6769 20.8821 12.9679"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 19.5L19 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.77309 14.7271C8.54309 13.4971 8.54309 11.5021 9.77309 10.2721C11.0031 9.04211 12.9981 9.04211 14.2281 10.2721"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0437 7.456C15.4967 6.259 13.7478 5.5 11.9998 5.5C8.50475 5.5 5.00975 8.533 3.11775 12.033C2.96075 12.324 2.96075 12.677 3.11775 12.968C4.06375 14.717 5.40975 16.349 6.95575 17.545"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default EyeClosed;
