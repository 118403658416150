import { FC, memo } from 'react';

import { Stack } from '@mui/material';

import { IFinanceGraphProps } from './types';

const _FinanceGraph: FC<IFinanceGraphProps> = ({ loadingState, used, available, reserved, overdue }) => {
  const total = (used ?? 0) + (reserved ?? 0) + (available ?? 0) + (overdue ?? 0);

  const usedWidth = ((used ?? 0) / (total ?? 1)) * 100;
  const reservedWidth = ((reserved ?? 0) / (total ?? 1)) * 100;
  const availableWidth = ((available ?? 0) / (total ?? 1)) * 100;
  const overdueWidth = ((overdue ?? 0) / (total ?? 1)) * 100;

  const isDataEmpty = [used, reserved, available, overdue].every((value) => value === 0);

  return (
    <Stack width="100%">
      {isDataEmpty || loadingState === 'loading' ? (
        <Stack width="100%" height="5px" bgcolor={(t) => t.palette.grey['300']} borderRadius={1} />
      ) : (
        <Stack width="100%" direction="row" gap={0.25}>
          <Stack borderRadius={1} width={`${usedWidth}%`} height="5px" bgcolor={(t) => t.palette.primary.main} />
          <Stack borderRadius={1} width={`${reservedWidth}%`} height="5px" bgcolor={(t) => t.palette.primary.dark} />
          <Stack borderRadius={1} width={`${availableWidth}%`} height="5px" bgcolor={(t) => t.palette.success.light} />
          <Stack borderRadius={1} width={`${overdueWidth}%`} height="5px" bgcolor={(t) => t.palette.error.main} />
        </Stack>
      )}
    </Stack>
  );
};

export const FinanceGraph = memo(_FinanceGraph);
