interface IHTTPErrorPayload {
  strCode?: string;
  message: string;
}

export class HTTPError extends Error {
  public strCode?: string;
  public message: string;
  public rawMessage: string;
  constructor({ strCode, message }: IHTTPErrorPayload) {
    super(`HTTP error: ${message} (${strCode})`);
    this.strCode = strCode;
    this.message = message;
    this.rawMessage = message;
  }
}
