import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useEventListener } from './useEventListener';
import { usePrevious } from './usePrevious';
import { useThrottle } from './useThrottle';

const THROTTLE_INTERVAL = 150;

export function useScrollReachesEnd<E extends HTMLElement>(
  /**
   * Ref to container scrollable element
   */
  scrollableContainerRef: RefObject<E>,
  /**
   * Callback on scroll reaches scrollable container end
   */
  onScrollReachesEnd: (currentPage: number) => void,
  /**
   * Flag to skill callback firing
   */
  shouldSkip: boolean,
  /**
   * A threshold value defining when callback is fired. (total height - 80%)
   */
  treshhold = 0.8,
  /**
   * Initial page counter
   */
  initialPage = 0
) {
  const currentPage = useRef(initialPage);
  const isBusy = useRef(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevShouldSkip = usePrevious(shouldSkip) as any;

  const handleScroll = useCallback(() => {
    if (scrollableContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableContainerRef.current;

      if (!shouldSkip && scrollTop + clientHeight >= scrollHeight * treshhold) {
        currentPage.current += 1;

        isBusy.current = true;
        onScrollReachesEnd(currentPage.current);
      }
    }
  }, [scrollableContainerRef, shouldSkip, treshhold, onScrollReachesEnd]);

  useEffect(() => {
    if (shouldSkip !== prevShouldSkip && !shouldSkip) {
      isBusy.current = false;
    }
  }, [prevShouldSkip, shouldSkip]);

  const handleScrollThrottled = useThrottle(handleScroll, THROTTLE_INTERVAL);

  useEventListener('scroll', handleScrollThrottled, scrollableContainerRef);
}
