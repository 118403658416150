import { defineMessages } from 'react-intl';

const scope = 'containers.Notifications';

export default defineMessages({
  newNotifications: {
    id: `${scope}.newNotifications`,
    defaultMessage: 'New ({count})',
  },
  deleteAll: {
    id: `${scope}.deleteAll`,
    defaultMessage: 'Delete all',
  },
  markAllAsRead: {
    id: `${scope}.markAllAsRead`,
    defaultMessage: 'Mark all as read',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'My profile',
  },
  log_out: {
    id: `${scope}.log_out`,
    defaultMessage: 'Log out',
  },
  emptyList: {
    id: `${scope}.emptyList`,
    defaultMessage: 'Notification list is empty',
  },
  seenEarlier: {
    id: `${scope}.seenEarlier`,
    defaultMessage: 'Seen earlier',
  },
  awaitingSigning: {
    id: `${scope}.awaitingSigning`,
    defaultMessage: 'The deal {name} has pending documents to be signed.',
  },
  limitChange: {
    id: `${scope}.limitChange`,
    defaultMessage: 'Financing limit for your buyer {name} has been changed to {limit}!',
  },
  moneyTransferred: {
    id: `${scope}.moneyTransferred`,
    defaultMessage: 'Congratulations! The deal {name} has been financed!',
  },
  invoiceRepaid: {
    id: `${scope}.invoiceRepaid`,
    defaultMessage: 'Invoice {name} has been settled!',
  },
  dealReviewNeeded: {
    id: `${scope}.dealReviewNeeded`,
    defaultMessage: 'Deal {name} review is required.',
  },
  invoiceOverdue: {
    id: `${scope}.invoiceOverdue`,
    defaultMessage: 'Invoice {name} is overdue. We look forward to receiving your payment shortly.',
  },
  referralUpdated: {
    id: `${scope}.referralUpdated`,
    defaultMessage: '{name} has {status} your invitation!',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  linkToCompanyCreate: {
    id: `${scope}.linkToCompanyCreate`,
    defaultMessage: '{name} wants to join your team',
  },
  repsAcceptedInvitation: {
    id: `${scope}.repsAcceptedInvitation`,
    defaultMessage: '{fullName} has accepted your invitation!',
  },
  repsRejectedInvitation: {
    id: `${scope}.repsRejectedInvitation`,
    defaultMessage: '{fullName} has rejected your invitation.',
  },
  repsInvitationisAboutToExpire: {
    id: `${scope}.repsInvitationisAboutToExpire`,
    defaultMessage: 'Invitation to {fullName}  is expiring on {expirationDate}.',
  },
  repsInvitationisExpiringToday: {
    id: `${scope}.repsInvitationisExpiringToday`,
    defaultMessage: 'Invitation to {fullName} is expiring today.',
  },
  repsInvitationHasExpired: {
    id: `${scope}.repsInvitationHasExpired`,
    defaultMessage: 'Your invitation to {fullName} has expired.',
  },
  repsNewLessons: {
    id: `${scope}.repsNewLessons`,
    defaultMessage: 'New lessons are available!',
  },
  repsBuyerLimitChanged: {
    id: `${scope}.repsBuyerLimitChanged`,
    defaultMessage: 'Financing limit for your buyer {buyerName} has been changed to {limitAmount}!',
  },
  repsBuyerMarketingLimitChanged: {
    id: `${scope}.repsBuyerMarketingLimitChanged`,
    defaultMessage: 'Marketing limit for your buyer {buyerName} has been changed to {limitAmount}!',
  },
  repsOverduePayment30days: {
    id: `${scope}.repsOverduePayment30days`,
    defaultMessage: 'Payment for the invoice {invoiceNumber} is overdue for 30 days.',
  },
  repsOverduePayment7days: {
    id: `${scope}.repsOverduePayment7days`,
    defaultMessage: 'Payment for the invoice {invoiceNumber} is overdue for 7 days.',
  },
  repsOverduePaymentDueDate: {
    id: `${scope}.repsOverduePaymentDueDate`,
    defaultMessage: 'Payment for the invoice {invoiceNumber} is due today.',
  },
  repsInvoiceRepaid: {
    id: `${scope}.repsInvoiceRepaid`,
    defaultMessage: 'Invoice {invoiceNumber} has been repaid.',
  },
  repsDealFinanced: {
    id: `${scope}.repsDealFinanced`,
    defaultMessage: 'Deal {dealNumber} has been financed.',
  },
  repsDealSigned: {
    id: `${scope}.repsDealSigned`,
    defaultMessage: 'Deal {dealNumber} documents have been signed.',
  },
  repsDocumentSentToSignatures: {
    id: `${scope}.repsDocumentSentToSignatures`,
    defaultMessage: 'Deal {dealNumber} documents have been sent to authorised signatories.',
  },
  repsOutgoingPayment: {
    id: `${scope}.repsOutgoingPayment`,
    defaultMessage:
      'Outgoing payment #{paymentNumber} to {supplierName} in the amount of {currency} {paymentAmount} has been created.',
  },
});
