import { getDevFeaturesFromStorage } from '@app/components-new/DevFeatureToggleContainer/helpers/getDevFeaturesFromStorage';
import { EDevFeature } from '@app/components-new/DevFeatureToggleContainer/types';
import { ELocalStorageKey } from '@app/storage-keys';

export const toggleDevFeature = (feature: EDevFeature, shouldEnable: boolean): void => {
  const config = getDevFeaturesFromStorage();
  if (shouldEnable) {
    config[feature] = true;
    localStorage.setItem(ELocalStorageKey.DevFeatures, JSON.stringify(config));
  } else {
    config[feature] = false;
    localStorage.setItem(ELocalStorageKey.DevFeatures, JSON.stringify(config));
  }
};
