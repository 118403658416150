import { ComponentType, FC, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useGetProfileV2 } from '@app/api/profile/useGetProfileV2';
import { UserContextProvider } from '@app/context/UserContext/UserContextProvider';
import { routes } from '@app/routes/config';
import { CircularProgress, Stack } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';

export const withAuthenticated = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAuthenticated(props: P): ReactElement | null {
    const { isAuthenticated } = useAuth();
    const { data: profile, needToShowSocialConsent, isLoading } = useGetProfileV2();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    if (!isAuthenticated) {
      searchParams.append('next', location.pathname);
      return <Navigate to={`${routes.auth.login.path}?${searchParams.toString()}`} />;
    }

    if (isLoading) {
      return (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }

    if (needToShowSocialConsent) {
      return <Component {...props} />;
    }
    if (!profile) return null;

    return (
      <UserContextProvider id={profile.userId} name={profile.fullName} email={profile.email}>
        <Component {...props} />
      </UserContextProvider>
    );
  };
};
