import { FC, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from '@app/routes/config';
import { fillRoutePathWithParams } from '@app/utils/routing';

interface IDocusignRedirectProps {
  docusignOutcome?: 'success' | 'cancel' | 'declined';
}

const DocusignRedirect: FC<IDocusignRedirectProps> = ({ docusignOutcome }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dealId = searchParams.get('dealId') || '';

  useEffect(() => {
    navigate(fillRoutePathWithParams(routes.deals.dealDetails.path, { dealId }), {
      state: { docusignOutcome },
      replace: true,
    });
  }, [dealId, docusignOutcome, navigate]);

  return null;
};

export default DocusignRedirect;
