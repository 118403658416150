export const apiRoutes = {
  AdminGetStatusCommToolsReplications: '/Admin/GetStatusCommToolsReplications',
  AdminGetStatusOnboardingReplications: '/Admin/GetStatusOnboardingReplications',
  AdminGetStatusOperationsReplications: '/Admin/GetStatusOperationsReplications',
  BankDetailsCanCreate: '/BankDetails/CanCreate',
  BankDetailsCanCreateForSupplierSupplierId: '/BankDetails/CanCreateForSupplier/:supplierId',
  BankDetailsCreate: '/BankDetails/Create',
  BankDetailsCreateForSupplierSupplierId: '/BankDetails/CreateForSupplier/:supplierId',
  BankDetailsEdit: '/BankDetails/Edit',
  BankDetailsGet: '/BankDetails/Get',
  BankDetailsGetSwiftInfo: '/BankDetails/GetSwiftInfo',
  BuyerGetBuyer: '/Buyer/GetBuyer',
  BuyerGetBuyerOrderBy: '/Buyer/GetBuyerOrderBy',
  BuyerGetBuyersForCurrentSupplier: '/Buyer/GetBuyersForCurrentSupplier',
  BuyerGetBuyerStates: '/Buyer/GetBuyerStates',
  CommunicationRequestGetCommunicationRequestToken: '/CommunicationRequest/GetCommunicationRequestToken',
  CompanyAddOwnCompany: '/Company/AddOwnCompany',
  CounterpartyAddCounterparty: '/Counterparty/AddCounterparty',
  CounterpartyGetCounterpartyInfo: '/Counterparty/GetCounterpartyInfo',
  Deals: '/Deals',
  DealsCount: '/Deals/Count',
  DealsGetById: '/Deals/GetById',
  DealsGetDeal: '/Deals/GetDeal',
  DealsGetDeals: '/Deals/GetDeals',
  DealsGetDealsCount: '/Deals/GetDealsCount',
  DealsGetSignatureLinkForDealAttachment: '/Deals/GetSignatureLinkForDealAttachment',
  DealsSubmitNewDeal: '/Deals/SubmitNewDeal',
  DocumentsDownloadInvoiceAttachmentDocumentId: '/Documents/DownloadInvoiceAttachment/:documentId',
  GetAnonymous: '/GetAnonymous',
  GetCreateDealNoLimit: '/GetCreateDealNoLimit',
  GetUnauthorized: '/GetUnauthorized',
  GetViewGeneralInfo: '/GetViewGeneralInfo',
  InfoGetUserCountryInfo: '/Info/GetUserCountryInfo',
  InvitationResendInvitation: '/Invitation/ResendInvitation',
  Invoice: '/Invoice',
  InvoiceCount: '/Invoice/Count',
  InvoiceCreateInvoice: '/Invoice/CreateInvoice',
  InvoiceGetInvoice: '/Invoice/GetInvoice',
  InvoiceGetInvoices: '/Invoice/GetInvoices',
  InvoiceGetInvoicesCount: '/Invoice/GetInvoicesCount',
  InvoiceGetMyDraftInvoices: '/Invoice/GetMyDraftInvoices',
  InvoiceGetTradeRelationsWithInvoices: '/Invoice/GetTradeRelationsWithInvoices',
  InvoiceUpdateInvoice: '/Invoice/UpdateInvoice',
  LimitRequestLimit: '/Limit/RequestLimit',
  LinkToCompanyRequestApproveByCompany: '/LinkToCompanyRequest/ApproveByCompany',
  LinkToCompanyRequestApproveByStenn: '/LinkToCompanyRequest/ApproveByStenn',
  LinkToCompanyRequestCreateByUser: '/LinkToCompanyRequest/CreateByUser',
  LinkToCompanyRequestDeclineByCompany: '/LinkToCompanyRequest/DeclineByCompany',
  LinkToCompanyRequestDeclineByStenn: '/LinkToCompanyRequest/DeclineByStenn',
  LinkToCompanyRequestGetForCompany: '/LinkToCompanyRequest/GetForCompany',
  LinkToCompanyRequestGetForUser: '/LinkToCompanyRequest/GetForUser',
  NotificationDeleteDeleteId: '/Notification/Delete/Delete/:id',
  NotificationDeleteAllDeleteAll: '/Notification/DeleteAll/DeleteAll',
  NotificationGetNotificationsGetNotifications: '/Notification/GetNotifications/GetNotifications',
  NotificationNotificationsInfoNotificationsInfo: '/Notification/NotificationsInfo/NotificationsInfo',
  NotificationReadReadId: '/Notification/Read/Read/:id',
  NotificationReadAllReadAll: '/Notification/ReadAll/ReadAll',
  Profile: '/Profile',
  SearchSearchDuns: '/Search/SearchDuns',
  SupplierGetSupplier: '/Supplier/GetSupplier',
  SupplierGetSupplierOrderBy: '/Supplier/GetSupplierOrderBy',
  SupplierGetSuppliersForCurrentBuyer: '/Supplier/GetSuppliersForCurrentBuyer',
  SupplierGetSupplierStates: '/Supplier/GetSupplierStates',
  WizardAbortWizard: '/Wizard/AbortWizard',
  WizardCompleteStep: '/Wizard/CompleteStep',
  WizardCompleteWizard: '/Wizard/CompleteWizard',
  WizardContinueWizard: '/Wizard/ContinueWizard',
  WizardGetAllSteps: '/Wizard/GetAllSteps',
  WizardGetClientWizardInfo: '/Wizard/GetClientWizardInfo',
  WizardGetLastPassedStep: '/Wizard/GetLastPassedStep',
  WizardGetLastStep: '/Wizard/GetLastStep',
  WizardGetStep: '/Wizard/GetStep',
  WizardPauseWizard: '/Wizard/PauseWizard',
  WizardResetSteps: '/Wizard/ResetSteps',
  WizardSaveCompletedStep: '/Wizard/SaveCompletedStep',
  WizardSaveStep: '/Wizard/SaveStep',
  WizardSendActivityEvent: '/Wizard/SendActivityEvent',
} as const;
