import _get from 'lodash.get';
import PropTypes from 'prop-types';

const BurgerMenuIcon = ({ colors, ...restProps }) => {
  const strokeWidth = 2;
  const strokeColor = _get(colors, '0', '#BCB9CF');

  return (
    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <line x1="4" y1="12" x2="20" y2="12" stroke={strokeColor} strokeWidth={strokeWidth} />
      <line x1="4" y1="6" x2="20" y2="6" stroke={strokeColor} strokeWidth={strokeWidth} />
      <line x1="4" y1="18" x2="20" y2="18" stroke={strokeColor} strokeWidth={strokeWidth} />
    </svg>
  );
};

BurgerMenuIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default BurgerMenuIcon;
