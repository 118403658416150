import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

import { deleteInvoiceAction } from '@app/components-new/Flow/InvoicesList/InvoiceDetails/DeleteInvoiceModalContent/hooks';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

import { IDeleteInvoiceDialogContentProps } from './types';

export const DeleteInvoiceDialogContent: FC<IDeleteInvoiceDialogContentProps> = ({
  invoiceNumber,
  invoiceId,
  onClose,
  onDelete,
}) => {
  const { mutate: deleteInvoiceMutation, isLoading: isDeleteInvoiceLoading } = useMutation(deleteInvoiceAction, {
    onSuccess: () => {
      onDelete();
    },
  });

  const handleDeleteClick = () => {
    deleteInvoiceMutation(invoiceId);
  };
  return (
    <>
      <DialogTitle>
        <FormattedMessage id="components.Invoice.DeleteInvoiceDialogContent.title" />
      </DialogTitle>
      <DialogContent>
        <Typography.TextMd.Regular>
          <FormattedMessage
            id="components.Invoice.DeleteInvoiceDialogContent.description"
            values={{ InvoiceNumber: invoiceNumber }}
          />
        </Typography.TextMd.Regular>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" size="medium" onClick={onClose}>
          <FormattedMessage id="constants.universalMessages.cancel" />
        </Button>
        <Button
          variant="primary"
          size="medium"
          color="error"
          onClick={handleDeleteClick}
          isLoading={isDeleteInvoiceLoading}
          disabled={isDeleteInvoiceLoading}
        >
          <FormattedMessage id="constants.universalMessages.delete" />
        </Button>
      </DialogActions>
    </>
  );
};
