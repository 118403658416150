const decodeStringParam = (param = '') => {
  const value = decodeURIComponent(param.replace(/\+/g, ' '));

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value as unknown as number)) {
    return Number(value);
  }

  const lowerCaseValue = value.toLowerCase();

  switch (lowerCaseValue) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
    case 'undefined':
      // eslint-disable-next-line consistent-return
      return undefined;
    default:
      return value;
  }
};

export const getSearchParams = <T = Record<string, string | number | boolean | null>>(search = '') => {
  const cleanQuery = /^[?#]/.test(search) ? search.substring(1) : search;

  if (typeof cleanQuery !== 'string' || cleanQuery.trim().length === 0) {
    return {} as unknown as T;
  }

  const paramsList = cleanQuery.split('&');
  const params = paramsList.reduce(
    (acc, currentQuery) => {
      const [queryKey, queryValue] = currentQuery.split('=');

      if (typeof queryKey === 'string' && queryKey.trim().length) {
        const cleanValue =
          queryValue && queryValue.charAt(queryValue.length - 1) === '/' ? queryValue.slice(0, -1) : queryValue;

        acc[queryKey] = cleanValue ? decodeStringParam(cleanValue) : true;
      }

      return acc;
    },
    {} as Record<string, string | number | boolean | undefined | null>
  );

  return params as unknown as T;
};
