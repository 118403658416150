const getMessageKeysForTable = (tableData, scope = '') => {
  const result = {};

  tableData.forEach((rowItem, rowItemIndex) => {
    rowItem.forEach((cellItem, cellItemIndex) => {
      result[`tableRow${rowItemIndex}TableCell${cellItemIndex}`] = {
        ...cellItem,
        id: `${scope}.tableRow${rowItemIndex}TableCell${cellItemIndex}`,
      };
    });
  });

  return result;
};

export default getMessageKeysForTable;
