import { useQueryClient } from 'react-query';

import { NOTIFICATIONS_REQUESTS_KEYS, NOTIFICATION_CODE_VALUES, NOTIFICATION_MAX_LIMIT } from '@app/core/constants';
import { useSignalrSubscription } from '@app/signalr/useSignalrSubscription';
import { Notifications } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

import * as SC from './styles';

interface IProps {
  onClick: () => void;
  counter?: number;
}

const computeCountValueToRender = (counter?: number) => {
  if (!counter) {
    return 0;
  }
  if (counter > NOTIFICATION_MAX_LIMIT) {
    return `${NOTIFICATION_MAX_LIMIT}+`;
  }
  return counter;
};

const NotificationsBell = ({ onClick, counter = 0 }: IProps) => {
  const counterValue = computeCountValueToRender(counter);

  const queryClient = useQueryClient();

  useSignalrSubscription(Object.values(NOTIFICATION_CODE_VALUES), async () => {
    queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getCounts);
  });

  return (
    <Stack position="relative">
      <IconButton onClick={onClick} size="small">
        <Notifications />
      </IconButton>
      {/* @ts-expect-error TODO: fix this */}
      {(counterValue > 0 || !!counterValue) && (
        <SC.NotificationBadge newNotificationsCount={counter}>{counterValue}</SC.NotificationBadge>
      )}
    </Stack>
  );
};

export default NotificationsBell;
