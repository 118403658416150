export const CURRENCIES_BASIC = {
  Usd: {
    isoName: 'Usd',
    isoNumericCode: 840,
    symbol: '$',
  },
  Gbp: {
    isoName: 'Gbp',
    isoNumericCode: 826,
    symbol: '£',
  },
  Eur: {
    isoName: 'Eur',
    isoNumericCode: 978,
    symbol: '€',
  },
  Sek: {
    isoName: 'Sek',
    isoNumericCode: 752,
    symbol: 'kr',
  },
  Cad: {
    isoName: 'Cad',
    isoNumericCode: 124,
    symbol: 'C$',
  },
  Chf: {
    isoName: 'Chf',
    isoNumericCode: 756,
    symbol: '₣',
  },
  Rub: {
    isoName: 'Rub',
    isoNumericCode: 643,
    symbol: '₽',
  },
} as const;

export const CURRENCIES_OPS = {
  None: {
    isoName: 'None',
    isoNumericCode: 0,
    symbol: 'None',
  },
  MultiCurrency: {
    isoName: 'Multicurrency',
    isoNumericCode: 5,
    symbol: 'Multicurrency',
  },
} as const;
