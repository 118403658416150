function TelIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" fill="none" viewBox="0 0 13 14" {...props}>
      <path
        fill="#000"
        d="M11.898 9.154l-2.944-1.32-.008-.003a1 1 0 00-.995.122L6.429 9.25c-.963-.468-1.958-1.456-2.426-2.407L5.3 5.3a1 1 0 00.118-.99v-.007l-1.323-2.95A1 1 0 003.058.757 3.516 3.516 0 000 4.25c0 4.962 4.038 9 9 9a3.516 3.516 0 003.492-3.058 1 1 0 00-.594-1.038zM9 12.25a8.009 8.009 0 01-8-8 2.513 2.513 0 012.18-2.5v.007l1.312 2.938L3.2 6.24a1 1 0 00-.098 1.03c.566 1.158 1.733 2.316 2.904 2.881a1 1 0 001.03-.106l1.52-1.296 2.937 1.316h.007A2.513 2.513 0 019 12.25z"
      />
    </svg>
  );
}

export default TelIcon;
