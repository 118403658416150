import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { IProfileDto, IProfileWithNormalizedFeaturesModel } from '@app/core/models';

import { EProfileRequestKey } from '../constants';
import getProfileRequest from '../requests/getProfile';

const normalizedProfileFeaturesSelector = (profile: IProfileDto) => {
  const featuresNormalized = profile.features.reduce<IProfileWithNormalizedFeaturesModel['features']>(
    (featuresObject, feature) => {
      featuresObject[feature.name] = feature;
      return featuresObject;
    },
    {}
  );

  const profileWithNormalizedFeatures: IProfileWithNormalizedFeaturesModel = {
    ...profile,
    features: featuresNormalized,
  };
  return profileWithNormalizedFeatures;
};

const useGetProfile = () => {
  const isAuthorized = useSelector((state: { user: { isAuthorized: boolean } }) => state.user.isAuthorized);

  return useQuery([EProfileRequestKey.GetProfile, { isVerified: true }], getProfileRequest, {
    select: normalizedProfileFeaturesSelector,
    notifyOnChangeProps: ['data', 'error'],
    cacheTime: 0,
    enabled: isAuthorized,
  });
};
export default useGetProfile;
