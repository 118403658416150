import { ValueOf } from '@app/core/utilities';

export type TLang = ValueOf<typeof LANGUAGES_IDS>;

const LANGUAGES_IDS = {
  en: 'en',
  es: 'es',
  zhHans: 'zh-Hans',
} as const;

export type TLanguage = {
  id: TLang;
  shortName: string;
  languageCode: 'En' | 'Es' | 'ZhHans';
  name: string;
};

export const LANGUAGES: TLanguage[] = [
  {
    id: LANGUAGES_IDS.en,
    shortName: 'EN',
    languageCode: 'En',
    name: 'English',
  },
  {
    id: LANGUAGES_IDS.es,
    shortName: 'ES',
    languageCode: 'Es',
    name: 'Español',
  },
  {
    id: LANGUAGES_IDS.zhHans,
    shortName: '简体中文',
    languageCode: 'ZhHans',
    name: '简体中文',
  },
];
