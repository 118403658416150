import { FC, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { removeHubspotDoNotTrack, revokeHubspotCookieConsent, updateGTMconsent } from '@app/core/analytics';
import { Nova2ThemeWrapper } from '@app/core/layouts';
import { loadHeapOnce, setHeapIdentity, setHotjarIdentity } from '@app/hooks/useSetAnalyticsIdentity/helpers';
import { CookiesConsent } from '@stenngroup/ui-kit';
import { TLocale } from '@stenngroup/ui-kit/internationalization/types';
import { hasCookiesConsent, setCookiesConsent, setCookiesConsentIfGlobalPrivacyPolicySet } from '@stenngroup/utilities';

interface ICookiesConsentWrapperProps {
  userId?: string;
}

export const CookiesConsentWrapper: FC<ICookiesConsentWrapperProps> = ({ userId }) => {
  const { locale } = useIntl();
  const [isCookiesConsentWidgetShown, setIsCookiesConsentWidgetShown] = useState(false);

  useEffect(() => {
    setCookiesConsentIfGlobalPrivacyPolicySet(window._STENN_.ENVIRONMENT === 'live', navigator)();
    const hasConsent = hasCookiesConsent(window._STENN_.ENVIRONMENT === 'live')();
    setIsCookiesConsentWidgetShown(!hasConsent);
  }, []);

  if (!isCookiesConsentWidgetShown) return null;

  return (
    <Nova2ThemeWrapper>
      <CookiesConsent
        locale={locale as Exclude<TLocale, 'zh-Hant'>}
        onSave={(result) => {
          setCookiesConsent(window._STENN_.ENVIRONMENT === 'live')(result);
          const isTrackingEnabled = result.performance;
          if (isTrackingEnabled) {
            loadHeapOnce();
            setHeapIdentity(userId);
            setHotjarIdentity(userId);
            removeHubspotDoNotTrack();
          } else {
            revokeHubspotCookieConsent();
          }
          updateGTMconsent(isTrackingEnabled);
          setIsCookiesConsentWidgetShown(false);
        }}
      />
    </Nova2ThemeWrapper>
  );
};
