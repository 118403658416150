import { FormattedMessage } from 'react-intl';

import { EContactStatus } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';

export const TeamMemberStatusText: Record<EContactStatus, JSX.Element> = {
  [EContactStatus.Active]: <FormattedMessage id="components.personalSpace.contactStatus.active" />,
  [EContactStatus.Requesting]: <FormattedMessage id="components.personalSpace.contactStatus.requesting" />,
  [EContactStatus.NotInvited]: <FormattedMessage id="components.personalSpace.contactStatus.notInvited" />,
  [EContactStatus.Pending]: <FormattedMessage id="components.personalSpace.contactStatus.pending" />,
  [EContactStatus.Approved]: <FormattedMessage id="components.personalSpace.contactStatus.approved" />,
  [EContactStatus.Declined]: <FormattedMessage id="components.personalSpace.contactStatus.declined" />,
  [EContactStatus.Rejected]: <FormattedMessage id="components.personalSpace.contactStatus.rejected" />,
  [EContactStatus.AuthorizedSignatory]: (
    <FormattedMessage id="components.personalSpace.contactStatus.authorizedSignatory" />
  ),
};
