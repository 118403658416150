import { CheckCircleOutline } from '@mui/icons-material';
import React from 'react';
import { IToastConfig } from './types';

export enum EToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

interface IToastConfigConstant extends IToastConfig {
  anchorOrigin: NonNullable<IToastConfig['anchorOrigin']>;
}

export const DEFAULT_TOAST_CONFIG: IToastConfigConstant = {
  type: EToastType.Success,
  autoHideDuration: 5000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
};

export const DEFAULT_TOAST_CONFIG_MOBILE: IToastConfigConstant = {
  ...DEFAULT_TOAST_CONFIG,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
};

export const TOAST_ICON_MAPPING: Partial<Record<EToastType, React.ReactNode>> = {
  success: <CheckCircleOutline />,
};
