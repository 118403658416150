import _get from 'lodash.get';
import PropTypes from 'prop-types';

const BuyersIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <ellipse opacity="0.4" cx="8" cy="7" rx="3" ry="3" fill={_get(colors, '0', 'currentColor')} />
      <path
        opacity="0.4"
        d="M2 14C2 12.8954 2.89543 12 4 12H12C13.1046 12 14 12.8954 14 14V20H2V14Z"
        fill={_get(colors, '1', 'currentColor')}
      />
      <ellipse cx="16" cy="7" rx="3" ry="3" fill={_get(colors, '2', 'currentColor')} />
      <path
        d="M10 14C10 12.8954 10.8954 12 12 12H20C21.1046 12 22 12.8954 22 14V20H10V14Z"
        fill={_get(colors, '3', 'currentColor')}
      />
    </svg>
  );
};

BuyersIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default BuyersIcon;
