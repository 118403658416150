import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { HelpWithTooltip } from '@app/components-new/HelpWithTooltip';
import { TEST_IDS } from '@app/core/constants/testIds';
import Stack from '@mui/material/Stack';
import { Typography } from '@stenngroup/ui-kit';

export const DealTypeSelectorTitle: FC = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography.Body1SemiBold color={(t) => t.palette.text.primary} marginRight={1}>
        <FormattedMessage id="components.Flow.InvoiceForm.DealType.dealTypeTitle" />
      </Typography.Body1SemiBold>
      <HelpWithTooltip
        testId={TEST_IDS.dealTypeSelectorTitleTooltip}
        description={<FormattedMessage id="components.Flow.InvoiceForm.DealType.dealTypeDescription" />}
      />
    </Stack>
  );
};
