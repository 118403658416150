export const USER_LOGOUT = 'USER_LOGOUT';

export const SET_CURRENT_LANGUAGE_ID = 'SET_CURRENT_LANGUAGE_ID';

export const SET_MAIN_MENU_VISIBILITY = 'SET_MAIN_MENU_VISIBILITY';

export const SET_MOBILE_HEADER_VISIBILITY = 'SET_MOBILE_HEADER_VISIBILITY';

export const SET_CURRENT_BREAKPOINT = 'SET_CURRENT_BREAKPOINT';

export const SET_SIDEBAR_OPTIONS = 'SET_SIDEBAR_OPTIONS';

export const SHOW_SERVICE_MODAL = 'SHOW_SERVICE_MODAL';
export const HIDE_SERVICE_MODAL = 'HIDE_SERVICE_MODAL';

export const SHOW_FORM_MODAL = 'SHOW_FORM_MODAL';
export const HIDE_FORM_MODAL = 'HIDE_FORM_MODAL';

export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL';
export const HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION_MODAL';

export const SET_STEP_BACK_HANDLER = 'SET_STEP_BACK_HANDLER';
export const RESET_STEP_BACK_HANDLER = 'RESET_STEP_BACK_HANDLER';

export const PUSH_TO_SIMPLE_ROUTES_HISTORY = 'PUSH_TO_SIMPLE_ROUTES_HISTORY';

export const CHANGE_WEB_BROWSER_LANGUAGE_REQUEST = 'CHANGE_WEB_BROWSER_LANGUAGE_REQUEST';
export const CHANGE_WEB_BROWSER_LANGUAGE_SUCCESS = 'CHANGE_WEB_BROWSER_LANGUAGE_SUCCESS';
export const CHANGE_WEB_BROWSER_LANGUAGE_FAILURE = 'CHANGE_WEB_BROWSER_LANGUAGE_FAILURE';
