import { MOBILE_SCREEN } from '@app/core/constants';
import { useToggle } from '@app/core/hooks';
import { AlertTitle, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DEFAULT_TOAST_CONFIG, DEFAULT_TOAST_CONFIG_MOBILE, TOAST_ICON_MAPPING } from '../constants';
import { IToastApi, IToastManagerProps, IToastParams } from '../types';
import { ToastProvider } from './Provider';

import * as SC from './styles';

/**
 * Component with context to store toast api
 */
const ToastManager = ({ children }: IToastManagerProps) => {
  const [isOpen, toggle] = useToggle(false);
  const [toastParams, setToastParams] = useState<Partial<IToastParams>>(DEFAULT_TOAST_CONFIG);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(MOBILE_SCREEN));

  const close = () => {
    toggle(false);
  };

  const open = () => {
    toggle(true);
  };

  const apiRef = useRef<IToastApi>({
    close,
    open,
  });

  const { type, autoHideDuration, title, text, anchorOrigin } = toastParams;

  useEffect(() => {
    const anchorOriginComputed = isMobile
      ? DEFAULT_TOAST_CONFIG_MOBILE.anchorOrigin
      : DEFAULT_TOAST_CONFIG.anchorOrigin;

    setToastParams((prevParams) => ({
      ...prevParams,
      anchorOrigin: { ...anchorOriginComputed, ...anchorOrigin },
    }));
  }, [isMobile]);

  return (
    <ToastProvider apiRef={apiRef} setToastParams={setToastParams}>
      {children}
      <Snackbar open={isOpen} onClose={close} autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin}>
        <SC.Alert severity={type} iconMapping={TOAST_ICON_MAPPING}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <span>{text}</span>
        </SC.Alert>
      </Snackbar>
    </ToastProvider>
  );
};

export default ToastManager;
