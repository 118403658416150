import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { StepContainer } from '@app/components-new/StepContainer';

interface ITradePartnerInvitedProps {}
export const TradePartnerInvited: FC<ITradePartnerInvitedProps> = () => {
  const { handleNextStep } = useFlowTransitionsContext();
  const handleNext = () => {
    handleNextStep();
  };
  return (
    <StepContainer title={<FormattedMessage id="createDeal.inviteSent" />} onNext={handleNext}>
      <div>TradePartnerInvited</div>
    </StepContainer>
  );
};
