import { IAddSupplierDTO } from '@app/core/models';
import { Nullable } from '@app/core/utilities';
import _get from 'lodash.get';

export const prepareCompanyDataToCreateRequest = (requestData: {
  companyName: string;
  webSite?: string;
  registrationNumber?: string;
  duns?: Nullable<string>;
  countryId?: string;
  address: {
    street?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  };
  chineseCompanyName?: string;
  chineseSocialCreditCode?: string;
}): IAddSupplierDTO => ({
  name: requestData.companyName,
  address: {
    street: _get(requestData, 'address.street', null),
    city: _get(requestData, 'address.city', null),
    state: _get(requestData, 'address.state', null),
    postalCode: _get(requestData, 'address.postalCode', null),
    country: _get(requestData, 'countryId', null),
  },
  webSite: requestData.webSite || null,
  registrationNumber: requestData.registrationNumber || null,
  duns: requestData.duns || null,
  chineseCompanyName: requestData.chineseCompanyName || null,
  chineseSocialCreditCode: requestData.chineseSocialCreditCode || null,
});
