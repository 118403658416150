const InfoAltIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="9" stroke="#5C7EE8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path
      stroke="#5C7EE8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.889 15.889h2.566M12.177 15.889v-4.722h-1.278M12.111 7.83a.278.278 0 11-.277-.277M11.833 7.553c.154 0 .278.124.278.277"
    />
  </svg>
);

export default InfoAltIcon;
