import _get from 'lodash.get';
import PropTypes from 'prop-types';

const CommissionsIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 16.9183 7.58172 20.5 12 20.5ZM14.5352 9.03516L8.51953 15.0508L9.44849 15.9797L15.4641 9.96411L14.5352 9.03516ZM11 10C11 10.8284 10.3284 11.5 9.5 11.5C8.67157 11.5 8 10.8284 8 10C8 9.17157 8.67157 8.5 9.5 8.5C10.3284 8.5 11 9.17157 11 10ZM14.5 16.5C15.3284 16.5 16 15.8284 16 15C16 14.1716 15.3284 13.5 14.5 13.5C13.6716 13.5 13 14.1716 13 15C13 15.8284 13.6716 16.5 14.5 16.5Z"
        fill={_get(colors, '0', 'currentColor')}
      />
    </svg>
  );
};

CommissionsIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default CommissionsIcon;
