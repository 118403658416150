// use await when calling this helper
export const parseResponse = (response: Response) => {
  const contentType = response.headers.get('Content-Type');

  if (contentType) {
    switch (true) {
      case contentType.includes('json'):
      case contentType.includes('text'):
        return response.text().then((text) => {
          try {
            return JSON.parse(text);
          } catch {
            return text;
          }
        });

      default:
        return response.blob();
    }
  }

  return response;
};
