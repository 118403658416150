import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { QueryStatus } from 'react-query';

import { EDealTradeRole } from '@app/components-new/FlowTransitions/context/types';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { truncateText } from '@app/utils/truncateText';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

interface IHeaderProps {
  tradeRole: EDealTradeRole | null;
  invoicesCount: number;
  buyerName: string | undefined;
  supplierName: string | undefined;
  loadingState: QueryStatus;
}

export const Header: FC<IHeaderProps> = ({ tradeRole, invoicesCount, buyerName, supplierName, loadingState }) => {
  const theme = useTheme();
  const tradePartner = match(tradeRole)
    .with(EDealTradeRole.Supplier, () => buyerName)
    .with(EDealTradeRole.Buyer, () => supplierName)
    .otherwise(() => null);
  return (
    <Stack direction="row" gap={1} flexGrow={1} width="100%">
      <Stack gap={1} flexGrow={1}>
        <Typography.TextMd.SemiBold align="center">
          <FormattedMessage id="createDeal.reviewDeal.yourRole" />
        </Typography.TextMd.SemiBold>
        <TextSkeleton
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
          }}
          status={loadingState}
          style={{ height: calculateTypographyHeight(theme.typography.body1) }}
        >
          <Typography.TextSm.Regular align="center">
            {match(tradeRole)
              .with(EDealTradeRole.Supplier, () => <FormattedMessage id="constants.universalMessages.supplier" />)
              .with(EDealTradeRole.Buyer, () => <FormattedMessage id="constants.universalMessages.buyer" />)
              .otherwise(() => null)}
          </Typography.TextSm.Regular>
        </TextSkeleton>
      </Stack>
      <Stack gap={1} flexGrow={1}>
        <Typography.TextMd.SemiBold align="center">
          <FormattedMessage id="createDeal.reviewDeal.tradePartner" />
        </Typography.TextMd.SemiBold>
        <TextSkeleton
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
          }}
          status={loadingState}
          style={{ height: calculateTypographyHeight(theme.typography.body1) }}
        >
          <Typography.TextSm.Regular align="center">{truncateText(tradePartner ?? '', 30)}</Typography.TextSm.Regular>
        </TextSkeleton>
      </Stack>
      <Stack gap={1} flexGrow={1}>
        <Typography.TextMd.SemiBold align="center">
          <FormattedMessage id="createDeal.reviewDeal.invoices" />
        </Typography.TextMd.SemiBold>
        <TextSkeleton
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
          }}
          status={loadingState}
          style={{ height: calculateTypographyHeight(theme.typography.body1) }}
        >
          <Typography.TextSm.Regular align="center">{invoicesCount}</Typography.TextSm.Regular>
        </TextSkeleton>
      </Stack>
    </Stack>
  );
};
