const SimpleTable = ({ messageKeysForTable, render }) => {
  let prevNumberRow;
  let preparedTableRows = {};

  Object.keys(messageKeysForTable).forEach((key) => {
    const matchedPosition = key.indexOf('TableCell');
    const numberRow = Number(key.substring(matchedPosition, 0).match(/\d/g).join(''));

    if (numberRow === prevNumberRow) {
      preparedTableRows = {
        ...preparedTableRows,
        [`row${numberRow}`]: [
          ...preparedTableRows[`row${numberRow}`],
          { key, cellType: messageKeysForTable[key].cellType },
        ],
      };
    } else {
      preparedTableRows = {
        ...preparedTableRows,
        [`row${numberRow}`]: [{ key, cellType: messageKeysForTable[key].cellType }],
      };
    }

    prevNumberRow = numberRow;
  });

  return render(preparedTableRows);
};

export default SimpleTable;
