import { ErrorLogger, LOG_ENTRY_TYPES } from '@app/core/logger';
import Cookies, { CookieAttributes } from 'js-cookie';

import { BaseStorageService, IStorage } from './BaseStorageService';
import { getCookiesDomain } from './utils';

const EXPIRE_DAYS = 30;
const COOKIES_DOMAIN = getCookiesDomain();
const DEFAULT_COOKIES_OPTIONS = {
  expires: EXPIRE_DAYS,
  domain: COOKIES_DOMAIN,
};

const setCookies = (key: string, value: string, options: CookieAttributes = {}) => {
  try {
    Cookies.set(key, value, { ...DEFAULT_COOKIES_OPTIONS, ...options });
  } catch (error: any) {
    const customMessage = `Failed to set cookie. Name: ${key}, Value: ${value}`;

    Object.assign(error, {
      message: `${error.message}, ${customMessage}`,
    });

    ErrorLogger.send(error, { errorType: LOG_ENTRY_TYPES.script });
  }
};

const storage: IStorage = {
  set: setCookies,
  get: (key: string) => Cookies.get(key) || null,
  has: (key: string) => Cookies.get(key) !== undefined,
  delete: (key, domain?: string) => {
    Cookies.remove(key, { domain: domain || COOKIES_DOMAIN });
  },
  getAll: () => Cookies.get(),
};

class CookiesStorageServiceBase extends BaseStorageService {
  setValueWithOptions = (key: string, value: string, options: CookieAttributes) => {
    const property = this.getProperty(key);

    setCookies(property, value, options);
  };

  deleteByPrefixes = (prefixes: string[], domain?: string) => {
    const allCookies = this.storage.getAll();

    for (const key of Object.keys(allCookies)) {
      const containPrefix = prefixes.some((prefix) => key.startsWith(prefix));

      if (containPrefix) {
        Cookies.remove(key, { domain });
      }
    }
  };
}

export const CookiesStorageService = new CookiesStorageServiceBase(storage);
