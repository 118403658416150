/* eslint-disable camelcase */
import { IntlShape } from 'react-intl';

import { backendErrorMessages, universalMessages } from '@app/core/internationalization';

import { getLocalTranslatedMessageId } from './getLocalTranslatedMessageId';

export const getBackendErrorByCode = ({
  intl,
  errorCode,
  errorParameters = [],
}: {
  intl: IntlShape;
  errorCode: number | string;
  errorParameters?: NonNullable<unknown>[];
}) => {
  const { formatMessage } = intl;
  const messageId = getLocalTranslatedMessageId(errorCode);
  const preparedErrorParameters = Array.isArray(errorParameters)
    ? errorParameters.reduce((acc, current, index) => Object.assign(acc, { [`param${index}`]: current }), {})
    : {};
  const message = messageId
    ? backendErrorMessages[messageId as keyof typeof backendErrorMessages]
    : universalMessages.somethingWentWrongSupportTeamAlreadyNotified;
  return formatMessage(message, {
    ...preparedErrorParameters, // { param0: 'value', param1: 'value' }
  });
};
