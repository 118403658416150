export const AUTH_STATES = {
  IN_PROGRESS: 'InProgress',
  UNAUTHENTICATED: 'Unauthenticated',
  NOT_ACTIVATED: 'NotActivated',
  NOT_ACTIVATED_ERROR: 'NotActivatedError',
  AUTHENTICATED: 'Authenticated',
  AUTHENTICATION_FINISHED: 'AuthenticationFinished',
  ERROR: 'Error',
};

export const AUTH_API_SCOPES = {
  EMAIL_ACCOUNT_IN_VERIFICATION: 'emailAccountInVerification',
  EMAIL_ACCOUNT_NOT_ACTIVATED_IN_STENN: 'emailAccountNotActivatedInStenn',
  EMAIL_ACCOUNT_NOT_CREATED_IN_STENN: 'emailAccountNotCreatedInStenn',
  SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN: 'socialAccountNotActivatedInStenn',
  PHONE_ACCOUNT_NOT_ACTIVATED_IN_STENN: 'phoneAccountNotActivatedInStenn',
};

export const REGISTRATION_TYPES = {
  email: 'Email',
  social: 'Social',
  phone: 'Phone',
};

export const AUTH_ERRORS = {
  PHONE_OR_CODE_IS_WRONG: {
    code: 'property missed',
    field: 'phone',
    description: 'Wrong phone number or verification code',
  },
  SIGN_UP_DATA_FAIL: {
    code: 'incorrect data',
    description: 'Looks like you already registered in Stenn. Please login',
  },
  PASSWORD_IS_WEAK: {
    code: 'incorrect data',
    description: 'Password is too weak',
  },
  UNEXPECTED_ERROR: {
    code: 'unexpected error',
    description: 'Unexpected error',
  },
  USER_ALREADY_EXISTS: {
    code: 'incorrect data',
    description: 'This user already exists',
  },
  INVITATION_NOT_FOUND: {
    code: 'incorrect data',
    description: 'This invitation in not valid',
  },
  ACCOUNT_NOT_FOUND: {
    code: 'incorrect data',
    description:
      'We couldn’t find an account with that email address. Would you like to sign up for a new Stenn App account?',
  },
  SHOULD_LOGIN_WITH_PHONE: {
    code: 'incorrect data',
    description: 'Please log in with your phone number',
  },
  SHOULD_LOGIN_WITH_GOOGLE: {
    code: 'incorrect data',
    description: 'Please log in with your Google social account',
  },
  SHOULD_LOGIN_WITH_FACEBOOK: {
    code: 'incorrect data',
    description: 'Please log in with your Facebook social account',
  },
  SHOULD_LOGIN_WITH_APP_CREDENTIALS: {
    code: 'unauthorized',
    description: 'Please log in with App account',
  },
};

export enum EAuthenticationErrorCode {
  CodeInvalid = '3017',
  CodeExpired = '3018',
  CodePasswordNotSignedUp = '3051',
  WrongPasswordCode = 'invalid_grant',
  UserNotFound = 404,
}
