export const LEVEL_CODES = {
  fatal: 'Fatal',
  information: 'Information',
} as const;

export const TEMPLATES = {
  exception: 'Exception',
  error: 'Error',
  badRequest: 'Bad request',
  unavailable: 'Unavailable',
  dataNotReady: 'Data is not ready',
  performance: 'Performance',
} as const;

export const LOG_ENTRY_TYPES = {
  render: 'render',
  script: 'script',
  auth: 'auth',
  api: 'api',
  network: 'network',
  routing: 'routing',
  performance: 'performance',
} as const;
