import { CSSProperties } from 'react';

export const calculateTypographyHeight = (typography: CSSProperties) => {
  const DEFAULT_FONT_SIZE = 14;
  const DEFAULT_LINE_HEIGHT = 1.25;
  const fontSize = parseFloat(String(typography.fontSize ?? `${DEFAULT_FONT_SIZE}px`));

  const lineHeightValue = String(typography.lineHeight ?? `${DEFAULT_LINE_HEIGHT * 100}%`);

  const lineHeight = parseFloat(lineHeightValue);
  if (Number.isNaN(fontSize) || Number.isNaN(lineHeight)) {
    return DEFAULT_FONT_SIZE * DEFAULT_LINE_HEIGHT;
  }

  if (lineHeightValue.includes('px')) {
    return parseFloat(lineHeightValue);
  }

  if (lineHeightValue.includes('%')) {
    const parsedLineHeight = parseFloat(lineHeightValue.replace('%', ''));
    const lineHeight = parsedLineHeight > 1 ? parsedLineHeight / 100 : parsedLineHeight;
    return fontSize * lineHeight;
  }

  const height = fontSize * lineHeight;
  return height;
};
