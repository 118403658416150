import _get from 'lodash.get';
import PropTypes from 'prop-types';

const SuccessIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="m6 13c-.3 0-.5-.1-.7-.3l-5-5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4.3 4.3 10.3-10.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-11 11c-.2.2-.4.3-.7.3z"
        fill={_get(colors, '0', 'currentColor')}
      />
    </svg>
  );
};

SuccessIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default SuccessIcon;
