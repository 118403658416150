import { useIntl } from 'react-intl';

import { isValidBeneficiaryNameLength } from '@app/core/utilities';
import { isOnlyNumberOrLetter, isSwift, isValidSwiftEnding, withoutSymbols } from '@stenngroup/utilities';
import * as yup from 'yup';

export const useBankDetailsValidationSchema = (): yup.Schema => {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    swift: yup
      .string()
      .transform((value) => value.toUpperCase())
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterYourSWIFT',
        })
      )
      .test(
        'isValidSwift',
        formatMessage({
          id: 'constants.frontendErrors.invalidSwift',
        }),
        isSwift
      )
      .test(
        'isValidEnding',
        formatMessage({
          id: 'constants.frontendErrors.invalidSwiftEnding',
        }),
        isValidSwiftEnding
      ),
    bankName: yup.string().required(
      formatMessage({
        id: 'constants.frontendErrors.enterBankName',
      })
    ),
    currency: yup.number().required(
      formatMessage({
        id: 'constants.frontendErrors.selectCurrency',
      })
    ),
    beneficiaryAccountNumber: yup
      .string()
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterBeneficiaryAccountNumber',
        })
      )
      .test(
        'hasOnlyNumbersOrLetters',
        formatMessage({
          id: 'constants.frontendErrors.invalidBeneficiaryAccountNumber',
        }),
        isOnlyNumberOrLetter
      ),
    beneficiaryName: yup
      .string()
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterBeneficiaryName',
        })
      )
      .test(
        'isValidBeneficiary',
        formatMessage({
          id: 'constants.frontendErrors.invalidBeneficiaryName',
        }),
        withoutSymbols
      )
      .test(
        'isValidBeneficiaryNameLength',
        formatMessage({
          id: 'constants.frontendErrors.invalidBeneficiaryNameLength',
        }),
        isValidBeneficiaryNameLength
      ),
    beneficiaryAddress1: yup
      .string()
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterBeneficiaryAddress1',
        })
      )
      .test(
        'isValidAddress',
        formatMessage({
          id: 'constants.frontendErrors.invalidBeneficiaryAddress1',
        }),
        withoutSymbols
      ),
    beneficiaryAddress2: yup
      .string()
      .optional()
      .test(
        'isValidAddress',
        formatMessage({
          id: 'constants.frontendErrors.invalidBeneficiaryAddress2',
        }),
        (value) => (value ? withoutSymbols(value) : true)
      ),
  });
};
