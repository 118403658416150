import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@stenngroup/ui-kit';

interface IProps {
  name: string;
  address: string;
  duns: string;
}

export const CompanyDetails: FC<IProps> = ({ name, address, duns }) => (
  <Stack spacing={1}>
    <Typography.Body2SemiBold>{name}</Typography.Body2SemiBold>

    <Stack spacing={0.5}>
      <Typography.TextSm.Regular color="text.secondary">{address}</Typography.TextSm.Regular>
      <Typography.TextSm.Regular color="text.secondary">
        <FormattedMessage
          id="createDeal.requestLimit.companyDetails.duns"
          values={{
            duns,
          }}
        />
      </Typography.TextSm.Regular>
    </Stack>
  </Stack>
);
