import { TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto } from '@app/core/__generated__';
import { EDocumentType } from '@app/core/constants/files';

import { IEditInvoiceForm } from '../../types';

interface IOldDocument {
  id: string;
  isUploaded: boolean;
}

export const formatToDto = (
  invoiceId: string,
  trId: string,
  params: IEditInvoiceForm
): TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto => {
  const oldDocuments: IOldDocument[] = [
    {
      id: params.invoiceDocument?.documentId || '',
      isUploaded: !!params.invoiceDocument?.isUploaded,
    },
    {
      id: params.transportDocument?.documentId || '',
      isUploaded: !!params.transportDocument?.isUploaded,
    },
    ...params.otherDocuments.map((doc) => ({ id: doc.documentId, isUploaded: !!doc.isUploaded })),
  ];

  const documents = [
    {
      documentType: EDocumentType.CommercialInvoice,
      documentId: params.invoiceDocument?.documentId ?? '',
      isUploaded: !!params.invoiceDocument?.isUploaded,
    },
    {
      documentType: EDocumentType.TransportDocument,
      documentId: params.transportDocument?.documentId ?? '',
      isUploaded: !!params.transportDocument?.isUploaded,
    },
    ...params.otherDocuments.map((doc) => ({
      documentType: EDocumentType.Other,
      documentId: doc.documentId,
      isUploaded: !!doc.isUploaded,
    })),
  ];
  return {
    invoiceId,
    invoice: {
      number: params.number,
      tradeRelationId: trId,
      dueDate: params?.dueDate?.toISOString(),
      amount: parseFloat(params.faceValueNationalCurrency),
      currencyIsoNumericCode: Number(params.currency),
      isCommodity: params.isCommodity,
      isPerishable: params.isPerishable,
    },
    documents: documents.filter(
      (doc) => !doc.isUploaded
    ) as TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto['documents'],
    oldDocumentIds: oldDocuments.filter((doc) => doc.isUploaded && doc.id).map((doc) => doc.id),
  };
};
