import { FetchError } from '@app/api/lib/FetchError';
import { BASE_API_URLS } from '@app/core/api/constants';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';
import { IProfileDto } from '@app/core/models';

export const getProfileRequest = async (params: {
  configuredFetch: typeof fetch;
  token: string | undefined;
  signal: AbortSignal | undefined;
}): Promise<IProfileDto> => {
  const { configuredFetch, token, signal } = params;

  const headers: HeadersInit = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  let res: Response;
  try {
    res = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Users/profile`, {
      signal,
      headers,
    });
  } catch (error) {
    throw new FetchError(error as Error);
  }

  if (!res.ok) throw await HttpClientError.fromResponse(res);

  return res.json();
};

export const getUnverifiedProfileRequest = async (params: {
  configuredFetch: typeof fetch;
  token: string | undefined;
  signal: AbortSignal | undefined;
}): Promise<IProfileDto> => {
  const { configuredFetch, token, signal } = params;

  const headers: HeadersInit = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  let res: Response;
  try {
    res = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Users/ProfileForNotVerifiedUser`, {
      signal,
      headers,
    });
  } catch (error) {
    throw new FetchError(error as Error);
  }

  if (!res.ok) throw await HttpClientError.fromResponse(res);

  return res.json();
};
