const DotSolid = (props: any) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.99967 12.1663C10.9201 12.1663 11.6663 11.4201 11.6663 10.4997C11.6663 9.5792 10.9201 8.83301 9.99967 8.83301C9.0792 8.83301 8.33301 9.5792 8.33301 10.4997C8.33301 11.4201 9.0792 12.1663 9.99967 12.1663Z"
      fill="currentColor"
    />
  </svg>
);

export default DotSolid;
