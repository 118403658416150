import PropTypes from 'prop-types';

const PREFIX = 'img';
/**
 * "./assets" folder if provided by NX configuration file.
 * You can find it here: apps/core/project.json (targets > build > options > assets).
 */
const PATH_TO_FLAGS_SPRITE = './assets/flags.svg';

/**
 * @deprecated
 */
export const FlagIcon = ({ id, width = 20, height = 15 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    preserveAspectRatio="xMinYMin"
    width={width}
    height={height}
    viewBox="0 0 640 480"
  >
    <use xlinkHref={`${PATH_TO_FLAGS_SPRITE}#${PREFIX}-${id}`} />
  </svg>
);

FlagIcon.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
