import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { OptionalValue } from '@app/components-new/OptionalValue';
import {
  ICompaniesListProps,
  TCompany,
} from '@app/components-new/SelectCompany/CompaniesListContainer/CompaniesList/types';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { TEST_IDS } from '@app/core/constants';
import { formatAddress } from '@app/utils/formatAddress';
import { truncateText } from '@app/utils/truncateText';
import { Stack, useTheme } from '@mui/material';
import { Chip, ChoiceBox, Typography } from '@stenngroup/ui-kit';

const CONTAINER_HEIGHT = 450;

const CompanyDetails: FC<Partial<TCompany> & { isLoading?: boolean }> = ({
  address,
  duns,
  isLoading,
  operationStatus,
}) => {
  const theme = useTheme();
  return (
    <Stack gap={0.5}>
      <TextSkeleton
        status={isLoading ? 'loading' : 'idle'}
        style={{
          height: calculateTypographyHeight(theme.typography.body2),
        }}
      >
        <Typography.Body2>
          {formatAddress({
            street: address?.street,
            state: address?.state,
            city: address?.city,
            postalCode: address?.postalCode,
            countryCode: address?.countryCode,
          })}
        </Typography.Body2>
      </TextSkeleton>
      <Stack direction="row" gap={0.5}>
        <TextSkeleton
          status={isLoading ? 'loading' : 'idle'}
          style={{
            height: calculateTypographyHeight(theme.typography.body2),
          }}
        >
          <Typography.Body2>
            DUNS: <OptionalValue text={duns} />
          </Typography.Body2>
        </TextSkeleton>
        {operationStatus === 'OutOfBusiness' && (
          <Chip
            color="warning"
            size="small"
            variant="secondary"
            label={
              <Typography.Body2>
                <FormattedMessage id="containers.BuyerSupplierFlow.steps.AddYourCompany.outOfBusiness" />
              </Typography.Body2>
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export const CompaniesList: FC<ICompaniesListProps> = ({ companies, selectedCompany, onCompanySelect }) => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1}>
      {companies.map((company) => (
        <ChoiceBox
          key={company.duns}
          label={truncateText(company.companyName ?? '', 30)}
          text={
            <CompanyDetails address={company.address} duns={company.duns} operationStatus={company.operationStatus} />
          }
          type="radio"
          name="company"
          value={company.duns}
          checked={selectedCompany?.duns === company.duns}
          onChange={() => onCompanySelect?.(company)}
          fullWidth
          data-testid={TEST_IDS.companyItem}
          disabled={company.operationStatus === 'OutOfBusiness'}
        />
      ))}
    </Stack>
  );
};

export const CompaniesListSkeleton = () => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1} height={CONTAINER_HEIGHT} overflow="auto">
      {Array.from({ length: 5 }).map((_, index) => (
        <ChoiceBox
          key={index}
          label={
            <TextSkeleton status="loading">
              <Typography.Body1 />
            </TextSkeleton>
          }
          text={<CompanyDetails isLoading />}
          type="radio"
          name="company"
          value=""
          fullWidth
          data-testid={TEST_IDS.companyItem}
          disabled
        />
      ))}
    </Stack>
  );
};
