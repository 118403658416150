import { BASE_API_URLS } from '@app/core/api';
import { TUnionApiResponse } from '@app/ts-types/apiResponse';

interface IEditContactPayload {
  configuredFetch: typeof fetch;
  payload: {
    firstName: string;
    lastName: string;
    email: string;
    position: string;
    phone?: string;
    contactId: string;
  };
}

export const editContactRequest = async ({
  configuredFetch,
  payload,
}: IEditContactPayload): Promise<TUnionApiResponse<boolean>> => {
  const { contactId, ...contactData } = payload;
  const response = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Contacts/editContact/${contactId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contactData),
  });
  if (!response.ok) {
    return {
      type: 'error',
    };
  }
  return {
    type: 'success',
    data: true,
  };
};
