import { FC } from 'react';

import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

interface IEmptyListProps {
  messageId: MessageDescriptor['id'];
}

export const EmptyList: FC<IEmptyListProps> = ({ messageId }) => {
  return (
    <Stack padding={1}>
      <Typography.Body1 color={(t) => t.palette.text.secondary}>
        <FormattedMessage id={messageId} />
      </Typography.Body1>
    </Stack>
  );
};
