import { type FC, type MouseEvent, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useGetProfileV2 } from '@app/api/profile/useGetProfileV2';
import { isDevFeatureEnabled } from '@app/components-new/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@app/components-new/DevFeatureToggleContainer/types';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EDealTradeRole, EFlowStep, EFlowType } from '@app/components-new/FlowTransitions/context/types';
import { TEST_IDS } from '@app/core/constants';
import { DashboardNavItems } from '@app/layouts/DashboardLayout/NavItems';
import { IDashboardLayoutProps } from '@app/layouts/DashboardLayout/types';
import { routes } from '@app/routes/config';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { Stack } from '@mui/material';
import {
  Menu,
  MenuItem,
  SideNavFabButton,
  SideNavItemV2,
  SideNavItemsListV2,
  SideNavSectionV2,
  SideNavSectionsV2,
  SideNavV2,
} from '@stenngroup/ui-kit';

import { DashboardHeader } from './Header';

export const DashboardLayout: FC<IDashboardLayoutProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data } = useGetProfileV2();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const isNewDealFlowEnabled = isDevFeatureEnabled(EDevFeature.NEW_DEAL_FLOW);
  const { startFlow } = useFlowTransitionsContext();
  const currentLocation = useLocation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isNewDealFlowEnabled) {
      startFlow({
        initialStep: EFlowStep.TRADE_ROLE,
        initialState: {
          tradeRole: EDealTradeRole.Unknown,
          flowType: EFlowType.CreateDeal,
          trId: '',
          myCompanyId: '',
          counterpartyCompanyId: '',
        },
      });
      return;
    }
    const { hasBuyerRole, hasSupplierRole } = data ?? {};
    const bothRolesAvailable = hasBuyerRole && hasSupplierRole;
    const bothRolesUnavailable = !hasBuyerRole && !hasSupplierRole;
    if (bothRolesAvailable || bothRolesUnavailable || hasBuyerRole) {
      setAnchorEl(event.currentTarget);
      return;
    }
    if (hasSupplierRole) {
      navigate(routes.supplierFlow.path);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentPath = currentLocation.pathname;

  const handleSupplierRoleClick = () => {
    navigate(routes.supplierFlow.path);
  };

  const handleBuyerRoleClick = () => {
    navigate(routes.buyerFlow.path);
  };

  return (
    <Stack
      direction="row"
      flexGrow={1}
      sx={(t) => ({
        [t.breakpoints.up('md')]: {
          height: '100%',
          flexDirection: 'row',
          flexGrow: 1,
        },
        [t.breakpoints.down('md')]: {
          height: '100%',
          flexDirection: 'row',
        },
      })}
    >
      <SideNavV2
        expandOnHover={false}
        isExpandedDefault={false}
        fab={
          <>
            <SideNavFabButton
              onClick={handleClick}
              size="medium"
              fullWidth
              variant="secondary"
              startIcon={<ControlPointRoundedIcon />}
              data-testid={TEST_IDS.dashboardLayoutAddDealButton}
            >
              <FormattedMessage id="components.AddDealButton.newDeal" />
            </SideNavFabButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  minWidth: 191,
                  '& .MuiButtonBase-root': {
                    height: 36,
                  },
                },
              }}
            >
              <MenuItem data-testid={TEST_IDS.dashboardLayoutAsABuyerMenuItem} onClick={handleBuyerRoleClick}>
                <FormattedMessage id="constants.universalMessages.asABuyer" />
              </MenuItem>
              <MenuItem data-testid={TEST_IDS.dashboardLayoutAsASupplierMenuItem} onClick={handleSupplierRoleClick}>
                <FormattedMessage id="constants.universalMessages.asASupplier" />
              </MenuItem>
            </Menu>
          </>
        }
      >
        <SideNavSectionsV2>
          <SideNavSectionV2>
            <SideNavItemsListV2>
              {DashboardNavItems.map(({ path, title, Icon }) => (
                <SideNavItemV2
                  key={path}
                  component={Link}
                  to={path}
                  isActive={currentPath.startsWith(path)}
                  title={title}
                  icon={<Icon fontSize="inherit" />}
                  data-testid={path.replace(/\//g, '')}
                />
              ))}
            </SideNavItemsListV2>
          </SideNavSectionV2>
        </SideNavSectionsV2>
      </SideNavV2>
      <Stack marginLeft={-2} height="100%" minHeight="100vh" component="main" flex="1 1 auto" maxWidth="100%">
        <DashboardHeader />

        <Stack paddingY={2} paddingX={2} bgcolor={(t) => t.palette.background.level1} flexGrow={1}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
