import type { FC, FormEvent } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { TStennClientsServiceApplicationPortModelsBankDetailsBankDetailCurrency } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormCurrencySelectFieldNew,
  FormTextField,
  ICurrencySelectOption,
  Stack,
  Typography,
} from '@stenngroup/ui-kit';
import { isSwift, isValidSwiftEnding } from '@stenngroup/utilities';

import { useBankDetailsValidationSchema } from './schema';
import { TFormValues, TSubmitData } from './types';

interface IProps {
  defaultCurrency: number | undefined;
  currencyOptions: ICurrencySelectOption[];
  onSubmit: (data: TSubmitData) => void;
}

const isValidSwift = (newSwift: string): boolean => [isSwift, isValidSwiftEnding].every((fn) => fn(newSwift));

export const AddBankDetailsForm: FC<IProps> = ({ defaultCurrency, currencyOptions, onSubmit }) => {
  const intl = useIntl();
  const httpClient = useHttpClient();
  const schema = useBankDetailsValidationSchema();
  const form = useForm<TFormValues>({
    defaultValues: {
      currency: defaultCurrency,
      swift: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const swiftValue = form.watch('swift');

  const { data, status, isLoading } = useQuery({
    queryKey: [apiRoutes.BankDetailsGetSwiftInfo, swiftValue],
    queryFn: ({ signal }) =>
      httpClient(apiRoutes.BankDetailsGetSwiftInfo, 'get', {
        query: {
          swift: swiftValue,
        },
        signal,
      }),
    enabled: isValidSwift(swiftValue),
    onSuccess(response) {
      if (response?.bankName) {
        form.setValue('bankName', response.bankName);
        form.clearErrors(['bankName', 'swift']);
      }
    },
    onError(error) {
      if (error instanceof HttpClientError && error.status === 400) {
        form.setError('swift', {
          message: intl.formatMessage({
            id: 'constants.frontendErrors.invalidSwift',
          }),
        });
      }
    },
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (form.getFieldState('swift').invalid || isLoading) {
      return;
    }

    return form.handleSubmit((data) => {
      const currency = currencyOptions.find((item) => item.value === data.currency);

      onSubmit({
        ...data,
        currency: currency?.title as TStennClientsServiceApplicationPortModelsBankDetailsBankDetailCurrency,
      });
    })(event);
  };

  return (
    <form id="bankDetailsForm" onSubmit={handleSubmit}>
      <Stack gap={2}>
        <Box>
          <Typography.TextMd.Medium>
            <FormattedMessage id="constants.universalMessages.bankDetails" />
          </Typography.TextMd.Medium>

          <Stack gap={2} mt={1.5} mb={2}>
            <FormTextField
              name="beneficiaryAccountNumber"
              control={form.control}
              data-testid={TEST_IDS.beneficiaryAccountInput}
              label={<FormattedMessage id="constants.universalMessages.beneficiaryAccount" />}
            />

            <Stack direction="row" gap={2}>
              <FormCurrencySelectFieldNew
                name="currency"
                control={form.control}
                data-testid={TEST_IDS.currencySelect}
                currencies={currencyOptions}
                label={<FormattedMessage id="constants.universalMessages.currency" />}
                sx={{
                  minWidth: 120,
                }}
              />

              <FormTextField
                name="swift"
                value={swiftValue.toUpperCase()}
                control={form.control}
                data-testid={TEST_IDS.swiftInput}
                label={<FormattedMessage id="constants.universalMessages.swift" />}
              />
            </Stack>
          </Stack>

          <Typography.TextSm.Regular>
            <FormattedMessage id="constants.universalMessages.bankName" />:
            <Typography.TextSm.SemiBold ml={0.5}>
              <TextSkeleton status={status}>{data?.bankName}</TextSkeleton>
            </Typography.TextSm.SemiBold>
          </Typography.TextSm.Regular>
        </Box>

        <Typography.TextMd.Medium>
          <FormattedMessage id="constants.universalMessages.beneficiaryDetails" />
        </Typography.TextMd.Medium>

        <FormTextField
          name="beneficiaryName"
          control={form.control}
          data-testid={TEST_IDS.beneficiaryNameInput}
          label={<FormattedMessage id="constants.universalMessages.beneficiary" />}
        />
        <FormTextField
          name="beneficiaryAddress1"
          control={form.control}
          data-testid={TEST_IDS.beneficiaryAddress1Input}
          label={<FormattedMessage id="constants.universalMessages.beneficiaryAddress" />}
        />
        <FormTextField
          name="beneficiaryAddress2"
          control={form.control}
          data-testid={TEST_IDS.beneficiaryAddress2Input}
          label={
            <>
              <FormattedMessage id="constants.universalMessages.secondBeneficiaryAddress" />{' '}
              <Typography.TextXs.Regular color="text.tertiary">
                <FormattedMessage id="constants.universalMessages.optionalFieldSuffix" />
              </Typography.TextXs.Regular>
            </>
          }
        />
      </Stack>
    </form>
  );
};
