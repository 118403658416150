export const SET_TRACKING_DATA = 'SET_TRACKING_DATA';

export const SET_AUTHORIZATION_STATE = 'SET_AUTHORIZATION_STATE';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const SET_REGISTRATION_COMPLETED = 'SET_REGISTRATION_COMPLETED';

export const GET_CURRENT_USER_PROFILE_REQUEST = 'GET_CURRENT_USER_PROFILE_REQUEST';
export const GET_CURRENT_USER_PROFILE_SUCCESS = 'GET_CURRENT_USER_PROFILE_SUCCESS';

export const SET_OWN_COMPANY_DATA = 'SET_OWN_COMPANY_DATA';
export const RESET_OWN_COMPANY_DATA = 'RESET_OWN_COMPANY_DATA';
export const EDIT_OWN_COMPANY_REQUEST = 'EDIT_OWN_COMPANY_REQUEST';
export const EDIT_OWN_COMPANY_SUCCESS = 'EDIT_OWN_COMPANY_SUCCESS';
export const EDIT_OWN_COMPANY_FAILURE = 'EDIT_OWN_COMPANY_FAILURE';

export const GET_INFO_OWN_COMPANY_REQUEST = 'GET_INFO_OWN_COMPANY_REQUEST';
export const GET_INFO_OWN_COMPANY_SUCCESS = 'GET_INFO_OWN_COMPANY_SUCCESS';

export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA';
export const RESET_REGISTRATION_DATA = 'RESET_REGISTRATION_DATA';

export const SET_COMPANIES_REFERRAL_INVITE_DATA = 'SET_COMPANIES_REFERRAL_INVITE_DATA';
export const RESET_COMPANIES_REFERRAL_INVITE_DATA = 'RESET_COMPANIES_REFERRAL_INVITE_DATA';

export const GET_INVITE_REQUEST = 'GET_INVITE_REQUEST';
export const GET_INVITE_SUCCESS = 'GET_INVITE_SUCCESS';
export const GET_INVITE_FAILURE = 'GET_INVITE_FAILURE';

export const GET_INVITE_INFO_BY_ID_REQUEST = 'GET_INVITE_INFO_BY_ID_REQUEST';
export const GET_INVITE_INFO_BY_ID_SUCCESS = 'GET_INVITE_INFO_BY_ID_SUCCESS';
export const GET_INVITE_INFO_BY_ID_FAILURE = 'GET_INVITE_INFO_BY_ID_FAILURE';

export const RESET_INVITATION = 'RESET_INVITATION';

export const GET_INVITATION_INFO_REQUEST = 'GET_INVITATION_INFO_REQUEST';
export const GET_INVITATION_INFO_SUCCESS = 'GET_INVITATION_INFO_SUCCESS';
export const GET_INVITATION_INFO_FAILURE = 'GET_INVITATION_INFO_FAILURE';

export const GET_AVAILABLE_FUNCTIONS_REQUEST = 'GET_AVAILABLE_FUNCTIONS_REQUEST';
export const GET_AVAILABLE_FUNCTIONS_SUCCESS = 'GET_AVAILABLE_FUNCTIONS_SUCCESS';

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';

export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';

export const RESET_CURRENT_CONTACT = 'RESET_CURRENT_CONTACT';

export const GET_WIZARD_INFO_REQUEST = 'GET_WIZARD_INFO_REQUEST';
export const GET_WIZARD_INFO_SUCCESS = 'GET_WIZARD_INFO_SUCCESS';

export const GET_LAST_WIZARD_STEP_REQUEST = 'GET_LAST_WIZARD_STEP_REQUEST';
export const GET_LAST_WIZARD_STEP_SUCCESS = 'GET_LAST_WIZARD_STEP_SUCCESS';

export const SAVE_USER_CHAT_AUTHENTIFICATION = 'SAVE_USER_CHAT_AUTHENTIFICATION';

export const SET_IMPERSONATION_STATUS_REQUEST = 'SET_IMPERSONATION_STATUS_REQUEST';
export const SET_IMPERSONATION_STATUS_SUCCESS = 'SET_IMPERSONATION_STATUS_SUCCESS';
export const STOP_IMPERSONATION_STATUS_SUCCESS = 'STOP_IMPERSONATION_STATUS_SUCCESS';
export const SET_IMPERSONATION_STATUS_FAILURE = 'SET_IMPERSONATION_STATUS_FAILURE';
