import _get from 'lodash.get';
import PropTypes from 'prop-types';

const ProfileIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M5 14.125C5 13.0204 5.89543 12.125 7 12.125H17C18.1046 12.125 19 13.0204 19 14.125V20.125H5V14.125Z"
        fill={_get(colors, '0', 'currentColor')}
      />
      <ellipse cx="12" cy="7.125" rx="3" ry="3" fill={_get(colors, '1', 'currentColor')} />
    </svg>
  );
};

ProfileIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ProfileIcon;
