export const GTM_CONSENT_TYPES = {
  denied: 'denied',
  granted: 'granted',
};

export const BYTEDANCE_CONVERT_ID = '1692561256458254';

export const GA_EVENTS = {
  preRegistrationPhone: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-phone-pre-registration',
  },
  preRegistrationEmail: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-email-pre-registration',
  },
  preRegistrationSocial: {
    category: 'Registration',
    action: 'Submit',
    label: 'app-social-pre-registration',
  },
};
