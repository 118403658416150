import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { ECountries } from '@app/core/models';
import { Nullable } from '@app/core/utilities';

type TCountryDTO = {
  alpha2Code: string;
  alpha3Code: string;
  alternativeNames: Nullable<string>;
  buyerFinancingProgram: string;
  comments: Nullable<string>;
  countryTier: Nullable<string>;
  dataYear: string;
  defaultApr: number;
  domesticDealsAllowed: boolean;
  dontNotifyAig: boolean;
  eligibility: Nullable<string>;
  eulerMaxExtension: number;
  eulerMaximumTermsOfPayment: number;
  fitchOutlook: Nullable<string>;
  flagId: string;
  id: string;
  isInEuropeanUnion: boolean;
  loadDate: string;
  maxSupplierFeesApr: Nullable<string>;
  moodysOutlook: Nullable<string>;
  name: string;
  natixisTier: Nullable<string>;
  numeric3Code: string;
  prefix: string;
  sdfCountryTier: Nullable<string>;
  spOutlook: Nullable<string>;
  supplierFinancingProgram: string;
};

type TStates = {
  description: string;
  stateCode: string;
};

const USA_OPTION_VALUE = 'US';

export const getCountryStates = () => {
  const requestOptions = {
    method: 'GET',
  };

  return ApiProvider.apiRequest<TStates[]>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Parameters/countryStates`,
    requestOptions
  ).then((response) => {
    return response;
  });
};

export const getCountriesListRequest = async () => {
  const response = await ApiProvider.apiQueryRequest<{
    value: TCountryDTO[];
  }>(`${BASE_API_URLS.onboarding}/api/odata/v1.2/countries`, {
    method: 'GET',
  });
  return response.value;
};

export const useGetCountriesList = () => {
  return useQuery({
    queryKey: ECountries.GetCountries,
    queryFn: getCountriesListRequest,
    staleTime: 5000,
  });
};

const useGetStates = (countryCode: string) => {
  return useQuery<{ description: string; stateCode: string }[]>(ECountries.getStates, getCountryStates, {
    enabled: countryCode === USA_OPTION_VALUE,
  });
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const useGetCountriesAndStates = (countryCode: string) => {
  const [state, setState] = useState<{
    countriesList?: TCountryDTO[];
    states?: TStates[];
  }>({
    states: [],
    countriesList: [],
  });

  const { data: countriesList } = useGetCountriesList();
  const { data: states } = useGetStates(countryCode);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setState({
      countriesList: countriesList,
      states: states,
    });
  }, [states?.length, countriesList?.length]);

  return { ...state };
};
