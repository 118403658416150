import _get from 'lodash.get';

const NotificationsBellIcon = (props: any) => {
  const { colors, ...restProps } = props;
  const color = _get(colors, '0', 'currentColor');

  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M12.5512 12.5811H3.11523L3.11523 7.07685C3.11523 4.47119 5.22754 2.35889 7.8332 2.35889C10.4389 2.35889 12.5512 4.47119 12.5512 7.07685L12.5512 12.5811Z"
        fill={color}
      />
      <path
        d="M12.5512 12.5811V13.3675C12.9854 13.3675 13.3375 13.0154 13.3375 12.5811L12.5512 12.5811ZM3.11523 12.5811L2.32891 12.5811C2.32891 12.7897 2.41175 12.9897 2.55922 13.1372C2.70668 13.2846 2.90669 13.3675 3.11523 13.3675L3.11523 12.5811ZM12.5512 7.07685H11.7648H12.5512ZM3.11523 7.07685H2.32891H3.11523ZM12.5512 11.7948L3.11523 11.7948L3.11523 13.3675L12.5512 13.3675L12.5512 11.7948ZM3.90156 12.5811L3.90156 7.07685H2.32891L2.32891 12.5811L3.90156 12.5811ZM11.7648 7.07685V12.5811L13.3375 12.5811L13.3375 7.07685H11.7648ZM7.8332 3.14521C10.0046 3.14521 11.7648 4.90547 11.7648 7.07685H13.3375C13.3375 4.03691 10.8731 1.57256 7.8332 1.57256V3.14521ZM3.90156 7.07685C3.90156 4.90547 5.66182 3.14521 7.8332 3.14521V1.57256C4.79326 1.57256 2.32891 4.03691 2.32891 7.07685H3.90156Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9102 12.5813C14.9102 13.0156 14.4575 13.3677 13.8992 13.3677L1.76725 13.3677C1.2089 13.3677 0.756261 13.0156 0.756261 12.5813C0.756261 12.1471 1.2089 11.795 1.76725 11.795L13.8992 11.795C14.4575 11.795 14.9102 12.1471 14.9102 12.5813Z"
        fill={color}
      />
      <rect x="7.047" width="1.57265" height="2.35898" rx="0.786327" fill={color} />
      <mask
        id="mask0_27994:435"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="14"
        width="6"
        height="3"
      >
        <rect x="5.47424" y="14.1538" width="4.71796" height="2.35898" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_27994:435)">
        <circle cx="7.8331" cy="14.1539" r="2.35898" fill={color} />
      </g>
    </svg>
  );
};

export default NotificationsBellIcon;
