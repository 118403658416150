import { IntlShape, defineMessages } from 'react-intl';

const scope = 'constants.frontendErrors';

const PASSWORD_VALIDATION_MESSAGES = defineMessages({
  minLength: {
    id: `${scope}.password.minLength`,
    defaultMessage: '8 or more characters',
  },
  minDigits: {
    id: `${scope}.password.minDigits`,
    defaultMessage: 'At least 1 digit (0-9)',
  },
  minUpperCase: {
    id: `${scope}.password.minUpperCase`,
    defaultMessage: 'At least 1 UPPERCASE character (A-Z)',
  },
  minLowerCase: {
    id: `${scope}.password.minLowerCase`,
    defaultMessage: 'At least 1 lowercase character (a-z)',
  },
});

const CUSTOM_ERROR_MESSAGES = defineMessages({
  wrongEmailOrPassword: {
    id: `${scope}.wrongEmailOrPassword`,
    defaultMessage: 'Wrong email or password',
  },
  bruteForceBlocked: {
    id: `${scope}.bruteForceBlocked`,
    defaultMessage:
      "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.",
  },
  wrongPhoneOrCode: {
    id: `${scope}.wrongPhoneOrCode`,
    defaultMessage: 'Wrong phone number or verification code',
  },
  wrongEmailFormat: {
    id: `${scope}.wrongEmailFormat`,
    defaultMessage: 'Please provide your corporate email address.',
  },
  provideValidURL: {
    id: `${scope}.provideValidURL`,
    defaultMessage: 'Please provide the website address',
  },
  alreadyRegistered: {
    id: `${scope}.alreadyRegistered`,
    defaultMessage: 'It seems that you are already signed up at Stenn. Please log in',
  },
  alreadyExists: {
    id: `${scope}.alreadyExists`,
    defaultMessage: 'This user already exists',
  },
  alreadyVerified: {
    id: `${scope}.alreadyVerified`,
    defaultMessage: 'This account is already verified',
  },
  invalidInvitation: {
    id: `${scope}.invalidInvitation`,
    defaultMessage: 'This invitation in not valid',
  },
  passwordIsTooWeak: {
    id: `${scope}.passwordIsTooWeak`,
    defaultMessage: 'Password is too weak',
  },
  phoneSignUpIsRestrictedInMexico: {
    id: `${scope}.phoneSignUpIsRestrictedInMexico`,
    defaultMessage: 'Currently, we do not support sign-up by phone number in Mexico. Please use other sign-up methods.',
  },
  accountNotFound: {
    id: `${scope}.accountNotFound`,
    defaultMessage:
      'We couldn’t find an account with that email address. Would you like to sign up for a new Stenn App account?',
  },
  shouldLogInWithPhone: {
    id: `${scope}.shouldLogInWithPhone`,
    defaultMessage: 'Please log in with your phone number',
  },
  shouldLogInWithGoogle: {
    id: `${scope}.shouldLogInWithGoogle`,
    defaultMessage: 'Please log in with your Google social account',
  },
  shouldLogInWithFacebook: {
    id: `${scope}.shouldLogInWithFacebook`,
    defaultMessage: 'Please log in with your Facebook social account',
  },
  shouldLogInWithAppCredentials: {
    id: `${scope}.shouldLogInWithAppCredentials`,
    defaultMessage: 'Please log in with App account',
  },
  emailAddresIsNotRegistered: {
    id: `${scope}.emailAddresIsNotRegistered`,
    defaultMessage: 'Email address is not registered in our system',
  },
});

export const frontendErrorMessages = defineMessages({
  chooseCountry: {
    id: `${scope}.chooseCountry`,
    defaultMessage: 'Please select your country',
  },
  chooseState: {
    id: `${scope}.chooseState`,
    defaultMessage: 'Please select your state',
  },
  chooseSuppliersCountry: {
    id: `${scope}.chooseSuppliersCountry`,
    defaultMessage: "Please choose your supplier's country",
  },
  chooseBuyerCountry: {
    id: `${scope}.chooseBuyerCountry`,
    defaultMessage: 'Please choose country for your buyer',
  },
  enterValidURL: {
    id: `${scope}.enterValidURL`,
    defaultMessage: 'Please enter a valid website address',
  },
  enterValidDUNS: {
    id: `${scope}.enterValidDUNS`,
    defaultMessage: 'Please enter a valid DUNS',
  },
  addDocumentsForGoods: {
    id: `${scope}.addDocumentsForGoods`,
    defaultMessage: 'Please upload the file.',
  },
  addDocumentsForServices: {
    id: `${scope}.addDocumentsForServices`,
    defaultMessage: 'Please upload the file.',
  },
  addDocumentsForInvoice: {
    id: `${scope}.addDocumentsForInvoice`,
    defaultMessage: 'Please upload invoice and at least one transport document',
  },
  addInvoiceDocument: {
    id: `${scope}.addInvoiceDocument`,
    defaultMessage: 'Please upload invoice file to submit this invoice',
  },
  addTransportDocument: {
    id: `${scope}.addTransportDocument`,
    defaultMessage: 'Please upload transport document to submit this invoice',
  },
  addPurchaseOrderDocument: {
    id: `${scope}.addPurchaseOrderDocument`,
    defaultMessage: `Please upload the file.`,
  },
  classifyInvoiceAndTransportDocuments: {
    id: `${scope}.classifyInvoiceAndTransportDocuments`,
    defaultMessage: 'Please categorise uploaded files. Please upload the invoice and transport document files',
  },
  classifyCommercialInvoice: {
    id: `${scope}.classifyCommercialInvoice`,
    defaultMessage: 'Please categorise uploaded files. Please upload the invoice file.',
  },
  classifyTransportDocument: {
    id: `${scope}.classifyTransportDocument`,
    defaultMessage: 'Please categorise uploaded files. Please upload the transport document file.',
  },
  codeIsNotValid: {
    id: `${scope}.codeIsNotValid`,
    defaultMessage: 'This code doesn’t exist',
  },
  invalidDueDate: {
    id: `${scope}.invalidDueDate`,
    defaultMessage: 'Due date must be in the future',
  },
  enterYourRefereeName: {
    id: `${scope}.enterYourRefereeName`,
    defaultMessage: 'Please enter your referee name',
  },
  enterYourRefereeSurname: {
    id: `${scope}.enterYourRefereeSurname`,
    defaultMessage: 'Please enter your referee surname',
  },
  enterYourSuppliersName: {
    id: `${scope}.enterYourSuppliersName`,
    defaultMessage: "Please enter your supplier's name",
  },
  enterYourSuppliersSurname: {
    id: `${scope}.enterYourSuppliersSurname`,
    defaultMessage: "Please enter your supplier's surname",
  },
  enterYourName: {
    id: `${scope}.enterYourName`,
    defaultMessage: 'Please enter a valid forename',
  },
  enterValidName: {
    id: `${scope}.enterValidName`,
    defaultMessage: 'Please enter a valid surname',
  },
  enterYourSurname: {
    id: `${scope}.enterYourSurname`,
    defaultMessage: 'Please enter your surname',
  },
  enterValidSurname: {
    id: `${scope}.enterValidSurname`,
    defaultMessage: 'Please enter your surname',
  },
  enterEmail: {
    id: `${scope}.enterEmail`,
    defaultMessage: 'Please enter the email',
  },
  enterYourEmail: {
    id: `${scope}.enterYourEmail`,
    defaultMessage: 'Please enter your email',
  },
  enterValidEmail: {
    id: `${scope}.enterValidEmail`,
    defaultMessage: 'Please enter a valid email address',
  },
  notUserEmail: {
    id: `${scope}.notUserEmail`,
    defaultMessage: 'Please enter the email of the person you want to be referred to',
  },
  emailAlreadyRegistered: {
    id: `${scope}.emailAlreadyRegistered`,
    defaultMessage: 'Email address is already connected to an account',
  },
  enterName: {
    id: `${scope}.enterName`,
    defaultMessage: 'Please enter the name',
  },
  enterFirstName: {
    id: `${scope}.enterFirstName`,
    defaultMessage: 'Please enter a first name',
  },
  enterLastName: {
    id: `${scope}.enterLastName`,
    defaultMessage: 'Please enter a last name',
  },
  enterValidFirstName: {
    id: `${scope}.enterValidFirstName`,
    defaultMessage: 'Please enter a valid first name',
  },
  enterValidLastName: {
    id: `${scope}.enterValidLastName`,
    defaultMessage: 'Please enter a valid last name',
  },
  invalidName: {
    id: `${scope}.invalidName`,
    defaultMessage: 'Invalid characters: 0-9, @',
  },
  enterYourWebsite: {
    id: `${scope}.enterYourWebsite`,
    defaultMessage: 'Please enter your website',
  },
  invalidSurname: {
    id: `${scope}.invalidSurname`,
    defaultMessage: 'Invalid characters: 0-9, @',
  },
  nameIsTooLong: {
    id: `${scope}.nameIsTooLong`,
    defaultMessage: 'Name exceeds the limit of 40 characters',
  },
  firstNameIsTooLong: {
    id: `${scope}.fistNameIsTooLong`,
    defaultMessage: 'First name exceeds the limit of 40 characters',
  },
  enterSurname: {
    id: `${scope}.enterSurname`,
    defaultMessage: 'Please enter the surname',
  },
  surnameIsTooLong: {
    id: `${scope}.surnameIsTooLong`,
    defaultMessage: 'Surname exceeds the limit of 40 characters',
  },
  lastNameIsTooLong: {
    id: `${scope}.lastNameIsTooLong`,
    defaultMessage: 'Last name exceeds the limit of 40 characters',
  },
  fillPassword: {
    id: `${scope}.fillPassword`,
    defaultMessage: 'Password should not be empty',
  },
  enterSWIFT: {
    id: `${scope}.enterSWIFT`,
    defaultMessage: 'Please enter the SWIFT code',
  },
  enterYourSWIFT: {
    id: `${scope}.enterYourSWIFT`,
    defaultMessage: 'Please enter your SWIFT code',
  },
  enterYourPosition: {
    id: `${scope}.enterYourPosition`,
    defaultMessage: 'Please enter your position',
  },
  enterPhoneNumber: {
    id: `${scope}.enterPhoneNumber`,
    defaultMessage: 'Please enter phone number',
  },
  enterYourPhoneNumber: {
    id: `${scope}.enterYourPhoneNumber`,
    defaultMessage: 'Please enter your phone number',
  },
  invalidSwift: {
    id: `${scope}.invalidSwift`,
    defaultMessage: 'Please enter a valid SWIFT',
  },
  invalidSwiftEnding: {
    id: `${scope}.invalidSwiftEnding`,
    defaultMessage: 'Incorrect format: SWIFT must not end with XXX',
  },
  shortPassword: {
    id: `${scope}.shortPassword`,
    defaultMessage: 'Password is too short',
  },
  nonZero: {
    id: `${scope}.nonZero`,
    defaultMessage: 'Cannot be 0 or less than 0',
  },
  phoneNumberIsTooShort: {
    id: `${scope}.phoneNumberIsTooShort`,
    defaultMessage: 'The phone number is too short',
  },
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Please enter phone number in international format',
  },
  chooseYourCountryCode: {
    id: `${scope}.chooseYourCountryCode`,
    defaultMessage: 'Please choose your country code',
  },
  unknownError: {
    id: `${scope}.unknownError`,
    defaultMessage: 'Unknown error',
  },
  isCodeFromSMS: {
    id: `${scope}.isCodeFromSMS`,
    defaultMessage: 'The code must contain 6 digits',
  },
  isValidUrlLength: {
    id: `${scope}.isValidUrlLength`,
    defaultMessage: 'The text is too long',
  },
  confirmYouAreSupplierAnAuthorisedSignatory: {
    id: `${scope}.confirmYouAreSupplierAnAuthorisedSignatory`,
    defaultMessage: 'Please tick the box to confirm you are an authorised signatory',
  },
  termsOfUseNotAccepted: {
    id: `${scope}.termsOfUseNotAccepted`,
    defaultMessage: 'Please accept terms and conditions',
  },
  invalidBeneficiaryAccountNumber: {
    id: `${scope}.invalidBeneficiaryAccountNumber`,
    defaultMessage: 'Only 0-9, A-Z expected',
  },
  invalidBankName: {
    id: `${scope}.invalidBankName`,
    defaultMessage: 'Bank name can only contain A-Z, 0-9, comma, dot, dash, whitespace and round brackets',
  },
  invalidBeneficiaryName: {
    id: `${scope}.invalidBeneficiaryName`,
    defaultMessage: "Beneficiary name cannot contain # or '",
  },
  invalidBeneficiaryNameLength: {
    id: `${scope}.invalidBeneficiaryNameLength`,
    defaultMessage: 'Beneficiary name is too long',
  },
  invalidBeneficiaryAddress1: {
    id: `${scope}.invalidBeneficiaryAddress1`,
    defaultMessage: "Beneficiary address 1 cannot contain # or '",
  },
  invalidBeneficiaryAddress2: {
    id: `${scope}.invalidBeneficiaryAddress2`,
    defaultMessage: "Beneficiary address 2 cannot contain # or '",
  },
  declinedBuyerOrSupplier: {
    id: `${scope}.declinedBuyerOrSupplier`,
    defaultMessage: 'Buyer or supplier is declined',
  },
  enterYourBuyersCompanyName: {
    id: `${scope}.enterYourBuyersCompanyName`,
    defaultMessage: "Please enter your buyer's company name",
  },
  enterYourCompanyName: {
    id: `${scope}.enterYourCompanyName`,
    defaultMessage: 'Please enter your company name',
  },
  enterYourRegisteredCompanyName: {
    id: `${scope}.enterYourRegisteredCompanyName`,
    defaultMessage: 'Please provide your registered company name',
  },
  enterYourSuppliersCompanyName: {
    id: `${scope}.enterYourSuppliersCompanyName`,
    defaultMessage: "Please enter your supplier's company name",
  },
  enterYourSuppliersWebsiteURL: {
    id: `${scope}.enterYourSuppliersWebsiteURL`,
    defaultMessage: "Please enter your supplier's website URL",
  },
  enterTextField: {
    id: `${scope}.enterTextField`,
    defaultMessage: 'Please enter text field',
  },
  enterBeneficiaryAccountNumber: {
    id: `${scope}.enterBeneficiaryAccountNumber`,
    defaultMessage: 'Please enter the Beneficiary Account Number',
  },
  enterBankName: {
    id: `${scope}.enterBankName`,
    defaultMessage: 'Please enter the Bank name',
  },
  enterBeneficiaryName: {
    id: `${scope}.enterBeneficiaryName`,
    defaultMessage: 'Please enter the Beneficiary name',
  },
  enterBeneficiaryAddress1: {
    id: `${scope}.enterBeneficiaryAddress1`,
    defaultMessage: 'Please enter Beneficiary Address 1',
  },
  enterBeneficiaryAddress2: {
    id: `${scope}.enterBeneficiaryAddress2`,
    defaultMessage: 'Please enter Beneficiary Address 2',
  },
  enterPosition: {
    id: `${scope}.enterPosition`,
    defaultMessage: 'Please provide a position',
  },
  enterWebsite: {
    id: `${scope}.enterWebsite`,
    defaultMessage: 'Please enter a valid website address',
  },
  websiteIsTooLong: {
    id: `${scope}.websiteIsTooLong`,
    defaultMessage: 'Website exceeds the limit of 500 characters',
  },
  enterInvoiceNumber: {
    id: `${scope}.enterInvoiceNumber`,
    defaultMessage: 'Please enter the invoice number',
  },
  enterDueDate: {
    id: `${scope}.enterDueDate`,
    defaultMessage: 'Please enter due date',
  },
  enterInvoiceValue: {
    id: `${scope}.enterInvoiceValue`,
    defaultMessage: 'Please enter invoice value',
  },
  selectCurrency: {
    id: `${scope}.selectCurrency`,
    defaultMessage: 'Please select currency',
  },
  selectDealType: {
    id: `${scope}.selectDealType`,
    defaultMessage: 'Please select deal type',
  },
  uploadBankStatements: {
    id: `${scope}.uploadBankStatements`,
    defaultMessage:
      'Please upload your business operating bank account statements for the prior three months: {monthsWithYear}',
  },
  uploadBankStatementFilesSize: {
    id: `${scope}.uploadBankStatementFilesSize`,
    defaultMessage: 'Please upload files less than 100mb',
  },
  chooseYourState: {
    id: `${scope}.chooseYourState`,
    defaultMessage: 'Please choose your state',
  },
  chooseCompanyInChinese: {
    id: `${scope}.chooseCompanyInChinese`,
    defaultMessage: 'Please enter your registered company name in Chinese',
  },

  chooseYourCompanyNameInChinese: {
    id: `${scope}.chooseYourCompanyNameInChinese`,
    defaultMessage: 'Please enter your registered company name in Chinese',
  },

  chooseUnifiedSocialCreditCode: {
    id: `${scope}.chooseUnifiedSocialCreditCode`,
    defaultMessage: 'Please enter an 18-character alphanumeric code',
  },

  invalidSignatoriesPhoneNumber: {
    id: `${scope}.invalidSignatoriesPhoneNumber`,
    defaultMessage: 'Please enter phone number in international format or correct area code',
  },
  ...CUSTOM_ERROR_MESSAGES,
  ...PASSWORD_VALIDATION_MESSAGES,
});

// matches straight by defineMessages
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTranslatedFrontendErrorCodes = (intl: IntlShape, error: any) => {
  const { formatMessage } = intl;

  if (typeof error === 'string' && frontendErrorMessages[error as keyof typeof frontendErrorMessages]) {
    return formatMessage(frontendErrorMessages[error as keyof typeof frontendErrorMessages]);
  }

  if (
    error &&
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    error.hasOwnProperty('id') &&
    frontendErrorMessages[error.id as keyof typeof frontendErrorMessages]
  ) {
    return formatMessage(frontendErrorMessages[error.id as keyof typeof frontendErrorMessages], error.options?.(intl));
  }

  return formatMessage(frontendErrorMessages.unknownError);
};

export default frontendErrorMessages;
