import { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { getDealInvoicesRequest } from '@app/components-new/Flow/DealOverview/queries';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { StepContainer } from '@app/components-new/StepContainer';
import { TStennClientsServiceApplicationOperationsModelsSubmitNewDealRequest } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { calculateInvoicesTotals } from '@app/core/features/mappers';
import { ERequestKey } from '@app/types/request';
import { Button, Card } from '@stenngroup/ui-kit';

import { ReviewDealCard } from './ReviewDealCard';

interface IReviewDealProps {}

export const ReviewDeal: FC<IReviewDealProps> = () => {
  const { handleNextStep } = useFlowTransitionsContext();

  const { getFlowState } = useFlowTransitionsContext();
  const { trId, tradeRole } = getFlowState();

  const { data: dealInvoicesByDealType, status } = useQuery(
    [ERequestKey.InvoicesListByTradeRelation, trId],
    () => getDealInvoicesRequest(trId),
    {
      cacheTime: 0,
    }
  );
  const invoicesTotals = useMemo(
    () => calculateInvoicesTotals(dealInvoicesByDealType?.value),
    [dealInvoicesByDealType]
  );

  const httpClient = useHttpClient();
  const submitDealMutation = useMutation({
    mutationFn: (data: TStennClientsServiceApplicationOperationsModelsSubmitNewDealRequest) =>
      httpClient(apiRoutes.DealsSubmitNewDeal, 'post', {
        data,
      }),
    onSuccess: () => {
      handleNextStep();
    },
  });

  const handleSubmitDeal = () => {
    submitDealMutation.mutate({
      invoiceIds: dealInvoicesByDealType?.value.map((invoice) => invoice.id) || [],
    });
  };
  return (
    <StepContainer
      actions={
        <Button
          variant="contained"
          size="medium"
          onClick={handleSubmitDeal}
          isLoading={submitDealMutation.isLoading}
          disabled={submitDealMutation.isLoading || !dealInvoicesByDealType?.value.length}
        >
          <FormattedMessage id="constants.universalMessages.submitDeal" />
        </Button>
      }
      title={<FormattedMessage id="createDeal.reviewDeal" />}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <ReviewDealCard
          invoicesCount={dealInvoicesByDealType?.value?.length ?? 0}
          tradeRole={tradeRole}
          totalInvoicesValue={invoicesTotals.value}
          supplierReceives={invoicesTotals.youWillReceive}
          firstPayment={invoicesTotals.firstPayment}
          secondPayment={invoicesTotals.secondPayment}
          totalFees={invoicesTotals.fees}
          volumeAdjustmentFees={invoicesTotals.volumeAdjustmentFees}
          insuranceCharges={invoicesTotals.insuranceCharges}
          latePaymentFees={invoicesTotals.latePaymentFees}
          currencyCode={invoicesTotals.currencyCode || 'USD'}
          buyerName={invoicesTotals.buyerName}
          supplierName={invoicesTotals.supplierName}
          loadingState={status}
        />
      </Card>
    </StepContainer>
  );
};
