export const toDate = (value) => {
  if (value instanceof Date) {
    return value;
  }

  const date = new Date(value);

  const isDateValid = !Number.isNaN(date.valueOf());

  return isDateValid ? date : undefined;
};

export const convertUTCDateToLocalDate = (dateInUTC) => {
  const normalizedUTCDate = toDate(dateInUTC);

  if (!normalizedUTCDate) {
    return 'Invalid date';
  }

  const newDate = new Date(normalizedUTCDate.getTime() + normalizedUTCDate.getTimezoneOffset() * 60 * 1000);

  const offset = normalizedUTCDate.getTimezoneOffset() / 60;
  const hours = normalizedUTCDate.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};
