import { FC, ReactNode, useCallback, useMemo } from 'react';

import { useQueryClient } from 'react-query';

import { createAuthConfig } from '@app/context/AuthProviderWrapper/helpers/createAuthConfig';
import { ECookiesStorageKey, ELocalStorageKey } from '@app/storage-keys';
import { AuthProvider } from '@stenngroup/auth0-sdk';

const auth0Config = createAuthConfig();

export const AuthProviderWrapper: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const queryClient = useQueryClient();

  const handleBeforeLogout = useCallback(async () => {
    queryClient.getQueryCache().clear();
    await queryClient.cancelQueries();
  }, [queryClient]);

  const storageConfig = useMemo(
    () => ({
      type: 'cookies',
      keyPrefix: ECookiesStorageKey.AuthData,
      domain: window._STENN_.APP_COOKIE_DOMAIN,
    }),
    []
  );

  return (
    <AuthProvider
      config={auth0Config}
      onBeforeLogout={handleBeforeLogout}
      localStorageKey={ELocalStorageKey.AuthData}
      // @ts-expect-error tofo fix
      storageConfig={storageConfig}
    >
      {children}
    </AuthProvider>
  );
};
