import { FC, useEffect } from 'react';

import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { AddBankDetails } from '@app/components-new/FlowTransitions/screens/AddBankDetails';
import { AddCompanySignatory } from '@app/components-new/FlowTransitions/screens/AddCompanySignatory';
import { AddTradePartnerSignatory } from '@app/components-new/FlowTransitions/screens/AddTradePartnerSignatory';
import { DealSubmitted } from '@app/components-new/FlowTransitions/screens/DealSubmitted';
import { InviteTradePartner } from '@app/components-new/FlowTransitions/screens/InviteTradePartner';
import { InvoicesList } from '@app/components-new/FlowTransitions/screens/InvoicesList';
import { RequestLimit } from '@app/components-new/FlowTransitions/screens/RequestLimit';
import { ReviewDeal } from '@app/components-new/FlowTransitions/screens/ReviewDeal';
import { SearchTradePartner } from '@app/components-new/FlowTransitions/screens/SearchTradePartner';
import { SelectNewTradePartner } from '@app/components-new/FlowTransitions/screens/SelectNewTradePartner';
import { SelectTradePartner } from '@app/components-new/FlowTransitions/screens/SelectTradePartner';
import { SelectTradeRole } from '@app/components-new/FlowTransitions/screens/SelectTradeRole';
import { TradePartnerCreated } from '@app/components-new/FlowTransitions/screens/TradePartnerCreated';
import { TradePartnerInvited } from '@app/components-new/FlowTransitions/screens/TradePartnerInvited';
import { UploadInvoice } from '@app/components-new/FlowTransitions/screens/UploadInvoice';
import { FlowTransitionsWrapper } from '@app/components-new/FlowTransitionsWrapper';
import { match } from 'ts-pattern';

export const FlowTransitions: FC = () => {
  const { currentStep, direction, reset } = useFlowTransitionsContext();

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <FlowTransitionsWrapper direction={direction} currentStep={currentStep ?? ''}>
      {match(currentStep)
        .with(EFlowStep.TRADE_ROLE, () => <SelectTradeRole />)
        .with(EFlowStep.SEARCH_TRADE_PARTNER, () => <SearchTradePartner />)
        .with(EFlowStep.SELECT_NEW_TRADE_PARTNER, () => <SelectNewTradePartner />)
        .with(EFlowStep.REQUEST_LIMIT, () => <RequestLimit />)
        .with(EFlowStep.TRADE_PARTNER_CREATED, () => <TradePartnerCreated />)
        .with(EFlowStep.SELECT_TRADE_PARTNER, () => <SelectTradePartner />)
        .with(EFlowStep.UPLOAD_INVOICE, () => <UploadInvoice />)
        .with(EFlowStep.INVOICES_LIST, () => <InvoicesList />)
        .with(EFlowStep.ADD_COMPANY_SIGNATORY, () => <AddCompanySignatory />)
        .with(EFlowStep.ADD_TRADE_PARTNER_SIGNATORY, () => <AddTradePartnerSignatory />)
        .with(EFlowStep.ADD_BANK_DETAILS, () => <AddBankDetails />)
        .with(EFlowStep.REVIEW_DEAL, () => <ReviewDeal />)
        .with(EFlowStep.DEAL_SUBMITTED, () => <DealSubmitted />)
        .with(EFlowStep.INVITE_TRADE_PARTNER, () => <InviteTradePartner />)
        .with(EFlowStep.TRADE_PARTNER_INVITED, () => <TradePartnerInvited />)
        .otherwise(() => null)}
    </FlowTransitionsWrapper>
  );
};
