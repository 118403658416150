import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { routes } from '@app/routes/config';
import { Refresh as RefreshIcon, WarningRounded as WarningIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Button, StennLogo } from '@stenngroup/ui-kit';

interface ICriticalErrorLayoutProps {
  errorCode?: number | null;
}

export const CriticalErrorLayout: FC<ICriticalErrorLayoutProps> = () => {
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
    queryClient.clear();
    window.location.reload();
  };
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={1.25}
      height="100%"
      padding={10}
      bgcolor={(t) => t.palette.common.white}
      sx={(t) => ({
        padding: 3,
        [t.breakpoints.up('md')]: {
          padding: 7.5,
        },
        [t.breakpoints.up('lg')]: {
          padding: 10,
        },
      })}
    >
      <Box>
        <Link to={routes.root.path}>
          <StennLogo />
        </Link>
      </Box>
      <Stack gap={3}>
        <Stack direction="row" gap={1} alignItems="center">
          <WarningIcon fontSize="large" sx={(t) => ({ color: t.palette.warning.main })} />
          <Typography variant="h4" color={(t) => t.palette.text.primary}>
            <FormattedMessage id="constants.universalMessages.somethingWentWrong" />
          </Typography>
        </Stack>
        <Typography variant="body1" color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="constants.universalMessages.pleaseReloadPage" />
        </Typography>
        <Box>
          <Button size="medium" variant="contained" color="primary" endIcon={<RefreshIcon />} onClick={handleLogout}>
            <FormattedMessage id="constants.universalMessages.refresh" />
          </Button>
        </Box>
      </Stack>
      <div />
    </Stack>
  );
};
