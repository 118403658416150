import { FC, useMemo } from 'react';

import { useQuery } from 'react-query';

import { getCountriesListRequest, getCountryStates } from '@app/api/hooks/useGetCountries';
import { TPlacesAutocompleteOption, TPlacesAutocompleteProps } from '@app/components/PlacesAutocomplete/types';
import { ERequestKey } from '@app/types/request';
import { Autocomplete, TextField } from '@mui/material';

export const FallbackPlacesAutocomplete: FC<TPlacesAutocompleteProps> = ({
  name,
  label,
  country,
  modifyValueForSearch,
  onSelect,
  type,
  freeSolo,
  error,
  helperText,
  value,
  ...rest
}) => {
  const { data: countriesList } = useQuery({
    queryKey: ERequestKey.GetCountries,
    queryFn: getCountriesListRequest,
    staleTime: 5000,
  });
  const { data: statesList } = useQuery({
    queryKey: ERequestKey.GetStates,
    queryFn: getCountryStates,
    staleTime: 5000,
    enabled: !!country && country === 'US',
  });
  const list = useMemo(() => {
    if (type === 'country') {
      return (
        countriesList?.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
          place: country.alpha2Code,
          description: country.name,
        })) || []
      );
    }
    if (type === 'administrative_area_level_1') {
      return (
        statesList?.map((state) => ({
          label: state.description,
          value: state.stateCode,
          place: state.stateCode,
          description: state.description,
        })) || []
      );
    }
    return [];
  }, [countriesList, statesList, type]);
  const handleOnChange = (_: React.ChangeEvent<{}>, selectedVal: string) => {
    const value = modifyValueForSearch ? modifyValueForSearch(selectedVal) : selectedVal;

    onSelect?.({
      label: value,
      value: '',
    });
  };
  const handleSelect = async (
    _: React.SyntheticEvent<any, Event>,
    option: TPlacesAutocompleteOption | string | null
  ) => {
    if (typeof option === 'string') return;
    if (!option) return;
    onSelect?.({
      label: option.label,
      value: option.value,
      place: option.value,
    });
  };
  return (
    <Autocomplete
      value={value}
      freeSolo={freeSolo}
      inputValue={value?.label || ''}
      size="small"
      options={list}
      onInputChange={handleOnChange}
      // @ts-expect-error types are incompatible
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField error={error} helperText={helperText} name={name || ''} label={label} {...params} />
      )}
      // @ts-expect-error types are incompatible
      renderOption={(props, option: TPlacesAutocompleteOption) => (
        <span {...props}>{typeof option === 'string' ? option : option.description}</span>
      )}
      {...rest}
    />
  );
};
