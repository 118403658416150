import { type FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormPhoneField, FormTextField, Stack, Typography } from '@stenngroup/ui-kit';

import { IContactForm, ISigner } from '../types';

import { getSchema } from './schema';

interface IProps {
  signer?: ISigner;
  onSubmit: (data: IContactForm) => void;
}

export const SignatoryForm: FC<IProps> = ({ onSubmit }) => {
  const { formatMessage } = useIntl();
  const { control, handleSubmit } = useForm<IContactForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(getSchema(formatMessage)),
  });

  const handleFormSubmit = handleSubmit((data) => onSubmit(data));

  return (
    <form id="signatoryForm" onSubmit={handleFormSubmit}>
      <Stack spacing={3}>
        <Typography.TextMd.Medium>
          <FormattedMessage id="createDeal.addTradePartnerSignatory.form.description" />
        </Typography.TextMd.Medium>

        <Stack spacing={2}>
          <FormTextField
            name="firstName"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.firstNameField" />}
          />
          <FormTextField
            name="lastName"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.lastNameField" />}
          />
          <FormTextField
            name="position"
            control={control}
            label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.positionField" />}
          />
        </Stack>
        <Stack spacing={2}>
          <Typography.TextMd.Medium>
            <FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField.description" />
          </Typography.TextMd.Medium>
          <Stack spacing={2}>
            <FormTextField
              name="email"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField" />}
            />

            <FormPhoneField
              name="phoneNumber"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.phoneField" />}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
