import _get from 'lodash.get';
import PropTypes from 'prop-types';

const CrossIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17.5 17.5"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fill={_get(colors, '0', 'currentColor')}
        d="M15.8,17.2c0.4,0.4,1.1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-7-7l7-7c0.4-0.4,0.4-1.1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-7,7l-7-7c-0.4-0.4-1.1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l7,7l-7,7c-0.4,0.4-0.4,1.1,0,1.4c0.4,0.4,1,0.4,1.4,0l7-7L15.8,17.2z"
      />
    </svg>
  );
};

CrossIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default CrossIcon;
