import { COOKIE_KEYS } from '@app/core/constants';
import { safeJSONparse } from '@app/core/utilities';
import { getCookiesConsent } from '@stenngroup/utilities';

import { LocalStorageService } from './LocalStorageService';

export const setCurrentLanguageToStorage = (langId) => {
  const cookiesDecision = getCookiesConsent(window._STENN_.ENVIRONMENT === 'live')();

  if (cookiesDecision.functionality) {
    const data = JSON.stringify({
      langId,
      timestamp: new Date().toISOString(),
    });

    LocalStorageService.setValue(COOKIE_KEYS.languageData, data);
  }
};

export const getCurrentLanguageFromStorage = () => {
  const value = LocalStorageService.getValue(COOKIE_KEYS.languageData);

  return safeJSONparse(value);
};
