import { omitUndefined } from '@app/helpers/omitUndefined';
import { AnyObject } from '@stenngroup/utilities';

export class HttpClientError extends Error {
  constructor(
    private readonly res: Response,
    private readonly body: AnyObject
  ) {
    super(
      `Http client error: code "${body.strCode ?? res.status}". Message: "${body.message ?? res.statusText ?? 'Unknown'}"`
    );
  }

  public get status(): number | null {
    return this.res.status;
  }

  public get path(): string {
    const url = new URL(this.res.url);
    return url.pathname;
  }

  public get strCode(): string {
    return this.body.strCode ?? '0';
  }

  public get rawMessage(): string {
    return this.body.message ?? this.body.title;
  }

  public get response(): AnyObject {
    return this.body;
  }

  public getFingerprint(): string[] {
    const fingerprint = JSON.stringify({
      status: this.status,
      path: this.path,
      code: this.strCode,
      msg: this.rawMessage.slice(0, 100),
    });
    return [fingerprint];
  }

  public toObject(): AnyObject {
    return omitUndefined({
      status: this.status,
      path: this.path,
      code: this.strCode,
      msg: this.rawMessage.slice(0, 100),
      key: this.response.exceptionKey,
      traceId: this.response.traceId,
    });
  }

  public static async fromResponse(res: Response): Promise<HttpClientError> {
    let body: AnyObject;
    try {
      if (res?.headers?.get('Content-Type')?.includes('application/json')) {
        body = await res.json();
      } else {
        body = {
          message: await res.text(),
        };
      }
    } catch {
      body = {};
    }
    return new HttpClientError(res, body);
  }
}
