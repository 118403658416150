import { useQuery } from 'react-query';

import { EProfileRequestKey } from '@app/api/constants';
import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { getProfileRequest, getUnverifiedProfileRequest } from '@app/api/profile/getProfileRequest';
import { AUTH_API_SCOPES } from '@app/core/authentication';
import { IProfileDto, IProfileWithNormalizedFeaturesModel } from '@app/core/models/profile';
import { useAuth } from '@stenngroup/auth0-sdk';

const normalizedProfileFeaturesSelector = (profile: IProfileDto) => {
  const featuresNormalized = profile.features.reduce<IProfileWithNormalizedFeaturesModel['features']>(
    (featuresObject, feature) => {
      featuresObject[feature.name] = feature;
      return featuresObject;
    },
    {}
  );

  const profileWithNormalizedFeatures: IProfileWithNormalizedFeaturesModel = {
    ...profile,
    features: featuresNormalized,
  };
  return profileWithNormalizedFeatures;
};

export const useGetProfileV2 = () => {
  const { isAuthenticated, userInfo } = useAuth();
  const configuredFetch = useConfiguredFetch();
  const query = useQuery(
    [EProfileRequestKey.GetProfile, { isVerified: userInfo?.isEmailVerified }],
    async ({ signal }) => {
      if (userInfo?.isEmailVerified) {
        return getProfileRequest({ configuredFetch, signal, token: undefined });
      }
      return getUnverifiedProfileRequest({ configuredFetch, signal, token: undefined });
    },
    {
      select: normalizedProfileFeaturesSelector,
      notifyOnChangeProps: ['data', 'error'],
      refetchOnMount: true,
      enabled:
        isAuthenticated &&
        !!userInfo &&
        typeof userInfo.isEmailVerified === 'boolean' &&
        !userInfo.userStates?.includes(AUTH_API_SCOPES.SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN),
      cacheTime: 5000,
      staleTime: 5000,
    }
  );
  return {
    ...query,
    needToShowSocialConsent:
      isAuthenticated && userInfo?.userStates?.includes(AUTH_API_SCOPES.SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN),
  };
};

// export const usePrefetchGetProfile = (): (() => Promise<void>) => {
//   const queryClient = useQueryClient();

//   const { getAccessTokenSilently } = useAuth();
//   const configuredFetch = useConfiguredFetch();

//   const prefetchGetProfile = useCallback(async () => {
//     return queryClient.prefetchQuery({
//       queryKey: EProfileRequestKey.GetProfile,
//       queryFn: async ({ signal }) => {
//         const token = await getAccessTokenSilently();
//         if (!token) throw new Error('Access token is required to prefetch profile');
//         return getProfileRequest({ configuredFetch, signal, token });
//       },
//     });
//   }, [queryClient, getAccessTokenSilently, configuredFetch]);

//   return prefetchGetProfile;
// };
