const tableData = [
  [
    {
      cellType: 'head',
      defaultMessage: 'Total amount of not-repaid invoices\u00A0(AR\u00A0value)',
    },
    {
      cellType: 'head',
      defaultMessage: 'Volume\u00A0adjustment fees',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: 'Less than $1,000,000',
    },
    {
      cellType: 'body',
      defaultMessage: '3.5% per annum',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: '$1,000,000 – $3,000,000',
    },
    {
      cellType: 'body',
      defaultMessage: '2.4% per annum',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: '$3,000,000 – $5,000,000',
    },
    {
      cellType: 'body',
      defaultMessage: '1.8% per annum',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: '$5,000,000 – $7,500,000',
    },
    {
      cellType: 'body',
      defaultMessage: '1.2% per annum',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: '$7,500,000 – $10,000,000',
    },
    {
      cellType: 'body',
      defaultMessage: '0.7% per annum',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: 'More than $10,000,000',
    },
    {
      cellType: 'body',
      defaultMessage: '0.0% per annum',
    },
  ],
];

export default tableData;
