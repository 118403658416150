import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { StepContainer } from '@app/components-new/StepContainer';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { formatAddress } from '@app/utils/formatAddress';
import { ArrowForward, ErrorOutlineRounded as ErrorOutlineRoundedIcon, Loop } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Button, Card, Divider, Loader, Typography } from '@stenngroup/ui-kit';
import { sleep } from '@stenngroup/utilities';
import { match } from 'ts-pattern';

import { CompanyDetails } from './CompanyDetails';
import { RequestLimitForm } from './RequestLimitForm';

interface IRequestLimitProps {}

export const RequestLimit: FC<IRequestLimitProps> = () => {
  const { handleNextStep, getStateByStep } = useFlowTransitionsContext();
  const httpClient = useHttpClient();

  const prevStepData = getStateByStep<{ counterpartyCompanyId: string; tradeRelationId: string }>(
    EFlowStep.SELECT_NEW_TRADE_PARTNER
  );

  const query = useQuery({
    queryKey: [apiRoutes.BuyerGetBuyer, prevStepData.counterpartyCompanyId],
    queryFn: ({ signal }) =>
      httpClient(apiRoutes.BuyerGetBuyer, 'post', {
        signal,
        data: {
          id: prevStepData.counterpartyCompanyId,
        },
      }),
  });

  const { mutate: requestLimit, isLoading: isSubmitting } = useMutation({
    // TODO will be replaced with actual endpoint call
    mutationFn: (_limit: number) => sleep(2000),
    onSuccess: () => {
      handleNextStep();
    },
  });

  const handleSubmit = (limit: number | undefined): void => {
    const totalLimit = query.data?.totalLimit ?? 0;

    if (limit && limit > totalLimit) {
      requestLimit(limit);
    } else {
      handleNextStep();
    }
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.requestLimit" />}
      actions={
        <Button
          variant="primary"
          type="submit"
          form="limitForm"
          endIcon={<ArrowForward />}
          disabled={isSubmitting || query.status === 'loading'}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      {match(query)
        .with({ status: 'loading' }, () => <Loader withWrapper />)
        .with({ status: 'error' }, () => (
          <Alert
            color="error"
            icon={<ErrorOutlineRoundedIcon />}
            action={
              <Button size="small" variant="tertiary" endIcon={<Loop />} onClick={() => query.refetch()}>
                <FormattedMessage id="constants.universalMessages.tryAgain" />
              </Button>
            }
          >
            <Typography.Body1>
              <FormattedMessage id="constants.universalMessages.somethingWentWrong" />
            </Typography.Body1>
          </Alert>
        ))
        .with({ status: 'success' }, ({ data }) => (
          <Card
            sx={(theme) => ({
              padding: 2,
              boxShadow: 'none',
              border: `1px solid ${theme.palette.grey['200']}`,
              borderRadius: theme.shape.borderRadius / 2,
            })}
          >
            <CompanyDetails
              name={data.counterpartyCompanyName || ''}
              address={formatAddress({
                street: data.address?.street,
                state: data.address?.state,
                city: data.address?.city,
                postalCode: data.address?.postalCode,
                countryCode: data.address?.countryName,
              })}
              duns={data.counterpartyDuns || ''}
            />

            <Divider sx={{ borderWidth: 1, marginBlock: 1.5 }} />

            <RequestLimitForm
              status={data.status}
              totalLimit={data.totalLimit ?? 0}
              requestedLimit={data.requestedLimit ?? 0}
              onSubmit={handleSubmit}
            />
          </Card>
        ))
        .otherwise(() => null)}
    </StepContainer>
  );
};
