import { AnyObject } from '@app/core/utilities';
import jwtDecode from 'jwt-decode';

export const checkIsAccessTokenValid = (accessToken: string) => {
  try {
    const accessData: AnyObject = jwtDecode(accessToken);

    if (accessData && accessData.exp) {
      return accessData.exp > parseInt(`${Date.now() / 1000}`, 10);
    }

    return false;
  } catch {
    return false;
  }
};
