import { useEffect } from 'react';

import { useGetProfileV2 } from '@app/api/profile/useGetProfileV2';
import { setHeapIdentityIfLoaded, setHotjarIdentity } from '@app/hooks/useSetAnalyticsIdentity/helpers';

export const useSetAnalitycsIdentity = (): void => {
  const { data: profile } = useGetProfileV2();

  useEffect(() => {
    setHeapIdentityIfLoaded(profile?.userId);
    setHotjarIdentity(profile?.userId);
  }, [profile?.userId]);
};
