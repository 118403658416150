import { EDevFeature } from '@app/components-new/DevFeatureToggleContainer/types';
import { ELocalStorageKey } from '@app/storage-keys';

export const getDevFeaturesFromStorage = (): Record<EDevFeature, boolean> => {
  const fallback = {
    [EDevFeature.NEW_DEAL_FLOW]: false,
  };
  const jsonString = localStorage.getItem(ELocalStorageKey.DevFeatures);
  if (!jsonString) return fallback;
  try {
    return JSON.parse(jsonString);
  } catch {
    return fallback;
  }
};
