import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

// TODO Replace api calls when a new one is ready
import { getSignerRequest } from '@app/components-new/Flow/AddContractorSignatory/queries/getSignerRequest';
// TODO Replace api calls when a new one is ready
import { EContractorType } from '@app/components-new/Flow/types/common';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { StepContainer } from '@app/components-new/StepContainer';
import { ERequestKey } from '@app/types/request';
import { ArrowForward, ErrorOutlineRounded as ErrorOutlineRoundedIcon, Loop } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Button, Card, Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { EDealTradeRole } from '../../context/types';

import { SignatoryForm } from './SignatoryForm';
import { addCounterPartyContactRequest } from './mutations/addCounterPartyContactRequest';
import { IContactForm } from './types';

interface IAddTradePartnerSignatoryProps {}

export const AddTradePartnerSignatory: FC<IAddTradePartnerSignatoryProps> = () => {
  const { handleNextStep, getFlowState } = useFlowTransitionsContext();
  const { counterpartyCompanyId, tradeRole } = getFlowState();

  const counterpartyTradeRole = match<EDealTradeRole | null, EContractorType | null>(tradeRole)
    .with(EDealTradeRole.Supplier, () => EContractorType.Buyer)
    .with(EDealTradeRole.Buyer, () => EContractorType.Supplier)
    .otherwise(() => null);

  // TODO Replace api calls when a new one is ready
  const {
    data: signerResponse,
    refetch: refetchSigner,
    status: signerStatus,
  } = useQuery(
    [ERequestKey.GetSigner, counterpartyCompanyId, counterpartyTradeRole],
    () =>
      counterpartyCompanyId && counterpartyTradeRole
        ? getSignerRequest(counterpartyCompanyId, counterpartyTradeRole)
        : undefined,
    {
      cacheTime: 0,
      enabled: !!counterpartyCompanyId,
    }
  );

  // TODO Replace api calls when a new one is ready
  const { mutate: addCounterPartyContact, isLoading: isCounterPartyContactLoading } = useMutation(
    addCounterPartyContactRequest,
    {
      onSuccess: () => {
        handleNextStep();
      },
    }
  );

  const addAuthorizedSignatory = async (requestData: IContactForm) => {
    if (!counterpartyCompanyId || !counterpartyTradeRole) {
      return;
    }

    if (!signerResponse?.canAddNewSigner) {
      handleNextStep();
      return;
    }

    addCounterPartyContact({
      data: {
        ...requestData,
        phoneNumber: requestData.phoneNumber,
        companyId: counterpartyCompanyId,
        companyRole: counterpartyTradeRole as unknown as EDealTradeRole,
        beSigner: true,
      },
    });
  };

  const handleSubmit = (data: IContactForm) => {
    if (signerResponse?.hasSigner && signerResponse.isConfirmed) {
      handleNextStep();
    }

    addAuthorizedSignatory(data);
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.addTradePartnerSignatory" />}
      actions={
        <Button
          variant="primary"
          type="submit"
          form="signatoryForm"
          endIcon={<ArrowForward />}
          disabled={signerStatus === 'loading' || isCounterPartyContactLoading}
          isLoading={isCounterPartyContactLoading}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        {match({ status: signerStatus })
          .with({ status: 'error' }, () => (
            <Alert
              color="error"
              icon={<ErrorOutlineRoundedIcon />}
              action={
                <Button size="small" variant="tertiary" endIcon={<Loop />} onClick={() => refetchSigner()}>
                  <FormattedMessage id="constants.universalMessages.tryAgain" />
                </Button>
              }
            >
              <Typography.Body1>
                <FormattedMessage id="constants.universalMessages.somethingWentWrong" />
              </Typography.Body1>
            </Alert>
          ))
          .otherwise(() => (
            <SignatoryForm onSubmit={handleSubmit} signer={signerResponse} />
          ))}
      </Card>
    </StepContainer>
  );
};
