import { CURRENCIES_OPS, DEFAULT_DATE_LOCALE, DEFAULT_DATE_OPTIONS, REGEXP } from '@app/core/constants';

import { toDate } from './date';

export const formatNumber = ({
  value,
  precision = 2,
  cleanTrailingZeros = true,
  prefix = '',
  postfix = '',
  fallback = '-',
  delimiter = ',',
}) => {
  // eslint-disable-next-line no-restricted-globals
  if (value === null || value === '' || typeof value === 'undefined' || !isFinite(value)) {
    return fallback;
  }
  const prefixWithoutNoneAndMulti = () => {
    const isCurrencyNone = prefix.includes(CURRENCIES_OPS.None.symbol);
    const isCurrencyMulti = prefix.includes(CURRENCIES_OPS.MultiCurrency.symbol);
    if (isCurrencyNone || isCurrencyMulti) {
      return '';
    }
    return prefix;
  };
  const roundedNumber = Number(value).toFixed(precision);
  const cleanNumber = cleanTrailingZeros ? parseFloat(roundedNumber) : roundedNumber;
  const formattedNumber = String(cleanNumber).replace(new RegExp(REGEXP.monetarySeparation, 'g'), delimiter);

  return `${prefixWithoutNoneAndMulti()}${formattedNumber}${postfix}`.trim();
};

/**
 *  Formats date by locale
 *  @param {string|object } date - ISO date string or Date object
 *  @param {string} locale - formatting locale
 *  @param {object} options - formatting options
 *  @return {string} - formatted string
 *  */

export const formatDate = ({ date, locale = DEFAULT_DATE_LOCALE, options = DEFAULT_DATE_OPTIONS }) => {
  const normalizedDate = toDate(date);

  if (!normalizedDate) {
    return 'Invalid date';
  }

  return normalizedDate.toLocaleDateString(locale, options);
};

export const parseStringValue = (value) =>
  // eslint-disable-next-line no-useless-escape
  parseFloat(String(value).replace(/\,+/g, ''));

export const formatPhone = (prefix, number, fallback = null) => prefix + number || fallback;

export const escapeRegExp = (string) =>
  // eslint-disable-next-line no-useless-escape
  string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
