import { FC, useEffect, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { TEST_IDS } from '@app/core/constants';
import { ECookiesStorageKey } from '@app/storage-keys';
import Stack from '@mui/material/Stack';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Dropzone, Typography } from '@stenngroup/ui-kit';
import { EDropzoneFileStatus, IDropzoneFile } from '@stenngroup/ui-kit/lib/Dropzone/types';
import Cookies from 'js-cookie';

import { API_URL, MAX_FILE_SIZE, UPLOAD_STEP_ACCEPTABLE_FILE_TYPES } from './constants';
import { IUploadStepProps } from './types';

export const UploadStep: FC<IUploadStepProps> = ({
  values,
  title,
  documentType,
  onChange,
  allowMultipleFiles,
  onDelete,
  accept,
  disabled,
}) => {
  const { getAccessTokenSilently } = useAuth();
  const tokenAccessor = `${ECookiesStorageKey.AuthData}-accessToken`;
  const token = useRef<string | null>(Cookies.get(tokenAccessor) ?? null);
  const [error, setError] = useState<boolean>(false);

  const handleDelete = (data: { uid: string; status?: EDropzoneFileStatus }) => {
    onDelete(data.uid);
  };

  useEffect(() => {
    const getAccessToken = async () => {
      token.current = await getAccessTokenSilently();
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  return (
    <Stack gap={1}>
      <Typography.Body1SemiBold color={(t) => t.palette.text.primary}>{title}</Typography.Body1SemiBold>
      <Stack>
        <Dropzone
          data-testid={`${TEST_IDS.dropzone}_${documentType}`}
          name="file"
          helperText={<FormattedMessage id="components.Flow.InvoiceForm.UploadStep.fileSize" />}
          onDelete={handleDelete}
          size="thin"
          method="POST"
          action={`${API_URL}?documentType=${documentType}`}
          headers={{
            Authorization: `Bearer ${token.current}`,
          }}
          defaultFileList={values as IDropzoneFile[]}
          validation={{
            maxSize: MAX_FILE_SIZE,
            types: accept ?? UPLOAD_STEP_ACCEPTABLE_FILE_TYPES,
          }}
          isMultiple={allowMultipleFiles}
          onError={() => setError(true)}
          disabled={disabled}
          onSuccess={(response, file) => {
            onChange({
              documentId: response.fileId as unknown as string,
              documentType,
              uid: file.uid,
              name: (response.fileName as unknown as string) ?? file.uid,
            });
          }}
        />
        {error && (
          <Typography.Caption color={(t) => t.palette.error.main}>
            <FormattedMessage
              id="components.Flow.InvoiceForm.UploadStep.fileSizeExceed"
              values={{
                maxSize: MAX_FILE_SIZE,
              }}
            />
          </Typography.Caption>
        )}
      </Stack>
    </Stack>
  );
};
