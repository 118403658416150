/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import produce from 'immer';

import * as commonActionTypes from '../actionTypes/common';

const commonInitialState = {
  potentialLimit: {},
  isPotentialLimitReady: false,

  limitState: {},
  isLimitStateReady: false,

  dnbCompaniesList: [],
  isDnbCompaniesListReady: false,
  isDnbCompaniesListFailed: false,

  countriesList: [],
  isCountriesListReady: false,

  blackList: [],
  isBlackListReady: false,

  countryStatesList: [],
  isCountryStatesListReady: false,

  representativeContactsCount: 0,
  representativeContactsList: [],
  areRepresentativeContactsReady: false,

  isContactRepresentativeInfoReady: false,
  contactInfo: {},

  isAccountingsSoftwareListReady: false,
  accountingsSoftwareList: {},

  isConnectDataSourceSuccess: false,

  integrationStatus: {},
  integrationStatusReady: false,

  isTradeRelationEligible: null,

  ocrolusRequiredMonthList: [],
  isOcrolusRequiredMonthListReady: false,
};

export const commonReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case commonActionTypes.GET_ACCOUNTING_SOFTWARE_REQUEST:
        draft.isAccountingsSoftwareListReady = false;
        break;
      case commonActionTypes.GET_ACCOUNTING_SOFTWARE_SUCCESS:
        draft.accountingsSoftwareList = action.payload;
        draft.isAccountingsSoftwareListReady = true;
        break;
      case commonActionTypes.RESET_ACCOUNTING_SOFTWARE:
        draft.accountingsSoftwareList = {};
        draft.isAccountingsSoftwareListReady = false;
        break;

      case commonActionTypes.GET_CONNECT_DATA_SOURCE_SUCCESS:
        draft.isConnectDataSourceSuccess = true;
        break;
      case commonActionTypes.GET_CONNECT_DATA_SOURCE_REQUEST:
      case commonActionTypes.GET_CONNECT_DATA_SOURCE_FAILURE:
      case commonActionTypes.RESET_DATA_SOURCE_ACTION:
        draft.isConnectDataSourceSuccess = false;
        break;

      case commonActionTypes.GET_STORED_LIMIT_REQUEST:
        draft.isPotentialLimitReady = false;
        break;

      case commonActionTypes.SET_ACCOUNTING_SOFTWARE_DATA:
        draft.softwareData = action.payload;
        break;

      case commonActionTypes.GET_REPRESENTATIVE_CONTACT_INFO_REQUEST:
        draft.isContactRepresentativeInfoReady = false;
        break;

      case commonActionTypes.GET_REPRESENTATIVE_CONTACT_INFO_SUCCESS:
        draft.contactInfo = action.payload.contactInfo;
        draft.isContactRepresentativeInfoReady = true;
        break;

      case commonActionTypes.GET_REPRESENTATIVE_CONTACTS_REQUEST:
        draft.areRepresentativeContactsReady = false;
        break;

      case commonActionTypes.GET_REPRESENTATIVE_CONTACTS_SUCCESS:
        draft.representativeContactsList = action.payload.representativeContactsList;
        draft.representativeContactsCount = action.payload.representativeContactsCount;
        draft.areRepresentativeContactsReady = true;
        break;

      case commonActionTypes.GET_LIMIT_STATE_REQUEST:
        draft.isLimitStateReady = false;
        break;
      case commonActionTypes.GET_LIMIT_STATE_SUCCESS:
        draft.limitState = action.payload;
        draft.isLimitStateReady = true;
        break;
      case commonActionTypes.RESET_LIMIT_STATE:
        draft.limitState = {};
        draft.isLimitStateReady = false;
        break;

      case commonActionTypes.GET_INTEGRATION_STATUS_SUCCESS:
        draft.integrationStatus = action.payload;
        draft.integrationStatusReady = true;
        break;
      case commonActionTypes.GET_INTEGRATION_STATUS_REQUEST:
      case commonActionTypes.GET_INTEGRATION_STATUS_FAILURE:
        draft.integrationStatusReady = false;
        break;

      case commonActionTypes.RESET_INTEGRATION_STATUS:
        draft.integrationStatus = {};
        draft.integrationStatusReady = false;
        break;

      case commonActionTypes.GET_STORED_LIMIT_SUCCESS:
        draft.potentialLimit = action.payload;
        draft.isPotentialLimitReady = true;
        break;

      case commonActionTypes.SET_POTENTIAL_LIMIT:
        draft.potentialLimit = action.payload;
        break;

      case commonActionTypes.SET_POTENTIAL_LIMIT_READINESS:
        draft.isPotentialLimitReady = action.payload;
        break;

      case commonActionTypes.RESET_POTENTIAL_LIMIT:
        draft.potentialLimit = {};
        draft.isPotentialLimitReady = false;
        break;

      case commonActionTypes.GET_DNB_COMPANIES_REQUEST:
        draft.isDnbCompaniesListReady = false;
        break;

      case commonActionTypes.GET_DNB_COMPANIES_SUCCESS:
        draft.dnbCompaniesList = action.payload.response;
        draft.isDnbCompaniesListReady = true;
        draft.isDnbCompaniesListFailed = false;
        break;

      case commonActionTypes.GET_DNB_COMPANIES_FAILURE:
        draft.isDnbCompaniesListFailed = true;
        break;

      case commonActionTypes.RESET_DNB_COMPANIES:
        draft.isDnbCompaniesListReady = false;
        draft.isDnbCompaniesListFailed = false;
        break;

      case commonActionTypes.GET_COUNTRIES_SUCCESS:
        draft.countriesList = action.payload;
        draft.isCountriesListReady = true;
        break;

      case commonActionTypes.GET_COUNTRIES_REQUEST:
      case commonActionTypes.GET_COUNTRIES_FAILURE:
        draft.countriesList = [];
        draft.isCountriesListReady = false;
        break;

      case commonActionTypes.GET_BLACK_LIST_SUCCESS:
        draft.blackList = action.payload;
        draft.isBlackListReady = true;
        break;

      case commonActionTypes.GET_BLACK_LIST_REQUEST:
      case commonActionTypes.GET_BLACK_LIST_FAILURE:
        draft.blackList = [];
        draft.isBlackListReady = false;
        break;

      case commonActionTypes.GET_COUNTRY_STATES_SUCCESS:
        draft.countryStatesList = action.payload;
        draft.isCountryStatesListReady = true;
        break;

      case commonActionTypes.GET_COUNTRY_STATES_REQUEST:
      case commonActionTypes.GET_COUNTRY_STATES_FAILURE:
        draft.countryStatesList = [];
        draft.isCountryStatesListReady = false;
        break;

      case commonActionTypes.GET_IS_TRADE_RELATION_ELIGIBLE_SUCCESS:
        draft.isTradeRelationEligible = action.payload;
        break;

      case commonActionTypes.GET_OCROLUS_REQUIRED_MONTHS_REQUEST:
      case commonActionTypes.GET_OCROLUS_REQUIRED_MONTHS_FAILURE:
        draft.isOcrolusRequiredMonthListReady = false;
        break;

      case commonActionTypes.GET_OCROLUS_REQUIRED_MONTHS_SUCCESS:
        draft.isOcrolusRequiredMonthListReady = true;
        draft.ocrolusRequiredMonthList = action.payload;
        break;

      case commonActionTypes.RESET_OCROLUS_REQUIRED_MONTHS:
        draft.isOcrolusRequiredMonthListReady = false;
        draft.ocrolusRequiredMonthList = [];
        break;

      default:
        break;
    }
  },
  { ...commonInitialState }
);
