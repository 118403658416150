import _get from 'lodash.get';
import PropTypes from 'prop-types';

const SearchIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5C6.136 3.5 3 6.636 3 10.5C3 14.364 6.136 17.5 10 17.5C13.864 17.5 17 14.364 17 10.5C17 6.636 13.864 3.5 10 3.5Z"
        stroke={_get(colors, '0', 'currentColor')}
        strokeWidth="2"
      />
      <path d="M15 15.5L20 20.5" stroke={_get(colors, '0', 'currentColor')} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

SearchIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default SearchIcon;
