import { Nullable } from '@app/core/utilities';

export interface IStorage {
  set: (key: string, value: string) => void;
  get: (key: string) => Nullable<string>;
  has: (key: string) => boolean;
  delete: (key: string, domain?: string) => void;
  getAll: () => Record<string, string>;
}

interface IBaseStorageService {
  setValue: (key: string, value: string) => void;
  getValue: (key: string) => Nullable<string>;
  hasValue: (key: string) => boolean;
  deleteValue: (key: string) => void;
  setUserId: (userId: string) => void;
  getUserId: () => string;
}

export class BaseStorageService implements IBaseStorageService {
  storage: IStorage;

  private userId = '';

  private privateKeys: string[];

  constructor(storage: IStorage, privateKeys: string[] = []) {
    this.storage = storage;
    this.privateKeys = privateKeys;
  }

  private isPrivateKey = (key: string) => this.privateKeys.some((privateKey) => privateKey === key);

  protected getProperty = (key: string) => {
    const isPrivateKey = this.isPrivateKey(key);

    if (isPrivateKey && this.userId) {
      return `${key}_${this.userId}`;
    }

    return key;
  };

  setValue = (key: string, value: string) => {
    const property = this.getProperty(key);

    this.storage.set(property, value);
  };

  getValue = (key: string) => {
    const property = this.getProperty(key);

    return this.storage.get(property);
  };

  hasValue = (key: string) => {
    const property = this.getProperty(key);

    return this.storage.has(property);
  };

  deleteValue = (key: string, domain?: string) => {
    const property = this.getProperty(key);

    this.storage.delete(property, domain);
  };

  private deleteOtherAccountData = () => {
    const storageValues = this.storage.getAll();

    for (const key of Object.keys(storageValues)) {
      const isPrivateKey = this.isPrivateKey(key);

      if (isPrivateKey && !key.endsWith(this.userId)) {
        this.storage.delete(key);
      }
    }
  };

  setUserId = (userId: string) => {
    this.userId = userId;

    this.deleteOtherAccountData();
  };

  getUserId = () => this.userId;
}
