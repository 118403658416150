import React, { memo } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Divider, Loader } from '@stenngroup/ui-kit';

import messages from '../../messages';
import { INotification, INotificationsInfoResponse } from '../../types';

import * as SC from './styles';

interface IProps {
  isNotificationsListOpen: boolean;
  readNotifications?: INotification[];
  newNotifications?: INotification[];
  notificationsCount?: INotificationsInfoResponse;
  isMobile: boolean;
  isFetching: boolean;
  isMutating: boolean;
  setNotificationsListVisibility: (isVisible: boolean) => void;
  deleteAllHandle: () => void;
  readAllHandle: () => void;
  readOneHandle: (notification: INotification) => void;
  isBuyer: boolean;
}

// eslint-disable-next-line react/display-name
const _NotificationList = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      isNotificationsListOpen,
      newNotifications = [],
      readNotifications = [],
      notificationsCount = { newCount: 0, readCount: 0 },
      isFetching,
      isMutating,
      setNotificationsListVisibility,
      deleteAllHandle,
      readAllHandle,
      readOneHandle,
    }: IProps,
    ref
  ) => {
    const { newCount, readCount } = notificationsCount;
    const navigate = useNavigate();

    const goTo = (route: string) => {
      setNotificationsListVisibility(false);
      const targetURL: URL = new URL(route, window.location.origin);
      if (targetURL.hash) {
        navigate({
          pathname: window.location.pathname,
          search: targetURL.search,
          hash: targetURL.hash,
        });
        return;
      }
      navigate({
        pathname: targetURL.pathname,
        search: targetURL.search,
        hash: targetURL.hash,
      });
    };

    const handleCheckNotificationsUrl = (url: string) => {
      goTo(url);
    };

    const hasNewNotifications = newNotifications.length > 0;
    const hasReadedNotifications = readNotifications.length > 0;
    const hasNotifications = hasNewNotifications || hasReadedNotifications;

    const showContainerLoader = isMutating;

    // to avoid scroll stuck when notfications are not loaded yet, but list height is equal its container height
    const showHiddenScrollableSpace = readCount + newCount > readNotifications.length + newNotifications.length;

    const onNewNotificationClick = (notification: INotification) => {
      readOneHandle(notification);
      if (notification.route) {
        handleCheckNotificationsUrl(notification.route);
      }
    };

    const onReadNotificationClick = (notification: INotification) => {
      if (notification.route) {
        handleCheckNotificationsUrl(notification.route);
      }
    };
    return (
      <SC.NotificationListContainer isNotificationsListOpen={isNotificationsListOpen}>
        {showContainerLoader && <Loader withWrapper={true} />}
        {!showContainerLoader && (
          <>
            <SC.ScrollableContainer ref={ref} isNotificationsListEmpty={!hasNotifications || showContainerLoader}>
              <SC.NotificationListHeader isListEmpty={!hasNotifications}>
                <SC.NewNotifications>
                  {hasNewNotifications ? (
                    <FormattedMessage
                      {...messages.newNotifications}
                      values={{
                        count: newCount && newCount,
                      }}
                    />
                  ) : (
                    <FormattedMessage {...messages.seenEarlier} />
                  )}
                </SC.NewNotifications>
                <SC.DeleteAndMark count={newCount}>
                  <SC.DeleteAll onClick={deleteAllHandle} count={newCount}>
                    <FormattedMessage {...messages.deleteAll} />
                  </SC.DeleteAll>
                  {hasNewNotifications && (
                    <>
                      <SC.Divider />
                      <SC.MarkAllAsRead onClick={readAllHandle}>
                        <FormattedMessage {...messages.markAllAsRead} />
                      </SC.MarkAllAsRead>
                    </>
                  )}
                </SC.DeleteAndMark>
                <SC.CloseButton onClick={() => setNotificationsListVisibility(false)}>
                  <SC.StyledSvgIcon name="cross" />
                </SC.CloseButton>
              </SC.NotificationListHeader>
              {hasNotifications && (
                <Divider
                  sx={{
                    mt: 1,
                  }}
                />
              )}
              {!hasNotifications && (
                <SC.EmptyListMessage>
                  <FormattedMessage {...messages.emptyList} />
                </SC.EmptyListMessage>
              )}
              {hasNewNotifications &&
                newNotifications.map((notification: INotification) => (
                  <SC.NewNotificationsList key={notification.id} onClick={() => onNewNotificationClick(notification)}>
                    <SC.NewNotificationsDate>
                      <SC.NewNotificationRedIcon />
                      <SC.NewNotificationDateText>{notification.date}</SC.NewNotificationDateText>
                    </SC.NewNotificationsDate>
                    <SC.NotificationText>{notification.message}</SC.NotificationText>

                    {notification.viewMode && (
                      <SC.ViewButton>
                        <FormattedMessage {...messages.view} />
                      </SC.ViewButton>
                    )}
                  </SC.NewNotificationsList>
                ))}
              {hasReadedNotifications && hasNewNotifications && (
                <SC.SeeEarlier>
                  <FormattedMessage {...messages.seenEarlier} />
                </SC.SeeEarlier>
              )}
              {hasReadedNotifications &&
                readNotifications.map((notification: INotification) => (
                  <SC.ReadedNotification
                    viewMode={notification.viewMode}
                    key={notification.id}
                    onClick={() => onReadNotificationClick(notification)}
                  >
                    <SC.NotificationsDate>{notification.date}</SC.NotificationsDate>
                    <SC.NotificationText>{notification.message}</SC.NotificationText>
                    {notification.viewMode && (
                      <SC.ViewButton>
                        <FormattedMessage {...messages.view} />
                      </SC.ViewButton>
                    )}
                  </SC.ReadedNotification>
                ))}
              {showHiddenScrollableSpace && <SC.HiddenScrollableSpace />}
            </SC.ScrollableContainer>
            {isFetching && (
              <SC.ListLoaderContainer>
                <Loader />
              </SC.ListLoaderContainer>
            )}
          </>
        )}
      </SC.NotificationListContainer>
    );
  }
);

const NotificationsList = memo(_NotificationList);

export default NotificationsList;
