import { FC } from 'react';

import { CompanyStatus } from '@app/components-new/CompanyStatus';
import { ITradePartnersListProps } from '@app/components-new/FlowTransitions/screens/SelectTradePartner/TradePartnersList/types';
import { ITradePartner } from '@app/components-new/FlowTransitions/screens/SelectTradePartner/types';
import { RequestLimitText } from '@app/components-new/RequestLimitText';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { TEST_IDS } from '@app/core/constants';
import { truncateText } from '@app/utils/truncateText';
import { Stack, useTheme } from '@mui/material';
import { ChoiceBox, Typography } from '@stenngroup/ui-kit';

const CONTAINER_HEIGHT = 450;

const TradePartnerDetails: FC<Pick<ITradePartner, 'status' | 'totalLimit'> & { isLoading?: boolean }> = ({
  isLoading,
  status,
  totalLimit,
}) => {
  const theme = useTheme();
  return (
    <Stack gap={0.5}>
      <Stack direction="row" gap={0.5}>
        <TextSkeleton
          status={isLoading ? 'loading' : 'idle'}
          style={{
            height: calculateTypographyHeight(theme.typography.body2),
          }}
        >
          <CompanyStatus status={status ?? 'Unknown'} />
          <RequestLimitText status={status ?? 'Unknown'} value={totalLimit ?? 0} />
        </TextSkeleton>
      </Stack>
    </Stack>
  );
};

export const TradePartnersList: FC<ITradePartnersListProps> = ({
  tradePartners,
  selectedTradePartner,
  onTradePartnerSelect,
}) => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1}>
      {tradePartners.map((tradePartner) => (
        <ChoiceBox
          key={tradePartner.counterpartyCompanyId}
          label={truncateText(tradePartner.counterpartyCompanyName ?? '', 30)}
          text={<TradePartnerDetails status={tradePartner.status} totalLimit={tradePartner.totalLimit} />}
          type="radio"
          name="company"
          value={tradePartner.counterpartyCompanyId}
          checked={selectedTradePartner?.counterpartyCompanyId === tradePartner.counterpartyCompanyId}
          onChange={() => onTradePartnerSelect?.(tradePartner)}
          fullWidth
          data-testid={TEST_IDS.companyItem}
          disabled={tradePartner.status === 'FinancingDenied'}
        />
      ))}
    </Stack>
  );
};

export const TradePartnersListSkeleton = () => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1} height={CONTAINER_HEIGHT} overflow="auto">
      {Array.from({ length: 5 }).map((_, index) => (
        <ChoiceBox
          key={index}
          label={
            <TextSkeleton status="loading">
              <Typography.Body1 />
            </TextSkeleton>
          }
          text={<TradePartnerDetails isLoading status="Unknown" />}
          type="radio"
          name="company"
          value=""
          fullWidth
          data-testid={TEST_IDS.companyItem}
          disabled
        />
      ))}
    </Stack>
  );
};
