import { useEffect } from 'react';

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { SentryFingerprintError } from '@app/common/sentry/SentryFingerprintError';
import * as Sentry from '@sentry/react';

if (window._STENN_.FCG_SENTRY_DSN && import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: window._STENN_.FCG_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    tracesSampleRate: window._STENN_.ENVIRONMENT === 'live' ? 1 : 0.1,
    environment: window._STENN_.ENVIRONMENT,
    beforeSend: (event, hint) => {
      const exception = hint.originalException;

      if (exception instanceof Error) {
        if (exception.message.includes('Failed to fetch dynamically imported module')) {
          if (window.confirm('Reload is required for application to work properly. Do you want to reload now?')) {
            window.location.reload();
            return null;
          }
        }

        if (exception instanceof SentryFingerprintError) {
          const fingerprint = exception.message.replace(/\W+/g, '-');
          event.fingerprint = [fingerprint];
        }
      }
      return event;
    },
  });
}
