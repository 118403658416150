import { Dispatch, RefObject, SetStateAction, useCallback, useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { NOTIFICATIONS_PER_PAGE, NOTIFICATIONS_REQUESTS_KEYS } from '@app/core/constants';
import { usePrevious, useSafeState, useScrollReachesEnd } from '@app/core/hooks';

import { notificationTyping } from './notificationTyping';
import {
  useDeleteAllMutation,
  useNewNotificationsRequest,
  useNotificationsCountRequest,
  useReadAllMutation,
  useReadCurrentNotificationMutation,
  useReadNotificationsRequest,
} from './requests';
import { INotification } from './types';

const uniqBy = <T>(arr: T[], iteratee: string | Function) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item: Record<string, string>) => item[prop];
  }

  return arr.filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};

export function useNotificationsPaging(notificationsListRef: RefObject<HTMLDivElement>) {
  const { formatMessage } = useIntl();
  const [newSkip, setNewSkip] = useSafeState(0);
  const [readSkip, setReadSkip] = useSafeState(0);

  const queryClient = useQueryClient();

  const {
    data: notificationsCounts,
    isSuccess: countRequestIsSuccess,
    isFetching: countIsFetching,
  } = useNotificationsCountRequest();

  const { mutate: deleteAllMutate, isLoading: deleteAllIsLoading } = useDeleteAllMutation();
  const { mutate: readAllMutate, isLoading: readAllIsLoading } = useReadAllMutation();

  const [newNotifications, setNewNotifications] = useSafeState([]) as [
    INotification[],
    Dispatch<SetStateAction<INotification[]>>,
  ];
  const [readNotifications, setReadNotifications] = useSafeState([]) as [
    INotification[],
    Dispatch<SetStateAction<INotification[]>>,
  ];

  // update notifications state if target notification should be read
  const moveNotificationToRead = (targetId: string) => {
    const target = newNotifications.find((item) => item.id === targetId);
    if (target) {
      const readNotificationsUpdated = [...readNotifications, { ...target }];
      const newNotificationsUpdated = newNotifications.filter((item) => item.id !== targetId);

      setNewNotifications(newNotificationsUpdated);
      setReadNotifications(readNotificationsUpdated);
      setNewSkip(newNotificationsUpdated.length);
    }
  };

  const { mutate: readOneMutate, isLoading: readOneIsLoading } = useReadCurrentNotificationMutation((notification) => {
    moveNotificationToRead(notification.id);
  });

  const isMutating = deleteAllIsLoading || readAllIsLoading || readOneIsLoading;

  const deleteAll = useCallback(() => {
    deleteAllMutate();
    setNewNotifications([]);
    setReadNotifications([]);
    setNewSkip(0);
    setReadSkip(0);
  }, [deleteAllMutate]);

  const readAll = useCallback(() => {
    readAllMutate();
    setNewSkip(0);
    setReadSkip(readNotifications.length + newNotifications.length);
  }, [readAllMutate, readNotifications, newNotifications]);

  const readOne = useCallback(
    (notification: INotification) => {
      readOneMutate(notification);
    },
    [readOneMutate]
  );

  const newCount = notificationsCounts?.newCount;
  const readCount = notificationsCounts?.readCount;

  const prevNewCount = usePrevious(newCount) as number | undefined;
  const prevReadCount = usePrevious(readCount) as number | undefined;

  const {
    data: newNotificationsData,
    isLoading: newNotificationsIsLoading,
    isFetching: newNotificationsIsFetching,
  } = useNewNotificationsRequest(NOTIFICATIONS_PER_PAGE, newSkip);

  const allNewNotificationsAreLoaded = newNotifications.length === newCount;

  const canUpdateReadNotifications =
    countRequestIsSuccess &&
    !countIsFetching &&
    allNewNotificationsAreLoaded &&
    !isMutating &&
    readCount !== undefined &&
    readCount > readNotifications.length;

  const {
    data: readNotificationsData,
    isLoading: readNotificationsIsLoading,
    isFetching: readNotificationsIsFetching,
  } = useReadNotificationsRequest(canUpdateReadNotifications, NOTIFICATIONS_PER_PAGE, readSkip);

  const isLoading = newNotificationsIsLoading || readNotificationsIsLoading;
  const isFetching = newNotificationsIsFetching || readNotificationsIsFetching;

  // update notifications if new is received from hub
  useEffect(() => {
    if (newCount !== undefined && readCount !== undefined && prevNewCount !== undefined && newCount !== prevNewCount) {
      if (newCount === 1 && readCount === 0) {
        queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications);
      } else if (newCount - prevNewCount === 1) {
        queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications);
        setNewSkip(Math.max(newCount - 1, 0));
        // need to substract because count is updated earlier then list of notifications.
        // e.g. newCount=2, readCount=0, so we need to substract 1 to get the 2nd new element (newSkip=1)
      }
    }
  }, [newCount, prevNewCount, readCount]);

  // concat new notification when page changes
  useEffect(() => {
    if (newNotificationsData) {
      const parsedNewNotifications = newNotificationsData.map((data) => notificationTyping(data, formatMessage));

      setNewNotifications((old) => {
        const result = uniqBy([...old, ...(parsedNewNotifications as INotification[])], 'id');

        return result;
      });
    }
  }, [newNotificationsData]);

  // concat read notification when page changes
  useEffect(() => {
    if (readNotificationsData && !isLoading && !isFetching) {
      const parsedReadNotifications = readNotificationsData.map((data) => notificationTyping(data, formatMessage));

      setReadNotifications((old) => {
        // unqiBy hack to prevent dublicated via query racecondition
        const result = uniqBy([...old, ...(parsedReadNotifications as INotification[])], 'id');
        return result;
      });
    }
  }, [readNotificationsData, isLoading, isFetching]);

  // reset only read notifications to 0 and concat them to nonRead
  useEffect(() => {
    if (readCount !== prevReadCount && newCount !== prevNewCount && newCount === 0 && readCount) {
      setReadNotifications((old) => {
        const readNotificationsUpdated = [...old, ...newNotifications];
        // unqiBy hack to prevent dublicated via query racecondition
        const result = uniqBy(readNotificationsUpdated, 'id');
        return result;
      });
      setNewNotifications([]);
    }
  }, [prevReadCount, prevNewCount, readCount, newCount, newNotifications]);

  const onScrollReachesEndHandler = useCallback(
    (page: number) => {
      if (
        newCount !== undefined &&
        readCount !== undefined &&
        !isLoading &&
        page * NOTIFICATIONS_PER_PAGE < newCount + readCount
      ) {
        if (newCount > newNotifications.length) {
          setNewSkip(page * NOTIFICATIONS_PER_PAGE);
        } else {
          setReadSkip(readNotifications.length);
        }
      }
    },
    [newCount, isLoading, readCount, newNotifications, readNotifications]
  );

  useScrollReachesEnd(notificationsListRef, onScrollReachesEndHandler, isFetching);

  return [
    newNotifications,
    readNotifications,
    notificationsCounts,
    deleteAll,
    readAll,
    readOne,
    isFetching,
    isMutating,
  ] as const;
}
