import { BASE_API_URLS, parseResponse } from '@app/core/api';
import { IError, ISendLogOptions, LEVEL_CODES, LOG_ENTRY_TYPES, TEMPLATES } from '@app/core/logger';
import { AnyObject, getApiQuery } from '@app/core/utilities';
import * as Sentry from '@sentry/react';

import { IAuth0Config, IAuthProviderErrorLogger, TRequestHeadersExtender } from './types';

const fallback = () => {
  console.error('Auth0Request should be initialized with "configure" and "extendRequestHeaders" methods before usage');
};

const errorLoggerFallbackInstance: IAuthProviderErrorLogger = {
  send: fallback,
};

export class Auth0Request {
  static _requestHeadersExtender: TRequestHeadersExtender = fallback as TRequestHeadersExtender;
  static _errorLoggerInstance: IAuthProviderErrorLogger = errorLoggerFallbackInstance;
  static _domainHeader = 'GLOBAL';
  static _config: IAuth0Config;

  static configure({
    errorLogger,
    domainHeader,
    config,
  }: {
    errorLogger: IAuthProviderErrorLogger;
    domainHeader: string;
    config: IAuth0Config;
  }) {
    this._errorLoggerInstance = errorLogger;
    this._domainHeader = domainHeader;
    this._config = config;
  }

  static extendRequestHeaders = (extenderFunction: TRequestHeadersExtender) => {
    this._requestHeadersExtender = extenderFunction;
  };

  static getAuthRequestHeaders = (token?: string) => {
    const extendedHeaders = this._requestHeadersExtender();

    const requestHeaders: AnyObject = {
      ENV_DOMAIN: this._domainHeader,
      ...extendedHeaders,
    };

    if (token) {
      requestHeaders.Authorization = `Bearer ${token}`;
    }

    return requestHeaders;
  };

  // resolve scheme: [error, parsed response, response status code]
  static handleAuthResponse = async (
    response: Response,
    url: string,
    requestOptions?: RequestInit,
    params: AnyObject = {}
  ) => {
    const parsedResponse = await parseResponse(response);

    if (response.ok) {
      return [null, parsedResponse || true, response.status];
    }

    const error: AnyObject = new Error(`${response.status} ${response.statusText}`);
    const shouldSendFatalReport = params.isCritical || response.status === 500;
    const options: ISendLogOptions = {
      errorType: LOG_ENTRY_TYPES.api,
      level: shouldSendFatalReport ? null : LEVEL_CODES.information,
      template: shouldSendFatalReport ? null : TEMPLATES.badRequest,
    };

    error.requestUrl = url;
    error.requestOptions = requestOptions;
    error.response = parsedResponse;

    this._errorLoggerInstance.send(error as IError, options);
    Sentry.captureException(error);

    return [parsedResponse, null, response.status];
  };

  // resolve scheme: [error, parsed response, response status code]
  static handleAuthRequestFail = (error: IError, url: string, requestOptions?: RequestInit) => {
    const networkError: AnyObject = error;

    networkError.requestUrl = url;
    networkError.requestOptions = requestOptions;

    this._errorLoggerInstance.send(networkError as IError, {
      errorType: LOG_ENTRY_TYPES.network,
    });

    return [networkError, null, null];
  };

  static authApiRequest = (url: string, requestOptions?: RequestInit, params?: AnyObject) =>
    fetch(url, requestOptions)
      .then((response) => this.handleAuthResponse(response, url, requestOptions, params))
      .catch((error) => this.handleAuthRequestFail(error, url, requestOptions));

  static getTokenRequest = (userId: string, refreshToken: string) => {
    const url = `https://${this._config.domain}/oauth/token`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Accept: 'application/json; charset=utf-8',
      },
      credentials: 'omit',
      body: getApiQuery({
        grant_type: 'refresh_token',
        client_id: userId.includes('sms') ? this._config.passwordLessClientId : this._config.clientID,
        refresh_token: refreshToken,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static activateUserAccountRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ActivateLocalUserAccount?region=${this._domainHeader}`;
    const requestOptions = {
      method: 'PATCH',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static resendVerificationEmailRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ResendVerificationEmail?region=${this._domainHeader}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static checkSocialConsentRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const query = getApiQuery({
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Auth/NeedToShowSocialConsentScreen?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static activateSocialUserAccountRequest = (accessToken: string, userData: AnyObject) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ActivateSocialUserAccount`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify({
        region: this._domainHeader,
        ...userData,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static createEmailRegistrationRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/CreateEmailRegistration?region=${this._domainHeader}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static сreateEmailRegistrationOtp = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/CreateEmailRegistrationOtp?region=${this._domainHeader}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static resendVerificationEmailOtp = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ResendVerificationEmailOtp?region=${this._domainHeader}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static activateEmailRegistrationOtp = (accessToken: string, code: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ActivateEmailRegistrationOtp?region=${this._domainHeader}&code=${code}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static createPhoneRegistrationRequest = (
    accessToken: string,
    updateData: { firstName: string; lastName: string; email: string }
  ) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ActivatePasswordlessUserAccount`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify({
        region: this._domainHeader,
        firstName: updateData.firstName,
        lastName: updateData.lastName,
        emailAddress: updateData.email,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static phoneCodeVerifyRequest = (data: AnyObject) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const url = `${this._config.authApiPath}/api/v1.2/Auth/phone/token`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify({
        region: this._domainHeader,
        ...data,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static phoneLoginRequest = ({ mobilePhone, captchaToken }: { mobilePhone: string; captchaToken: string }) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const url = `${this._config.authApiPath}/api/v1.2/Auth/phone/login`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify({
        mobilePhone,
        token: captchaToken,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static changeEmailAccountPasswordRequest = (email: string) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const query = getApiQuery({
      email,
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Auth/SendChangePasswordEmail?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static changeEmailAccountPasswordOtpRequest = (email: string) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const query = getApiQuery({
      email,
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Auth/SendChangePasswordEmailOtp?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static changePasswordOtpRequest = (otp: string, email: string, password: string) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const url = `${this._config.authApiPath}/api/v1.2/Auth/ChangePasswordByOtp`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify({
        otp,
        email,
        password,
        region: this._domainHeader,
      }),
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static findPersonRegistrationInfoRequest = (email: string) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const query = getApiQuery({
      email,
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Users/findPersonRegistrationInfo?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static checkEmailAlreadyExists = (email: string) => {
    if (!email) {
      return Promise.resolve(true);
    }
    const requestHeaders = this.getAuthRequestHeaders();
    const query = getApiQuery({
      email,
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Users/findPersonRegistrationInfo?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return fetch(url, requestOptions).then((response) => {
      if (response.ok) {
        return false;
      }
      if (response.status === 404) {
        return true;
      }
      throw Error(response.statusText);
    });
  };

  static checkIfEmailNotFound = (email: string) => {
    if (!email) {
      return Promise.resolve();
    }
    const requestHeaders = this.getAuthRequestHeaders();
    const query = getApiQuery({
      email,
      region: this._domainHeader,
    });
    const url = `${this._config.authApiPath}/api/v1.2/Users/findPersonRegistrationInfo?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return fetch(url, requestOptions).then((response: AnyObject) => {
      if (response.ok) {
        return;
      }

      throw Error(response.title);
    });
  };

  static checkPartnershipCodeRequest = (partnershipCode: string) => {
    const url = `${BASE_API_URLS.onboarding}/api/v1.2/PartnershipCode/ValidateCode?partnershipCode=${partnershipCode}`;
    const requestOptions = {
      method: 'POST',
    };
    return fetch(url, requestOptions).then((response: AnyObject) => {
      if (response.ok) {
        return response.json() as boolean;
      }

      throw Error(response.title);
    });
  };

  static getInvitationInfoRequest = (invitationCode: string) => {
    const requestHeaders = this.getAuthRequestHeaders();
    const url = `${this._config.authApiPath}/api/v1.2/Invitations/info/${invitationCode}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions, { isCritical: true });
  };

  static resetFlowStepRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.authApiPath}/api/v1.2/Users/saveQuizWizardStep`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static getWizardInfoRequest = (accessToken: string) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.clients}/Wizard/GetClientWizardInfo`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static getLastWizardStepInfoRequest = (accessToken: string, flowType: any) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.clients}/Wizard/GetLastStep?wizardType=${flowType}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static completeWizardRequest = (accessToken: string, flowType: any) => {
    const requestHeaders = this.getAuthRequestHeaders(accessToken);
    const url = `${this._config.clients}/Wizard/CompleteWizard?wizardType=${flowType}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        ...requestHeaders,
      },
      credentials: 'omit',
    } as const;

    return this.authApiRequest(url, requestOptions);
  };

  static getConfig = () => {
    return this._config;
  };
}
