export const REGEXP = {
  validName: /^(?!.*\/\/)[^\\!|@#$%&=?¿¡_,*"{}[\]]+$/,
  monetarySeparation: '\\B(?=(\\d{3})+(?!\\d))',
  lowerCase: '[a-z]',
  upperCase: '[A-Z]',
  number: '\\d',
  isPositiveNumber: '^\\d+$',
  positiveOrNegativeInteger: '^-?\\d+$',
  positiveOrNegativeFloat: '^-?\\d*.?\\d+$',
  onlyNumberOrLetter: '^[A-Za-z\\d]+$',
  validUrl:
    '^((?:https?:)?(?:/{2})?)?((?:[\\w\\d-_]{1,64})\\.(?:[\\w\\d-_.]{2,64}))(:\\d{2,6})?((?:/|\\?|#|&){1}(?:[\\w\\d\\S]+)?)?$',
  allLetters: '[^A-Za-z]',
  hasLetters: '[A-Za-z]',
  uuid: '^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$',
  floatingPointNumber: '^(((([0-9]{1,3}[,.]{1})([0-9]{3}[,.]{1})*[0-9]{3})|[0-9]{1,9})([,.]{1}[0-9]+)?)$',
  email:
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  phoneNumber: '^[\\d|-]+$',
  /**
   * phoneNumberWithPrefix
   * like a backend side from seedWrok
   */
  phoneNumberWithPrefix: /^\+?(?:[-()\s]*\d[-()\s]*){4,16}$/,
  emailWithPlus:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  swift: '^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
  withoutNumbers: '^[^\\d]+$',
  bankName: '^[A-Za-z][A-Za-z\\d -.,]*$',
  userName: '^[^@\\d]+$',
  /**
   * signatoryUserName
   * ^(?=.([0-9]+[a-z])) - обязательно должен присутствовать текст и если есть буквы то допустими цифры
   * [^@|*~`!@#$%^&()_={}[\\]:;,.<>+\\/?-\\d] - список символов которые недопустимы без букв
   */
  signatoryUserName: '^(?=.([0-9]+[a-z]))|[^@|*~`!@#$%^&()_={}[\\]:;,.<>+\\/?-\\d]',
  hasApostropheOrSharp: "[#|']",
  hasSymbols: /[-?:().,'+#=!"%&*<>;{@]/,
  notHasSymbols: /[^-?:().,'+#=!"%&*<>;{@]/,
  isEmailValid: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  guid: '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
  fileAnyExtensionType: /\.([a-zA-Z0-9]+)$/,
};
