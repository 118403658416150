import { captureException } from '@sentry/react';

export const loadHeapOnce = (): void => {
  if (!window._STENN_.HEAP_APP_ID) return;
  if (!window.heap) {
    captureException(new Error('window.heap must be defined if HEAP_APP_ID is defined — add script to index.html'));
    return;
  }

  if ('identify' in window.heap) {
    return;
  }

  if ('identify' in window.heap) return;

  window.heap.load(window._STENN_.HEAP_APP_ID);
};

export const setHeapIdentity = (userId: string | undefined): void => {
  if (!window._STENN_.HEAP_APP_ID) return;
  if (!window.heap) {
    captureException(new Error('window.heap must be defined if HEAP_APP_ID is defined — add script to index.html'));
    return;
  }
  if (!('identify' in window.heap)) {
    captureException(new Error('window.heap.load(window._STENN_.HEAP_APP_ID) must be called first'));
    return;
  }
  if (userId) {
    window.heap.identify(userId);
  } else {
    window.heap.resetIdentity();
  }
};

export const setHotjarIdentity = (userId: string | undefined): void => {
  if (!window.hj) return;
  window.hj('identify', userId || null, {});
};

export const setHeapIdentityIfLoaded = (userId: string | undefined): void => {
  if (!window.heap) return;
  if (!('identify' in window.heap)) return;

  setHeapIdentity(userId);
};
