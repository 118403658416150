import { FC, useMemo, useState } from 'react';

import { LangContext } from '@app/context/LangContext/context';
import { ELocalStorageKey } from '@app/storage-keys';

export const LangContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [langId, setLangId] = useState(() => {
    const langData = JSON.parse(localStorage.getItem(ELocalStorageKey.LangData) ?? '{}');
    return langData?.id || 'en';
  });

  const contextValue = useMemo(() => {
    return {
      langId,
      setLangId: (langId: string) => {
        setLangId(langId);
        localStorage.setItem(ELocalStorageKey.LangData, JSON.stringify({ id: langId }));
      },
    };
  }, [langId, setLangId]);

  return <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>;
};
