import _get from 'lodash.get';
import PropTypes from 'prop-types';

const FilterIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg
      height="14"
      width="16"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
      fill={_get(colors, '0', 'currentColor')}
      {...restProps}
    >
      <path d="M8 7L0 1.41326e-06L16 0L8 7Z" />
      <rect x="6" width="4" height="14" />
    </svg>
  );
};

FilterIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default FilterIcon;
