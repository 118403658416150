/* eslint-disable no-param-reassign */
import produce from 'immer';

import * as userActionTypes from '../actionTypes/user';

const userInitialState = {
  // combined analytics data for sign up process
  trackingData: null,

  // custom auth parameter
  isAuthorized: false, // true when auth is complete and profile data is ready

  // user data
  profile: {},
  isProfileReady: false,
  ownCompanyReceivedData: [],
  isOwnCompanyReceivedDataReady: false,
  availableFunctions: [],
  isUserFunctionsListReady: false,
  isBddRole: false,
  isSalesSupportRole: false,
  impersonationStatus: {
    isLoading: false,
    isError: false,
    data: {
      isImpersonated: false,
      impersonatedEmail: null,
    },
  },

  // contacts
  contacts: [],
  isContactsReady: false,

  currentContact: {},
  isCurrentContactReady: false,

  // startup flow
  wizardInfo: {},
  isWizardInfoReady: false,

  wizardStepInfo: {},
  isWizardStepInfoReady: false,

  // common app forms data
  ownCompanyData: {
    fields: {
      name: '',
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      website: '',
      registrationNumber: '',
      duns: '',
    },
    isDataComplete: false,
    isEditingCompletedSuccess: false,
  },

  registrationData: {
    fields: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    referrer: {},
    isDataComplete: false,
    isRegistrationInProgress: false,
    isRegistrationCompleted: false,
    invitationInfo: {},
    isInvitationInfoReady: false,
    userId: '',
  },

  companiesReferralInviteData: {
    fields: {
      firstName: '',
      lastName: '',
      email: '',
    },
    isDataComplete: false,
  },

  invitationInfo: {
    invitationInfoReady: false,
    invitationCode: '',
    invitationId: '',
  },
};

export const userReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case userActionTypes.SET_TRACKING_DATA:
        draft.trackingData = action.payload;
        break;

      case userActionTypes.SET_AUTHORIZATION_STATE:
        draft.isAuthorized = action.payload;
        break;

      case userActionTypes.GET_CURRENT_USER_PROFILE_REQUEST:
        draft.profile = userInitialState.profile;
        draft.isProfileReady = false;
        break;

      case userActionTypes.GET_CURRENT_USER_PROFILE_SUCCESS:
        draft.profile = action.payload;
        draft.isProfileReady = true;
        break;

      case userActionTypes.SET_IMPERSONATION_STATUS_REQUEST:
        draft.impersonationStatus.isLoading = true;
        break;

      case userActionTypes.SET_IMPERSONATION_STATUS_FAILURE:
        draft.impersonationStatus.isLoading = false;
        break;

      case userActionTypes.SET_IMPERSONATION_STATUS_SUCCESS:
        draft.impersonationStatus.isLoading = false;
        draft.impersonationStatus.data.impersonatedEmail = action.payload;
        draft.impersonationStatus.data.isImpersonated = !!action.payload;
        break;

      case userActionTypes.STOP_IMPERSONATION_STATUS_SUCCESS:
        draft.impersonationStatus.isLoading = false;
        draft.impersonationStatus.data.email = null;
        draft.impersonationStatus.data.isImpersonated = false;
        break;

      case userActionTypes.SET_COMPANIES_REFERRAL_INVITE_DATA:
        draft.companiesReferralInviteData = {
          ...draft.companiesReferralInviteData,
          ...action.payload,
        };
        break;

      case userActionTypes.RESET_COMPANIES_REFERRAL_INVITE_DATA:
        draft.companiesReferralInviteData = userInitialState.companiesReferralInviteData;
        break;

      case userActionTypes.GET_INVITE_REQUEST:
      case userActionTypes.GET_INVITE_FAILURE:
      case userActionTypes.GET_INVITE_INFO_BY_ID_FAILURE:
      case userActionTypes.GET_INVITE_INFO_BY_ID_REQUEST:
      case userActionTypes.RESET_INVITATION:
        draft.invitationInfo = userInitialState.invitationInfo;
        break;

      case userActionTypes.GET_INVITE_SUCCESS:
        draft.invitationInfo.invitationId = action.payload.response;
        break;

      case userActionTypes.GET_INVITE_INFO_BY_ID_SUCCESS:
        draft.invitationInfo = action.payload;
        draft.invitationInfo.invitationInfoReady = true;
        break;

      case userActionTypes.GET_INVITATION_INFO_REQUEST:
        draft.registrationData.isInvitationInfoReady = false;
        break;

      case userActionTypes.GET_INVITATION_INFO_SUCCESS:
        draft.registrationData.isInvitationInfoReady = true;
        draft.registrationData.invitationInfo = action.payload;
        break;

      case userActionTypes.GET_INVITATION_INFO_FAILURE:
        draft.registrationData.isInvitationInfoReady = true;
        draft.registrationData.invitationInfo = {};
        break;

      case userActionTypes.SET_OWN_COMPANY_DATA:
        draft.ownCompanyData.fields = action.payload.fields;
        draft.ownCompanyData.isDataComplete = action.payload.isDataComplete;
        break;

      case userActionTypes.RESET_OWN_COMPANY_DATA:
        draft.ownCompanyData = action.payload;
        break;

      case userActionTypes.GET_INFO_OWN_COMPANY_REQUEST:
        draft.isOwnCompanyReceivedDataReady = false;
        break;

      case userActionTypes.GET_INFO_OWN_COMPANY_SUCCESS:
        draft.ownCompanyReceivedData = action.payload.value;
        draft.isOwnCompanyReceivedDataReady = true;
        break;

      case userActionTypes.SET_REGISTRATION_DATA:
        draft.registrationData.fields = action.payload.fields;
        draft.registrationData.isDataComplete = action.payload.isDataComplete;
        draft.registrationData.userId = action.payload.userId;
        break;

      case userActionTypes.RESET_REGISTRATION_DATA:
        draft.registrationData = {
          ...userInitialState.registrationData,
        };
        break;

      case userActionTypes.SET_REGISTRATION_COMPLETED:
        draft.registrationData.isRegistrationCompleted = action.payload;
        break;

      case userActionTypes.SIGN_UP_REQUEST:
        draft.registrationData.isRegistrationInProgress = true;
        break;

      case userActionTypes.SIGN_UP_SUCCESS:
      case userActionTypes.SIGN_UP_FAILURE:
        draft.registrationData.isRegistrationInProgress = false;
        break;

      case userActionTypes.EDIT_OWN_COMPANY_REQUEST:
        draft.ownCompanyData.isEditingCompletedSuccess = false;
        break;

      case userActionTypes.EDIT_OWN_COMPANY_SUCCESS:
        draft.ownCompanyData.isEditingCompletedSuccess = true;
        break;

      case userActionTypes.EDIT_OWN_COMPANY_FAILURE:
        draft.ownCompanyData.isEditingCompletedSuccess = false;
        break;

      case userActionTypes.GET_AVAILABLE_FUNCTIONS_REQUEST:
        draft.isUserFunctionsListReady = false;
        break;

      case userActionTypes.GET_AVAILABLE_FUNCTIONS_SUCCESS:
        draft.availableFunctions = action.payload.availableFunctions;
        draft.isBddRole = action.payload.isBddRole;
        draft.isSalesSupportRole = action.payload.isSalesSupportRole;
        draft.isUserFunctionsListReady = true;
        break;

      case userActionTypes.GET_CONTACTS_REQUEST:
        draft.isContactsReady = false;
        break;

      case userActionTypes.GET_CONTACTS_SUCCESS:
        draft.isContactsReady = true;
        draft.contacts = action.payload;
        break;

      case userActionTypes.GET_CONTACT_REQUEST:
        draft.isCurrentContactReady = false;
        break;

      case userActionTypes.GET_CONTACT_SUCCESS:
        draft.currentContact = action.payload;
        draft.isCurrentContactReady = true;
        break;

      case userActionTypes.RESET_CURRENT_CONTACT:
        draft.currentContact = {};
        draft.isCurrentContactReady = false;
        break;

      case userActionTypes.GET_WIZARD_INFO_REQUEST:
        draft.isWizardInfoReady = false;
        break;

      case userActionTypes.GET_WIZARD_INFO_SUCCESS:
        draft.wizardInfo = action.payload;
        draft.isWizardInfoReady = true;
        break;

      case userActionTypes.GET_LAST_WIZARD_STEP_REQUEST:
        draft.isWizardStepInfoReady = false;
        break;

      case userActionTypes.GET_LAST_WIZARD_STEP_SUCCESS:
        draft.wizardStepInfo = action.payload;
        draft.isWizardStepInfoReady = true;
        break;

      case userActionTypes.SAVE_USER_CHAT_AUTHENTIFICATION:
        draft.profile = { ...draft.profile, ...action.payload };
        break;
      default:
        break;
    }
  },
  { ...userInitialState }
);
