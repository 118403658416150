import { EContactStatus, ICompanyContact } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';
import { match } from 'ts-pattern';

export const isTeamMember = (contact: ICompanyContact) => {
  return match(contact.status)
    .with(
      EContactStatus.Active,
      EContactStatus.Pending,
      EContactStatus.Approved,
      EContactStatus.AuthorizedSignatory,
      () => true
    )
    .otherwise(() => false);
};
