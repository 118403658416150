import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { StepContainer } from '@app/components-new/StepContainer';

interface IDealSubmittedProps {}

export const DealSubmitted: FC<IDealSubmittedProps> = () => {
  const { handleNextStep } = useFlowTransitionsContext();
  const handleNext = () => {
    handleNextStep();
  };
  return (
    <StepContainer title={<FormattedMessage id="createDeal.dealSubmitted" />} onNext={handleNext}>
      <div>dealSubmitted</div>
    </StepContainer>
  );
};
