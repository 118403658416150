export const enum ERequestKey {
  TradeRelation = 'TradeRelation',
  TradeRelationInvoices = 'TradeRelationInvoices',
  InvoicesListByTradeRelation = 'InvoicesListByTradeRelation',
  GetContractorId = 'GetContractorId',
  GetCreatedDealInvoices = 'GetCreatedDealInvoices',
  GetLastStep = 'GetLastStep',
  GetWizard = 'GetWizard',
  GetClientWizardInfo = 'GetClientWizardInfo',
  GetCounterparties = 'GetCounterparties',
  GetInvitationById = 'GetInvitationById',
  GetLastInvitationByTrId = 'GetLastInvitationByTrId',
  GetSigner = 'GetSigner',
  GetBankDetailsState = 'GetBankDetailsState',
  GetInvoiceById = 'GetInvoiceById',
  GetInvoices = 'GetInvoices',
  GetBuyers = 'GetBuyers',
  GetCountries = 'GetCountries',
  GetStates = 'GetStates',
  GetCompanyEligibility = 'GetCompanyEligibility',
  GetUserCountry = 'GetUserCountry',
  GetDeals = 'getDeals',
  GetDealById = 'getDealByIdRequest',
  GetLinkToDocumentInDocusign = 'getLinkToDocumentInDocusign',
  DownloadAttachment = 'DownloadAttachment',
  GetInvitationInfo = 'GetInvitationInfo',
  NeedToShowSocialConsent = 'NeedToShowSocialConsent',
  GetSuppliers = 'GetSuppliers',
  GetSupplier = 'GetSupplier',
  GetBuyer = 'GetBuyer',
  GetBuyersList = 'GetBuyersList',
  PersonalSpaceFinanceData = 'PersonalSpaceFinanceData',
  PersonalSpaceContacts = 'PersonalSpaceContacts',
  GetCompanyBankDetails = 'GetCompanyBankDetails',
  GetDashboardDeals = 'GetDashboardDeals',
  GetDashboardDueInvoices = 'GetDashboardDueInvoices',
  GetDashboardOverdueInvoices = 'GetDashboardOverdueInvoices',
  GetCompanySignatoryInfoView = 'GetCompanySignatoryInfoView',
  GetAvailableFunctions = 'GetAvailableFunctions',
}
