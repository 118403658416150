import { FetchError } from '@app/api/lib/FetchError';
import { HTTPError } from '@app/api/lib/HTTPError';
import { isDevMode } from '@app/components-new/ToggleDevModeContainer/helpers/isDevMode';
import { AbortError } from '@app/core/api/AbortError';
import { API_REQUEST_TYPES, CRITICAL_API_REQUEST_TYPES, SILENT_API_REQUEST_TYPES } from '@app/core/constants';
import { IError, LOG_ENTRY_TYPES, TEMPLATES } from '@app/core/logger';
import { AnyObject } from '@app/core/utilities';
import _get from 'lodash.get';

import { NetworkError } from './NetworkError';
import { RequestError, isRequestError } from './RequestError';
import {
  ApiRequestExtendedParams,
  ApiRequestParams,
  ExtendedResponse,
  IApiProviderAuth0Connection,
  IApiProviderErrorLogger,
  TErrorHandler,
  TRequestHeadersExtender,
} from './types';
import { parseResponse } from './utils';

const fallback = () => {
  console.error('ApiProvider should be initialized with "configure" and "extendRequestHeaders" methods before usage');
};

const auth0ConnectionFallbackInstance: IApiProviderAuth0Connection = {
  getAccessTokenSilently: fallback as () => Promise<string>,
  logoutWithRedirect: fallback,
};
const errorLoggerFallbackInstance: IApiProviderErrorLogger = { send: fallback };

class ApiProviderClass {
  private _auth0ConnectionInstance: IApiProviderAuth0Connection =
    auth0ConnectionFallbackInstance as IApiProviderAuth0Connection;
  private _errorLoggerInstance: IApiProviderErrorLogger = errorLoggerFallbackInstance;
  private _requestHeadersExtender: TRequestHeadersExtender = fallback as TRequestHeadersExtender;
  private _errorHandler: TErrorHandler = fallback;

  /**
   * Initial ApiProvider configurator
   */
  public configure({
    auth0Connection,
    errorLogger,
    errorHandler,
  }: {
    auth0Connection: IApiProviderAuth0Connection;
    errorLogger: IApiProviderErrorLogger;
    errorHandler: TErrorHandler;
  }) {
    this._auth0ConnectionInstance = auth0Connection;
    this._errorLoggerInstance = errorLogger;
    this._errorHandler = errorHandler;
  }

  public extendRequestHeaders = (extenderFunction: TRequestHeadersExtender) => {
    this._requestHeadersExtender = extenderFunction;
  };

  private getRequestHeaders = (
    baseHeaders: {
      'stenn-application'?: string;
      ENV_DOMAIN?: string;
      Authorization?: string;
    },
    requestOptions: RequestInit
  ) => {
    const requestHeaders: Record<string, string> = { ...baseHeaders };
    const isGet = !requestOptions.method || requestOptions.method.toLowerCase() === 'get';
    const isFormData = !!requestOptions.body && requestOptions.body instanceof FormData;

    if (!isGet && !isFormData) {
      requestHeaders['Content-Type'] = 'application/json';
    }

    return requestHeaders;
  };

  /**
   * Resolve scheme: parsed response or null
   */
  private handleResponse = async (response: Response, url: string, requestOptions: RequestInit, params: AnyObject) => {
    const requestType = _get(params, 'requestType', null);
    const parsedResponse = await parseResponse(response);
    // 2xx
    if (response.ok) {
      return parsedResponse; // could be raw if Content-Type is not specified
    }

    // // 1xx, 3xx, 4xx, 5xx
    const error: AnyObject = new Error(`${response.status} ${response.statusText}`);

    error.requestUrl = url;
    error.requestOptions = requestOptions;
    error.response = parsedResponse;

    this._errorLoggerInstance.send(error as IError, {
      errorType: LOG_ENTRY_TYPES.api,
      template: response.status === 400 ? TEMPLATES.badRequest : TEMPLATES.error,
      requestType,
    });

    if (response.status >= 400) {
      if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
        const errorPage = response.status === 401 || response.status === 403 ? '/session-expired' : '/oops';

        this._auth0ConnectionInstance.logoutWithRedirect(errorPage);
      } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
        this._errorHandler(parsedResponse, params);
      }
      if (isDevMode()) {
        throw new HTTPError({
          strCode: parsedResponse.strCode,
          message: parsedResponse.message,
        });
      }
    }

    return null;
  };

  /**
   * Resolve scheme: [error, parsed response, response status code]
   */
  private handleResponseExtended = async <T = any>(
    response: Response,
    url: string,
    requestOptions: RequestInit,
    params: AnyObject
  ): Promise<ExtendedResponse<T>> => {
    const requestType = _get(params, 'requestType', null);
    const parsedResponse = await parseResponse(response);

    // 2xx
    if (response.ok) {
      return [null, parsedResponse || true, response.status];
    }

    // // 1xx, 3xx, 4xx, 5xx
    const error: AnyObject = new Error(`${response.status} ${response.statusText}`);

    error.requestUrl = url;
    error.requestOptions = requestOptions;
    error.response = parsedResponse;

    this._errorLoggerInstance.send(error as IError, {
      errorType: LOG_ENTRY_TYPES.api,
      template: response.status === 400 ? TEMPLATES.badRequest : TEMPLATES.error,
      requestType,
    });

    if (response.status >= 400) {
      if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
        const errorPage = response.status === 401 || response.status === 403 ? '/session-expired' : '/oops';

        this._auth0ConnectionInstance.logoutWithRedirect(errorPage);
      } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
        this._errorHandler(parsedResponse, params);
      }
    }

    return [parsedResponse, null, response.status];
  };

  /**
   * Resolve scheme: always null
   */
  private handleRequestFail = (error: AnyObject, url: string, requestOptions: RequestInit, params: AnyObject) => {
    const requestType = _get(params, 'requestType', null);

    if (requestType !== API_REQUEST_TYPES.log) {
      const networkError = error;

      networkError.requestUrl = url;
      networkError.requestOptions = requestOptions;

      this._errorLoggerInstance.send(networkError as IError, {
        errorType: LOG_ENTRY_TYPES.network,
        requestType,
      });
    }

    if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
      this._auth0ConnectionInstance.logoutWithRedirect('/oops');
    } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
      this._errorHandler(null, params);
    }

    if (isDevMode()) {
      if (error instanceof HTTPError) {
        throw error;
      }
      throw new FetchError(error as Error);
    }

    return null;
  };

  /**
   * Resolve scheme: [error, parsed response, response status code]
   */
  private handleRequestFailExtended = <T = any>(
    error: RequestError,
    url: string,
    requestOptions: RequestInit,
    params: AnyObject
  ): ExtendedResponse<T> => {
    const requestType = _get(params, 'requestType', null);
    const networkError = error;

    networkError.requestUrl = url;
    networkError.requestOptions = requestOptions;

    this._errorLoggerInstance.send(networkError as IError, {
      errorType: LOG_ENTRY_TYPES.network,
      requestType,
    });

    if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
      this._auth0ConnectionInstance.logoutWithRedirect('/oops');
    } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
      this._errorHandler(null, params);
      // this._handleOpenServiceModal({
      //     type: MODAL_TYPES.error,
      //     onClose: _get(params, 'onClose', null),
      //     contentProps: {
      //         errorData: null,
      //         onRetry: _get(params, 'onRetry', null),
      //     },
      // });
    }

    return [networkError, null, null];
  };

  /**
   * For private API calls with access token
   */
  public async apiRequest<T = any>(url: string, requestOptions: RequestInit, params?: ApiRequestParams): Promise<T>;
  public async apiRequest<T = any>(
    url: string,
    requestOptions: RequestInit,
    params: ApiRequestExtendedParams
  ): Promise<T | ExtendedResponse<T>> {
    const token = await this._auth0ConnectionInstance.getAccessTokenSilently();
    if (!token) {
      return new Promise((resolve) => resolve({} as T));
    }

    const extendedHeaders = this._requestHeadersExtender();

    const baseHeaders = {
      Authorization: `Bearer ${token}`,
      ...extendedHeaders,
    };

    const requestHeaders = this.getRequestHeaders(baseHeaders, requestOptions);

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    if (params && params.withExtendedResponse) {
      return fetch(url, requestOptions)
        .then((response) => this.handleResponseExtended<T>(response, url, requestOptions, params))
        .catch((error) => this.handleRequestFailExtended(error, url, requestOptions, params));
    }

    return fetch(url, requestOptions)
      .then((response) => this.handleResponse(response, url, requestOptions, params))
      .catch((error) => this.handleRequestFail(error, url, requestOptions, params));
  }

  public async startUploadFileWithProgress<T>(
    url: string,
    formData: FormData,
    onProgress: (value: number) => void,
    signal: AbortSignal
  ): Promise<T> {
    const token = await this._auth0ConnectionInstance.getAccessTokenSilently();
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);

      xhr.upload.onprogress = (event) => {
        let percentComplete = 0;
        if (event.total !== 0) {
          percentComplete = (event.loaded / event.total) * 100;
        }

        onProgress(percentComplete);
      };
      signal.addEventListener('abort', () => {
        xhr.abort();
        reject(new AbortError('Request aborted'));
      });

      xhr.onload = () => {
        if (xhr.status === 200) {
          let result;
          try {
            result = JSON.parse(xhr.response);
          } catch {
            result = xhr.response;
          }
          resolve(result);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);

      xhr.send(formData);
    });
  }

  /**
   * For public API calls without token
   * @deprecated
   */
  public async publicApiRequestDeprecated<T = any>(
    url: string,
    requestOptions: RequestInit,
    params: ApiRequestParams = {}
  ): Promise<T> {
    const extendedHeaders = this._requestHeadersExtender();
    const baseHeaders = {
      ...extendedHeaders,
    };
    const requestHeaders = this.getRequestHeaders(baseHeaders, requestOptions);

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    return fetch(url, requestOptions)
      .then((response) => this.handleResponse(response, url, requestOptions, params))
      .catch((error) => this.handleRequestFail(error, url, requestOptions, params));
  }

  /**
   * For public API calls without token, but with extended response.
   * It was added because when you use publicApiRequestDeprecated you cannot access http error.
   */
  public async publicApiRequestWithExtendedResponse<T = any>(
    url: string,
    requestOptions: RequestInit,
    params: ApiRequestParams = {}
  ): Promise<ExtendedResponse<T>> {
    const extendedHeaders = this._requestHeadersExtender();
    const baseHeaders = {
      ...extendedHeaders,
    };
    const requestHeaders = this.getRequestHeaders(baseHeaders, requestOptions);

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    try {
      const response = await fetch(url, requestOptions);
      return await this.handleResponseExtended<T>(response, url, requestOptions, params);
    } catch (error) {
      return this.handleRequestFailExtended<T>(error as RequestError, url, requestOptions, params);
    }
  }

  /**
   * For third-party public API calls
   */
  public externalApiRequest = (url: string, requestOptions: RequestInit, params: AnyObject = {}) => {
    const requestHeaders = this.getRequestHeaders({}, requestOptions);

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    return fetch(url, requestOptions)
      .then((response) => this.handleResponse(response, url, requestOptions, params))
      .catch((error) => this.handleRequestFail(error, url, requestOptions, params));
  };

  private handleQueryResponse = async (response: Response, url: string, requestOptions?: RequestInit) => {
    const parsedResponse = await parseResponse(response);
    const status = response.status;

    const responseWithStatus = {
      ...parsedResponse,
      status,
    };

    // 2xx
    if (response.ok) {
      return parsedResponse; // could be raw if Content-Type is not specified
    }

    // 1xx, 3xx, 4xx, 5xx
    throw new RequestError({
      statusText: `${response.status} ${response.statusText}`,
      requestUrl: url,
      requestOptions,
      response: responseWithStatus,
    });
  };

  private handleRequestErrorCatch = (error: RequestError, params?: AnyObject) => {
    const parsedResponse = error.response;
    const requestType = _get(params, 'requestType', null);

    this._errorLoggerInstance.send(error, {
      errorType: LOG_ENTRY_TYPES.api,
      template: parsedResponse.status === 400 ? TEMPLATES.badRequest : TEMPLATES.error,
      requestType,
    });

    if (parsedResponse.status >= 400) {
      if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
        const errorPage = parsedResponse.status === 401 || parsedResponse.status === 403 ? '/session-expired' : '/oops';
        this._auth0ConnectionInstance.logoutWithRedirect(errorPage);
      } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
        this._errorHandler(parsedResponse, params);
      }
    }

    throw error;
  };

  private handleNetworkErrorCatch = (url: string, requestOptions?: RequestInit, params?: AnyObject) => {
    const requestType = _get(params, 'requestType', null);
    const networkError = new NetworkError({
      requestUrl: url,
      requestOptions,
    });

    if (requestType !== API_REQUEST_TYPES.log) {
      this._errorLoggerInstance.send(networkError, {
        errorType: LOG_ENTRY_TYPES.network,
        requestType,
      });
    }

    if (CRITICAL_API_REQUEST_TYPES.includes(requestType)) {
      this._auth0ConnectionInstance.logoutWithRedirect('/oops');
    } else if (!SILENT_API_REQUEST_TYPES.includes(requestType)) {
      this._errorHandler(null, params);
      // this._handleOpenServiceModal({
      //     type: MODAL_TYPES.error,
      //     onClose: _get(params, 'onClose', null),
      //     contentProps: {
      //         errorData: null,
      //         onRetry: _get(params, 'onRetry', null),
      //     },
      // });
    }

    throw networkError;
  };

  // Resolve scheme: always null (react-query)
  private handleQueryRequestFailure = (
    error: RequestError | Error,
    url: string,
    requestOptions?: RequestInit,
    params?: AnyObject
  ) => {
    if (isRequestError(error)) {
      this.handleRequestErrorCatch(error, params);
    } else {
      this.handleNetworkErrorCatch(url, requestOptions, params);
    }
  };

  // For private API calls with access token (react query)
  public apiQueryRequest = async <R>(
    url: string,
    requestOptions: RequestInit = {},
    params?: AnyObject,
    ignoreCatchOrHandle?: boolean | ((error: RequestError | Error) => boolean)
  ): Promise<R> => {
    const token = await this._auth0ConnectionInstance.getAccessTokenSilently();

    if (!token) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => resolve({} as any));
    }

    const extendedHeaders = this._requestHeadersExtender();
    const baseHeaders = {
      Authorization: `Bearer ${token}`,
      ...extendedHeaders,
    };
    const requestHeaders = this.getRequestHeaders(baseHeaders, requestOptions || {});

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    return fetch(url, requestOptions)
      .then((response) => this.handleQueryResponse(response, url, requestOptions))
      .catch((error) => {
        if (typeof ignoreCatchOrHandle === 'function') {
          if (!ignoreCatchOrHandle(error)) {
            return this.handleQueryRequestFailure(error, url, requestOptions, params);
          }
        } else if (ignoreCatchOrHandle) {
          throw error;
        } else {
          return this.handleQueryRequestFailure(error, url, requestOptions, params);
        }
      });
  };

  // For public API calls without token (react-query)
  public publicApiQueryRequest = <R>(url: string, requestOptions: RequestInit = {}, params?: AnyObject): Promise<R> => {
    const extendedHeaders = this._requestHeadersExtender();
    const baseHeaders = {
      ...extendedHeaders,
    };
    const requestHeaders = this.getRequestHeaders(baseHeaders, requestOptions);

    Object.assign(requestOptions, {
      headers: requestHeaders,
    });

    return fetch(url, requestOptions)
      .then((response) => this.handleQueryResponse(response, url, requestOptions))
      .catch((error) => this.handleQueryRequestFailure(error, url, requestOptions, params));
  };
}

export const ApiProvider = new ApiProviderClass();
