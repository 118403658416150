const WarningIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2V2C17.5233 2 22 6.47667 22 12V12C22 17.5233 17.5233 22 12 22V22C6.47667 22 2 17.5233 2 12V12C2 6.47667 6.47667 2 12 2Z"
      stroke="#F44336"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 13V7" stroke="#F44336" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.999 16.25C11.861 16.25 11.749 16.362 11.75 16.5C11.75 16.638 11.862 16.75 12 16.75C12.138 16.75 12.25 16.638 12.25 16.5C12.25 16.362 12.138 16.25 11.999 16.25"
      stroke="#F44336"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WarningIcon;
