import styled, { css } from 'styled-components';

export const Table = styled.table.attrs({
  cellSpacing: 0,
  cellPadding: 0,
})`
  margin-bottom: ${({ theme }) => theme.spacing(1.6)};
  font-size: 11px;
  line-height: 18px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(0)}`};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  vertical-align: baseline;

  ${({ isHeadCell, theme }) =>
    isHeadCell &&
    css`
      color: ${theme.colors.grey};
      font-weight: 400;
    `};
`;

export const VolumeAdjustmentChargeTableHead = styled(TableCell)`
  color: ${({ theme }) => theme.palette.text.secondary};

  &:first-child {
    width: 70%;
  }

  &:last-child {
    width: 30%;
  }
`;

export const LatePaymentFeesTableHead = styled(TableCell)`
  &:first-child {
    width: 28%;
  }

  &:last-child {
    width: 55%;
  }
`;

export const TableRow = styled.tr`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
