export const API_REQUEST_TYPES = {
  common: 'common',
  log: 'log',
  hubspot: 'hubspot',
  profile: 'profile',
  ownCompanyInfo: 'ownCompanyInfo',
  userFunctions: 'userFunctions',
  pushSenderTokens: 'pushSenderTokens',
  changeWebBrowserLanguage: 'changeWebBrowserLanguage',
  uploadFiles: 'uploadFiles',
  disconnectDataSource: 'disconnectDataSource',
  invitationInfo: 'invitationInfo',
  swiftInfo: 'swiftInfo',
  flowActivity: 'flowActivity',
  techMetadata: 'techMetadata',
  dnb: 'dnb',
};

const RBF_API_REQUEST_TYPES = {
  sendNewPasswordWithOtp: 'sendNewPasswordWithOtp',
};

// leads to critical error flow if failed
export const CRITICAL_API_REQUEST_TYPES = [
  API_REQUEST_TYPES.profile,
  API_REQUEST_TYPES.ownCompanyInfo,
  API_REQUEST_TYPES.userFunctions,
];

// does not trigger error modal if failed
export const SILENT_API_REQUEST_TYPES = [
  API_REQUEST_TYPES.hubspot,
  API_REQUEST_TYPES.pushSenderTokens,
  API_REQUEST_TYPES.changeWebBrowserLanguage,
  API_REQUEST_TYPES.uploadFiles,
  API_REQUEST_TYPES.disconnectDataSource,
  API_REQUEST_TYPES.invitationInfo,
  API_REQUEST_TYPES.swiftInfo,
  API_REQUEST_TYPES.flowActivity,
  API_REQUEST_TYPES.techMetadata,
  API_REQUEST_TYPES.dnb,
  RBF_API_REQUEST_TYPES.sendNewPasswordWithOtp,
];
