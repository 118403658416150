import React, { FC } from 'react';

import { QueryStatus } from 'react-query';

import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { Typography, useTheme } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@stenngroup/ui-kit';

interface ICardRowProps {
  text: React.ReactNode;
  value: React.ReactNode;
  expandedContent?: React.ReactNode;
  expanded?: boolean;
  thin?: boolean;
  loadingState: QueryStatus;
}

export const CardRow: FC<ICardRowProps> = ({ text, value, expandedContent, expanded, loadingState, thin = false }) => {
  const variant = thin ? 'TextSm.Regular' : 'TextSm.SemiBold';
  const theme = useTheme();
  return (
    <Stack>
      <Accordion expanded={expanded}>
        <AccordionSummary>
          <Typography variant={variant}>{text}</Typography>

          <TextSkeleton
            status={loadingState}
            style={{
              height: calculateTypographyHeight(theme.typography.body1SemiBold),
            }}
          >
            <Typography variant={variant}>{value}</Typography>
          </TextSkeleton>
        </AccordionSummary>
        {!!expandedContent && (
          <AccordionDetails>
            <Typography variant="caption" color={(t) => t.palette.text.secondary}>
              {expandedContent}
            </Typography>
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};
