import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { API_REQUEST_TYPES } from '@app/core/constants';
import { isValidLanguage, setHtmlLanguageAttribute } from '@app/core/internationalization';
import { setCurrentLanguageToStorage } from '@app/core/storage';

import * as appActionTypes from '../actionTypes/app';

export const performLogoutAction = () => (dispatch) => {
  dispatch({
    type: appActionTypes.USER_LOGOUT,
  });
};

export const setCurrentLanguageId = (langId) => (dispatch) => {
  if (isValidLanguage(langId)) {
    setHtmlLanguageAttribute(langId);
    setCurrentLanguageToStorage(langId);

    dispatch({
      type: appActionTypes.SET_CURRENT_LANGUAGE_ID,
      payload: langId,
    });
  }
};

export const showServiceModalAction = (options) => (dispatch) => {
  dispatch({
    type: appActionTypes.SHOW_SERVICE_MODAL,
    payload: options,
  });
};

export const hideServiceModalAction = (options) => (dispatch) => {
  dispatch({
    type: appActionTypes.HIDE_SERVICE_MODAL,
    payload: options,
  });
};

export const changeWebBrowserLanguageAction = (languageCode) => (dispatch) => {
  const requestOptions = {
    method: 'POST',
  };

  const params = {
    requestType: API_REQUEST_TYPES.changeWebBrowserLanguage,
  };

  dispatch({
    type: appActionTypes.CHANGE_WEB_BROWSER_LANGUAGE_REQUEST,
  });

  return ApiProvider.apiRequest(
    `${BASE_API_URLS.onboarding}/api/v1.2/Parameters/changeWebBrowserLanguage/${languageCode}`,
    requestOptions,
    params
  ).then((response) => {
    if (response) {
      dispatch({
        type: appActionTypes.CHANGE_WEB_BROWSER_LANGUAGE_SUCCESS,
      });
    } else {
      dispatch({
        type: appActionTypes.CHANGE_WEB_BROWSER_LANGUAGE_FAILURE,
      });
    }
    return response;
  });
};
