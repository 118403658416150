import type { FC, PropsWithChildren } from 'react';

import { FormattedMessage } from 'react-intl';

import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { IStepContainer } from '@app/components-new/StepContainer/types';
import { TEST_IDS } from '@app/core/constants';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box, Button, DrawerActions, DrawerContent, Stack, Typography } from '@stenngroup/ui-kit';

const CONTAINER_PADDING = 3;

export const StepContainer: FC<PropsWithChildren<IStepContainer>> = ({
  children,
  title,
  onNext,
  isNextDisabled,
  isNextLoading,
  actions,
  dataTestId,
  headerContent,
  canGoBack: canGoBackProp = true,
}) => {
  const { canGoBack, handlePrevStep } = useFlowTransitionsContext();
  const isBackButtonAvailable = canGoBackProp && canGoBack;
  return (
    <>
      <DrawerContent padding={0} gap={0} overflow="hidden" data-testid={dataTestId}>
        <Stack
          sx={{ minHeight: 58 }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          bgcolor={(theme) => theme.palette.background.level3}
        >
          <IconButton
            onClick={() => handlePrevStep()}
            sx={{
              opacity: isBackButtonAvailable ? 1 : 0,
              transition: '150ms opacity',
              pointerEvents: isBackButtonAvailable ? 'all' : 'none',
            }}
            disabled={!isBackButtonAvailable}
          >
            <ArrowBack />
          </IconButton>
          <Typography.H6>{title}</Typography.H6>
          <Box width={40} />
        </Stack>

        <Box
          overflow="auto"
          flexGrow={1}
          p={CONTAINER_PADDING}
          pt={headerContent ? 0 : CONTAINER_PADDING}
          bgcolor={(t) => t.palette.background.level1}
        >
          {headerContent && (
            <Box
              position="sticky"
              zIndex={1}
              top={0}
              pt={CONTAINER_PADDING}
              pb={CONTAINER_PADDING / 2}
              bgcolor={(t) => t.palette.background.level1}
            >
              {headerContent}
            </Box>
          )}

          {children}
        </Box>
      </DrawerContent>

      <DrawerActions>
        {actions || (
          <Button
            variant="primary"
            onClick={onNext}
            endIcon={<ArrowForward />}
            isLoading={isNextLoading}
            disabled={isNextDisabled || isNextLoading}
            data-testid={TEST_IDS.nextButton}
          >
            <FormattedMessage id="constants.universalMessages.next" />
          </Button>
        )}
      </DrawerActions>
    </>
  );
};
