import { SyntheticEvent } from 'react';

import { MOBILE_SCREEN } from '@app/core/constants';
import { Icon } from '@app/core/icons';
import { IconButton, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Head = styled.div<{
  hasContent: boolean;
  customBottomMargin?: number;
  isMobileHeaderVisible: boolean;
}>`
  margin-bottom: ${({ hasContent }) => (hasContent ? '32px' : '0')};

  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    margin-bottom: ${({ hasContent }) => (hasContent ? '28px' : '0')};
  }

  ${({ customBottomMargin }) =>
    customBottomMargin &&
    css`
      margin-bottom: ${customBottomMargin}px;
    `}
`;

export const SwipCloseButton = styled.div`
  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    position: absolute;
    width: 40px;
    height: 4px;
    left: 50%;
    top: 4px;
    transform: translateX(-50%);
    background-color: ${({ theme }) =>
      // @ts-expect-error fix
      theme.palette.action.hoverBackground};
    border-radius: 7px;
  }
`;

export const Title = styled(Typography)`
  display: inline-block;
  white-space: pre-wrap;
` as typeof Typography;

export const Subtitle = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.colors.grey};
  font-weight: 600;
  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    margin-bottom: 8px;
  }
`;

export const Description = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.palette.text.tertiary};
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    margin-top: ${({ theme }) => theme.spacing(1)};
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CloseButtonContainer = styled.div<{ isAbsolute: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    position: absolute;
    top: 28px;
    right: 28px;
  }

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 28px;
      right: 28px;
    `}
`;

export const BackButton = styled(IconButton)`
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.palette.black};
  ${({ theme }) => theme.breakpoints.down(MOBILE_SCREEN)} {
    top: 6px;
    left: 8px;
  }
`;

export const CloseButton = styled(Icon)<{
  name: string;
  onClick: (e: SyntheticEvent) => void;
}>`
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.colors.greyishBlue};
  font-size: 18px;
  cursor: pointer;
`;

export const LadingContainer = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
