import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EDealTradeRole, EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { StepContainer } from '@app/components-new/StepContainer';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Button, Card, Typography } from '@stenngroup/ui-kit';

interface ITradePartnerCreated {}

export const TradePartnerCreated: FC<ITradePartnerCreated> = () => {
  const { goTo, close, getFlowState } = useFlowTransitionsContext();
  const { counterpartyCompanyId, tradeRole } = getFlowState();
  const theme = useTheme();

  const targetRequest = tradeRole === EDealTradeRole.Supplier ? apiRoutes.BuyerGetBuyer : apiRoutes.SupplierGetSupplier;
  const httpClient = useHttpClient();
  const { data } = useQuery({
    queryKey: [targetRequest, counterpartyCompanyId],
    queryFn: () => {
      return httpClient(targetRequest, 'post', {
        data: {
          id: counterpartyCompanyId!,
        },
      });
    },
  });

  return (
    <StepContainer
      dataTestId={TEST_IDS.tradePartnerCreated}
      canGoBack={false}
      title={<FormattedMessage id="createDeal.tradePartnerCreated.title" />}
      actions={
        <Button variant="contained" size="medium" onClick={() => close()} data-testid={TEST_IDS.closeButton}>
          <FormattedMessage id="constants.universalMessages.close" />
        </Button>
      }
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <Stack gap={2} textAlign="center" alignItems="center">
          <CheckCircle
            fontSize="large"
            style={{
              fontSize: 80,
            }}
            color="success"
          />
          <Typography.TextMd.SemiBold color="text.primary">
            <FormattedMessage
              id="createDeal.tradePartnerCreated.description1"
              values={{
                Name: <span data-testid={TEST_IDS.companyName}>{data?.counterpartyCompanyName}</span> ?? (
                  <TextSkeleton
                    data-testid={TEST_IDS.companyNameLoading}
                    status="loading"
                    style={{
                      height: calculateTypographyHeight(theme.typography.body1),
                      width: 100,
                    }}
                  />
                ),
              }}
            />
          </Typography.TextMd.SemiBold>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.tradePartnerCreated.description2" />
          </Typography.TextMd.Regular>
          <Button
            variant="secondary"
            size="small"
            onClick={() => goTo(EFlowStep.UPLOAD_INVOICE)}
            data-testid={TEST_IDS.addDealButton}
          >
            <FormattedMessage id="createDeal.tradePartnerCreated.createDeal" />
          </Button>
        </Stack>
      </Card>
    </StepContainer>
  );
};
