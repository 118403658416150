import { ReactElement } from 'react';

import { useIntl } from 'react-intl';

import { ConfigProvider } from '@stenngroup/ui-kit';

type Nova2ThemeWrapperProps = {
  children: ReactElement;
  isCompatibilityMode?: boolean;
};

type Locale = 'en' | 'es' | 'zh-Hans';

export const Nova2ThemeWrapper = ({ children, isCompatibilityMode = false }: Nova2ThemeWrapperProps) => {
  const { locale } = useIntl();
  return (
    <ConfigProvider colorMode="light" locale={locale as Locale} isCompatibilityMode={isCompatibilityMode}>
      {children}
    </ConfigProvider>
  );
};
