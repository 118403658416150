import { FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useAvailableCurrencies } from '@app/api/hooks/useAvailableCurrencies';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EDealTradeRole } from '@app/components-new/FlowTransitions/context/types';
import { AddBankDetailsFormSkeleton } from '@app/components-new/FlowTransitions/screens/AddBankDetails/AddBankDetailsFormSkeleton';
import { StepContainer } from '@app/components-new/StepContainer';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { EDealType } from '@app/core/constants';
import { ArrowForward } from '@mui/icons-material';
import { Button, Card } from '@stenngroup/ui-kit';

import { AddBankDetailsForm } from './AddBankDetailsForm';
import type { TSubmitData } from './AddBankDetailsForm/types';

interface IAddBankDetailsProps {}

export const AddBankDetails: FC<IAddBankDetailsProps> = () => {
  const { handleNextStep, getFlowState } = useFlowTransitionsContext();
  const httpClient = useHttpClient();

  const { trId: tradeRelationId, myCompanyId, counterpartyCompanyId, tradeRole } = getFlowState();

  const { data, isLoading: isLoadingCurrencies } = useAvailableCurrencies({
    tradeRelationId,
    isEnabled: !!tradeRelationId,
    invoiceProductType: EDealType.Goods,
    cacheTime: 0,
  });

  const supplierId = tradeRole === EDealTradeRole.Supplier ? myCompanyId : counterpartyCompanyId;

  const { data: isAbleToCreateBankAccount, isLoading: isLoadingBankDetailsState } = useQuery({
    queryKey: [apiRoutes.BankDetailsCanCreateForSupplierSupplierId, tradeRelationId],
    queryFn: ({ signal }) =>
      httpClient(apiRoutes.BankDetailsCanCreateForSupplierSupplierId, 'get', {
        signal,
        routeParams: {
          supplierId: supplierId ?? '',
        },
      }),
    cacheTime: 0,
    enabled: Boolean(supplierId),
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: [apiRoutes.BankDetailsCreateForSupplierSupplierId],
    mutationFn: (bankAccountDetails: TSubmitData) =>
      httpClient(apiRoutes.BankDetailsCreateForSupplierSupplierId, 'post', {
        routeParams: {
          supplierId: supplierId ?? '',
        },
        data: {
          ...bankAccountDetails,
        },
      }),
    onSuccess() {
      handleNextStep();
    },
  });

  const handleSubmit = (data: TSubmitData): void => {
    if (supplierId) {
      mutate(data);
    }
  };

  const currencyOptions =
    data?.map((currency) => ({
      value: currency.isoNumericCode,
      title: currency.description,
    })) ?? [];

  const defaultCurrency = data?.[0]?.isoNumericCode;

  const isPending = isLoadingCurrencies || isLoadingBankDetailsState;

  useEffect(() => {
    if (isLoadingBankDetailsState) {
      return;
    }

    if (!isAbleToCreateBankAccount) {
      handleNextStep();
    }
  }, [handleNextStep, isAbleToCreateBankAccount, isLoadingBankDetailsState]);

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.addBankDetails" />}
      actions={
        <Button
          variant="primary"
          type="submit"
          form="bankDetailsForm"
          endIcon={<ArrowForward />}
          disabled={isLoading}
          isLoading={isLoading}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        {isPending ? (
          <AddBankDetailsFormSkeleton />
        ) : (
          <AddBankDetailsForm
            onSubmit={handleSubmit}
            defaultCurrency={defaultCurrency}
            currencyOptions={currencyOptions}
          />
        )}
      </Card>
    </StepContainer>
  );
};
