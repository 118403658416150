const ShareIcon = () => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 4.66667L9.16667 0V2.66667C3.33333 3.33333 0.833333 6.66667 0 10C2.08333 7.66667 5 6.6 9.16667 6.6V9.33333L15 4.66667Z"
      fill="#4B6883"
    />
  </svg>
);

export default ShareIcon;
