import { EDealType } from '@app/core/constants';

import { IUploadInvoiceForm } from '../types';

export function getFormData(): IUploadInvoiceForm {
  return {
    number: '',
    currency: '',
    faceValueNationalCurrency: '',
    dueDate: null,
    otherDocuments: [],
    invoiceDocument: null,
    transportDocument: null,
    dealType: EDealType.Goods,
    isCommodity: false,
    isPerishable: false,
    isUnknownCategory: false,
  };
}
