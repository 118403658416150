type TDebouncedFunc<T extends (...args: any[]) => any> = (...args: Parameters<T>) => ReturnType<T> | void;

export function debounce<T extends (...args: any) => any>(
  func: T,
  wait: number,
  immediate?: boolean
): TDebouncedFunc<T> {
  let timeout: ReturnType<typeof setTimeout> | null;
  return function (...args: any) {
    // @ts-expect-error fix
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate && !timeout) func.apply(context, args);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
}

export function throttle<T extends (...args: any) => any>(func: T, timeFrame: number): TDebouncedFunc<T> {
  let lastTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}
