export enum NotificationStatus {
  NEW = 'New',
  READ = 'Read',
}

export interface INotificationData {
  id: string;
  key: string;
  notificationId: string;
  value: string;
}

export interface INotificationsResponse {
  body: string;
  code: string;
  entityId: string;
  id: string;
  notificationData: INotificationData[];
  notificationDate: string;
  status: NotificationStatus;
  title: string;
}

export interface INotification {
  date: string;
  id: string;
  route?: string;
  viewMode: boolean;
  message: string;
}

export interface INotificationsInfoResponse {
  newCount: number;
  readCount: number;
}
