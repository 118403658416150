import { IntlFormatters } from 'react-intl';

import { EDealType } from '@app/core/constants';
import { universalMessages } from '@app/core/internationalization';

export function getDealTypes(formatMessage: IntlFormatters['formatMessage']) {
  return [
    {
      label: formatMessage(universalMessages.goods),
      value: EDealType.Goods,
    },
    {
      label: formatMessage(universalMessages.services),
      value: EDealType.Services,
    },
  ];
}
