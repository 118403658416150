import { useCallback, useEffect, useRef, useState } from 'react';

import { Nullable } from '@app/core/utilities';
import { Loader } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/react';

import { GOOGLE_MAPS_API_KEY } from './constants';

export function useGooglePlaces() {
  const autoCompleteService = useRef<Nullable<google.maps.places.AutocompleteService>>(null);

  const placesService = useRef<Nullable<google.maps.places.PlacesService>>(null);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | null>(null);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);

  const getGooglePlaceById = useCallback(
    (placeId: string) =>
      new Promise<Nullable<google.maps.places.PlaceResult>>((resolve, reject) => {
        if (!placesService.current) {
          return reject(new Error('[getGooglePlaceById]: placesService is not initialized'));
        }
        if (!sessionToken.current) {
          return reject(new Error('sessionToken is not defined'));
        }

        placesService.current.getDetails(
          {
            placeId,
            fields: ['address_components'],
            sessionToken: sessionToken.current,
          },
          (details, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(details);
            } else {
              reject(new Error(`Can't get details: status — "${status}"`));
            }
          }
        );
      }),
    [sessionToken]
  );

  const getPlacePredictions = useCallback(
    async (input: string, options = {}) => {
      if (!autoCompleteService.current) {
        throw new Error('autoCompleteService is not initialized');
      }
      if (!sessionToken.current) {
        throw new Error('sessionToken is not defined');
      }
      const { predictions } = await autoCompleteService.current.getPlacePredictions({
        ...options,
        input,
        sessionToken: sessionToken.current,
        language: 'en',
      });

      return predictions ?? [];
    },
    [sessionToken]
  );

  const initializeService = () => {
    if (!window.google) throw new Error('[useGooglePlaces]: Google script not loaded');
    if (!window.google.maps) throw new Error('[useGooglePlaces]: Google maps script not loaded');
    if (!window.google.maps.places) throw new Error('[useGooglePlaces]: Google maps places script not loaded');
    autoCompleteService.current = new window.google.maps.places.AutocompleteService();

    placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
    setIsReady(true);
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google?.maps?.places) {
          const loader = new Loader({
            apiKey: GOOGLE_MAPS_API_KEY,
            libraries: ['places'],
          });

          await loader.load();
        }
        initializeService();
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        setIsUnavailable(true);
      }
    };

    if (GOOGLE_MAPS_API_KEY) {
      init();
    } else {
      initializeService();
    }
  }, []);

  return { getPlacePredictions, getGooglePlaceById, isReady, isUnavailable };
}
