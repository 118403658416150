import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

interface IConfirmationDialogContentProps {
  onContinue: () => void;
  onCancel: () => void;
}

export const ConfirmationDialogContent: FC<IConfirmationDialogContentProps> = ({ onContinue, onCancel }) => {
  return (
    <>
      <DialogTitle>
        <Typography.H5>
          <FormattedMessage id="createDeal.uploadInvoices.invoiceNotSaved" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent>
        <Typography.TextMd.Regular>
          <FormattedMessage id="createDeal.uploadInvoices.invoiceNotSavedDescription" />
        </Typography.TextMd.Regular>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onContinue}>
          <FormattedMessage id="constants.universalMessages.discard" />
        </Button>
        <Button variant="contained" onClick={onCancel}>
          <FormattedMessage id="constants.universalMessages.continueUploading" />
        </Button>
      </DialogActions>
    </>
  );
};
