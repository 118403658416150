import { ISearchCompanyForm } from '../types';

export const getFormData = (defaultData?: Partial<ISearchCompanyForm> | null): Partial<ISearchCompanyForm> => {
  return {
    companyName: defaultData?.companyName || '',
    country: {
      label: defaultData?.country?.label || '',
      value: defaultData?.country?.value || '',
      place: defaultData?.country?.place || '',
    },
    duns: defaultData?.duns || '',
    state: {
      label: defaultData?.state?.label || '',
      value: defaultData?.state?.value || '',
      place: defaultData?.state?.place || '',
    },
    companyNameInChinese: defaultData?.companyNameInChinese || undefined,
    unifiedSocialCreditCode: defaultData?.unifiedSocialCreditCode || undefined,
    registrationNumber: defaultData?.registrationNumber || '',
  };
};
