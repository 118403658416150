import styled from 'styled-components';

export const NotificationBadge = styled.span<{ newNotificationsCount: number }>`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  position: absolute;
  width: ${({ newNotificationsCount }) => (newNotificationsCount > 9 ? '18px' : '16px')};
  height: 16px;
  top: -2px;
  right: 0;
  color: white;
  background: ${({ theme }) => theme.palette.error.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.contrast};
  border-radius: 2000px;
`;
