import { forwardRef } from 'react';

import { ILegacyIconProps } from './types';

/**
 * @deprecated
 */
export const Icon = forwardRef<HTMLElement, ILegacyIconProps>(
  ({ name, title = '', className = '', onClick, onMouseEnter, onMouseLeave }, ref) => (
    <i
      ref={ref}
      role={onClick ? 'button' : ''}
      className={`icon icon-${name} ${className}`.trim()}
      title={title}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
);
