import { FC } from 'react';

import { FormattedDate, FormattedMessage, MessageDescriptor } from 'react-intl';

import { OptionalValue } from '@app/components-new/OptionalValue';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { TeamMemberStatusText } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/TeamCard/constants/status';
import { EContactStatus } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';
import { Divider, Stack, useTheme } from '@mui/material';
import { ChipColor } from '@stenngroup/theme';
import { Chip, Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { ITeamCardProps } from './types';

export const TeamCard: FC<ITeamCardProps> = ({ contact, actions, loadingState, dataTestId }) => {
  const chipColor: ChipColor = match<EContactStatus, ChipColor>(contact.status)
    .with(EContactStatus.Active, () => 'success')
    .with(EContactStatus.Approved, () => 'success')
    .with(EContactStatus.AuthorizedSignatory, () => 'primary')
    .with(EContactStatus.Declined, () => 'error')
    .with(EContactStatus.NotInvited, () => 'error')
    .with(EContactStatus.Rejected, () => 'error')
    .with(EContactStatus.Pending, () => 'warning')
    .with(EContactStatus.Requesting, () => 'warning')
    .otherwise(() => 'info');

  const dateTitleId = match<EContactStatus, MessageDescriptor['id']>(contact.status)
    .with(EContactStatus.Pending, () => 'constants.universalMessages.inviteDate')
    .with(EContactStatus.Requesting, () => 'constants.universalMessages.requestDate')
    .otherwise(() => 'constants.universalMessages.joiningDate');

  const theme = useTheme();
  return (
    <Stack
      bgcolor={(t) => t.palette.background.level1}
      borderRadius={(t) => t.shape.borderRadius}
      border={1}
      borderColor={(t) => t.palette.grey[200]}
      padding={(t) => t.spacing(2)}
      data-testid={dataTestId}
    >
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack gap={0.5}>
            <TextSkeleton
              status={loadingState}
              style={{
                height: calculateTypographyHeight(theme.typography.caption),
              }}
            >
              <Typography.Caption color={(t) => t.palette.text.secondary}>{contact.email}</Typography.Caption>
            </TextSkeleton>
            <TextSkeleton
              status={loadingState}
              style={{
                height: calculateTypographyHeight(theme.typography.h6),
              }}
            >
              <Typography.H6>{contact.fullName}</Typography.H6>
            </TextSkeleton>
          </Stack>
          <Stack>{actions}</Stack>
        </Stack>
        <Stack>
          <TextSkeleton
            status={loadingState}
            style={{
              height: calculateTypographyHeight(theme.typography.h6),
            }}
          >
            <Chip size="small" variant="secondary" label={TeamMemberStatusText[contact.status]} color={chipColor} />
          </TextSkeleton>
        </Stack>
      </Stack>
      <Stack paddingY={1}>
        <Divider />
      </Stack>
      <Stack direction="row">
        <Stack gap={0.5} flexGrow={1} width="33%">
          <Typography.Caption color={(t) => t.palette.text.secondary}>
            <FormattedMessage id="constants.universalMessages.phone" />
          </Typography.Caption>
          <Typography.Body1>
            <OptionalValue text={contact.phoneNumber} />
          </Typography.Body1>
        </Stack>
        <Stack paddingX={1}>
          <Divider orientation="vertical" />
        </Stack>
        <Stack gap={0.5} flexGrow={1} width="33%">
          <Typography.Caption color={(t) => t.palette.text.secondary}>
            <FormattedMessage id="constants.universalMessages.position" />
          </Typography.Caption>
          <TextSkeleton
            status={loadingState}
            style={{
              height: calculateTypographyHeight(theme.typography.body1),
              width: 130,
            }}
          >
            <Typography.Body1
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '130px',
              }}
            >
              <OptionalValue text={contact.position} />
            </Typography.Body1>
          </TextSkeleton>
        </Stack>
        <Stack paddingX={1}>
          <Divider orientation="vertical" />
        </Stack>
        <Stack gap={0.5} flexGrow={1} width="33%">
          <Typography.Caption color={(t) => t.palette.text.secondary}>
            <FormattedMessage id={dateTitleId} />
          </Typography.Caption>
          <TextSkeleton
            status={loadingState}
            style={{
              height: calculateTypographyHeight(theme.typography.body1),
              width: 50,
            }}
          >
            <Typography.Body1>
              {contact.effectiveDate ? <FormattedDate dateStyle="medium" value={contact.effectiveDate} /> : '-'}
            </Typography.Body1>
          </TextSkeleton>
        </Stack>
      </Stack>
    </Stack>
  );
};
