import { FC } from 'react';

import { TEST_IDS } from '@app/core/constants/testIds';
import { Chip, Stack, Typography } from '@stenngroup/ui-kit';

import { StatusColor } from './constants/statusColor';
import { StatusText } from './constants/statusText';
import { ICompanyStatusProps } from './types';

export const CompanyStatus: FC<ICompanyStatusProps> = ({ status }) => {
  return (
    <Stack>
      <Chip
        size="small"
        variant="secondary"
        label={<Typography.Caption>{StatusText[status]}</Typography.Caption>}
        color={StatusColor[status]}
        data-testid={TEST_IDS.companyStatus}
      />
    </Stack>
  );
};
