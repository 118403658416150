import type { FC } from 'react';

import { Skeleton, Stack } from '@stenngroup/ui-kit';

export const AddBankDetailsFormSkeleton: FC = () => (
  <Stack gap={2}>
    <Skeleton height={20} sx={{ transform: 'none', width: 90 }} />
    <Skeleton height={36} sx={{ transform: 'none', flexGrow: 1 }} />

    <Stack direction="row" gap={2}>
      <Skeleton height={36} sx={{ transform: 'none', flexBasis: 120 }} />
      <Skeleton height={36} sx={{ transform: 'none', flexGrow: 1 }} />
    </Stack>
  </Stack>
);
