import { FC, useMemo } from 'react';

import { UserContext } from '../context';

import { IUserContextProviderProps } from './types';

export const UserContextProvider: FC<IUserContextProviderProps> = (props) => {
  const { id, email, name, children } = props;

  const value = useMemo(() => ({ id, email, name }), [id, email, name]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
