import React from 'react';

interface INotificationContext {
  isSignalrConnected: boolean;
}

const NotificationContext = React.createContext<INotificationContext>({
  isSignalrConnected: false,
});

export default NotificationContext;
