import { defineMessages } from 'react-intl';

const scope = 'backendErrors';
export const backendErrorMessages = defineMessages({
  backendError_cmn_001: {
    // Check ErrorLogger before changing this message!
    id: `${scope}.backendError_cmn_001`,
    defaultMessage:
      'An error occurred while processing your request. Please retry later or contact cs@stenn.com. \n\nException key: {param0}',
  },
  backendError_onb_2014: {
    id: `${scope}.backendError_onb_2014`,
    defaultMessage:
      'This company has been registered on the platform already. Our Support team will contact you shortly with the details about that.',
  },
  backendError_onb_1: {
    id: `${scope}.backendError_onb_1`,
    defaultMessage: 'We do not have enough information to make a decision at this stage',
  },
  backendError_onb_2: {
    id: `${scope}.backendError_onb_2`,
    defaultMessage:
      "The buyer's limit has been fully used:\r\nTotal Limit: {param0}\r\nUsed Limit (with Reserved): {param1}\r\nThe limit will be available after the buyer pays the financed invoices.  If you would like to get funding for other invoices,  please replace the invoices in the last deal  or ask for an increase of the limit by sending a request to Support Team.",
  },
  backendError_onb_4: {
    id: `${scope}.backendError_onb_4`,
    defaultMessage: 'First name cannot be empty',
  },
  backendError_onb_5: {
    id: `${scope}.backendError_onb_5`,
    defaultMessage: 'Last name cannot be empty',
  },
  backendError_onb_2020: {
    id: `${scope}.backendError_onb_2020`,
    defaultMessage:
      "You can not add this company as buyer, because it's trade relation with your supplier is in Denied state.",
  },
  backendError_onb_2021: {
    id: `${scope}.backendError_onb_2021`,
    defaultMessage: "Unfortunately we can't finance invoices of the selected buyer. Please select another buyer.",
  },
  backendError_onb_2022: {
    id: `${scope}.backendError_onb_2022`,
    defaultMessage: 'You can not create a company, because financing of this supplier has been denied.',
  },
  backendError_onb_2024: {
    id: `${scope}.backendError_onb_2024`,
    defaultMessage:
      'You’re trying to add an existing user of the platform. Please contact this person directly to get details or add another referee. Thank you!',
  },
  backendError_onb_2025: {
    id: `${scope}.backendError_onb_2025`,
    defaultMessage: 'Sorry, you can’t add your own company as a buyer',
  },
  backendError_onb_2026: {
    id: `${scope}.backendError_onb_2026`,
    defaultMessage: 'You can’t add your own company',
  },
  backendError_onb_2037: {
    id: `${scope}.backendError_onb_2037`,
    defaultMessage:
      'You’re trying to add an existing user of the platform. Our Support team will contact you shortly with the details about that.',
  },
  backendError_onb_2038: {
    id: `${scope}.backendError_onb_2038`,
    defaultMessage: 'This user already exists in our system',
  },
  backendError_onb_2039: {
    id: `${scope}.backendError_onb_2039`,
    defaultMessage: 'User already exists with such email',
  },
  backendError_onb_2040: {
    id: `${scope}.backendError_onb_2040`,
    defaultMessage: 'Please enter the email of the person you want to referred.',
  },
  backendError_onb_2041: {
    id: `${scope}.backendError_onb_2041`,
    defaultMessage: 'The invitation has already been accepted',
  },
  backendError_onb_2042: {
    id: `${scope}.backendError_onb_2042`,
    defaultMessage: 'The invitation has already been rejected',
  },
  backendError_opr_2050: {
    id: `${scope}.backendError_opr_2050`,
    defaultMessage: 'An invoice with the same number {param0} already exists for the current supplier.',
  },
  backendError_opr_2060: {
    id: `${scope}.backendError_opr_2060`,
    defaultMessage:
      'You are trying to add different types of invoices to this deal. Please check that all invoices in the deal are of the same type and try to submit deal again.',
  },
  backendError_opr_12002: {
    id: `${scope}.backendError_onb_12002`,
    defaultMessage:
      'Trade relation is not valid. Please check suppliers banks details, signers and trade relation state. Details: Buyer must have signer for own company.',
  },
  backendError_onb_2060: {
    id: `${scope}.backendError_onb_2060`,
    defaultMessage: 'Buyer is declined',
  },
  backendError_onb_2061: {
    id: `${scope}.backendError_onb_2061`,
    defaultMessage: 'Supplier is declined',
  },
  backendError_onb_2063: {
    id: `${scope}.backendErrors_onb_2063`,
    defaultMessage: 'Buyer or supplier is declined',
  },
  backendError_onb_2067: {
    id: `${scope}.backendErrors_onb_2067`,
    defaultMessage: 'The specified company already has a signer',
  },
  backendError_onb_2068: {
    id: `${scope}.backendErrors_onb_2068`,
    defaultMessage:
      'Sorry, you cannot add more buyers until one of your deals is financed by Stenn. Or contact our support service.',
  },
  backendError_onb_2069: {
    id: `${scope}.backendErrors_onb_2069`,
    defaultMessage: 'This company is not a Supplier for this buyer!',
  },
  backendError_cs_0: {
    id: `${scope}.backendError_cs_0`,
    defaultMessage: 'Access denied. The requested resource is not available for the current user.',
  },
  backendError_cs_2081: {
    id: `${scope}.backendError_cs_2081`,
    defaultMessage: 'Currency is unavailable for current invoice',
  },
  backendError_cs_2089: {
    id: `${scope}.backendError_cs_2089`,
    defaultMessage: 'You already are creating invoice. Please wait until operation completed!',
  },
  backendError_cs_2092: {
    id: `${scope}.backendError_cs_2092`,
    defaultMessage: 'The invoice categorized as a goods product type lacks a transport document.',
  },
  backendError_cs_2100: {
    id: `${scope}.backendError_cs_2100`,
    defaultMessage: 'This information is currently unavailable. Please try again later.',
  },
  backendError_cs_2177: {
    id: `${scope}.backendError_cs_2177`,
    defaultMessage:
      'You have reached your limit of draft invoices. Submit your deal or delete unnecessary invoices to continue',
  },
  backendError_cs_2178: {
    id: `${scope}.backendError_cs_2178`,
    defaultMessage:
      'You cannot create a new deal at this time until you have at least one funded deal. Please contact welcome@stenn.com for assistance.',
  },
  backendError_cs_3014: {
    id: `${scope}.backendError_cs_3014`,
    defaultMessage: 'We are sorry, but you cannot submit requests anymore. Please contact our support',
  },
  backendError_cs_3015: {
    id: `${scope}.backendError_cs_3015`,
    defaultMessage: 'Your previous request is still processing',
  },
  backendError_cs_3016: {
    id: `${scope}.backendError_cs_3016`,
    defaultMessage: 'This person already works for another company',
  },
  backendError_onb_2178: {
    id: `${scope}.backendError_onb_2178`,
    defaultMessage:
      'You cannot create a new deal at this time until you have at least one funded deal. Please contact welcome@stenn.com for assistance.',
  },
  backendError_onb_3000: {
    id: `${scope}.backendError_onb_3000`,
    defaultMessage: 'The request to the identity provider cannot be completed at this time.',
  },
  backendError_onb_3001: {
    id: `${scope}.backendError_onb_3001`,
    defaultMessage: 'User does not exist in identity provider',
  },
  backendError_onb_3002: {
    id: `${scope}.backendError_onb_3002`,
    defaultMessage: 'User already exists in database',
  },
  backendError_onb_3003: {
    id: `${scope}.backendError_onb_3003`,
    defaultMessage: 'User with this email was not found',
  },
  backendError_onb_3004: {
    id: `${scope}.backendError_onb_3004`,
    defaultMessage: 'User creation in database failed',
  },
  backendError_onb_3005: {
    id: `${scope}.backendError_onb_3005`,
    defaultMessage: 'User is already activated',
  },
  backendError_onb_3006: {
    id: `${scope}.backendError_onb_3006`,
    defaultMessage: 'User already exists in identity provider',
  },
  backendError_onb_3007: {
    id: `${scope}.backendError_onb_3007`,
    defaultMessage: 'User already has this social registration',
  },
  backendError_onb_3008: {
    id: `${scope}.backendError_onb_3008`,
    defaultMessage: 'User data integrity error',
  },
  backendError_onb_3009: {
    id: `${scope}.backendError_onb_3009`,
    defaultMessage: 'The invitation has been already accepted',
  },
  backendError_onb_3010: {
    id: `${scope}.backendError_onb_3010`,
    defaultMessage: 'Invitation code is invalid',
  },
  backendError_onb_3011: {
    id: `${scope}.backendError_onb_3011`,
    defaultMessage: 'User is already activating',
  },
  backendError_onb_3012: {
    id: `${scope}.backendError_onb_3012`,
    defaultMessage: 'Invalid external integration',
  },
  backendError_onb_3017: {
    id: `${scope}.backendError_onb_3017`,
    defaultMessage: 'Invalid code',
  },
  backendError_onb_3018: {
    id: `${scope}.backendError_onb_3018`,
    defaultMessage: 'Code expired',
  },
  backendError_onb_3023: {
    id: `${scope}.backendError_onb_3023`,
    defaultMessage: 'Editing email of registered person is prohibited',
  },
  backendError_onb_3050: {
    id: `${scope}.backendError_onb_3050`,
    defaultMessage: '{param0} content is empty',
  },
  backendError_onb_3051: {
    id: `${scope}.backendError_onb_3051`,
    defaultMessage:
      "You can't change your password since you are not signed up with Stenn. Go through the registration process, please.",
  },
  backendError_onb_3201: {
    id: `${scope}.backendError_onb_3201`,
    defaultMessage: 'Country code is invalid',
  },
} as const);

export default backendErrorMessages;
