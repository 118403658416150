import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { createRootReducer } from './rootReducer';

const composeEnhancers =
  import.meta.env.MODE !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
        trace: true,
      })
    : compose;

const configureStore = (initialState) => {
  const middlewares = [thunk];
  const enhancers = [applyMiddleware(...middlewares)];
  const store = createStore(createRootReducer, initialState, composeEnhancers(...enhancers));

  return store;
};

export const store = configureStore({});
