import { TCounterparyCompanyDto } from '@app/components-new/Flow/AddCounterpartyCompany/SelectCompany/types';
import { ApiProvider } from '@app/core/api/ApiProvider';
import { BASE_API_URLS } from '@app/core/api/constants';
import { prepareCompanyDataToCreateRequest } from '@app/core/features/mappers/dataForRequest';

export const addCounterparyCompanyRequest = async (data: TCounterparyCompanyDto) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      ...prepareCompanyDataToCreateRequest(data),
      companyRole: data.companyRole,
    }),
  };

  return ApiProvider.apiRequest(`${BASE_API_URLS.onboarding}/api/v1.2/Companies/addCounterparty`, requestOptions);
};
