/* eslint-disable no-param-reassign */
import { history } from '@app/core/history';
import { getInitialLanguageId } from '@app/core/internationalization';
import { isWindowSizeLessThanBreakpoint } from '@app/core/utilities/breakpoints';
import produce from 'immer';

import * as appActionTypes from '../actionTypes/app';

const DEFAULT_LANG = window._STENN_.CULTURE === 'common' ? 'en' : 'zh-Hans';

// substate of this reducer is not re-initializing after logout!
const appInitialState = {
  initCount: 0, // change of this triggers whole app re-render in AppInitializer (mainly on user logout)
  langId: getInitialLanguageId(DEFAULT_LANG),
  simpleRoutesHistory: [`${history.location.pathname}${history.location.search}`],

  isMainMenuOpen: isWindowSizeLessThanBreakpoint('xl'),
  isMobileHeaderVisible: false,
  currentBreakpoint: '',

  handleStepBack: null,

  sidebar: {
    isVisible: false, // option to preserve place for sidebar on the screen
    isOpen: false, // option to render sidebar content
  },

  serviceModal: {
    type: null, // from '@app/core/constants'
    contentProps: {},
    onOpen: null,
    isClosable: true,
    isOverflowClosable: true,
    onClose: null, // fires on unmount
    onXClose: null, // fires on X button click and before unmount
    onOverlayClose: null, // fires on overlay click and before unmount
  },
  formModal: {
    type: null, // from '@app/core/constants'
    onOpen: null,
    onClose: null,
    contentProps: {},
  },
  notification: {
    type: null, // from '@app/core/constants'
    contentProps: {},
    timeout: true,
    onClose: null,
  },
};

export const appReducer = produce(
  (draft, action) => {
    switch (action.type) {
      // this reducer fires after createRootReducer on logout!
      case appActionTypes.USER_LOGOUT:
        draft.initCount += 1;
        draft.langId = getInitialLanguageId(DEFAULT_LANG);
        break;

      case appActionTypes.SET_CURRENT_LANGUAGE_ID:
        draft.langId = action.payload;
        break;

      case appActionTypes.PUSH_TO_SIMPLE_ROUTES_HISTORY:
        if (draft.simpleRoutesHistory.length >= 10) {
          draft.simpleRoutesHistory.shift();
        }
        draft.simpleRoutesHistory.push(action.payload);
        break;

      case appActionTypes.SET_MAIN_MENU_VISIBILITY:
        draft.isMainMenuOpen = action.payload;
        break;

      case appActionTypes.SET_MOBILE_HEADER_VISIBILITY:
        draft.isMobileHeaderVisible = action.payload;
        break;

      case appActionTypes.SET_CURRENT_BREAKPOINT:
        draft.currentBreakpoint = action.payload || '';
        break;

      case appActionTypes.SET_SIDEBAR_OPTIONS:
        draft.sidebar = {
          ...draft.sidebar,
          ...action.payload,
        };
        break;

      case appActionTypes.SHOW_SERVICE_MODAL:
        draft.serviceModal = {
          ...appInitialState.serviceModal,
          ...action.payload,
        };
        break;

      case appActionTypes.HIDE_SERVICE_MODAL:
        draft.serviceModal = appInitialState.serviceModal;
        break;

      case appActionTypes.SHOW_NOTIFICATION_MODAL:
        draft.notification = {
          ...appInitialState.notification,
          ...action.payload,
        };
        break;

      case appActionTypes.HIDE_NOTIFICATION_MODAL:
        draft.notification = appInitialState.notification;
        break;

      case appActionTypes.SHOW_FORM_MODAL:
        draft.formModal = {
          ...appInitialState.formModal,
          ...action.payload,
        };
        break;

      case appActionTypes.HIDE_FORM_MODAL:
        draft.formModal = appInitialState.formModal;
        break;

      case appActionTypes.SET_STEP_BACK_HANDLER:
        draft.handleStepBack = typeof action.payload === 'function' ? action.payload : null;
        break;

      case appActionTypes.RESET_STEP_BACK_HANDLER:
        draft.handleStepBack = null;
        break;

      default:
        break;
    }
  },
  { ...appInitialState }
);
