import { FC } from 'react';

import { useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { CircularProgress, DialogContent } from '@mui/material';
import { Stack } from '@mui/system';

import { EditInvoiceInner } from './EditInvoiceInner';
import { IEditInvoiceDialogContentProps } from './types';

export const EditInvoiceDialogContent: FC<IEditInvoiceDialogContentProps> = ({
  trId,
  invoiceId,
  onClose,
  onSuccess,
}) => {
  const httpClient = useHttpClient();
  const { data: invoice } = useQuery({
    queryKey: [apiRoutes.InvoiceGetInvoice, invoiceId],
    queryFn: () =>
      httpClient(apiRoutes.InvoiceGetInvoice, 'post', {
        data: {
          actual: true,
          id: invoiceId,
        },
      }),
    cacheTime: 0,
    staleTime: 0,
  });

  if (!invoice)
    return (
      <DialogContent>
        <Stack flexGrow={1} alignItems="center" justifyContent="center" height="100%" minHeight="80vh">
          <CircularProgress />
        </Stack>
      </DialogContent>
    );

  return (
    <EditInvoiceInner
      trId={trId}
      invoice={invoice as Required<TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto>}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};
