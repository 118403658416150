import { FC, ReactNode, useMemo } from 'react';

import { IntlShape, useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';

import { FetchError } from '@app/api/lib/FetchError';
import { HTTPError } from '@app/api/lib/HTTPError';
import { getBackendErrorByCode } from '@app/core/error-handling';
import { HttpClientError } from '@app/core/httpClient/HttpClientError';
import { captureException } from '@sentry/react';
import { useToast } from '@stenngroup/ui-kit';
import isNetworkError from 'is-network-error';

const parseError = (error: HttpClientError | HTTPError, intl: IntlShape): string => {
  let message = intl.formatMessage({
    id: 'constants.universalMessages.somethingWentWrongSupportTeamAlreadyNotified',
  });
  if (error instanceof HTTPError || error instanceof HttpClientError) {
    const strCode = error?.strCode;
    const errorMessage = getBackendErrorByCode({
      errorCode: strCode as string,
      intl,
    });
    message = errorMessage;
    if (strCode === '0') {
      message = error.rawMessage ?? error.message;
    }
  }
  return message;
};

export const QueryClientProviderWrapper: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const toast = useToast();
  const intl = useIntl();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            refetchOnMount: true,
            keepPreviousData: false,
            onError: (err): void => {
              if (err instanceof FetchError) {
                captureException(err.fetchError);
              } else if (err instanceof HttpClientError) {
                captureException(err, {
                  fingerprint: err.getFingerprint(),
                  extra: err.toObject(),
                });
              } else {
                captureException(err);
              }
              toast.showError(parseError(err as HTTPError | HttpClientError, intl));
            },
            retry: (failureCount, error): boolean => {
              // retry only in case if it's network error — for example, the user entered the subway metro tunnel and was lost
              // CORS error, invalid URL and backend responses are not considered network errors
              if (isNetworkError(error) || (error instanceof FetchError && isNetworkError(error.fetchError))) {
                return failureCount < 3;
              }
              return false;
            },
          },
          mutations: {
            onError: (err): void => {
              if (err instanceof FetchError) {
                captureException(err.fetchError);
              } else if (err instanceof HttpClientError) {
                captureException(err, {
                  fingerprint: err.getFingerprint(),
                  extra: err.toObject(),
                });
              } else {
                captureException(err);
              }

              toast.showError(parseError(err as HTTPError | HttpClientError, intl));
            },
          },
        },
      }),
    [toast]
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
