import _get from 'lodash.get';
import PropTypes from 'prop-types';

const DealsIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4.00391C5.89543 4.00391 5 4.89934 5 6.00391V18.0039C5 19.1085 5.89543 20.0039 7 20.0039H17C18.1046 20.0039 19 19.1085 19 18.0039V6.00391C19 4.89934 18.1046 4.00391 17 4.00391H7ZM12.3594 17.0948H11.456L11.4602 16.119C9.70455 15.9741 8.60511 15.0238 8.54545 13.4258H10.1009C10.1562 14.1971 10.706 14.6317 11.4687 14.7511L11.4858 12.1729L11.1023 12.0749C9.66619 11.7042 8.77557 10.984 8.77557 9.73118C8.77131 8.32067 9.92187 7.34055 11.5199 7.17862L11.5241 6.18572H12.4276L12.4233 7.17862C14.0341 7.33203 15.0994 8.32067 15.125 9.67578H13.6037C13.5355 9.06214 13.0966 8.65305 12.4148 8.53374L12.3977 10.967L12.679 11.0394C13.9915 11.3505 15.2401 12.0408 15.2443 13.5536C15.2401 14.9812 14.1918 15.9869 12.3636 16.119L12.3594 17.0948ZM12.3892 12.4116L12.3722 14.7511C13.152 14.6232 13.6591 14.1715 13.6634 13.5408C13.6591 12.9357 13.1648 12.6374 12.3892 12.4116ZM10.3778 9.63317C10.3693 10.2383 10.9062 10.5494 11.4943 10.7369L11.5114 8.53374C10.7955 8.65731 10.3821 9.08345 10.3778 9.63317Z"
        fill={_get(colors, '0', 'currentColor')}
      />
    </svg>
  );
};

DealsIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default DealsIcon;
