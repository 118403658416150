import { useCallback, useEffect, useRef } from 'react';

import { throttle } from '@app/utils/debounce';

export const useThrottle = (callback, delay, options = { leading: true, trailing: true }) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => callbackRef.current(...args), delay, options),
    [delay]
  );
};
