import { TooltipProps, tooltipClasses } from '@mui/material';
import { Tooltip } from '@stenngroup/ui-kit';
import styled from 'styled-components';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} trigger="hover" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.level1,
    color: theme.palette.text.primary,
    fontSize: 11,
    borderRadius: 8,
    padding: '12px 8px',
    border: `1px solid ${theme.palette.grey['500']}`,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.02)',
    maxWidth: 208,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.level1,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.palette.grey['500']}`,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.02)',
  },
}));
