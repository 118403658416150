import { FC } from 'react';

import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

import { LimitText } from './constants/limitText';
import { IRequestLimitTextProps } from './types';

export const RequestLimitText: FC<IRequestLimitTextProps> = ({ status, value }) => {
  return (
    <Stack gap={0.5} alignItems="center" direction="row">
      <Typography.Overline color="text.secondary">{LimitText[status]}</Typography.Overline>
      <Typography.Overline color="text.primary">
        {formatCurrency(value, {
          currency: 'USD',
        })}
      </Typography.Overline>
    </Stack>
  );
};
