import {
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto,
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto,
} from '@app/core/__generated__';
import { EDealType } from '@app/core/constants';
import { EDocumentType } from '@app/core/constants/files';

import { IEditInvoiceForm } from '../../types';

function formatDocument(
  document: TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto | undefined,
  documentType: EDocumentType
) {
  if (!document) return null;
  return {
    documentType,
    documentId: document.id,
    name: document.fileName,
    uid: document.id,
    isUploaded: true,
  };
}

export const getFormData = (
  invoice: Required<TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto>
): IEditInvoiceForm => {
  return {
    number: invoice.number ?? '',
    currency: String(invoice.currencyIsoNumericCode) ?? '',
    faceValueNationalCurrency: String(invoice.totalValue),
    dueDate: new Date(invoice.dueDate),
    otherDocuments: (invoice.attachments ?? [])
      .filter((doc) => doc.documentType === EDocumentType.Other)
      .map((doc) => formatDocument(doc, EDocumentType.Other)) as IEditInvoiceForm['otherDocuments'],
    invoiceDocument: formatDocument(
      invoice.attachments?.find((doc) => doc.documentType === EDocumentType.CommercialInvoice),
      EDocumentType.CommercialInvoice
    ) as IEditInvoiceForm['invoiceDocument'],
    transportDocument: formatDocument(
      invoice.attachments?.find((doc) => doc.documentType === EDocumentType.TransportDocument),
      EDocumentType.TransportDocument
    ) as IEditInvoiceForm['transportDocument'],
    dealType: EDealType.Goods,
    isCommodity: invoice.isCommodity,
    isPerishable: invoice.isPerishable,
    isUnknownCategory: invoice.isCommodity === false && invoice.isPerishable === false,
  };
};
