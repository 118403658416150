import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { TEST_IDS } from '@app/core/constants';
import { ErrorOutlined } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import Stack from '@mui/material/Stack';

export const ServiceBasedDealAlert: FC = () => {
  return (
    <Alert
      data-testid={TEST_IDS.serviceBasedAlert}
      variant="outlined"
      color="error"
      sx={{ borderRadius: 2 }}
      icon={<ErrorOutlined />}
    >
      <AlertTitle>
        <FormattedMessage id="components.Flow.InvoiceForm.serviceAlertTitle" />
      </AlertTitle>
      <Stack>
        <FormattedMessage id="components.Flow.InvoiceForm.serviceAlertText1" />
      </Stack>
      <Stack marginTop={1}>
        <FormattedMessage id="components.Flow.InvoiceForm.serviceAlertText2" />
      </Stack>
    </Alert>
  );
};
