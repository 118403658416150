import { useMemo } from 'react';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { createHttpClient } from '@app/core/httpClient';

export const useHttpClient = () => {
  const configuredFetch = useConfiguredFetch();
  const httpClient = useMemo(() => createHttpClient(configuredFetch), [configuredFetch]);
  return httpClient;
};
