export class RequestError extends Error {
  requestUrl: string;

  response: any;

  requestOptions?: RequestInit;

  name = 'RequestError';

  constructor({
    statusText,
    requestUrl,
    requestOptions,
    response,
  }: {
    statusText: string;
    requestUrl: string;
    requestOptions?: RequestInit;
    response: any;
  }) {
    super(statusText);
    this.requestUrl = requestUrl;
    this.requestOptions = requestOptions;
    this.response = response;
  }
}

export function isRequestError<E extends Error>(error: RequestError | E): error is RequestError {
  return typeof (error as RequestError).response !== 'undefined';
}
