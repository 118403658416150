export const NOTIFICATION_CODES = {
  deal_review_needed: true,
  referral_updated: true,
  money_transferred: false,
  limit_change_minus: false,
  limit_change_plus: false,
  invoices_overdue: false,
  invoice_repaid: false,
  deal_attachment_is_awaiting_signing: false,
  link_to_company_request_created: false,
  link_to_company_request_approved: false,
  link_to_company_request_declined: false,
  reps_documents_have_been_sent_to_signatories: false,
  reps_documents_have_been_signed: false,
  reps_deal_has_been_financed: false,
  reps_outgoing_payment: false,
  reps_invoice_has_been_repaid: false,
  reps_overdue_payment_on_the_due_date: false,
  reps_overdue_payment_after_7_days: false,
  reps_overdue_payment_after_30_days: false,
  reps_buyer_limit_has_been_changed: false,
  reps_new_lessons: false,
  reps_invitation_has_been_accepted: false,
  reps_invitation_has_been_rejected: false,
  reps_invitation_is_about_to_expire: false,
  reps_invitation_is_expiring_today: false,
  reps_invitation_has_expired: false,
  reps_buyer_marketing_limit_has_been_changed: false,
  default_notification: false,
};

export const NOTIFICATION_CODE_VALUES = {
  deal_review_needed: 'deal_review_needed',
  referral_updated: 'referral_updated',
  money_transferred: 'money_transferred',
  limit_change_minus: 'limit_change_minus',
  limit_change_plus: 'limit_change_plus',
  invoices_overdue: 'invoices_overdue',
  invoice_repaid: 'invoice_repaid',
  deal_attachment_is_awaiting_signing: 'deal_attachment_is_awaiting_signing',
  link_to_company_request_created: 'link_to_company_request_created',
  link_to_company_request_approved: 'link_to_company_request_approved',
  link_to_company_request_declined: 'link_to_company_request_declined',
  reps_documents_have_been_sent_to_signatories: 'reps_documents_have_been_sent_to_signatories',
  reps_documents_have_been_signed: 'reps_documents_have_been_signed',
  reps_deal_has_been_financed: 'reps_deal_has_been_financed',
  reps_outgoing_payment: 'reps_outgoing_payment',
  reps_invoice_has_been_repaid: 'reps_invoice_has_been_repaid',
  reps_overdue_payment_on_the_due_date: 'reps_overdue_payment_on_the_due_date',
  reps_overdue_payment_after_7_days: 'reps_overdue_payment_after_7_days',
  reps_overdue_payment_after_30_days: 'reps_overdue_payment_after_30_days',
  reps_buyer_limit_has_been_changed: 'reps_buyer_limit_has_been_changed',
  reps_new_lessons: 'reps_new_lessons',
  reps_invitation_has_been_accepted: 'reps_invitation_has_been_accepted',
  reps_invitation_has_been_rejected: 'reps_invitation_has_been_rejected',
  reps_invitation_is_about_to_expire: 'reps_invitation_is_about_to_expire',
  reps_invitation_is_expiring_today: 'reps_invitation_is_expiring_today',
  reps_invitation_has_expired: 'reps_invitation_has_expired',
  reps_buyer_marketing_limit_has_been_changed: 'reps_buyer_marketing_limit_has_been_changed',
  default_notification: 'default_notification',
} as const;

export const NOTIFICATION_DATA_KEYS = {
  entityDisplayName: 'EntityDisplayName',
  newLimit: 'NewLimit',
  isSupplierInvitation: 'IsSupplierInvitation',
  supplierId: 'SupplierId',
  status: 'Status',
  fullName: 'FullName',
  expirationDate: 'ExpirationDate',
  buyerName: 'BuyerName',
  limitAmount: 'LimitAmount',
  invoiceNumber: 'InvoiceNumber',
  dealNumber: 'DealNumber',
  dealId: 'DealId',
  paymentNumber: 'PaymentNumber',
  supplierName: 'SupplierName',
  currency: 'Currency',
  paymentAmount: 'PaymentAmount',
};

export const NOTIFICATION_MAX_LIMIT = 9;

export const NOTIFICATIONS_PER_PAGE = 6;

export const NOTIFICATIONS_REQUESTS_KEYS = {
  getNewNotifications: 'notifications_new_get',
  getReadNotifications: 'notifications_read_get',
  getCounts: 'notifications_get_counts',
} as const;
