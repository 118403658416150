import { history } from '@app/core/history';
import { getCurrentLanguageFromStorage } from '@app/core/storage';
import { getSearchParams } from '@app/core/utilities';

import { LANGUAGES, TLang } from './languages';

export const setHtmlLanguageAttribute = (attribute: TLang) => {
  const HTML_NODE = document.getElementsByTagName('html')[0];
  HTML_NODE.setAttribute('lang', attribute);
};

export const isValidLanguage = (langId: string) => Boolean(LANGUAGES.find((item) => item.id === langId));

export const getInitialLanguageId = (defaultLang: TLang) => {
  const searchParams = getSearchParams(history.location.search);
  const langFromUrl = searchParams.lang as string;

  if (isValidLanguage(langFromUrl)) {
    return langFromUrl;
  }

  const langDataFromStorage = getCurrentLanguageFromStorage();
  const combinedLangData = [];

  if (langDataFromStorage) {
    combinedLangData.push(langDataFromStorage);
  }

  if (combinedLangData.length) {
    combinedLangData.sort((a, b) => Number(new Date(b.timestamp)) - Number(new Date(a.timestamp)));

    for (let i = 0; i < combinedLangData.length; i += 1) {
      if (isValidLanguage(combinedLangData[i].langId)) {
        return combinedLangData[i].langId;
      }
    }
  }

  return defaultLang;
};
