import { BASE_API_URLS } from '@app/core/api/constants';
import { ICompanyContact } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';

interface IGetCompanyCompanyContactsPayload {
  configuredFetch: typeof fetch;
}

export const getCompanyContactsRequests = async ({
  configuredFetch,
}: IGetCompanyCompanyContactsPayload): Promise<ICompanyContact[]> => {
  const response = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Contacts/GetContacts`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
