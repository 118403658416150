import _get from 'lodash.get';
import PropTypes from 'prop-types';

const ColoredDocumentIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g>
        <path
          d="M1,3c0-1.1,0.9-2,2-2h18.1c0.6,0,1.1,0.2,1.5,0.7l5.9,6.8C28.8,8.8,29,9.3,29,9.8V39c0,1.1-0.9,2-2,2H3
                c-1.1,0-2-0.9-2-2V3z"
          fill={_get(colors, '1', 'currentColor')}
        />
        <path
          d="M27,42H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h18.1c0.9,0,1.7,0.4,2.3,1l5.9,6.8C29.7,8.3,30,9,30,9.8V39
                C30,40.7,28.7,42,27,42z M3,2C2.4,2,2,2.4,2,3v36c0,0.6,0.4,1,1,1h24c0.6,0,1-0.4,1-1V9.8c0-0.2-0.1-0.5-0.2-0.7l-5.9-6.8
                C21.7,2.1,21.4,2,21.1,2H3z"
          fill={_get(colors, '0', 'currentColor')}
        />
      </g>
      <g>
        <path
          d="M23.3,30H6.8c-0.3,0-0.5-0.2-0.5-0.5S6.5,29,6.8,29h16.5c0.3,0,0.5,0.2,0.5,0.5S23.5,30,23.3,30z"
          fill={_get(colors, '0', 'currentColor')}
        />
      </g>
      <g>
        <path
          d="M23.3,34H6.8c-0.3,0-0.5-0.2-0.5-0.5S6.5,33,6.8,33h16.5c0.3,0,0.5,0.2,0.5,0.5S23.5,34,23.3,34z"
          fill={_get(colors, '0', 'currentColor')}
        />
      </g>
      <g>
        <path
          d="M29,9.5h-6c-0.8,0-1.5-0.7-1.5-1.5V1h1v7c0,0.3,0.2,0.5,0.5,0.5h6V9.5z"
          fill={_get(colors, '0', 'currentColor')}
        />
      </g>
    </svg>
  );
};

ColoredDocumentIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ColoredDocumentIcon;
