import { MessageDescriptor } from 'react-intl';

import { ListContainer } from '@app/components-new/PageListContainer/ListContainer';
import { Pagination, Stack } from '@mui/material';

interface IPageListContainerProps<T extends { id: string }> {
  Row: React.ComponentType<T>;
  LoadingIndicator: React.ReactNode;
  items: T[] | undefined;
  pages: number;
  page: number;
  status: 'loading' | 'error' | 'success' | 'idle';
  onPageChange?: (_e: React.ChangeEvent<unknown>, page: number) => void;
  emptyMessageId: MessageDescriptor['id'];
}

export const PageListContainer = <T extends { id: string }>({
  Row,
  items,
  page,
  pages,
  status,
  onPageChange,
  emptyMessageId,
  LoadingIndicator,
}: IPageListContainerProps<T>): JSX.Element => {
  return (
    <>
      <Stack gap={1}>
        <ListContainer
          items={items}
          status={status}
          emptyMessageId={emptyMessageId}
          Row={Row}
          LoadingIndicator={LoadingIndicator}
        />
      </Stack>
      {pages > 1 && (
        <Stack flexDirection="row" justifyContent="center" marginTop={2}>
          <Pagination count={pages} page={page} onChange={onPageChange} />
        </Stack>
      )}
    </>
  );
};
