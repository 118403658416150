const tableData = [
  [
    {
      cellType: 'head',
      defaultMessage: 'Overdue',
    },
    {
      cellType: 'head',
      defaultMessage: 'Late Payment Fees Percent',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: 'From 7 days to\u00A060\u00A0days',
    },
    {
      cellType: 'body',
      defaultMessage:
        'Additional 2% per annum is applied to the face value of that account receivable for the following period: STARTING the date that follows the due date UP TO the date (included) the account receivable is settled in full by your debtor.',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: 'From 61 to\u00A0120\u00A0days',
    },
    {
      cellType: 'body',
      defaultMessage:
        'Additional 4% per annum is applied to the face value of that account receivable for the following period: STARTING the date that follows the due date UP TO the date (included) the account receivable is settled in full by your debtor.',
    },
  ],
  [
    {
      cellType: 'body',
      defaultMessage: 'More\u00A0than 120\u00A0days',
    },
    {
      cellType: 'body',
      defaultMessage: 'No rebate will be paid to you in respect of any account receivable.',
    },
  ],
];

export default tableData;
