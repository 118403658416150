import * as yup from 'yup';

export const getSchema = (formIsRequired: boolean, maxLimit: number, requiredMessage: string): yup.Schema => {
  const baseRule = yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .lessThan(maxLimit);

  return yup.object().shape({
    limit: formIsRequired ? baseRule.required(requiredMessage) : baseRule,
  });
};
