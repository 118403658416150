import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { EEntityCreatorType, IInvoiceDTO } from '@app/core/models';
import { getApiQuery } from '@app/core/utilities';
import { omitUndefined } from '@app/helpers/omitUndefined';

interface IGetInvoiceResponse {
  '@odata.count'?: number;
  value: IInvoiceDTO[];
}

interface IGetInvoicesByTrIdRequestPayload {
  trId: string;
  top?: number;
  skip?: number;
  loadDocuments?: boolean;
}

export const getInvoicesByTrIdRequest = async ({
  trId,
  top,
  skip,
  loadDocuments,
}: IGetInvoicesByTrIdRequestPayload) => {
  const requestOptions = {
    method: 'GET',
  };

  const queryOptions = omitUndefined({
    EntityCreatorType: EEntityCreatorType.All,
    Filter: `tradeRelation/OnboardingId eq ${trId} and dealId eq null and (state eq 'Draft' or state eq 'New')`,
    count: true,
    OrderBy: 'created asc',
    Top: top,
    Skip: skip,
    LoadDocuments: loadDocuments,
  });

  const query = getApiQuery(queryOptions);

  return ApiProvider.apiRequest<IGetInvoiceResponse>(
    `${BASE_API_URLS.clientsServiceWeb}/Invoice?${query}`,
    requestOptions
  ).then((response) => ({
    count: response['@odata.count'],
    value: response.value,
  }));
};
