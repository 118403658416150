import { FC } from 'react';

import { TeamCard } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/TeamCard';
import { EContactStatus } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';

export const TeamCardSkeleton: FC = () => {
  return (
    <TeamCard
      contact={{
        id: '',
        position: '',
        firstName: '',
        lastName: '',
        email: '',
        fullName: '',
        status: EContactStatus.Pending,
        effectiveDate: '',
        editable: false,
        requestId: '',
      }}
      loadingState="loading"
    />
  );
};
