import { ApiProvider, STENN_APP_HEADER } from '@app/core/api';
import { Auth0Connection } from '@app/core/authentication';
import { ErrorLogger } from '@app/core/logger';
import { getCurrentLanguageFromStorage } from '@app/core/storage';

import { MODAL_TYPES } from '../core/constants';
import { getFullErrorCode, getParsedBackendErrorData } from '../core/error-handling';
import { showServiceModalAction, store } from '../core/global-store';

const DOMAIN_HEADER = window._STENN_.CULTURE === 'common' ? 'GLOBAL' : 'CHINA';

export const configureApiProvider = () => {
  ApiProvider.configure({
    auth0Connection: Auth0Connection,
    errorLogger: ErrorLogger,
    errorHandler: (error, params) => {
      if (error === null) {
        return;
      }
      const preparedErrorData = getParsedBackendErrorData(error);
      const errorCode = getFullErrorCode(preparedErrorData);

      if (!errorCode) {
        return;
      }

      store.dispatch(
        showServiceModalAction({
          type: MODAL_TYPES.error,
          onClose: params?.onClose || null,
          contentProps: {
            errorData: preparedErrorData,
            errorCode,
            onRetry: params?.onRetry || null,
          },
        })
      );
    },
  });

  ApiProvider.extendRequestHeaders(() => {
    const headers = {
      lang: getCurrentLanguageFromStorage() || 'en',
      ENV_DOMAIN: DOMAIN_HEADER,
      'stenn-application': STENN_APP_HEADER,
    };
    return headers;
  });
};
