import { REGEXP, TOO_LONG_NUMBER, TOO_SHORT_NUMBER } from '@app/core/constants';
import isEmailValidator from 'validator/lib/isEmail';

export const hasNumber = (value: string) => new RegExp(REGEXP.number).test(value);

export const hasUpperCase = (value: string) => new RegExp(REGEXP.upperCase).test(value);

export const hasLowerCase = (value: string) => new RegExp(REGEXP.lowerCase).test(value);

export const isNotEmptyString = (value?: string) => typeof value === 'string' && value.trim().length > 0;

export const isEmail = (value: string) =>
  typeof value === 'string' && isEmailValidator(value) && new RegExp(REGEXP.email).test(value);

export const isEmailWithPlus = (value: string) =>
  typeof value === 'string' && isEmailValidator(value) && new RegExp(REGEXP.emailWithPlus).test(value);

export const isNotShortPassword = (value: string) => value.length >= 8;

export const isAllowedNameLength = (value: string) => value.length <= 40;

export const isOnlyNumberOrLetter = (value: string) => new RegExp(REGEXP.onlyNumberOrLetter).test(value);

export const isNotEmpty = (value: string | any[] | null) => {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return true;
  }

  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  if (typeof value.length !== 'undefined') {
    if (/^[\s]*$/.test(value.toString())) {
      return false;
    }
    return value.length !== 0;
  }

  let count = 0;

  Object.keys(value).forEach((item) => {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (value.hasOwnProperty(item)) {
      count += 1;
    }
  });

  return count !== 0;
};

export const isValidBeneficiaryNameLength = (value: string): boolean => {
  const MAX_LENGTH = 35;
  return value.length <= MAX_LENGTH;
};

export const isPhoneNumberTooShort = (value?: string) =>
  value ? value.slice(1).trim().length >= TOO_SHORT_NUMBER : true;

export const isPhoneNumberNotLong = (value?: string) => (value ? value.slice(1).trim().length < TOO_LONG_NUMBER : true);

export const isPhoneNumberWithPrefix = (value?: string) =>
  value ? new RegExp(REGEXP.phoneNumberWithPrefix).test(value) : true;

export const isSwift = (value: string) => new RegExp(REGEXP.swift).test(value);

export const isValidSwiftEnding = (value: string) => {
  if (value.length < 3) {
    return true;
  }

  return value.slice(-3).toUpperCase() !== 'XXX';
};

export const isUserName = (value: string) => {
  const preparedValue = typeof value === 'string' ? value.trim() : value;

  const isNotEmptyValue = isNotEmptyString(preparedValue);
  const isValidUserName = new RegExp(REGEXP.userName).test(preparedValue);

  return isNotEmptyValue && isValidUserName;
};

export const isBankName = (value: string) => new RegExp(REGEXP.bankName).test(value);

export const withoutSymbols = (value: string) => !new RegExp(REGEXP.hasSymbols).test(value);

export const isUnifiedSocialCreditCode = (value: string): boolean => /^[\u4e00-\u9fa5a-zA-Z0-9]{18}$/.test(value);

export const isChineseCompanyName = (value: string): boolean => {
  const regexp = /^([\s\u4e00-\u9fff])*$/g;
  return regexp.test(value);
};

export const dunsValidation = (value: string): boolean => /^\d{9}$/.test(value);

/**
 * CIN is a 21 digits alpha-numeric code (eg, U12345AB6784CDE123456, additional info) and LLPIN a 7-digit alphanumeric (eg, AAA-7663)
 * @returns boolean
 */
export const isCin = (value: string): boolean => /^(?:[A-Z0-9]{21}|[A-Z]{3}-[A-Z0-9]{4})$/.test(value);

export const isValidName = (value: string): boolean => new RegExp(REGEXP.validName).test(value);
