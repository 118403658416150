import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ICompanyNotFoundDialogContent } from '@app/components-new/SelectCompany/DialogsContainer/CompanyNotFoundDialogContent/types';
import { TEST_IDS } from '@app/core/constants/testIds';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, useTheme } from '@mui/material';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

export const CompanyNotFoundDialogContent: FC<ICompanyNotFoundDialogContent> = ({ onLogoutClick, onNextClick }) => {
  const theme = useTheme();
  return (
    <>
      <DialogTitle
        sx={{
          py: 1,
        }}
        width="100%"
        alignItems="center"
      >
        <Typography.H5 color={(t) => t.palette.text.primary}>
          <FormattedMessage id="pages.addCompanyInformation.selectCompanyPage.companyNotFoundTitle" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
        }}
      >
        <Typography.Body1>
          <FormattedMessage
            id="pages.addCompanyInformation.selectCompanyPage.companyNotFound"
            values={{
              link: (
                <a
                  style={{
                    color: theme.palette.primary.main,
                  }}
                  href="mailto:cs@stenn.com"
                >
                  cs@stenn.com
                </a>
              ),
            }}
          />
        </Typography.Body1>
      </DialogContent>
      <DialogActions>
        <Stack gap={1} direction="row">
          <Button variant="tertiary" size="medium" data-testid={TEST_IDS.acceptButton} onClick={onLogoutClick}>
            <FormattedMessage id="constants.universalMessages.logOut" />
          </Button>
          <Button
            variant="primary"
            size="medium"
            data-testid={TEST_IDS.nextButton}
            onClick={onNextClick}
            endIcon={<ArrowForwardIcon />}
          >
            <FormattedMessage id="pages.addCompanyInformation.selectCompanyPage.editCompany" />
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};
