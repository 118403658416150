import _get from 'lodash.get';
import PropTypes from 'prop-types';

const InfoIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={_get(colors, '0', 'currentColor')}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5Z"
        fill="#BCB9CF"
      />
      <rect x="11" y="7.5" width="2" height="2" rx="1" fill="white" />
      <rect x="11" y="11.5" width="2" height="6" fill="white" />
    </svg>
  );
};

InfoIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default InfoIcon;
