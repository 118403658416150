import { FC, ReactNode } from 'react';

import { createPortal } from 'react-dom';
import { Link, useResolvedPath } from 'react-router-dom';

import { Breadcrumbs, BreadcrumbsLink } from '@stenngroup/ui-kit';

const BREADCRUMBS_CONTAINER_ID = 'breadcrumb';

export const BreadcrumbOutlet: FC = () => {
  return <div id={BREADCRUMBS_CONTAINER_ID} />;
};

interface IBreadcrumb {
  disabled?: boolean;
  active?: boolean;
  icon?: ReactNode;
  text?: ReactNode;
  path?: string;
}

interface IAppBreadcrumbProps {
  items: IBreadcrumb[];
}

export const AppBreadcrumb: FC<IAppBreadcrumbProps> = ({ items }) => {
  const outletElement = document.getElementById(BREADCRUMBS_CONTAINER_ID) as HTMLOListElement;
  const url = useResolvedPath('').pathname;

  if (!outletElement || !items.length) {
    return null;
  }

  const Crumbs = items.map(({ path, active, text, icon, disabled }, index) => {
    const isActive = items.length - 1 === index;
    return (
      <BreadcrumbsLink key={index} to={path || url} component={Link} active={active || isActive} disabled={disabled}>
        {icon} {text}
      </BreadcrumbsLink>
    );
  });

  return createPortal(
    <Breadcrumbs maxItems={3} variant="plain">
      {Crumbs}
    </Breadcrumbs>,
    outletElement
  );
};
