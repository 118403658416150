import { BASE_API_URLS } from '@app/core/api/constants';
import { ECompanyStatus } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';

// @TODO fix this file after migration
interface IGetCompanyFinanceDataPayload {
  configuredFetch: typeof fetch;
  payload: {
    hasBuyerRole: boolean;
  };
}

interface IFinanceData {
  state: ECompanyStatus;
  buyerClientsStatus: ECompanyStatus;
  id: string;
  marketingLimitAcquired: boolean;
  companyIdentity: {
    id: string;
    companyName: string;
  };
  fullLimit: number;
  invoiceFinanced: number;
  invoiceFees: number;
  invoiceOutstanding: number;
  invoiceRepaymentOutstanding: number;
  invoiceRepaymentOverdue: number;
  amtPastDueDate: number;
  reservedLimit: number;
  availableLimit: number;
  firstFundingDate: string | null;
  companyLimitState: string;
  companyLimitStateInfo: {
    companyIdentityId: string;
    additionalData: {
      LimitValue: number;
    };
    state: string;
    availableActions: any[];
  };
  insurance: boolean;
  operationId: string;
  available: null;
  requestedAmount: number;
}

export const getCompanyFinanceDataRequest = async ({
  payload,
  configuredFetch,
}: IGetCompanyFinanceDataPayload): Promise<IFinanceData | null> => {
  if (!payload.hasBuyerRole) {
    return null;
  }
  const response = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Buyers/GetMyBuyerRoleInfo`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  const availableLimit = !data.availableLimit || data.availableLimit < 0 ? 0 : data.availableLimit;
  return {
    ...data,
    availableLimit,
    fullLimit: availableLimit + data.reservedLimit + data.invoiceOutstanding,
  };
};
