const WarningOutlined = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9797_189163)">
      <path d="M12 13.1199V9.37988" stroke="#F19F00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.999 16.125C11.861 16.125 11.749 16.237 11.75 16.375C11.75 16.513 11.862 16.625 12 16.625C12.138 16.625 12.25 16.513 12.25 16.375C12.25 16.237 12.138 16.125 11.999 16.125"
        stroke="#F19F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0294 4.17309L21.6888 17.5183C22.5788 19.0695 21.4538 21 19.6597 21H4.34088C2.54579 21 1.42073 19.0695 2.31178 17.5183L9.97117 4.17309C10.8682 2.60897 13.1323 2.60897 14.0294 4.17309Z"
        stroke="#F19F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9797_189163">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WarningOutlined;
