import AttachFileIcon from './Icons/AttachFileIcon';
import BurgerMenuIcon from './Icons/BurgerMenuIcon';
import BuyersIcon from './Icons/BuyersIcon';
import CalendarIcon from './Icons/CalendarIcon';
import CheckCircle from './Icons/CheckCircle';
import ChevronIcon from './Icons/ChevronIcon';
import CollapsedIcon from './Icons/CollapsedIcon';
import ColoredDocumentIcon from './Icons/ColoredDocumentIcon';
import CommissionsIcon from './Icons/CommissionsIcon';
import CrossCircle from './Icons/CrossCircle';
import CrossIcon from './Icons/CrossIcon';
import DashboardIcon from './Icons/DashboardIcon';
import DashedCircleIcon from './Icons/DashedCircle';
import DealsIcon from './Icons/DealsIcon';
import DocumentsFile from './Icons/DocumentsFile';
import DotSolid from './Icons/DotSolid';
import ErrorCircleIcon from './Icons/ErrorCIrcle';
import ExpandIcon from './Icons/ExpandIcon';
import EyeClosed from './Icons/EyeClosed';
import EyeOpened from './Icons/EyeOpened';
import FacebookIcon from './Icons/FacebookIcon';
import FilterIcon from './Icons/FilterIcon';
import GoogleIcon from './Icons/GoogleIcon';
import InfoAltIcon from './Icons/InfoAltIcon';
import InfoIcon from './Icons/InfoIcon';
import InfoOutlined from './Icons/InfoOutlined';
import InvoicesIcon from './Icons/InvoicesIcon';
import MailIcon from './Icons/MailIcon';
import NotificationsBellIcon from './Icons/NotificationsBellIcon';
import NotificationsIcon from './Icons/NotificationsIcon';
import ProfileIcon from './Icons/ProfileIcon';
import SearchIcon from './Icons/SearchIcon';
import ShareIcon from './Icons/ShareIcon';
import SuccessIcon from './Icons/SuccessIcon';
import SuccessOutlined from './Icons/SuccessOutlined';
import SuppliersIcon from './Icons/SuppliersIcon';
import TelIcon from './Icons/TelIcon';
import WarningIcon from './Icons/WarningIcon';
import WarningOutlined from './Icons/WarningOutlined';

const icons = {
  dashboard: DashboardIcon,
  invoices: InvoicesIcon,
  suppliers: SuppliersIcon,
  commission: CommissionsIcon,
  deals: DealsIcon,
  buyers: BuyersIcon,
  cross: CrossIcon,
  calendar: CalendarIcon,
  info: InfoIcon,
  infoAlt: InfoAltIcon,
  mail: MailIcon,
  tel: TelIcon,
  infoOutlined: InfoOutlined,
  notifications: NotificationsIcon,
  notificationsBell: NotificationsBellIcon,
  checkCircle: CheckCircle,
  crossCircle: CrossCircle,
  eyeClosed: EyeClosed,
  eyeOpened: EyeOpened,
  dotSolid: DotSolid,
  profile: ProfileIcon,
  burgerMenu: BurgerMenuIcon,
  filter: FilterIcon,
  search: SearchIcon,
  chevron: ChevronIcon,
  coloredDocument: ColoredDocumentIcon,
  success: SuccessIcon,
  successOutlined: SuccessOutlined,
  facebook: FacebookIcon,
  google: GoogleIcon,
  documents: DocumentsFile,
  attach: AttachFileIcon,
  share: ShareIcon,
  dashedCircle: DashedCircleIcon,
  errorCircle: ErrorCircleIcon,
  warning: WarningIcon,
  warningOutlined: WarningOutlined,
  ExpandIcon: ExpandIcon,
  CollapsedIcon: CollapsedIcon,
} as const;

export default icons;
