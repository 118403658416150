import { useController, useWatch } from 'react-hook-form';

import PlacesAutocomplete from '@app/components/PlacesAutocomplete';
import { IFormPlacesAutocompleteProps } from '@app/components/PlacesAutocomplete/FormPlacesAutocomplete/types';
import { TPlacesAutocompleteOption } from '@app/components/PlacesAutocomplete/types';

export const FormPlacesAutocomplete = <T extends object>(props: IFormPlacesAutocompleteProps<T>): JSX.Element => {
  const {
    field: { onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const value = useWatch({
    name: props.name,
    control: props.control,
  });

  const handleSelect = (option: TPlacesAutocompleteOption) => {
    onChange(option);
    if (props.onSelect) {
      props.onSelect(option);
    }
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur();
    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  return (
    <PlacesAutocomplete
      {...props}
      type={props.type}
      value={value}
      onSelect={handleSelect}
      onBlur={handleBlur}
      error={!!error}
      helperText={error?.message}
    />
  );
};
