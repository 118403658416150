import { combineReducers } from 'redux';

import { USER_LOGOUT } from './actionTypes/app';
import { appReducer } from './reducers/app';
import { commonReducer } from './reducers/common';
import { systemAdminReducer } from './reducers/systemAdministrator';
import { userReducer } from './reducers/user';

const combinedReducers = combineReducers({
  app: appReducer,
  user: userReducer,
  common: commonReducer,
  systemAdministrator: systemAdminReducer,
});

export const createRootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    const { app } = state;
    // eslint-disable-next-line no-param-reassign
    state = { app };
  }

  return combinedReducers(state, action);
};
