const CalendarIcon = (props: Record<string, any>) => (
  <svg width="18" height="19" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 1V5" stroke="#386E9B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 1V5" stroke="#386E9B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 8H19" stroke="#386E9B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 3H3C1.895 3 1 3.895 1 5V18C1 19.105 1.895 20 3 20H17C18.105 20 19 19.105 19 18V5C19 3.895 18.105 3 17 3Z"
      stroke="#386E9B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0146 11.7266C9.87663 11.7266 9.76463 11.8386 9.76563 11.9766C9.76563 12.1146 9.87763 12.2266 10.0156 12.2266C10.1536 12.2266 10.2656 12.1146 10.2656 11.9766C10.2656 11.8386 10.1536 11.7266 10.0146 11.7266"
      stroke="#386E9B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0146 11.7266C14.8766 11.7266 14.7646 11.8386 14.7656 11.9766C14.7656 12.1146 14.8776 12.2266 15.0156 12.2266C15.1536 12.2266 15.2656 12.1146 15.2656 11.9766C15.2656 11.8386 15.1536 11.7266 15.0146 11.7266"
      stroke="#386E9B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.01463 15.7266C4.87663 15.7266 4.76463 15.8386 4.76563 15.9766C4.76563 16.1146 4.87763 16.2266 5.01563 16.2266C5.15363 16.2266 5.26563 16.1146 5.26563 15.9766C5.26563 15.8386 5.15363 15.7266 5.01463 15.7266"
      stroke="#386E9B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0146 15.7266C9.87663 15.7266 9.76463 15.8386 9.76563 15.9766C9.76563 16.1146 9.87763 16.2266 10.0156 16.2266C10.1536 16.2266 10.2656 16.1146 10.2656 15.9766C10.2656 15.8386 10.1536 15.7266 10.0146 15.7266"
      stroke="#386E9B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
