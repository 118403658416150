import icons from './icons';

interface ILegacySvgIconProps {
  name: keyof typeof icons;
  title?: string;
  className?: string;
  onClick?: () => void;
  colors?: string[];
}

export const SvgIcon = ({ colors = [], title = '', name, className, onClick }: ILegacySvgIconProps) => {
  const Icon = icons[name];

  return <Icon name={name} title={title} className={className} onClick={onClick} colors={colors} />;
};
