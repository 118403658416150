export const getODataFullQuery = (options) => {
  // TODO The helper function must be updated to accept any parameters(&buyerIds)
  const result = Object.keys(options).reduce((query, key) => {
    const delimiter = query.length ? '&' : '';
    const value = encodeURIComponent(options[key]);

    if (!value.length) {
      return query;
    }

    return `${query}${delimiter}$${key}=${value}`;
  }, '');

  return result;
};
export const getApiQuery = (params = {}) =>
  Object.keys(params)
    .filter((param) => !!params[param]) // exclude keys with undefined value
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
