import { FC } from 'react';

import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { EProfileRequestKey } from '@app/api/constants';
import useGetProfile from '@app/api/hooks/useGetProfile';
import { acceptPolicyRequest } from '@app/components/AcceptPoliciesContainer/mutations/acceptPolicyRequest';
import { Nova2ThemeWrapper } from '@app/core/layouts';
import { AcceptPolicies, useToast } from '@stenngroup/ui-kit';
import { TLocale } from '@stenngroup/ui-kit/internationalization/types';

export const AcceptPoliciesContainer: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { formatMessage, locale } = useIntl();
  const { data: profile } = useGetProfile();
  const queryClient = useQueryClient();
  const acceptPolicyMutation = useMutation(acceptPolicyRequest, {
    onSuccess: () => {
      queryClient.refetchQueries([EProfileRequestKey.GetProfile, { isVerified: true }]);
    },
    onError: () => {
      toast.showError(
        formatMessage({
          id: 'constants.universalMessages.somethingWentWrongSupportTeamAlreadyNotified',
        })
      );
    },
  });

  if (!profile) return null;

  if (profile.hasAcceptedLegalPolicies) return null;

  return (
    <Nova2ThemeWrapper>
      <AcceptPolicies
        locale={locale as Exclude<TLocale, 'zh-Hant'>}
        onAgreeClick={(): void => {
          acceptPolicyMutation.mutate();
        }}
        onLogOutClick={(): void => {
          navigate('/logout');
        }}
        isAgreeButtonLoading={acceptPolicyMutation.isLoading}
      />
    </Nova2ThemeWrapper>
  );
};
