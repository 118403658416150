import { FC, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageSelectorWrapper } from '@app/components-new/Internationalization/LanguageSelectorWrapper';
import Notifications from '@app/components-new/Notifications';
import { useUserContext } from '@app/context/UserContext/hooks/useUserContext';
import { TEST_IDS } from '@app/core/constants';
import { isWindowSizeLessThanBreakpoint } from '@app/core/utilities/breakpoints';
import { PersonalSpace } from '@app/layouts/DashboardLayout/Header/PersonalSpace';
import { LogoutOutlined, PersonOutlineRounded } from '@mui/icons-material';
import { MenuItem, Stack, Theme, useTheme } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';
import { Drawer, Typography, UserMenu } from '@stenngroup/ui-kit';

import { BreadcrumbOutlet } from '../Crumbs';

export const DashboardHeader: FC = () => {
  const currentUser = useUserContext();

  const { logout } = useAuth();
  const queryClient = useQueryClient();

  const handleLogout = async (): Promise<void> => {
    await logout();
    queryClient.getQueryCache().clear();
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenPersonalSpace = (): void => {
    // set hash to personal-space to open drawer
    navigate(`${location.pathname}#personal-space`);
  };

  const theme = useTheme();
  const minBreakpoint = theme.breakpoints.values.sm;

  const isMobile = isWindowSizeLessThanBreakpoint('xs');

  useEffect(() => {
    setIsDrawerOpen(window.location.hash === '#personal-space');
  }, [location.hash]);

  const handleDrawerClose = (): void => {
    navigate(location.pathname);
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={({ spacing }): string => spacing(2)}
      minHeight={({ spacing }): string => spacing(7)}
      borderBottom={({ palette }): string => `1px solid ${palette.secondary.main08}`}
      bgcolor={({ palette }): string => palette.background.paper}
      boxShadow={({ palette }): string => `0px 6px 15px -2px ${palette.secondary.main08}`}
      padding={({ spacing }): string => `${spacing(1)} ${spacing(2)}}`}
      position="sticky"
      top={0}
      zIndex={1100}
      sx={{
        paddingInline: `${({ theme }: { theme: Theme }): string => theme.spacing(2)}`,
        paddingBlock: `${({ theme }: { theme: Theme }): string => theme.spacing(1)}`,
      }}
    >
      <Stack flexDirection="row">
        <BreadcrumbOutlet />
      </Stack>
      <Stack flexDirection="row" alignItems="center">
        <Notifications isMobile={isMobile} />

        <UserMenu email={currentUser.email}>
          <MenuItem
            sx={{
              width: '100%',
              padding: 0,
            }}
          >
            <Stack direction="row" alignItems="center" gap={1} width="100%">
              <LanguageSelectorWrapper />
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleOpenPersonalSpace} data-testid={TEST_IDS.personalSpaceMenuItem}>
            <Stack direction="row" alignItems="center" gap={1}>
              <PersonOutlineRounded fontSize="inherit" />
              <Typography.Body2>
                <FormattedMessage id="constants.universalMessages.personalSpace" />
              </Typography.Body2>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Stack direction="row" alignItems="center" gap={1}>
              <LogoutOutlined fontSize="inherit" />
              <Typography.Body2>
                <FormattedMessage id="constants.universalMessages.logOut" />
              </Typography.Body2>
            </Stack>
          </MenuItem>
        </UserMenu>
      </Stack>
      <Drawer
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        anchor="right"
        width={minBreakpoint}
        sx={{
          zIndex: 1200,
        }}
        header={
          <Typography.H5 data-testid={TEST_IDS.personalSpaceTitle}>
            <FormattedMessage id="constants.universalMessages.personalSpace" />
          </Typography.H5>
        }
      >
        <PersonalSpace />
      </Drawer>
    </Stack>
  );
};
