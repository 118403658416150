import { IInvoiceModel, IInvoiceModelDeprecated } from '@app/core/models';

type InvoiceFieldsToSumm = keyof Pick<
  IInvoiceModel,
  | 'youReceiveNationalCurrency'
  | 'feesNationalCurrency'
  | 'firstPaymentNationalCurrency'
  | 'invoiceFaceValue'
  | 'secondPaymentNationalCurrency'
  | 'discountNationalCurrency'
  | 'insuranceChargesNationalCurrency'
  | 'latePaymentFeesNationalCurrency'
  | 'volumeAdjustmentFeesNationalCurrency'
>;

const getTotalValueByInvoiceField = (field: InvoiceFieldsToSumm, invoices: IInvoiceModel[] = []) =>
  invoices.reduce((amount, invoice) => amount + invoice[field], 0);

type InvoiceFieldsToPickNumber = keyof Pick<
  IInvoiceModel,
  | 'volumeAdjustmentFeesPercent'
  | 'latePaymentFeesPercent'
  | 'insuranceChargesPercent'
  | 'discountPercent'
  | 'insuranceCharges'
  | 'paymentTerms'
  | 'currencyIsoNumericCode'
>;

type InvoiceFieldsToPickString = keyof Pick<
  IInvoiceModel,
  'commissionVersion' | 'insuranceChargesVariant' | 'state' | 'currency' | 'buyer' | 'supplier'
>;

type GetFirstValueByInvoiceField = {
  (field: InvoiceFieldsToPickString, invoices: IInvoiceModel[]): string;
  (field: InvoiceFieldsToPickNumber, invoices: IInvoiceModel[]): number;
};
const getFirstValueByInvoiceField: GetFirstValueByInvoiceField = (field, invoices = []) => {
  const targetInvoice: any = invoices.find((invoice) => (invoice as any)[field]);
  if (!targetInvoice) {
    return field === 'commissionVersion' || field === 'currency' || field === 'buyer' || field === 'supplier' ? '' : 0;
  }
  return targetInvoice[field];
};

const getCheckedInvoicesIds = (checkedInvoices: IInvoiceModel[] | IInvoiceModelDeprecated[]) =>
  checkedInvoices.map((invoice) => invoice.id);

export interface IInvoicesTotals {
  ids: string[];
  value: number;
  youWillReceive: number;
  fees: number;
  firstPayment: number;
  secondPayment: number;
  discountTotal: number;
  discountPercent: number;
  insuranceCharges: number;
  insuranceChargesPercent: number;
  latePaymentFees: number;
  latePaymentFeesPercent: number;
  volumeAdjustmentFees: number;
  volumeAdjustmentFeesPercent: number;
  currencyIsoNumericCode?: number;
  currencyCode?: string;
  buyerName?: string;
  supplierName?: string;
}

export const calculateInvoicesTotals = (invoices: IInvoiceModel[] = []): IInvoicesTotals =>
  ({
    ids: getCheckedInvoicesIds(invoices),
    value: getTotalValueByInvoiceField('invoiceFaceValue', invoices),
    youWillReceive: getTotalValueByInvoiceField('youReceiveNationalCurrency', invoices),
    fees: getTotalValueByInvoiceField('feesNationalCurrency', invoices),
    firstPayment: getTotalValueByInvoiceField('firstPaymentNationalCurrency', invoices),
    secondPayment: getTotalValueByInvoiceField('secondPaymentNationalCurrency', invoices),
    discountTotal: getTotalValueByInvoiceField('discountNationalCurrency', invoices),
    insuranceCharges: getTotalValueByInvoiceField('insuranceChargesNationalCurrency', invoices),
    latePaymentFees: getTotalValueByInvoiceField('latePaymentFeesNationalCurrency', invoices),
    volumeAdjustmentFees: getTotalValueByInvoiceField('volumeAdjustmentFeesNationalCurrency', invoices),
    discountPercent: getFirstValueByInvoiceField('discountPercent', invoices),
    insuranceChargesPercent: getFirstValueByInvoiceField('insuranceChargesPercent', invoices),
    latePaymentFeesPercent: getFirstValueByInvoiceField('latePaymentFeesPercent', invoices),
    volumeAdjustmentFeesPercent: getFirstValueByInvoiceField('volumeAdjustmentFeesPercent', invoices),
    currencyIsoNumericCode: getFirstValueByInvoiceField('currencyIsoNumericCode', invoices),
    currencyCode: getFirstValueByInvoiceField('currency', invoices),
    buyerName: getFirstValueByInvoiceField('buyer', invoices),
    supplierName: getFirstValueByInvoiceField('supplier', invoices),
  }) as const;
