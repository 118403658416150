import { useState } from 'react';
import { useIsMounted } from './useIsMounted';

export const useSafeState = (initState) => {
  const isMounted = useIsMounted();
  const [state, setState] = useState(initState);

  const safeSetState = (newState) => {
    if (isMounted.current) {
      setState(newState);
    }
  };

  return [state, safeSetState];
};
