import { BaseStorageService, IStorage } from './BaseStorageService';

const storage: IStorage = {
  set: (key: string, value: string) => {
    sessionStorage.setItem(key, value);
  },
  get: (key: string) => sessionStorage.getItem(key),
  has: (key: string) => sessionStorage.getItem(key) !== null,
  delete: (key) => {
    sessionStorage.removeItem(key);
  },
  getAll: () => sessionStorage,
};

class SessionStorageServiceBase extends BaseStorageService {
  clear = () => {
    sessionStorage.clear();
  };
}

export const SessionStorageService = new SessionStorageServiceBase(storage);
