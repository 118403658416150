import { FC } from 'react';

import { LightTooltip } from '@app/components-new/HelpWithTooltip/styles';
import { IHelpWithTooltipProps } from '@app/components-new/HelpWithTooltip/types';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@stenngroup/ui-kit';

export const HelpWithTooltip: FC<IHelpWithTooltipProps> = ({ color, description, testId, style }) => {
  return (
    <LightTooltip
      placement="top"
      data-testid={testId}
      title={
        <Typography.Caption display="inline-flex" textAlign="center">
          {description}
        </Typography.Caption>
      }
      style={style}
      arrow
    >
      <HelpIcon
        color={color ?? 'primary'}
        fontSize="small"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      />
    </LightTooltip>
  );
};
