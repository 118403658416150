/**
 * use stennAppTheme instead
 * @deprecated
 */
export const theme = {
  fonts: {
    main: 'Inter, sans-serif',
    chineseTraditional: 'Noto Sans TC, sans-serif',
    chineseSimplified: 'Noto Sans SC, sans-serif',
    baseFontSize: '16px',
    modalTitleFontSize: '24px',
  },
  colors: {
    white: '#ffffff',
    yellow: '#f2c94c',
    orange: '#f28b00',
    blueUltraLight: '#f4f3f7',
    ultraLight: '#f7f7f7',
    lightGray: '#e5e5e5',
    midGrey: '#e5e1e6',
    greyUltraLight: '#f9f9fb',
    grey: '#9b9c9b',
    darkGrey: '#363a36',
    ultraLightGreen: '#f2fcf4',
    lightGreen: '#c8ffc7',
    green: '#4cd964',
    darkGreen: '#27ae60',
    ultraDarkGreen: '#367661',
    blue: '#21145f',
    greyishBlue: '#bcb9cf',
    red: '#eb251b',
    black: '#000000',
    pink: '#fde9e8',
    startColorMainGradient: '#f8c37b',
    endColorMainGradient: '#524ebc',
    lightBlue: '#5051b5',
    royalBlue: '#2f80ed',
    warningBlue: '#2196F3',
    sectionHeaderBlack: '#171A17',
    primaryLight: '#9089AF',
  },
  breakpoints: {
    xs: 400,
    sm: 480,
    md: 576,
    lg: 768,
    lgLandscape: 850,
    xl: 1025,
    xxl: 1200,
    xxxl: 1400,
    xxxxl: 1620,
  },
};
