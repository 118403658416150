import { BASE_API_URLS } from '@app/core/api/constants';
import { TUnionApiResponse } from '@app/ts-types/apiResponse';

interface IGetAvailableFunctionsPayload {
  configuredFetch: typeof fetch;
}

export const getAvailableFunctionsRequest = async ({
  configuredFetch,
}: IGetAvailableFunctionsPayload): Promise<TUnionApiResponse<string[], never>> => {
  const requestOptions = {
    method: 'GET',
  };

  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/security/getAvailableFunctions`,
    requestOptions
  );
  if (response.ok) {
    const data = await response.json();
    return {
      data,
      type: 'success',
    };
  }
  return {
    type: 'error',
  };
};
