import { IInvoice } from '@app/components-new/Flow/InvoicesList/types';
import { getInvoicesByTrIdRequest } from '@app/components-new/Flow/queries/getInvoicesRequest';
import { EDealType, EDocumentType } from '@app/core/constants';
import { IInvoiceDocumentDTO } from '@app/core/models';

interface IFormatInvoiceOutputPayload {
  documents: IInvoiceDocumentDTO[] | null;
  id: string;
  number: string;
  invoiceFaceValue: number;
  currency: string;
  currencyIsoNumericCode: number;
  dueDate: string;
  invoiceProductType?: string;
  isCommodity: boolean;
  isPerishable: boolean;
}

function formatInvoiceOutput(invoice: IFormatInvoiceOutputPayload): IInvoice {
  const otherDocuments = invoice.documents?.filter(
    (doc: IInvoiceDocumentDTO) => doc.type !== EDocumentType.CommercialInvoice
  );
  const invoiceDocument =
    invoice.documents?.find((doc: IInvoiceDocumentDTO) => doc.type === EDocumentType.CommercialInvoice) ?? null;

  const transportDocument =
    invoice.documents?.find((doc: IInvoiceDocumentDTO) => doc.type === EDocumentType.TransportDocument) ?? null;

  return {
    id: invoice.id,
    number: invoice.number,
    invoiceFaceValue: invoice.invoiceFaceValue,
    currency: invoice.currency,
    currencyIsoNumericCode: invoice.currencyIsoNumericCode,
    dueDate: invoice.dueDate,
    invoiceProductType: (invoice.invoiceProductType as EDealType) || null,
    otherDocuments: otherDocuments ?? [],
    invoiceDocument,
    transportDocument,
    isCommodity: invoice.isCommodity,
    isPerishable: invoice.isPerishable,
    isUnknownCategory: invoice.isCommodity === false && invoice.isPerishable === false,
  };
}

export const getTradeRelationsInvoicesByIdRequest = async (tradeRelationId: string): Promise<IInvoice[]> => {
  const response = await getInvoicesByTrIdRequest({
    trId: tradeRelationId,
    top: 100,
    skip: 0,
    loadDocuments: true,
  });
  const result = response.value;
  const invoices = result.map(formatInvoiceOutput);

  return invoices;
};
