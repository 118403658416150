import { useQueryClient } from 'react-query';

import { useAuth } from '@stenngroup/auth0-sdk';

export const useLogout = () => {
  const { logout } = useAuth();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    queryClient.removeQueries();
    await logout();
    window.location.reload();
  };

  return { handleLogout };
};
