import _get from 'lodash.get';
import PropTypes from 'prop-types';

const InvoicesIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V6C19 4.89543 18.1046 4 17 4H7ZM16 7H8V8H16V7ZM8 10H16V11H8V10ZM13 13H8V14H13V13Z"
        fill={_get(colors, '0', 'currentColor')}
      />
    </svg>
  );
};

InvoicesIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default InvoicesIcon;
