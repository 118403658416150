import { FC, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { PageListContainer } from '@app/components-new/PageListContainer';
import { TEST_IDS } from '@app/core/constants';
import { sendInvitationRequest } from '@app/layouts/DashboardLayout/Header/PersonalSpace/mutations/sendInvitationRequest';
import { getCompanyContactsRequests } from '@app/layouts/DashboardLayout/Header/PersonalSpace/queries/getCompanyContactsRequest';
import { EditUserDialogContent } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/EditUserDialogContent';
import { TeamCard } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/TeamCard';
import { TeamCardSkeleton } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/TeamCard/TeamCardSkeleton';
import { isTeamMember } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/helpers/isTeamMember';
import { acceptContactRequest } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/mutations/acceptContactRequest';
import { declineContactRequest } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/mutations/declineContactRequest';
import { EContactStatus, ICompanyContact } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';
import { ERequestKey } from '@app/types/request';
import { Stack } from '@mui/material';
import { Button, Dialog, Typography, useToast } from '@stenngroup/ui-kit';
import { sleep } from '@stenngroup/utilities';

const REFETCH_SLEEP_TIME = 5_000;

export const Team: FC = () => {
  const [selectedContact, setSelectedContact] = useState<ICompanyContact | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const configuredFetch = useConfiguredFetch();
  const { data: contacts, status } = useQuery(
    [ERequestKey.PersonalSpaceContacts],
    () => getCompanyContactsRequests({ configuredFetch }),
    {
      cacheTime: 10_000,
      staleTime: 10_000,
    }
  );

  const teamContacts = (contacts ?? []).filter(isTeamMember);
  const requestingContacts = (contacts ?? []).filter((contact) => contact.status === EContactStatus.Requesting);

  const toast = useToast();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const acceptContactMutation = useMutation(acceptContactRequest, {
    onSuccess: async (response, { payload }) => {
      if (response.type === 'error') {
        toast.showError(formatMessage({ id: 'components.personalSpace.acceptContact.failed' }));
        return;
      }
      await sleep(REFETCH_SLEEP_TIME);
      await queryClient.refetchQueries([ERequestKey.PersonalSpaceContacts]);
      toast.showSuccess(
        formatMessage(
          { id: 'components.personalSpace.acceptContact.success' },
          {
            fullName: payload.fullName,
          }
        )
      );
    },
  });

  const declineContactMutation = useMutation(declineContactRequest, {
    onSuccess: async (response, { payload }) => {
      if (response.type === 'error') {
        toast.showError(formatMessage({ id: 'components.personalSpace.declineContact.failed' }));
        return;
      }
      await queryClient.refetchQueries([ERequestKey.PersonalSpaceContacts]);
      toast.showInfo(
        formatMessage(
          { id: 'components.personalSpace.declineContact.success' },
          {
            fullName: payload.fullName,
          }
        )
      );
    },
  });

  const resendInvitationMutation = useMutation(sendInvitationRequest, {
    onSuccess: async (response, { payload }) => {
      if (response.type === 'error') {
        toast.showError(formatMessage({ id: 'components.personalSpace.resendInvite.invitationFailed' }));
        return;
      }
      toast.showSuccess(
        formatMessage(
          {
            id: 'components.personalSpace.resendInvite.invitationSent',
          },
          {
            email: payload.email,
          }
        )
      );
      await queryClient.refetchQueries([ERequestKey.PersonalSpaceContacts]);
    },
  });

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedContact(null);
  };

  const handleModalOpen = (contact: ICompanyContact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const handleResendInvitation = (contact: ICompanyContact) => {
    resendInvitationMutation.mutate({
      configuredFetch,
      payload: {
        email: contact.email,
        fullName: contact.fullName,
        contactId: contact.id,
      },
    });
  };

  const handleDeclineClick = (contact: ICompanyContact) => {
    if (!contact.requestId) return;

    declineContactMutation.mutate({
      configuredFetch,
      payload: {
        requestId: contact.requestId,
        fullName: contact.fullName,
      },
    });
  };

  const handleAcceptClick = (contact: ICompanyContact) => {
    if (!contact.requestId) return;

    acceptContactMutation.mutate({
      configuredFetch,
      payload: {
        requestId: contact.requestId,
        fullName: contact.fullName,
      },
    });
  };

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Typography.Body1SemiBold data-testid={TEST_IDS.teamRequestsTitle}>
          <FormattedMessage id="constants.universalMessages.requests" />
        </Typography.Body1SemiBold>
        <Stack gap={2}>
          <PageListContainer
            items={requestingContacts}
            page={1}
            pages={1}
            status={status}
            emptyMessageId="pages.personalSpace.emptyRequestingList"
            Row={(contact) => (
              <TeamCard
                key={contact.id}
                contact={contact}
                loadingState={status}
                dataTestId={TEST_IDS.teamMemberRequestCard}
                actions={
                  <Stack gap={1} direction="row">
                    <Button
                      size="small"
                      isLoading={
                        declineContactMutation.isLoading &&
                        declineContactMutation.variables?.payload.requestId === contact.requestId
                      }
                      variant="secondary"
                      color="error"
                      onClick={() => handleDeclineClick(contact)}
                      data-testid={TEST_IDS.declineButton}
                    >
                      <FormattedMessage id="constants.universalMessages.decline" />
                    </Button>
                    <Button
                      size="small"
                      isLoading={
                        acceptContactMutation.isLoading &&
                        acceptContactMutation.variables?.payload.requestId === contact.requestId
                      }
                      variant="secondary"
                      color="primary"
                      onClick={() => handleAcceptClick(contact)}
                      data-testid={TEST_IDS.acceptButton}
                    >
                      <FormattedMessage id="constants.universalMessages.accept" />
                    </Button>
                  </Stack>
                }
              />
            )}
            LoadingIndicator={
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <TeamCardSkeleton key={index} />
                ))}
              </>
            }
          />
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Typography.Body1SemiBold>
          <FormattedMessage id="constants.universalMessages.teamMembers" />
        </Typography.Body1SemiBold>
        <Stack gap={2}>
          <PageListContainer
            items={teamContacts}
            page={1}
            pages={1}
            status={status}
            emptyMessageId="pages.personalSpace.emptyList"
            Row={(contact) => (
              <TeamCard
                key={contact.id}
                contact={contact}
                loadingState={status}
                dataTestId={TEST_IDS.teamMemberCard}
                actions={
                  <Stack gap={1} direction="row">
                    {contact.editable && (
                      <>
                        <Button
                          size="small"
                          variant="tertiary"
                          onClick={() => handleModalOpen(contact)}
                          data-testid={TEST_IDS.editButton}
                        >
                          <FormattedMessage id="constants.universalMessages.edit" />
                        </Button>
                        <Button
                          data-testid={TEST_IDS.resendInvitationButton}
                          size="small"
                          variant="tertiary"
                          isLoading={
                            resendInvitationMutation.isLoading &&
                            resendInvitationMutation.variables?.payload.contactId === contact.id
                          }
                          onClick={() => handleResendInvitation(contact)}
                        >
                          <FormattedMessage id="constants.universalMessages.resendInvitation" />
                        </Button>
                      </>
                    )}
                  </Stack>
                }
              />
            )}
            LoadingIndicator={
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <TeamCardSkeleton key={index} />
                ))}
              </>
            }
          />
        </Stack>
      </Stack>
      {!!selectedContact && (
        <Dialog
          open={isModalOpen}
          handleClose={handleModalClose}
          fullWidth
          size="medium"
          data-testid={TEST_IDS.personalSpaceEditUserDialog}
        >
          <EditUserDialogContent contact={selectedContact} onClose={handleModalClose} />
        </Dialog>
      )}
    </Stack>
  );
};
