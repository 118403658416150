import { ComponentType } from 'react';

import { TLang } from '@app/core/internationalization';

import ChineeseFlag from './flags/china-flag.svg?react';
import SpanishFlag from './flags/spain-flag.svg?react';
import EnglishFlag from './flags/uk-flag.svg?react';

export const flagsMap: Record<TLang, ComponentType> = {
  'zh-Hans': ChineeseFlag,
  'en': EnglishFlag,
  'es': SpanishFlag,
};
