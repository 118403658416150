import { IBuyerSigner, ISigner, ISupplierSigner } from '@app/components-new/Flow/AddContractorSignatory/types';
import { EContractorType } from '@app/components-new/Flow/types/common';
import { ApiProvider } from '@app/core/api/ApiProvider';
import { BASE_API_URLS } from '@app/core/api/constants';

const getBuyerSignerByTrId = (contractorId: string) => {
  return ApiProvider.apiQueryRequest<IBuyerSigner>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Contacts/getBuyerSigner/${contractorId}`
  );
};

const getSupplierSignerByTrId = (contractorId: string) => {
  return ApiProvider.apiQueryRequest<ISupplierSigner>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Contacts/getSupplierSigner/${contractorId}`
  );
};

export const getSignerRequest = async <T extends EContractorType>(
  contractorId: string,
  contractorType: T
): Promise<ISigner> => {
  if (contractorType === EContractorType.Buyer) {
    const response = await getBuyerSignerByTrId(contractorId);
    return {
      hasSigner: response.hasSigner,
      contact: response.buyerContact && {
        id: response.buyerContact.id,
        firstName: response.buyerContact.firstName,
        lastName: response.buyerContact.lastName,
        fullName: response.buyerContact.fullName,
        email: response.buyerContact.email,
        position: response.buyerContact.position,
        phoneNumber: response.buyerContact.phoneNumber,
        editable: response.buyerContact.editable,
        forSign: response.buyerContact.forSign,
        forNotifications: response.buyerContact.forNotifications,
        isCanEditPhoneNumber: response.buyerContact.isCanEditPhoneNumber,
        contactInvitationId: response.buyerContact.contactInvitationId,
      },
      isConfirmed: response.isSignerConfirmedByThisSupplier,
      canAddNewSigner: response.canAddNewSigner,
    };
  }
  const response = await getSupplierSignerByTrId(contractorId);
  return {
    hasSigner: response.hasSigner,
    contact: response.supplierContact && {
      id: response.supplierContact.id,
      firstName: response.supplierContact.firstName,
      lastName: response.supplierContact.lastName,
      fullName: response.supplierContact.fullName,
      email: response.supplierContact.email,
      position: response.supplierContact.position,
      phoneNumber: response.supplierContact.phoneNumber,
      editable: response.supplierContact.editable,
      forSign: response.supplierContact.forSign,
      forNotifications: response.supplierContact.forNotifications,
      isCanEditPhoneNumber: response.supplierContact.isCanEditPhoneNumber,
      contactInvitationId: response.supplierContact.contactInvitationId,
    },
    // @TODO ask
    isConfirmed: false,
    canAddNewSigner: true,
  };
};
