import { SyntheticEvent } from 'react';

import { MOBILE_SMALL_SCREEN } from '@app/core/constants';
import { Icon } from '@app/core/icons';
import { IconButton } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';
import styled, { css } from 'styled-components';

export const Head = styled.div<{
  hasContent: boolean;
  customBottomMargin?: number;
  isMobileHeaderVisible: boolean;
}>`
  margin-bottom: ${({ theme, hasContent }) => (hasContent ? theme.spacing(3) : theme.spacing(0))};
  ${({ customBottomMargin }) =>
    customBottomMargin &&
    css`
      margin-bottom: ${customBottomMargin}px;
    `}
`;

export const SwipCloseButton = styled.div`
  ${({ theme }) => theme.breakpoints.down(MOBILE_SMALL_SCREEN)} {
    position: absolute;
    width: 40px;
    height: 4px;
    left: 50%;
    top: ${({ theme }) => theme.spacing(0.5)};
    transform: translateX(-50%);
    background-color: ${({ theme }) =>
      // @ts-expect-error fix
      theme.palette.action.hoverBackground};
    border-radius: ${({ theme }) => theme.spacing(1.9)};
  }
`;

export const Title = styled(Typography.H5)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  white-space: pre-wrap;
`;

export const TitleMobile = styled(Typography.H4)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-align: left;
  white-space: pre-wrap;
`;

export const Subtitle = styled(Typography.Body1)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled(Typography.Body1)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) => theme.breakpoints.down(MOBILE_SMALL_SCREEN)} {
    text-align: left;
  }
`;

export const CloseButtonContainer = styled.div<{ isAbsolute: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.breakpoints.down(MOBILE_SMALL_SCREEN)} {
    position: absolute;
    top: ${({ theme }) => theme.spacing(3.5)};
    right: ${({ theme }) => theme.spacing(3.5)};
  }

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: ${({ theme }) => theme.spacing(3.5)};
      right: ${({ theme }) => theme.spacing(3.5)};
    `}
`;

export const BackButton = styled(IconButton)`
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.palette.black};
  ${({ theme }) => theme.breakpoints.down(MOBILE_SMALL_SCREEN)} {
    top: ${({ theme }) => theme.spacing(0.8)};
    left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const CloseButton = styled(Icon)<{
  name: string;
  onClick: (e: SyntheticEvent) => void;
}>`
  color: ${({ theme }) =>
    // @ts-expect-error fix
    theme.colors.greyishBlue};
  font-size: 18px;
  cursor: pointer;
`;

export const LadingContainer = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
