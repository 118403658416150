export const COOKIE_KEYS = {
  firstLandingUrl: 'stenn_app_first_landing_url',
  firstLandingReferrer: 'stenn_app_first_landing_referrer',
  languageData: 'stenn_app_language_data',
} as const;

export const LOCAL_STORAGE_KEYS = {
  public: {
    authData: 'stenn_app_auth0',
    referralURLsearch: 'stenn_app_referral_url_search', // location.search after redirect from stenn.com
    perPageCount: 'stenn_app_per_page_count',
  },
  private: {
    selectedSupplierData: 'stenn_app_selected_supplier_data',
    forgotPasswordEmail: 'stenn_app_forgot_password_email',
    otpCode: 'stenn_app_otp_code',
  },
} as const;
