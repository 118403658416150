const EyeOpened = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.11775 12.467C2.96075 12.176 2.96075 11.823 3.11775 11.532C5.00975 8.033 8.50475 5 11.9998 5C15.4948 5 18.9898 8.033 20.8818 11.533C21.0388 11.824 21.0388 12.177 20.8818 12.468C18.9898 15.967 15.4948 19 11.9998 19C8.50475 19 5.00975 15.967 3.11775 12.467Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1213 9.87868C15.2929 11.0502 15.2929 12.9497 14.1213 14.1213C12.9497 15.2929 11.0502 15.2929 9.87868 14.1213C8.70711 12.9497 8.70711 11.0502 9.87868 9.87868C11.0502 8.70711 12.9497 8.70711 14.1213 9.87868"
      stroke="currentColor"
      strokeWidth="1.4286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeOpened;
