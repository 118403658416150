import { ComponentType, FC, ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { routes } from '@app/routes/config';
import { useAuth } from '@stenngroup/auth0-sdk';

export const withUnauthenticated = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithUnauthenticated(props: P): ReactElement | null {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <Navigate to={routes.root.path} /> : <Component {...props} />;
  };
};
