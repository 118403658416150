import { FC, memo, useRef, useState } from 'react';

import { useGetProfileV2 } from '@app/api/profile/useGetProfileV2';
import { useOutsideClickListener } from '@app/core/hooks';

import NotificationsBell from './components/NotificationsBell';
import NotificationList from './components/NotificationsList';
import * as SC from './styles';
import { useNotificationsPaging } from './useNotificationsPaging';

interface IProps {
  isMobile: boolean;
}

const _Notifications: FC<IProps> = ({ isMobile }) => {
  const [isNotificationsListOpen, setNotificationsListVisibility] = useState(false);

  const notificationsListRef = useRef(null);
  const { data: profile } = useGetProfileV2();

  const [
    newNotifications,
    readNotifications,
    notificationsCounts,
    deleteAll,
    readAll,
    readOne,
    isFetching,
    isMutating,
  ] = useNotificationsPaging(notificationsListRef);

  useOutsideClickListener(notificationsListRef, () => setNotificationsListVisibility(false));

  const onNotificationBellClickHandler = () => {
    setNotificationsListVisibility((prevVisibilityState) => !prevVisibilityState);
  };

  const handleDeleteAll = () => {
    deleteAll();
  };

  const handleReadAll = () => {
    readAll();
  };

  return (
    <SC.NotificationContainer isMobile={isMobile}>
      <NotificationList
        ref={notificationsListRef}
        isMobile={isMobile}
        readNotifications={readNotifications}
        newNotifications={newNotifications}
        notificationsCount={notificationsCounts}
        isNotificationsListOpen={isNotificationsListOpen}
        isFetching={isFetching}
        isMutating={isMutating}
        setNotificationsListVisibility={setNotificationsListVisibility}
        readOneHandle={readOne}
        deleteAllHandle={handleDeleteAll}
        readAllHandle={handleReadAll}
        isBuyer={!!profile?.hasBuyerRole}
      />
      <NotificationsBell onClick={onNotificationBellClickHandler} counter={notificationsCounts?.newCount} />
    </SC.NotificationContainer>
  );
};
const Notifications = memo(_Notifications);
export default Notifications;
