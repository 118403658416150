import { BASE_API_URLS } from '@app/core/api';

export const MAX_FILE_SIZE = 25;
export const UPLOAD_STEP_INVOICE_FILE_TYPES = ['application/pdf'];
export const UPLOAD_STEP_ACCEPTABLE_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const API_URL = `${BASE_API_URLS.onboarding}/api/v1.2/Invoices/UploadInvoiceFile`;
