/* eslint-disable consistent-return */
import { RefObject, useEffect } from 'react';

export const useEventListener = <K extends keyof (DocumentEventMap & WindowEventMap), T extends HTMLElement | Window>(
  eventKey: K,
  callback: (this: Document, ev: (DocumentEventMap & WindowEventMap)[K]) => void,
  target: RefObject<T> | T
) => {
  useEffect(() => {
    const targetNode = (target as RefObject<T>).current ? (target as RefObject<T>).current : (target as T);
    if (targetNode) {
      targetNode.addEventListener(eventKey, callback as EventListenerOrEventListenerObject);
    }

    return () => {
      if (targetNode) {
        targetNode.removeEventListener(eventKey, callback as EventListenerOrEventListenerObject);
      }
    };
  }, [callback, target, eventKey]);
};
