export const getCookiesDomain = () => {
  if (import.meta.env.MODE === 'development') {
    return window.location.hostname;
  }

  if (window._STENN_.ENVIRONMENT !== 'prod') {
    // workaround for testing contours hosted on azurewebsites
    return `.${window.location.hostname.split('.').slice(-3).join('.')}`;
  }

  if (window._STENN_.CULTURE === 'zh') {
    return '.stenn.cn';
  }

  return '.stenn.com';
};
