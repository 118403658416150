export type TTransitions = {
  [key in EFlowStep]: {
    step: EFlowStep;
    next: ((state: IFlowState) => EFlowStep | null) | null;
  };
};

export interface IFlowState {
  trId: string;
  flowType: EFlowType | null;
  tradeRole: EDealTradeRole | null;
  counterpartyCompanyId: string | null;
  myCompanyId: string | null;
}

export interface IStartFlowParams {
  initialStep: EFlowStep;
  initialState: IFlowState;
}

export interface IStepParams {
  stepState?: unknown;
  newFlowState?: Partial<IFlowState>;
}

export interface IFlowTransitionsContext {
  startFlow: (params: IStartFlowParams) => void;
  direction: 'forward' | 'backward';
  currentStep: EFlowStep | null;
  history: EFlowStep[];
  handleNextStep: (params?: IStepParams) => void;
  handlePrevStep: (params?: IStepParams) => void;
  canGoBack: boolean;
  goTo: (step: EFlowStep, direction?: 'forward' | 'backward', params?: IStepParams) => void;
  getFlowState: () => IFlowState;
  getStateByStep: <T>(step: EFlowStep) => T;
  reset: () => void;
  isOpen: boolean;
  close: () => void;
}

export const enum EFlowStep {
  TRADE_ROLE = 'trade-role',
  SEARCH_TRADE_PARTNER = 'search-trade-partner',
  SELECT_NEW_TRADE_PARTNER = 'select-new-trade-partner',
  REQUEST_LIMIT = 'request-limit',
  TRADE_PARTNER_CREATED = 'trade-partner-created',
  SELECT_TRADE_PARTNER = 'select-trade-partner',
  UPLOAD_INVOICE = 'upload-invoice',
  INVOICES_LIST = 'invoices-list',
  ADD_COMPANY_SIGNATORY = 'add-company-signatory',
  ADD_TRADE_PARTNER_SIGNATORY = 'add-trade-partner-signatory',
  ADD_BANK_DETAILS = 'add-bank-details',
  REVIEW_DEAL = 'review-deal',
  DEAL_SUBMITTED = 'deal-submitted',
  INVITE_TRADE_PARTNER = 'invite-trade-partner',
  TRADE_PARTNER_INVITED = 'trade-partner-invited',
}

export enum EFlowType {
  AddTradePartner = 'AddTradePartner',
  CreateDeal = 'CreateDeal',
}

export enum EDealTradeRole {
  Buyer = 'Buyer',
  Supplier = 'Supplier',
  Unknown = 'Unknown',
}
