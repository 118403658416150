/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyObject, Nullable } from '@app/core/utilities';
import * as Sentry from '@sentry/react';

import { IError, IErrorLogger, ISendLogOptions } from './types';

// TODO: Use shared logger from stenhub https://stenn.myjetbrains.com/youtrack/issue/SA-7576/Ispolzovat-obshchii-logger-iz-stenkhab
class ErrorLoggerClass implements IErrorLogger {
  private _prevRequestData: object | null = null;
  private _useExtension: any | null = null;
  private _baseApiUrl: Nullable<string> = null;
  private _domainHeader = 'GLOBAL';
  // @TODO fix
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Property '_appHeader' has no initializer and is not definitely assigned in the constructor.
  private _appHeader: string;

  public configure({
    baseApiUrl,
    domainHeader,
    appHeader,
  }: {
    baseApiUrl: string;
    domainHeader: string;
    appHeader: string;
  }) {
    this._baseApiUrl = baseApiUrl;
    this._domainHeader = domainHeader;
    this._appHeader = appHeader;
  }

  send = (err: IError, _options: ISendLogOptions) => {
    const errorResponse = err?.response;
    if (!errorResponse) {
      return;
    }

    let errorMessage: string;

    if (typeof errorResponse === 'string') {
      errorMessage = errorResponse;
    } else {
      errorMessage = errorResponse?.strCode
        ? `${errorResponse.strCode} ${errorResponse.code || ''} ${errorResponse.exceptionKey || ''}`
        : `${errorResponse.status || ''} ${errorResponse.statusText || ''} ${err.requestUrl || ''}`;
    }

    const error = new Error(errorMessage);
    Sentry.captureException(error, {
      extra: {
        ...err,
      },
    });
  };

  public sendBatch(_data: AnyObject[], _options: ISendLogOptions) {}

  public extendSend = (extension: any) => {
    this._useExtension = extension;
  };
}

export const ErrorLogger = new ErrorLoggerClass();
