/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import { BYTEDANCE_CONVERT_ID, GTM_CONSENT_TYPES } from '@app/core/constants';
import { CookiesStorageService } from '@app/core/storage';
import { getKeys } from '@app/core/utilities';
import { loadHeapOnce } from '@app/hooks/useSetAnalyticsIdentity/helpers';
import { getCookiesConsent } from '@stenngroup/utilities';

import { IGAEvent } from './types';

const isProduction = import.meta.env.MODE === 'production';

const dataLayer = (window.dataLayer = window.dataLayer || []);
const _hsq = (window._hsq = window._hsq || []);
const _hsp = (window._hsp = window._hsp || []);

// Google
function gtag() {
  // biome-ignore lint/style/noArguments: <explanation>
  dataLayer.push(arguments);
}

export const sendEventToGA = ({ category, action, label, value }: IGAEvent & { value?: string }) => {
  if (isProduction && window._STENN_.GTM_ID && category && action) {
    const data = {
      event: 'ga-event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    };

    getKeys(data).forEach((keyName) => {
      if (!data[keyName]) {
        delete data[keyName];
      }
    });

    dataLayer.push({ ...data });
  }
};

const setDefaultGTMconsent = (isTrackingEnabled: boolean) => {
  const consentValue = isTrackingEnabled ? GTM_CONSENT_TYPES.granted : GTM_CONSENT_TYPES.denied;

  (gtag as any)('consent', 'default', {
    ad_storage: consentValue,
    analytics_storage: consentValue,
  });
};

export const updateGTMconsent = (isTrackingEnabled: boolean) => {
  const consentValue = isTrackingEnabled ? GTM_CONSENT_TYPES.granted : GTM_CONSENT_TYPES.denied;

  (gtag as any)('consent', 'update', {
    ad_storage: consentValue,
    analytics_storage: consentValue,
  });
};

export const sendHubSpotPageView = (pathname?: string) => {
  if (window._STENN_.HS_SCRIPT_ID && pathname) {
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);
  }
};

export const clearHubSpotConversations = () => {
  if (window._STENN_.HS_SCRIPT_ID && window.hsConversationsSettings) {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    _hsp.push(['revokeCookieConsent']);
  }

  CookiesStorageService.deleteByPrefixes(['__hs', 'hs-messages', 'messagesUtk', 'hubspot']);

  if (window.HubSpotConversations) {
    window.HubSpotConversations.clear({ resetWidget: true });
  }
};

export const revokeHubspotCookieConsent = (): void => {
  _hsp.push(['revokeCookieConsent']);
};

const addHubspotDoNotTrack = (): void => {
  _hsq.push(['doNotTrack']);
};

export const removeHubspotDoNotTrack = (): void => {
  _hsq.push(['doNotTrack'], { track: true });
};

// Bytedance Tracking
export const sendEventToBytedanceTracking = () => {
  const { meteor } = window;

  if (isProduction && window._STENN_.CULTURE === 'zh' && window._STENN_.BYTEDANCE_ID && meteor && meteor.track) {
    meteor.track('form', { convert_id: BYTEDANCE_CONVERT_ID });
  }
};

export const initAnalyticScripts = (): void => {
  const cookiesConsent = getCookiesConsent(window._STENN_.ENVIRONMENT === 'live')();
  const isTrackingEnabled = cookiesConsent.performance;

  if (!isProduction) {
    return;
  }

  // Google Tag Manager (GTM)
  if (window._STENN_.GTM_ID) {
    setDefaultGTMconsent(isTrackingEnabled);
  }

  if (!isTrackingEnabled) {
    // Hubspot
    addHubspotDoNotTrack();
    return;
  }

  // Heap
  loadHeapOnce();
};
