import { useCallback, useState } from 'react';

export const useToggle = (defaultValue = false) => {
  const [isOn, setIsOn] = useState(defaultValue);

  const toggle = useCallback((state?: boolean) => {
    setIsOn((prevState) => (state !== undefined ? state : !prevState));
  }, []);

  return [isOn, toggle] as const;
};
