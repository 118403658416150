import { Nullable } from '@app/core/utilities';

import { CommissionVersionType } from './common';
import { IDigitalSignerDto } from './other';
import { IOperationsTradeRelationDto } from './tradeRelation';

export interface IDealDto {
  buyer: string;
  buyerCompanyIdentityId: string;
  buyerOnboardingId: string;
  buyerOperationsId: string;
  commentForRequest: string;
  created: string;
  currency: string;
  currencyIsoNumericCode: number;
  dealAttachments: IDealAttachmentDto[];
  dealProductType: string;
  discount: number;
  discountNationalCurrency: number;
  discountPercent: number;
  fees: number;
  feesNationalCurrency: number;
  financingDate: Nullable<string>;
  firstPayment: number;
  firstPaymentNationalCurrency: number;
  id: string;
  insuranceCharges: number;
  insuranceChargesNationalCurrency: number;
  insuranceChargesPercent: number;
  invoiceCount: number;
  latePaymentFees: number;
  latePaymentFeesNationalCurrency: number;
  latePaymentFeesPercent: number;
  number: string;
  secondPayment: number;
  secondPaymentNationalCurrency: number;
  signType: string;
  state: string;
  supplier: string;
  supplierOnboardingId: string;
  supplierCompanyIdentityId: string;
  supplierId: string;
  totalInvoicesNationalCurrency: number;
  tradeRelationId: string;
  volumeAdjustmentFees: number;
  volumeAdjustmentFeesNationalCurrency: number;
  volumeAdjustmentFeesPercent: number;
  youReceive: number;
  youReceiveNationalCurrency: number;
  declineReason?: string;
  extendedState?: string;
  totalInvoicesAdvanceAmountNationalCurrency: number;
  totalInvoicesPurchaseAmountNationalCurrency: number;
  commissionVersion: CommissionVersionType;
}

interface IDealAttachmentDto {
  id: string;
  attachmentType: string;
  attachmentVersionId: string;
  status: string;
  deal: {
    id: string;
    tradeRelation: IOperationsTradeRelationDto;
  };
  digitalSigners: IDigitalSignerDto[];
  isExternalLink?: boolean | null;
}

export enum ECompanyInfo {
  GetCompanyInfo = 'GetCompanyInfo',
  DnbCompanies = 'DnbCompanies',
  getDnbCompanyByDuns = 'getDnbCompanyByDunn',
}

export enum ECountries {
  GetCountries = 'GetCountries',
  getStates = 'states',
}

export enum EBankDetails {
  BankDetails = 'BankDetails',
}
