import { Alert as AlertMui, alertClasses } from '@mui/material';
import styled from 'styled-components';

export const Alert = styled(AlertMui)`
  &.${alertClasses.root} {
    border-radius: 8px;
    ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                width: 100%;
            }
        `}
  }
  &.${alertClasses.standardSuccess} {
    background: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) =>
      // @ts-expect-error fix
      theme.palette?.white?.main ?? theme.palette?.common?.white};
    .${alertClasses.icon} {
      color: ${({ theme }) =>
        // @ts-expect-error fix
        theme.palette?.white?.main ?? theme.palette?.common?.white};
    }
  }
`;
