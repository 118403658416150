import { STENN_APP_HEADER } from '@app/core/api';
import { Auth0Connection, Auth0Request, IAuth0Config } from '@app/core/authentication';
import { performLogoutAction, store } from '@app/core/global-store';
import { history } from '@app/core/history';
import { ErrorLogger } from '@app/core/logger';
import { routes } from '@app/routes/config';

const DOMAIN_HEADER = window._STENN_.CULTURE === 'common' ? 'GLOBAL' : 'CHINA';

const AUTH_CONFIG: IAuth0Config = {
  authApiPath: window._STENN_.API_ONBOARDING_PATH,
  clients: window._STENN_.API_CLIENTS_SERVICE_WEB,
  namespace: 'https://app.stenn.com/',
  domain: window._STENN_.AUTH_DOMAIN,
  clientID: window._STENN_.AUTH_CLIENT_ID,
  scope: 'openid email offline_access profile', // DO NOT ADD ADDRESS HERE (it identifies as Hub app)
  audience: `https://${window._STENN_.AUTH_ORIGINAL_DOMAIN}/api/v2/`,
  redirectUri: window.location.origin,
  cacheLocation: 'localStorage',
  responseType: 'token id_token',
  prompt: 'none',
  passwordLessClientId: window._STENN_.AUTH_PASSWORDLESS_CLIENT_ID,
};

export const configureAuthentication = () => {
  Auth0Request.configure({
    errorLogger: ErrorLogger,
    domainHeader: DOMAIN_HEADER,
    config: AUTH_CONFIG,
  });
  Auth0Request.extendRequestHeaders(() => {
    const reduxState = store.getState();
    const headers = {
      lang: reduxState?.app?.langId || null,
      'stenn-application': STENN_APP_HEADER,
    };
    return headers;
  });

  Auth0Connection.configure({
    config: AUTH_CONFIG,
    errorLogger: ErrorLogger,
    history: history,
    onLogout: () => {
      store.dispatch(performLogoutAction());
    },
    redirectsConfig: {
      socialAccountActivation: routes.auth.signUp.socialAccountActivation.path,
      accountNotCreated: routes.auth.signUp.path,
      emailConfirmation: routes.auth.emailConfirmation.path,
      signUpError: routes.auth.signUp.path,
      nonAuthorized: routes.auth.login.path,
    },
    domainHeader: DOMAIN_HEADER,
  });
  Auth0Connection.init();
};
