import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { QueryStatus } from 'react-query';

import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack, useTheme } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

import { IFinanceStatsProps } from './types';

const FinanceStat: FC<{
  label: JSX.Element;
  value: number;
  loadingState: QueryStatus;
  color: string;
  currencyCode: string;
}> = ({ loadingState, label, value, color, currencyCode }) => {
  const theme = useTheme();
  return (
    <Stack gap={0.5} direction="row" alignItems="center" minWidth="60px">
      <svg width="4" height="30" viewBox="0 0 4 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="4" height="30" fill={color} rx={2} ry={2} />
      </svg>
      <Stack>
        <Typography.Overline color="text.secondary">{label}</Typography.Overline>
        <TextSkeleton
          status={loadingState}
          style={{
            height: calculateTypographyHeight(theme.typography.overline),
            width: '96px',
          }}
        >
          <Typography.Overline
            color="text.primary"
            style={{
              minWidth: '96px',
            }}
          >
            {formatCurrency(value ?? 0, {
              currency: currencyCode,
            })}
          </Typography.Overline>
        </TextSkeleton>
      </Stack>
    </Stack>
  );
};

export const FinanceStats: FC<IFinanceStatsProps> = ({
  currencyCode,
  used,
  reserved,
  available,
  overdue,
  loadingState,
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      <FinanceStat
        loadingState={loadingState}
        label={<FormattedMessage id="pages.buyersPage.usedLimit" />}
        value={used ?? 0}
        color={theme.palette.primary.main}
        currencyCode={currencyCode}
      />
      <FinanceStat
        loadingState={loadingState}
        label={<FormattedMessage id="pages.buyersPage.reservedLimit" />}
        value={reserved ?? 0}
        color={theme.palette.primary.dark}
        currencyCode={currencyCode}
      />
      <FinanceStat
        loadingState={loadingState}
        label={<FormattedMessage id="pages.buyersPage.availableLimit" />}
        value={available ?? 0}
        color={theme.palette.success.light}
        currencyCode={currencyCode}
      />
      <FinanceStat
        loadingState={loadingState}
        label={<FormattedMessage id="pages.buyersPage.overdueLimit" />}
        value={overdue ?? 0}
        color={theme.palette.error.main}
        currencyCode={currencyCode}
      />
    </Stack>
  );
};
