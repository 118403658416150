import { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useGetCountriesList } from '@app/api/hooks/useGetCountries';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { addCounterparyCompanyRequest } from '@app/components-new/Flow/AddCounterpartyCompany/SelectCompany/mutations';
import {
  EEligibilityStatus,
  getPairEligibilityMutation,
} from '@app/components-new/Flow/mutations/getEligibilityMutation';
import { useFlowTransitionsContext } from '@app/components-new/FlowTransitions/context/hooks/useFlowTransitionsContext';
import { EFlowStep } from '@app/components-new/FlowTransitions/context/types';
import { ISearchCompanyForm } from '@app/components-new/SearchCompanyForm/types';
import { CompaniesListContainer } from '@app/components-new/SelectCompany/CompaniesListContainer';
import { TCompany } from '@app/components-new/SelectCompany/CompaniesListContainer/CompaniesList/types';
import { SelectCompanyDialogsContainer } from '@app/components-new/SelectCompany/DialogsContainer';
import { StepContainer } from '@app/components-new/StepContainer';
import {
  TStennClientsServiceContractsEnumsCompanyRole,
  TStennClientsServiceRegistryClientModelsSearchDto,
  TStennClientsServiceRegistryClientModelsSearchResultDto,
} from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { assert } from '@app/helpers/assert';
import { useLogout } from '@app/hooks/auth/useLogout';
import { ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@stenngroup/ui-kit';
import { sleep } from '@stenngroup/utilities';
import { match } from 'ts-pattern';

interface ISelectNewTradePartnerProps {}

export const SelectNewTradePartner: FC<ISelectNewTradePartnerProps> = () => {
  const { handlePrevStep, getStateByStep, getFlowState, handleNextStep } = useFlowTransitionsContext();
  const value = getStateByStep<ISearchCompanyForm>(EFlowStep.SEARCH_TRADE_PARTNER);
  const companyRole = getFlowState().tradeRole as TStennClientsServiceContractsEnumsCompanyRole;
  const [selectedCompany, setSelectedCompany] = useState<TCompany | null>(null);
  const [newCompany, setNewCompany] = useState<TStennClientsServiceRegistryClientModelsSearchResultDto | null>(null);

  const httpClient = useHttpClient();

  const query: TStennClientsServiceRegistryClientModelsSearchDto = useMemo(
    () => ({
      companyName: value.companyName,
      duns: value.duns,
      registrationNumber: value.registrationNumber,
      countryCode: value.country?.place,
      state: value.state?.place,
    }),
    [value]
  );

  const {
    data: companies,
    status,
    error,
  } = useQuery([apiRoutes.SearchSearchDuns, { query }], () =>
    httpClient(apiRoutes.SearchSearchDuns, 'post', {
      data: {
        ...query,
      },
    })
  );

  const contractorCompanyRole = match<
    TStennClientsServiceContractsEnumsCompanyRole,
    TStennClientsServiceContractsEnumsCompanyRole
  >(companyRole)
    .with('Buyer', () => 'Supplier')
    .with('Supplier', () => 'Buyer')
    .exhaustive();

  const { handleLogout } = useLogout();

  const [currentDialog, setCurrentDialog] = useState<'companyNotListed' | 'dnbError' | 'ineligibleCompany' | null>(
    null
  );

  useEffect(() => {
    if (!error) return;
    setCurrentDialog('dnbError');
  }, [error]);

  const handleSubmitNewCompany = async (data: ISearchCompanyForm) => {
    setCurrentDialog(null);
    const company: TCompany = {
      companyName: data.companyName,
      duns: data.duns ?? null,
      registrationNumber: data.registrationNumber,
      address: {
        countryCode: data.country?.place ?? null,
        state: data.state?.place ?? null,
        city: null,
        street: null,
        postalCode: null,
      },
      operationStatus: 'Unknown',
    };
    setNewCompany(company);
    setSelectedCompany(company);
  };

  const handleSearchAgain = () => {
    setCurrentDialog(null);
    handlePrevStep();
  };

  const { data: countriesList } = useGetCountriesList();
  const { mutate, isLoading } = useMutation(addCounterparyCompanyRequest, {
    onSuccess: async (data) => {
      // temporary solution for the issue with replications
      await sleep(2500);
      handleNextStep({
        newFlowState: {
          trId: data.tradeRelationId,
          counterpartyCompanyId: data.counterpartyCompanyId,
        },
        stepState: {
          ...data,
        },
      });
    },
  });

  const { mutateAsync: getEligibility, isLoading: isEligibilityFetching } = useMutation(getPairEligibilityMutation);

  const handleNext = async () => {
    assert(selectedCompany, 'selectedCompany is not defined');
    const eligibilityStatus = await getEligibility({
      // @ts-expect-error fix
      companyRole: companyRole,
      alpha2Code: value.country.place ?? '',
      counterPartyDuns: selectedCompany.duns || undefined,
    });
    if (eligibilityStatus !== EEligibilityStatus.Eligible) {
      setCurrentDialog('ineligibleCompany');
      return;
    }
    mutate({
      ...selectedCompany,
      countryId: countriesList?.find((country) => country.alpha2Code === value.country.place)?.id ?? '',
      // @ts-expect-error fix
      companyRole: contractorCompanyRole,
      chineseCompanyName: value.companyNameInChinese,
      chineseSocialCreditCode: value.unifiedSocialCreditCode,
    });
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.selectTradePartner" />}
      actions={
        <Stack gap={1} direction="row">
          <Button size="medium" variant="secondary" onClick={() => setCurrentDialog('companyNotListed')}>
            <FormattedMessage id="pages.SelectCompanyPage.companyIsNotOnTheList" />
          </Button>
          <Button
            variant="primary"
            onClick={handleNext}
            endIcon={<ArrowForward />}
            disabled={isLoading || isEligibilityFetching || !selectedCompany}
            isLoading={isLoading || isEligibilityFetching}
          >
            <FormattedMessage id="constants.universalMessages.next" />
          </Button>
        </Stack>
      }
    >
      <CompaniesListContainer
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        newCompany={newCompany}
        status={status}
      />
      <SelectCompanyDialogsContainer
        onClose={() => setCurrentDialog(null)}
        currentDialog={currentDialog}
        currentRole={contractorCompanyRole}
        handleLogout={handleLogout}
        handleSubmitNewCompany={handleSubmitNewCompany}
        handleSearchAgain={handleSearchAgain}
        defaultFormValues={value}
      />
    </StepContainer>
  );
};
