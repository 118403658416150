import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { DeleteInvoiceDialogContent } from '@app/components-new/Invoice/DeleteInvoiceDialogContent';
import { EditInvoiceDialogContent } from '@app/components-new/Invoice/EditInvoiceDialogContent';
import { TEST_IDS } from '@app/core/constants';
import { CheckCircle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Dialog, Typography } from '@stenngroup/ui-kit';

import { IInvoiceDetailsProps } from './types';

export const InvoiceDetails: FC<IInvoiceDetailsProps> = ({
  trId,
  invoiceId,
  invoiceNumber,
  invoiceValue,
  dueDate,
  onSave,
}) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <>
      <Stack
        borderRadius={1}
        border="1px solid"
        borderColor={(t) => t.palette.grey['200']}
        bgcolor={(t) => t.palette.background.level3}
        padding={2}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack gap={1}>
            <Stack gap={0.5} direction="row" alignItems="center">
              <CheckCircle fontSize="small" color="success" />
              <Typography.Body2SemiBold color={(t) => t.palette.text.primary}>
                <FormattedMessage
                  id="createDeal.invoicesList.invoiceNumber"
                  values={{
                    invoiceNumber,
                  }}
                />
              </Typography.Body2SemiBold>
            </Stack>
            <Typography.Body2 color={(t) => t.palette.text.secondary}>
              <FormattedMessage id="createDeal.invoicesList.invoiceValue" values={{ invoiceValue }} />
            </Typography.Body2>
            <Typography.Body2 color={(t) => t.palette.text.secondary}>
              <FormattedMessage id="createDeal.invoicesList.dueDate" values={{ dueDate }} />
            </Typography.Body2>
          </Stack>
          <Stack direction="row">
            <IconButton onClick={() => setIsOpenEditModal(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => setIsDeleteModalOpen(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        shouldCloseOnBackDropClick={false}
        data-testid={TEST_IDS.invoiceDetailsEditModal}
        fullWidth
      >
        <EditInvoiceDialogContent
          onSuccess={() => {
            setIsOpenEditModal(false);
            onSave();
          }}
          trId={trId}
          invoiceId={invoiceId}
          onClose={() => setIsOpenEditModal(false)}
        />
      </Dialog>
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        shouldCloseOnBackDropClick={false}
        data-testid={TEST_IDS.invoiceDetailsDeleteModal}
        fullWidth
      >
        <DeleteInvoiceDialogContent
          onDelete={() => {
            setIsDeleteModalOpen(false);
            onSave();
          }}
          invoiceNumber={invoiceNumber}
          invoiceId={invoiceId}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      </Dialog>
    </>
  );
};
