export const DEFAULT_DATE_LOCALE = 'en-GB';
export const DEFAULT_DATE_OPTIONS = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
} as const;

export const EXPANDED_DATE_OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
} as const;

export const EXPANDED_NUMERIC_DATE_OPTIONS = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
} as const;
