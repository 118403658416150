/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable max-len */
import { isDevMode } from '@app/components-new/ToggleDevModeContainer/helpers/isDevMode';
import { clearHubSpotConversations, sendEventToBytedanceTracking, sendEventToGA } from '@app/core/analytics';
import { FLOW_STATUSES, GA_EVENTS, LOCAL_STORAGE_KEYS } from '@app/core/constants';
import { getNumericErrorCode } from '@app/core/error-handling';
import { IError, LEVEL_CODES, LOG_ENTRY_TYPES, TEMPLATES } from '@app/core/logger';
import { CookiesStorageService, LocalStorageService, SessionStorageService } from '@app/core/storage';
import { AnyObject, Nullable, SimpleFunction } from '@app/core/utilities';
import { ECookiesStorageKey, ELocalStorageKey } from '@app/storage-keys';
import auth0 from 'auth0-js';
import { History } from 'history';
import jwtDecode from 'jwt-decode';
import isJSON from 'validator/lib/isJSON';

import { Auth0Request } from './Auth0Request';
import { AUTH_API_SCOPES, AUTH_ERRORS, AUTH_STATES, REGISTRATION_TYPES } from './constants';
import { IAuth0Config, IAuthProviderErrorLogger, IAuthRedirectsConfig, ILoginAsyncData } from './types';
import { checkIsAccessTokenValid } from './utils';

const DEFAULT_CONNECTION = window._STENN_.AUTH_DATABASE_CONNECTION;

const nonRedirectionPaths = ['/privacy-policy', '/terms-of-use'];

const isPathExcludedFromAuthFlow = (location: History['location']) =>
  !!nonRedirectionPaths.find((path) => location.pathname.includes(path));

const triggerHandlersMap = (map: { [s: string]: unknown } | ArrayLike<unknown>, value: any) => {
  Object.values(map).forEach((handlerItem: any) => {
    handlerItem(value);
  });
};

const fallback = () => {
  console.error(
    'Auth0Connection should be initialized with "configure" and "extendRequestHeaders" methods before usage'
  );
};

const errorLoggerFallbackInstance: IAuthProviderErrorLogger = {
  send: fallback,
};

class Auth0ConnectionClass {
  auth0ClientWeb: Nullable<auth0.WebAuth>;
  auth0Client: Nullable<auth0.Authentication>;
  refreshTokenSilentlyInstance: Nullable<Promise<string>>;
  authStatus: string;
  statusHandlersEventsMap: AnyObject;
  userDataGetHandlersEventsMap: AnyObject;
  errorHandlersEventsMap: AnyObject;
  _authData: Nullable<AnyObject>;
  _userInfo: Nullable<AnyObject>;
  _error: AnyObject[] | AnyObject;
  // @ts-ignore Property has no initializer and is not definitely assigned in the constructor.
  authFlowIsPostponed: boolean;

  private _errorLoggerInstance: IAuthProviderErrorLogger = errorLoggerFallbackInstance;
  private _onLogout: SimpleFunction = fallback;
  // @ts-ignore Property has no initializer and is not definitely assigned in the constructor.
  private _redirectsConfig: IAuthRedirectsConfig;
  // @ts-ignore Property has no initializer and is not definitely assigned in the constructor.
  private _history: History;
  private _domainHeaer: string;
  // @ts-ignore Property has no initializer and is not definitely assigned in the constructor.
  private _config: IAuth0Config;

  private getAccessTokenSilentlyExternal: (() => Promise<string | null>) | null;

  constructor() {
    this.auth0ClientWeb = null;
    this.auth0Client = null;
    this.refreshTokenSilentlyInstance = null;
    this.authStatus = AUTH_STATES.IN_PROGRESS;
    this.statusHandlersEventsMap = {};
    this.userDataGetHandlersEventsMap = {};
    this.errorHandlersEventsMap = {};
    this._authData = null;
    this._userInfo = null;
    this._error = [];
    this._domainHeaer = 'GLOBAL';
    this.getAccessTokenSilentlyExternal = null;
  }

  public configure({
    errorLogger,
    onLogout,
    redirectsConfig,
    history,
    domainHeader,
    config,
  }: {
    errorLogger: IAuthProviderErrorLogger;
    onLogout: SimpleFunction;
    redirectsConfig: IAuthRedirectsConfig;
    history: History;
    domainHeader: string;
    config: IAuth0Config;
  }) {
    this._errorLoggerInstance = errorLogger;
    this._onLogout = onLogout;
    this._redirectsConfig = redirectsConfig;
    this._history = history;
    this._domainHeaer = domainHeader;
    this._config = config;
  }

  init = () => {
    try {
      this.auth0ClientWeb = new auth0.WebAuth({
        ...this._config,
      });
      this.auth0Client = new auth0.Authentication({
        ...this._config,
      });
    } catch (initError) {
      this.sendErrorLog({
        // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
        error: initError,
        methodName: 'Auth0Connection.init',
        isCritical: true,
      });
      this.logoutWithRedirect('/oops');
    } finally {
      if (typeof this.auth0ClientWeb?.parseHash === 'function') {
        this.setInitialStatus();
      }
    }
  };

  performAuthRedirect = (path = '/') => {
    if (this._history.location.pathname !== path) {
      this._history.push({
        pathname: path,
        state: { from: this._history.location.pathname },
      });
    }
  };

  setInitialStatus = (disableAuthFlowPostpone = false) => {
    if (isDevMode()) return;
    this.auth0ClientWeb?.parseHash(
      { hash: window.location.hash },
      // @ts-ignore Type 'null' is not assignable to type 'AnyObject'.
      (authError: Nullable<AnyObject>, authResult: AnyObject) => {
        if (
          !disableAuthFlowPostpone &&
          isPathExcludedFromAuthFlow(this._history.location) &&
          this.status === AUTH_STATES.IN_PROGRESS
        ) {
          this.status = AUTH_STATES.UNAUTHENTICATED;

          if (!this.authFlowIsPostponed) {
            this.authFlowIsPostponed = true;
            this._history.block((location: any) => {
              if (!isPathExcludedFromAuthFlow(location)) {
                (window as AnyObject).location = location.pathname;
              }
              return false;
            });
          }
          return;
        }

        if (!authError && authResult !== null) {
          window.location.hash = '';
          this.afterLoginMovementsNew(null, authResult);
          return;
        }

        if (authError && authError.errorDescription === 'user is blocked') {
          this.logoutWithRedirect('/blocked');
          return;
        }

        if (this.authData && this.authData.accessToken) {
          if (this.isAccessTokenValid) {
            this.afterLoginMovementsNew(null, this.authData);
            return;
          }

          if (this.refreshToken) {
            this.requestTokenRefreshNew();
            return;
          }
        }

        this.status = AUTH_STATES.UNAUTHENTICATED;
      }
    );
  };

  signUpAsync = (data: AnyObject = {}) => {
    const { email, password, ...metaData } = data;
    metaData.region = this._domainHeaer;
    this.clearError();
    return new Promise<void>((resolve, reject) => {
      this.auth0ClientWeb?.signup(
        {
          email,
          password,
          connection: DEFAULT_CONNECTION,
          userMetadata: metaData,
        },
        (error) => {
          if (error === null) {
            sendEventToGA(GA_EVENTS.preRegistrationEmail);
            sendEventToBytedanceTracking();
            resolve();
          } else {
            reject(error);
          }
        }
      );
    });
  };

  loginAsync = async (username: string, password: string): Promise<ILoginAsyncData> => {
    const [_error, response, _status] = await Auth0Request.findPersonRegistrationInfoRequest(username);

    return new Promise((resolve, reject) => {
      this.auth0Client?.login(
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          clientID: this.config.clientID,
          username,
          password,
          realm: DEFAULT_CONNECTION,
        },
        (auth0ClientError: Nullable<AnyObject>, auth0ClientResponse: ILoginAsyncData) => {
          if (auth0ClientError === null) {
            resolve(auth0ClientResponse);
          } else {
            if (response && response.registrationType.includes(REGISTRATION_TYPES.social)) {
              this.error =
                response.socialRegistrations === 'Google'
                  ? AUTH_ERRORS.SHOULD_LOGIN_WITH_GOOGLE
                  : AUTH_ERRORS.SHOULD_LOGIN_WITH_FACEBOOK;
              reject({
                code: this.error[0]?.code,
                description: this.error[0]?.description,
                statusCode: auth0ClientError.code,
              });
              return;
            } else {
              reject(auth0ClientError);
              return;
            }
          }
        }
      );
    });
  };

  loginWithCheck = async (username: string, password: string, callback = () => {}) => {
    const [error, response, status] = await Auth0Request.findPersonRegistrationInfoRequest(username);

    this.clearError();

    if (status === 404) {
      this.error = AUTH_ERRORS.ACCOUNT_NOT_FOUND;
      callback();
      return;
    }

    if (error) {
      this.error = error;
      callback();
      return;
    }

    if (error === null && response) {
      switch (response.registrationType) {
        case REGISTRATION_TYPES.phone:
          this.error = AUTH_ERRORS.SHOULD_LOGIN_WITH_PHONE;
          callback();
          break;
        case REGISTRATION_TYPES.social:
          this.error =
            response.socialRegistrations === 'Google'
              ? AUTH_ERRORS.SHOULD_LOGIN_WITH_GOOGLE
              : AUTH_ERRORS.SHOULD_LOGIN_WITH_FACEBOOK;
          callback();
          break;
        default:
          this.auth0Client?.login(
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              clientID: this.config.clientID,
              username,
              password,
              realm: DEFAULT_CONNECTION,
            },
            (auth0ClientError: Nullable<AnyObject>, auth0ClientResponse: AnyObject) => {
              this.afterLoginMovements(auth0ClientError, auth0ClientResponse, () => {
                if (auth0ClientError === null) {
                  this.performAuthRedirect();
                }
              });

              if (auth0ClientError) {
                callback();
              }
            }
          );
          break;
      }
    }
  };

  activateUserAccount = async () => {
    const [error, response] = await Auth0Request.activateUserAccountRequest(this.accessToken);

    if (error === null && response) {
      this.requestTokenRefresh(this.performAuthRedirect);
    } else {
      const errorCode = getNumericErrorCode(error);

      if (errorCode === 3003) {
        this.logoutWithRedirect('/session-expired');
        return;
      }

      if (errorCode === 3005) {
        this.status = AUTH_STATES.UNAUTHENTICATED;
        this.performAuthRedirect('/session-failed');
        return;
      }

      this.status = AUTH_STATES.NOT_ACTIVATED_ERROR;
      this.performAuthRedirect('/activation-error');
    }
  };

  resendVerificationEmail = async () => {
    const [error, response] = await Auth0Request.resendVerificationEmailRequest(this.accessToken);

    if (error === null && response) {
      this.requestTokenRefresh(null, () => {
        this._history.push({
          pathname: this._history.location.pathname,
          search: '?success=true',
        });
      });

      return;
    }

    const errorCode = getNumericErrorCode(error);

    if (errorCode === 3005) {
      this.logoutWithRedirect('/session-failed');
      return;
    }

    this._history.push({
      pathname: this._history.location.pathname,
      search: '?success=false',
    });
  };

  checkSocialConsent = async () => {
    const [, response, status] = await Auth0Request.checkSocialConsentRequest(this.accessToken);

    if (status === 200 && response && response.result === true) {
      this.status = AUTH_STATES.UNAUTHENTICATED;
      this.performAuthRedirect(this._redirectsConfig.socialAccountActivation);
    } else {
      try {
        await this.linkSocialAccount();
      } catch (linkSocialAccountError) {
        this.sendErrorLog({
          // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
          error: linkSocialAccountError,
          methodName: 'Auth0Connection.checkSocialConsent',
          isCritical: true,
        });
        this.logoutWithRedirect('/oops');
      }
    }
  };

  getRedirectsConfig = () => {
    return this._redirectsConfig;
  };

  linkSocialAccount = async (userData: AnyObject = {}) => {
    const updatedUserData = {
      ...userData,
      fromConsentScreen: !!userData.fromConsentScreen,
      region: this._domainHeaer,
    };
    const [error, response, status] = await Auth0Request.activateSocialUserAccountRequest(
      this.accessToken,
      updatedUserData
    );
    const errorCode = getNumericErrorCode(error);
    if (error === null && response) {
      sendEventToGA(GA_EVENTS.preRegistrationSocial);
      this.requestTokenRefreshNew();
    } else if (errorCode === 3008 || errorCode === 0 || status === 500 || !status) {
      this.status = AUTH_STATES.UNAUTHENTICATED;
      this.performAuthRedirect(this._redirectsConfig.signUpError);
    } else {
      this.error = error;
      throw error;
    }
  };

  /**
   * @deprecated
   */
  afterLoginMovements = async (err: Nullable<AnyObject>, res?: AnyObject, onSuccess?: Nullable<() => void>) => {
    if (err === null) {
      const token: AnyObject = jwtDecode(res?.accessToken);
      const isUserAfterLogin = !this.authData;
      // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
      this.authData = res;
      const tokenRefreshRequired = token[`${this._config.namespace}tokenRefreshRequired`] || '';
      if (tokenRefreshRequired === 'true') {
        await this.requestTokenRefresh(this.performAuthRedirect);
        return false;
      }
      const userStates = token[`${this._config.namespace}userStates`] || '';
      let shouldPreventSuccessCallback = false;
      this.requestUserData(res);

      // email flow
      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_NOT_CREATED_IN_STENN)) {
        this.status = AUTH_STATES.NOT_ACTIVATED;
        try {
          const [createEmailError, createEmailResponse] = await Auth0Request.createEmailRegistrationRequest(
            this.accessToken
          );

          const errorCode = getNumericErrorCode(createEmailError);

          if (createEmailResponse) {
            await this.requestTokenRefresh(this.performAuthRedirect);
          } else if (errorCode === 3008) {
            // deadlock
            this.performAuthRedirect(this._redirectsConfig.signUpError);
          } else if (errorCode === 3011 || errorCode === 3005) {
            this.logoutWithRedirect('/session-failed');
          } else {
            this.performAuthRedirect('/account-not-created');
          }
        } catch (createEmailRegistrationError) {
          this.sendErrorLog({
            // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
            error: createEmailRegistrationError,
            methodName: 'Auth0Connection.afterLoginMovements',
            isCritical: true,
          });
          this.status = AUTH_STATES.NOT_ACTIVATED_ERROR;
          this.performAuthRedirect('/account-not-created');
        }

        return false;
      }

      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_NOT_ACTIVATED_IN_STENN)) {
        this.status = AUTH_STATES.NOT_ACTIVATED;
        try {
          await this.activateUserAccount();
        } catch (activateUserAccountError) {
          this.sendErrorLog({
            // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
            error: activateUserAccountError,
            methodName: 'Auth0Connection.afterLoginMovements',
            isCritical: true,
          });
          this.status = AUTH_STATES.NOT_ACTIVATED_ERROR;
          this.performAuthRedirect('/activation-error');
        }

        return false;
      }

      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_IN_VERIFICATION)) {
        this.status = AUTH_STATES.AUTHENTICATED;

        if (!this._history.location.pathname.includes('/email-account-activation')) {
          this.performAuthRedirect('/email-confirmation');
        }

        return false;
      }

      // social flow
      if (
        userStates.includes(AUTH_API_SCOPES.SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN) &&
        !this._history.location.pathname.includes('/privacy-policy') &&
        !this._history.location.pathname.includes('/terms-of-use')
      ) {
        this.checkSocialConsent();

        return false;
      }

      // phone flow
      // if (
      //     userStates.includes(
      //         AUTH_API_SCOPES.PHONE_ACCOUNT_NOT_ACTIVATED_IN_STENN
      //     )
      // ) {
      //     this.status = AUTH_STATES.NOT_ACTIVATED;
      //     this.performAuthRedirect('/sms-account-activation');

      //     return false;
      // }

      // quiz flow
      if (isUserAfterLogin) {
        const [, wizardInfoResponse] = await Auth0Request.getWizardInfoRequest(this.accessToken);

        if (
          wizardInfoResponse &&
          wizardInfoResponse.wizardStatus === FLOW_STATUSES.open &&
          wizardInfoResponse.inWizard
        ) {
          const flowType = wizardInfoResponse.wizardType;
          const [, wizardStepInfoResponse] = await Auth0Request.getLastWizardStepInfoRequest(
            this.accessToken,
            flowType
          );

          if (wizardStepInfoResponse && wizardStepInfoResponse.stepData && isJSON(wizardStepInfoResponse.stepData)) {
            const stepData = JSON.parse(wizardStepInfoResponse.stepData);

            if (stepData.shouldResetStepAfterLogin) {
              await Auth0Request.completeWizardRequest(this.accessToken, flowType);
            }
          }

          // disable this.performAuthRedirect to '/'
          shouldPreventSuccessCallback = true;
        }
      }

      // clear chat data to re-init with auth data later
      clearHubSpotConversations();
      // user is authorized and app is ready to proceed to private area
      this.status = AUTH_STATES.AUTHENTICATION_FINISHED;

      if (!shouldPreventSuccessCallback && typeof onSuccess === 'function') {
        onSuccess();
      }

      return true;
    }

    if (err && err.description === 'user is blocked') {
      this.logoutWithRedirect('/blocked');

      return false;
    }

    this.error = err;

    return false;
  };

  // new flow for signup/login redesign
  afterLoginMovementsNew = async (err: Nullable<AnyObject>, res: AnyObject, onSuccess?: Nullable<() => void>) => {
    if (err === null) {
      const token: AnyObject = jwtDecode(res.accessToken);
      const isUserAfterLogin = !this.authData;
      this.authData = res;

      const tokenRefreshRequired = token[`${this._config.namespace}tokenRefreshRequired`] || '';
      if (tokenRefreshRequired === 'true') {
        await this.requestTokenRefreshNew(this.performAuthRedirect);
        return false;
      }
      const userStates = token[`${this._config.namespace}userStates`] || '';
      let shouldPreventSuccessCallback = false;
      this.requestUserData(res);

      // email flow
      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_NOT_CREATED_IN_STENN)) {
        this.status = AUTH_STATES.NOT_ACTIVATED;
        try {
          const [createEmailError, createEmailResponse] = await Auth0Request.сreateEmailRegistrationOtp(
            this.accessToken
          );
          const errorCode = getNumericErrorCode(createEmailError);

          if (createEmailResponse) {
            await this.requestTokenRefreshNew(this.performAuthRedirect);
          } else if (errorCode === 3008) {
            // deadlock
            this.performAuthRedirect(this._redirectsConfig.signUpError);
          } else if (errorCode === 3011 || errorCode === 3005) {
            this.logoutWithRedirect('/session-failed');
          } else {
            this.performAuthRedirect(this._redirectsConfig.accountNotCreated);
          }
        } catch (createEmailRegistrationError) {
          this.sendErrorLog({
            // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
            error: createEmailRegistrationError,
            methodName: 'Auth0Connection.afterLoginMovements',
            isCritical: true,
          });
          this.status = AUTH_STATES.NOT_ACTIVATED_ERROR;
          this.performAuthRedirect(this._redirectsConfig.accountNotCreated);
        }

        return false;
      }

      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_NOT_ACTIVATED_IN_STENN)) {
        this.status = AUTH_STATES.NOT_ACTIVATED;
        try {
          await this.activateUserAccount();
        } catch (activateUserAccountError) {
          this.sendErrorLog({
            // @ts-ignore Type 'unknown' is not assignable to type 'AnyObject'.
            error: activateUserAccountError,
            methodName: 'Auth0Connection.afterLoginMovements',
            isCritical: true,
          });
          this.status = AUTH_STATES.NOT_ACTIVATED_ERROR;
          this.performAuthRedirect('/activation-error');
        }

        return false;
      }

      if (userStates.includes(AUTH_API_SCOPES.EMAIL_ACCOUNT_IN_VERIFICATION)) {
        this.status = AUTH_STATES.AUTHENTICATED;

        if (!this._history.location.pathname.includes('/email-account-activation')) {
          if (isUserAfterLogin) {
            this.resendVerificationEmailCode();
          }
          this.performAuthRedirect(this._redirectsConfig.emailConfirmation);
        }

        return false;
      }

      // social flow
      if (userStates.includes(AUTH_API_SCOPES.SOCIAL_ACCOUNT_NOT_ACTIVATED_IN_STENN)) {
        this.checkSocialConsent();

        return false;
      }

      // phone flow
      if (userStates.includes(AUTH_API_SCOPES.PHONE_ACCOUNT_NOT_ACTIVATED_IN_STENN)) {
        this.status = AUTH_STATES.NOT_ACTIVATED;
        this.performAuthRedirect('/sms-account-activation');

        return false;
      }

      // quiz flow
      if (isUserAfterLogin) {
        const [, wizardInfoResponse] = await Auth0Request.getWizardInfoRequest(this.accessToken);

        if (
          wizardInfoResponse &&
          wizardInfoResponse.wizardStatus === FLOW_STATUSES.open &&
          wizardInfoResponse.inWizard
        ) {
          const flowType = wizardInfoResponse.wizardType;
          const [, wizardStepInfoResponse] = await Auth0Request.getLastWizardStepInfoRequest(
            this.accessToken,
            flowType
          );

          if (wizardStepInfoResponse && wizardStepInfoResponse.stepData && isJSON(wizardStepInfoResponse.stepData)) {
            const stepData = JSON.parse(wizardStepInfoResponse.stepData);

            if (stepData.shouldResetStepAfterLogin) {
              await Auth0Request.completeWizardRequest(this.accessToken, flowType);
            }
          }

          // disable this.performAuthRedirect to '/'
          shouldPreventSuccessCallback = true;
        }
      }

      // clear chat data to re-init with auth data later
      clearHubSpotConversations();
      // user is authorized and app is ready to proceed to private area
      this.status = AUTH_STATES.AUTHENTICATION_FINISHED;

      if (!shouldPreventSuccessCallback && typeof onSuccess === 'function') {
        onSuccess();
      }

      return true;
    }

    if (err && err.description === 'user is blocked') {
      this.logoutWithRedirect('/blocked');

      return false;
    }

    this.error = err;

    return false;
  };

  finishPhoneRegistration = async (updateData: { firstName: string; lastName: string; email: string }) => {
    const [error] = await Auth0Request.createPhoneRegistrationRequest(this.accessToken, updateData);

    if (error === null) {
      sendEventToGA(GA_EVENTS.preRegistrationPhone);
      this.requestTokenRefresh(() => {
        this.performAuthRedirect();
      });
    } else {
      const errorCode = getNumericErrorCode(error);

      if (errorCode === 3002) {
        this.error = AUTH_ERRORS.USER_ALREADY_EXISTS;
        return;
      }

      this.error = error;
    }
  };

  loginWithGoogle = ({
    isSilent = false,
    email = null,
    trackingData,
  }: {
    isSilent?: boolean;
    email?: Nullable<string>;
    trackingData?: AnyObject;
  } = {}) => {
    const options: AnyObject = {
      responseType: 'token id_token',
      scope: 'openid email profile',
      connection: 'google-oauth2',
    };

    if (!isSilent) {
      options.approvalPrompt = 'force';
    }

    if (isSilent && email) {
      options.loginHint = email;
    }

    if (trackingData) {
      options.wa = { ...trackingData };
    }

    this.auth0ClientWeb?.authorize({
      ...options,
    });
  };

  loginWithFacebook = ({ isSilent = false, trackingData }: { isSilent?: boolean; trackingData?: AnyObject } = {}) => {
    const options: AnyObject = {
      responseType: 'token id_token',
      scope: 'openid email profile',
      connection: 'facebook',
    };

    if (!isSilent) {
      options.approvalPrompt = 'force';
    }

    if (trackingData) {
      options.wa = { ...trackingData };
    }

    this.auth0ClientWeb?.authorize({
      ...options,
    });
  };

  loginWithPhone = (phone: string, captchaToken: string) =>
    new Promise<void>((resolve, reject) => {
      const requestData = {
        mobilePhone: phone,
        captchaToken,
      };

      Auth0Request.phoneLoginRequest(requestData).then(([error]) => {
        if (error === null) {
          resolve();
        } else {
          this.error = AUTH_ERRORS.UNEXPECTED_ERROR;
          reject(AUTH_ERRORS.UNEXPECTED_ERROR);
        }
      });
    });

  verifyCode = async (phone: string, code: string, trackingData: AnyObject) => {
    const requestData = {
      phoneNumber: phone,
      otp: code,
      ...trackingData,
    };
    const [error, response, status] = await Auth0Request.phoneCodeVerifyRequest(requestData);

    if (status === 201 && response) {
      this.authData = (({ accessToken, idToken, refreshToken }) => ({
        accessToken,
        idToken,
        refreshToken,
      }))(response);
      this.requestTokenRefresh();
    } else if (error === null) {
      this.afterLoginMovements(null, response);
    } else {
      this.error = AUTH_ERRORS.PHONE_OR_CODE_IS_WRONG;
    }
  };

  verifyEmailRegistrationCode = async (code: string) => {
    const [error, response] = await Auth0Request.activateEmailRegistrationOtp(this.accessToken, code);
    if (error === null) {
      return response;
    }
    throw error;
  };

  resendVerificationEmailCode = async () => {
    const [error, response] = await Auth0Request.resendVerificationEmailOtp(this.accessToken);
    if (error === null) {
      return response;
    }
    throw error;
  };

  changePasswordWithApi = async (email: string) => {
    const [error, response] = await Auth0Request.changeEmailAccountPasswordRequest(email);

    if (error === null && response) {
      return response;
    }
    throw error;
  };

  changePasswordWithApiOtp = async (email: string) => {
    const [error, response] = await Auth0Request.changeEmailAccountPasswordOtpRequest(email);

    if (error === null && response) {
      return response;
    }
    throw error;
  };

  sendNewPasswordOtp = (otp: string, email: string, password: string) =>
    new Promise<void>((resolve, reject) => {
      Auth0Request.changePasswordOtpRequest(otp, email, password).then(([error]) => {
        if (error === null) {
          resolve();
        } else {
          this.error = error;
          reject(error);
        }
      });
    });

  logout = async () => {
    // do not use it directly in view components
    CookiesStorageService.deleteByPrefixes(['com.auth0.auth', 'auth0', ECookiesStorageKey.AuthData]);
    clearHubSpotConversations();
    LocalStorageService.setUserId('');
    LocalStorageService.deleteValue(ELocalStorageKey.AuthData);
    SessionStorageService.setUserId('');
    CookiesStorageService.setUserId('');

    this.statusHandlersEventsMap = {};
    this.userDataGetHandlersEventsMap = {};
    this.errorHandlersEventsMap = {};
    this.authData = null; // setter that clears local storage and cookies
    this._userInfo = null;
    this._error = [];
    this.authStatus = AUTH_STATES.UNAUTHENTICATED;

    if (this._onLogout) {
      this._onLogout();
    }

    return true;
  };

  logoutWithRedirect = async (path = '/') => {
    const isLoggedOut = await this.logout();

    if (isLoggedOut) {
      this.performAuthRedirect(path);
    }
  };

  requestTokenRefreshNew = async (
    onSuccessLogin?: Nullable<(path?: string) => void>,
    onSuccessRefresh?: () => void
  ) => {
    const { authData } = this;
    const idTokenData: AnyObject = authData && authData.idToken ? jwtDecode(authData.idToken) : {};
    const userId = idTokenData.sub || '';
    const provider = idTokenData[`${this._config.namespace}socialProvider`] || '';

    if (userId.includes('facebook') || provider === 'facebook') {
      this.loginWithFacebook({ isSilent: true });
    } else if (userId.includes('google-oauth2') || provider === 'google-oauth2') {
      this.loginWithGoogle({ isSilent: true, email: idTokenData.email });
    } else {
      const [error, response, status] = await Auth0Request.getTokenRequest(userId, this.refreshToken);

      if (error === null && response) {
        await this.afterLoginMovementsNew(
          null,
          {
            accessToken: response.access_token,
            expiresIn: response.expires_in,
            idToken: response.id_token,
            refreshToken: response.refresh_token,
            scope: response.scope,
            tokenType: response.token_type,
          },
          onSuccessLogin
        );

        if (typeof onSuccessRefresh === 'function') {
          onSuccessRefresh();
        }
      } else if (status) {
        this.logoutWithRedirect('/session-expired');
      } else {
        this.logoutWithRedirect('/oops');
      }
    }
  };

  /**
   * @deprecated
   */
  requestTokenRefresh = async (onSuccessLogin?: Nullable<() => void>, onSuccessRefresh?: () => void) => {
    const { authData } = this;
    const idTokenData: AnyObject = authData && authData.idToken ? jwtDecode(authData.idToken) : {};
    const userId = idTokenData.sub || '';
    const provider = idTokenData[`${this._config.namespace}socialProvider`] || '';

    if (userId.includes('facebook') || provider === 'facebook') {
      this.loginWithFacebook({ isSilent: true });
    } else if (userId.includes('google-oauth2') || provider === 'google-oauth2') {
      this.loginWithGoogle({ isSilent: true, email: idTokenData.email });
    } else {
      const [error, response, status] = await Auth0Request.getTokenRequest(userId, this.refreshToken);

      if (error === null && response) {
        await this.afterLoginMovements(
          null,
          {
            accessToken: response.access_token,
            expiresIn: response.expires_in,
            idToken: response.id_token,
            refreshToken: response.refresh_token,
            scope: response.scope,
            tokenType: response.token_type,
          },
          onSuccessLogin
        );

        if (typeof onSuccessRefresh === 'function') {
          onSuccessRefresh();
        }
      } else if (status) {
        this.logoutWithRedirect('/session-expired');
      } else {
        this.logoutWithRedirect('/oops');
      }
    }
  };

  setAccessTokenGetter = (accessTokenGetter: () => Promise<string | null>): void => {
    this.getAccessTokenSilentlyExternal = accessTokenGetter;
  };

  getAccessTokenSilently = (): Promise<string> => {
    if (this.getAccessTokenSilentlyExternal) {
      return this.getAccessTokenSilentlyExternal() as Promise<string>;
    }
    if (this.isAccessTokenValid) {
      this.refreshTokenSilentlyInstance = null;

      return new Promise((resolve) => {
        resolve(this.accessToken);
      });
    }

    if (this.refreshTokenSilentlyInstance) {
      return this.refreshTokenSilentlyInstance;
    }

    this.refreshTokenSilentlyInstance = new Promise((resolve, reject) => {
      const { authStatus, authData } = this;
      const idTokenData: AnyObject = authData && authData.idToken ? jwtDecode(authData.idToken) : {};
      const userId = idTokenData.sub || '';

      if (authStatus === AUTH_STATES.UNAUTHENTICATED || !userId) {
        return;
      }

      if (userId.includes('facebook') || userId.includes('google-oauth2')) {
        const { audience, scope } = this._config;

        this.auth0ClientWeb?.checkSession(
          { audience, scope },
          async (err: Nullable<AnyObject>, authResult: AnyObject) => {
            if (err) {
              if (err && err.code === 'login_required' && userId.includes('facebook')) {
                this.loginWithFacebook({ isSilent: true });
                reject(new Error('Facebook login required'));
                return;
              }

              if (err && err.code === 'login_required' && userId.includes('google-oauth2')) {
                this.loginWithGoogle({
                  isSilent: true,
                  email: idTokenData.email,
                });
                reject(new Error('Google login required'));
                return;
              }
              await this.afterLoginMovements(err);
              reject(new Error(`checkSession failed in refreshTokenSilentlyInstance: ${JSON.stringify(err)}`));
            } else if (authResult && authResult.accessToken) {
              await this.afterLoginMovements(null, authResult);
              resolve(authResult.accessToken);
            }
          }
        );
      } else {
        Auth0Request.getTokenRequest(userId, this.refreshToken).then(async ([error, response, status]) => {
          if (error === null && response) {
            await this.afterLoginMovements(null, {
              accessToken: response.access_token,
              expiresIn: response.expires_in,
              idToken: response.id_token,
              refreshToken: response.refresh_token,
              scope: response.scope,
              tokenType: response.token_type,
            });

            resolve(response.access_token);
          } else if (status) {
            this.logoutWithRedirect('/session-expired');
            reject(new Error(`Wrong status while updating token in refreshTokenSilentlyInstance: ${status}`));
          } else {
            this.logoutWithRedirect('/oops');
            reject(new Error('Can not update token in refreshTokenSilentlyInstance'));
          }
        });
      }
    });

    return this.refreshTokenSilentlyInstance;
  };

  requestUserData = (res: AnyObject = {}) => {
    this.auth0Client?.userInfo(
      (res && res.accessToken) || this.accessToken,
      (err: Nullable<AnyObject>, userInfoData: AnyObject) => {
        if (err === null) {
          this._userInfo = userInfoData;
          triggerHandlersMap(this.userDataGetHandlersEventsMap, userInfoData);
        } else {
          this.error = err;
        }
      }
    );
  };

  clearError() {
    this._error = [];
    triggerHandlersMap(this.errorHandlersEventsMap, this._error);
  }

  setStatusEventHandler(
    key: string | number,
    handler: Nullable<(arg: string) => void>,
    errorHandler: (arg: Nullable<AnyObject>) => void,
    getUserDataHandlers?: (arg: Nullable<AnyObject>) => void
  ) {
    if (key) {
      if (typeof getUserDataHandlers === 'function') {
        this.userDataGetHandlersEventsMap[key] = getUserDataHandlers;
        getUserDataHandlers(this.userInfo);
      }
      if (typeof handler === 'function') {
        this.statusHandlersEventsMap[key] = handler;
        handler(this.status);
      }
      if (typeof errorHandler === 'function') {
        this.errorHandlersEventsMap[key] = errorHandler;
        errorHandler(this.error);
      }
    }
  }

  unsetStatusEventHandler(key: string | number) {
    if (key) {
      if (this.statusHandlersEventsMap[key]) {
        delete this.statusHandlersEventsMap[key];
      }
      if (this.errorHandlersEventsMap[key]) {
        delete this.errorHandlersEventsMap[key];
      }
      if (this.userDataGetHandlersEventsMap[key]) {
        delete this.userDataGetHandlersEventsMap[key];
      }
    }
  }

  getFromAuthData = (key: string): string => {
    if (this.authData !== null) {
      return this.authData[key] || '';
    }

    return '';
  };

  sendErrorLog = ({
    error,
    methodName,
    isCritical = false,
  }: {
    error: AnyObject;
    methodName: string;
    isCritical?: boolean;
  }) => {
    Object.assign(error, {
      message: `${error.message}, ${methodName}`,
    });

    const options = {
      errorType: LOG_ENTRY_TYPES.auth,
      level: isCritical ? null : LEVEL_CODES.information,
      template: isCritical ? null : TEMPLATES.badRequest,
    };

    this._errorLoggerInstance.send(error as IError, options);
  };

  set error(error) {
    if (window._STENN_.ENVIRONMENT !== 'prod') {
      // eslint-disable-next-line no-console
      console.error('Auth0Connection Error: ', error);
    }

    let currentError: any[] | AnyObject = AUTH_ERRORS.UNEXPECTED_ERROR;

    if (error) {
      currentError = Array.isArray(error) ? error : [error];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._error = [...this.error, ...currentError];
    this.status = AUTH_STATES.ERROR;
    triggerHandlersMap(this.errorHandlersEventsMap, this._error);
  }

  set authData(newData) {
    this._authData = newData;
    try {
      if (newData !== null) {
        const { accessToken, expiresIn, ...rest } = newData;

        CookiesStorageService.setValueWithOptions(`${ECookiesStorageKey.AuthData}-accessToken`, accessToken, {
          domain: window._STENN_.APP_COOKIE_DOMAIN,
          sameSite: 'strict',
          secure: import.meta.env.MODE === 'production',
          expires: expiresIn,
        });

        LocalStorageService.setValue(
          LOCAL_STORAGE_KEYS.public.authData,
          JSON.stringify({ expiresIn, accessToken, ...rest })
        );
      } else {
        LocalStorageService.deleteValue(LOCAL_STORAGE_KEYS.public.authData);

        CookiesStorageService.deleteValue(
          `${ECookiesStorageKey.AuthData}-accessToken`,
          window._STENN_.APP_COOKIE_DOMAIN
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  set status(status) {
    this.authStatus = status;
    triggerHandlersMap(this.statusHandlersEventsMap, status);
  }

  get isAccessTokenValid() {
    return checkIsAccessTokenValid(this.accessToken);
  }

  get error() {
    return this._error;
  }

  get userInfo() {
    return this._userInfo;
  }

  get authData() {
    if (this._authData !== null) {
      return this._authData;
    }

    const accessTokenString = CookiesStorageService.getValue(`${ECookiesStorageKey.AuthData}-accessToken`);

    if (accessTokenString) {
      return { accessToken: accessTokenString };
    }
    return null;
  }

  get status() {
    return this.authStatus;
  }

  get parsedAccessToken() {
    const token = this.accessToken;

    if (token) {
      return jwtDecode(token);
    }

    return null;
  }

  get accessToken() {
    return this.getFromAuthData('accessToken');
  }

  get refreshToken() {
    return this.getFromAuthData('refreshToken');
  }

  get config() {
    return this._config;
  }
}

export const Auth0Connection = new Auth0ConnectionClass();
