export const INVOICE_STATUSES = {
  draft: 'Draft',
  new: 'New',
  approved: 'Approved',
  financed: 'Financed',
  repaid: 'Repaid',
  declined: 'Declined',
  requesting: 'Requesting',
  writtenOff: 'WrittenOff',
  manualCheckInOps: 'ManualCheckinOps',
  overdue: 'Overdue', // virtual status, does not come from backend
};

export const FLOW_STATUSES = {
  open: 'Open',
  paused: 'Paused',
  aborted: 'Aborted',
  completed: 'Completed',
};
