export class NetworkError extends Error {
  requestUrl: string;

  requestOptions?: RequestInit;

  name = 'NetworkError';

  constructor({
    statusText,
    requestUrl,
    requestOptions,
  }: {
    statusText?: string;
    requestUrl: string;
    requestOptions?: RequestInit;
  }) {
    super(statusText || 'Network error');
    this.requestUrl = requestUrl;
    this.requestOptions = requestOptions;
  }
}
