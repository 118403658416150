import _get from 'lodash.get';
import PropTypes from 'prop-types';

const DashboardIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <rect x="5" y="4" width="6" height="4" rx="1" fill={_get(colors, '0', 'currentColor')} />
      <rect x="13" y="4" width="6" height="10" rx="1" fill={_get(colors, '1', 'currentColor')} />
      <rect x="5" y="10" width="6" height="10" rx="1" fill={_get(colors, '2', 'currentColor')} />
      <rect x="13" y="16" width="6" height="4" rx="1" fill={_get(colors, '3', 'currentColor')} />
    </svg>
  );
};

DashboardIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default DashboardIcon;
