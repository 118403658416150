const GoogleIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M23.5199 12.2668C23.5199 11.2802 23.4399 10.5601 23.2666 9.81348H12V14.2668H18.6133C18.48 15.3735 17.76 17.0401 16.16 18.1601L16.1375 18.3092L19.6998 21.0689L19.9466 21.0935C22.2133 19.0002 23.5199 15.9201 23.5199 12.2668Z"
        fill="#4285F4"
      />
      <path
        d="M12.0003 24.0001C15.2403 24.0001 17.9602 22.9334 19.9469 21.0933L16.1602 18.1599C15.1469 18.8666 13.7869 19.3599 12.0003 19.3599C8.82699 19.3599 6.13369 17.2666 5.17366 14.3733L5.03292 14.3852L1.32873 17.252L1.28027 17.3867C3.2536 21.3066 7.3069 24.0001 12.0003 24.0001Z"
        fill="#34A853"
      />
      <path
        d="M5.17326 14.3735C4.91991 13.6269 4.7733 12.8269 4.7733 12.0003C4.7733 11.1735 4.91991 10.3736 5.15987 9.62696L5.15313 9.46792L1.40268 6.55518L1.27995 6.61355C0.466627 8.24019 0 10.0669 0 12.0003C0 13.9336 0.466627 15.7601 1.27995 17.3869L5.17326 14.3735Z"
        fill="#FBBC05"
      />
      <path
        d="M12.0004 4.63998C14.2537 4.63998 15.7737 5.6133 16.6404 6.42672L20.0271 3.12006C17.9471 1.1867 15.2404 0 12.0004 0C7.30697 0 3.25363 2.69332 1.28027 6.61332L5.16032 9.62665C6.13375 6.73334 8.82708 4.63998 12.0004 4.63998Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="23.52" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
