const AttachFileIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 11.5L10.8284 20.1715C10.298 20.702 9.57858 21 8.82843 21C8.07828 21 7.35886 20.702 6.82843 20.1715C6.298 19.6411 6 18.9217 6 18.1715C6 17.4214 6.298 16.702 6.82843 16.1715L15 8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 13L12.5503 4.44972C13.4785 3.52146 14.7375 2.99997 16.0503 2.99997C17.363 2.99997 18.622 3.52146 19.5503 4.44972C20.4785 5.37798 21 6.63696 21 7.94972C21 9.26248 20.4785 10.5215 19.5503 11.4497"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AttachFileIcon;
