import { BASE_API_URLS } from '@app/core/api/constants';

interface ISetBrowserLanguagePayload {
  configuredFetch: typeof fetch;
  languageCode: string;
}

export const setBrowserLanguageRequest = async ({ configuredFetch, languageCode }: ISetBrowserLanguagePayload) => {
  return configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Parameters/changeWebBrowserLanguage/${languageCode}`, {
    method: 'POST',
  });
};
