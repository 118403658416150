import { ICompanyStatusProps } from '@app/components-new/CompanyStatus/types';
import { ChipColor } from '@stenngroup/theme';

export const StatusColor: Record<ICompanyStatusProps['status'], ChipColor> = {
  'Available': 'success',
  'FinancingDenied': 'error',
  'Unknown': 'error',
  'Overdue': 'error',
  'UnderReview': 'primary',
  'RequestingLimit': 'primary',
};
