import { IntlFormatters } from 'react-intl';

import { ISearchCompanyForm } from '@app/components-new/SearchCompanyForm/types';
import {
  isChineseCompanyName,
  isCin,
  isNotEmptyString,
  isUnifiedSocialCreditCode,
} from '@app/core/utilities/validators';
import * as yup from 'yup';

const selectSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
  place: yup.string(),
});

export const searchCompanyBaseSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  yup.object().shape<Record<Exclude<keyof ISearchCompanyForm, 'duns'>, yup.AnySchema>>({
    companyName: yup
      .string()
      .required(formatMessage({ id: 'constants.frontendErrors.enterYourRegisteredCompanyName' }))
      .test('name', formatMessage({ id: 'constants.frontendErrors.enterYourRegisteredCompanyName' }), (value) => {
        return isNotEmptyString(value);
      }),
    country: selectSchema
      .required(formatMessage({ id: 'constants.frontendErrors.chooseCountry' }))
      .test('country', formatMessage({ id: 'constants.frontendErrors.chooseCountry' }), (value) => {
        return isNotEmptyString(value?.place);
      }),
    state: selectSchema
      .required(formatMessage({ id: 'constants.frontendErrors.chooseState' }))
      .test('state', formatMessage({ id: 'constants.frontendErrors.chooseState' }), (value, context) => {
        if (context.parent.country?.place === 'US') {
          return isNotEmptyString(value?.place);
        }
        return true;
      }),
    companyNameInChinese: yup
      .string()
      .test(
        'companyNameInChinese',
        formatMessage({ id: 'constants.frontendErrors.chooseYourCompanyNameInChinese' }),
        (value, context) => {
          if (context.parent.country?.place === 'CN' && !!value?.trim()) {
            return isChineseCompanyName(value);
          }
          return true;
        }
      ),
    unifiedSocialCreditCode: yup
      .string()
      .test(
        'unifiedSocialCreditCode',
        formatMessage({ id: 'constants.frontendErrors.chooseUnifiedSocialCreditCode' }),
        (value, context) => {
          if (context.parent.country?.place === 'CN') {
            return !!value && isUnifiedSocialCreditCode(value);
          }
          return true;
        }
      ),
    registrationNumber: yup
      .string()
      .test(
        'registrationNumber',
        formatMessage({ id: 'constants.frontendErrors.chooseCorporateIdentificationNumber' }),
        (value, context) => {
          if (context.parent.country?.place === 'IN') {
            return !!value && isCin(value);
          }
          return true;
        }
      ),
  });
