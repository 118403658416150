import { ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { sendHubSpotPageView } from './utils';

export const HubSpotAnalyticsListener = ({ children }: { children: ReactElement }) => {
  const location = useLocation();

  useEffect(() => {
    sendHubSpotPageView(location.pathname);
  }, [location.pathname]);

  return children;
};
