import _get from 'lodash.get';
import PropTypes from 'prop-types';

const NotificationsIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M18 17L6 17L6 10C6 6.68629 8.68629 4 12 4C15.3137 4 18 6.68629 18 10L18 17Z"
        fill={_get(colors, '0', 'currentColor')}
      />
      <path
        d="M18 17V18C18.2652 18 18.5196 17.8946 18.7071 17.7071C18.8946 17.5196 19 17.2652 19 17H18ZM6 17H5C5 17.5523 5.44772 18 6 18L6 17ZM18 10H19H18ZM6 10L7 10L6 10ZM18 16L6 16L6 18L18 18V16ZM7 17L7 10L5 10L5 17L7 17ZM17 10L17 17L19 17L19 10L17 10ZM12 5C14.7614 5 17 7.23858 17 10L19 10C19 6.13401 15.866 3 12 3V5ZM7 10C7 7.23858 9.23858 5 12 5V3C8.13401 3 5 6.13401 5 10L7 10Z"
        fill={_get(colors, '0', 'currentColor')}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 17C21 17.5523 20.4244 18 19.7143 18L4.28572 18C3.57563 18 3 17.5523 3 17C3 16.4477 3.57563 16 4.28572 16L19.7143 16C20.4244 16 21 16.4477 21 17Z"
        fill={_get(colors, '0', 'currentColor')}
      />
      <rect x="11" y="1" width="2" height="3" rx="1" fill={_get(colors, '0', 'currentColor')} />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="19" width="6" height="3">
        <rect x="9" y="19" width="6" height="3" fill={_get(colors, '0', 'currentColor')} />
      </mask>
      <g mask="url(#mask0)" />
      <circle cx="12" cy="19" r="3" fill={_get(colors, '0', 'currentColor')} />
    </svg>
  );
};

NotificationsIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default NotificationsIcon;
