export const loadTranslations = async (locale: string) => {
  switch (locale) {
    case 'en':
      return import('./en.json');
    case 'es':
      return import('./es.json');
    case 'zh-Hans':
      return import('./zh-Hans.json');
    default:
      return import('./en.json');
  }
};
