import { Nullable } from '@app/core/utilities';

export enum EEntityCreatorType {
  All = 'All',
  ByMe = 'ByMe',
  WithMe = 'WithMe',
}

export interface ICompanyLimitStateInfo {
  additionalData: { LimitValue: number };
  availableActions: unknown[];
  companyIdentityId: string;
  state: string;
}

export interface IDigitalSignerDto {
  status: string;
  fullName: string;
  email: string;
}

export interface IAddressDTO {
  street: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  postalCode: Nullable<string>;
  country: any;
}

export interface ISwiftInfo {
  bankName?: string;
  swift?: string;
  beneficiaryCountryCode?: string;
}
