import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { SentryFingerprintError } from '@app/common/sentry/SentryFingerprintError';
import { FallbackPlacesAutocomplete } from '@app/components/PlacesAutocomplete/FallbackPlacesAutocomplete';
import { TPlacesAutocompleteOption, TPlacesAutocompleteProps } from '@app/components/PlacesAutocomplete/types';
import { useGooglePlaces } from '@app/core/google-places/useGooglePlaces';
import { Autocomplete, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';

const GOOGLE_PLACES_LANGUAGE = 'en';
const FIRST_OPTION_LETTTER = 'A';

const PlacesAutocomplete: FC<TPlacesAutocompleteProps> = (props) => {
  const {
    name,
    label,
    country,
    modifyValueForSearch,
    onSelect,
    options: initialProps = [],
    type,
    freeSolo,
    error,
    helperText,
    loadOnMount,
    value,
    testId,
    ...rest
  } = props;
  const { isReady, getPlacePredictions, getGooglePlaceById, isUnavailable } = useGooglePlaces();
  const [options, setOptions] = useState(initialProps);

  const componentRestrictions = useMemo(() => {
    return country ? { country } : undefined;
  }, [country]);

  const loadOptions = useCallback(
    async (inputValue: string) => {
      const predictions = await getPlacePredictions(inputValue, {
        componentRestrictions,
        language: GOOGLE_PLACES_LANGUAGE,
        types: [type],
      });
      const newOptions = predictions.map((prediction) => ({
        label: prediction.structured_formatting.main_text,
        value: prediction.place_id,
        description: prediction.description,
      }));

      setOptions(newOptions);
    },
    [componentRestrictions, getPlacePredictions, type]
  );

  useEffect(() => {
    if (loadOnMount && isReady) {
      loadOptions(FIRST_OPTION_LETTTER);
    }
  }, [loadOnMount, isReady, loadOptions]);

  if (isUnavailable) {
    Sentry.captureException(new SentryFingerprintError('Google Places API is unavailable. Fallback Autocomplete used'));
    return <FallbackPlacesAutocomplete {...props} />;
  }

  const handleOnChange = async (_: React.SyntheticEvent<Element, Event>, selectedVal: string) => {
    if (!isReady) return;
    const value = modifyValueForSearch ? modifyValueForSearch(selectedVal) : selectedVal;

    onSelect?.({
      label: value,
      value: '',
    });
    if (!value) return;
    loadOptions(value);
  };

  const handleSelect = async (
    _: React.SyntheticEvent<any, Event>,
    option: TPlacesAutocompleteOption | string | null
  ) => {
    if (typeof option === 'string') return;
    if (!option) return;
    const place = await getGooglePlaceById(option.value);
    const shortName = place?.address_components?.[0].short_name;
    onSelect?.({
      label: option.label,
      value: option.value,
      place: shortName,
    });
  };

  return (
    <Autocomplete
      value={value}
      freeSolo={freeSolo}
      inputValue={value?.label || ''}
      size="small"
      options={options}
      onInputChange={handleOnChange}
      // @ts-expect-error types are incompatible
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          data-testid={testId}
          error={error}
          helperText={helperText}
          name={name || ''}
          label={label}
          {...params}
        />
      )}
      // @ts-expect-error types are incompatible
      renderOption={(props, option: TPlacesAutocompleteOption) => (
        <span {...props}>{typeof option === 'string' ? option : option.description}</span>
      )}
      {...rest}
    />
  );
};

export default PlacesAutocomplete;
