export const SET_POTENTIAL_LIMIT = 'SET_POTENTIAL_LIMIT';
export const SET_POTENTIAL_LIMIT_READINESS = 'SET_POTENTIAL_LIMIT_READINESS';

export const RESET_POTENTIAL_LIMIT = 'RESET_POTENTIAL_LIMIT';

export const GET_LIMIT_STATE_REQUEST = 'GET_LIMIT_STATE_REQUEST';
export const GET_LIMIT_STATE_SUCCESS = 'GET_LIMIT_STATE_SUCCESS';

export const RESET_LIMIT_STATE = 'RESET_LIMIT_STATE';

export const RESET_DATA_SOURCE_ACTION = 'RESET_DATA_SOURCE_ACTION';

export const GET_DNB_COMPANIES_REQUEST = 'GET_DNB_COMPANIES_REQUEST';
export const GET_DNB_COMPANIES_SUCCESS = 'GET_DNB_COMPANIES_SUCCESS';
export const GET_DNB_COMPANIES_FAILURE = 'GET_DNB_COMPANIES_FAILURE';

export const RESET_DNB_COMPANIES = 'RESET_DNB_COMPANIES';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const GET_BLACK_LIST_REQUEST = 'GET_BLACK_LIST_REQUEST';
export const GET_BLACK_LIST_SUCCESS = 'GET_BLACK_LIST_SUCCESS';
export const GET_BLACK_LIST_FAILURE = 'GET_BLACK_LIST_FAILURE';

export const GET_REPRESENTATIVE_CONTACTS_REQUEST = 'GET_REPRESENTATIVE_CONTACTS_REQUEST';
export const GET_REPRESENTATIVE_CONTACTS_SUCCESS = 'GET_REPRESENTATIVE_CONTACTS_SUCCESS';

export const GET_REPRESENTATIVE_CONTACT_INFO_REQUEST = 'GET_REPRESENTATIVE_CONTACT_INFO_REQUEST';
export const GET_REPRESENTATIVE_CONTACT_INFO_SUCCESS = 'GET_REPRESENTATIVE_CONTACT_INFO_SUCCESS';

export const SET_ACCOUNTING_SOFTWARE_DATA = 'SET_ACCOUNTING_SOFTWARE_DATA';

export const GET_COUNTRY_STATES_REQUEST = 'GET_COUNTRY_STATES_REQUEST';
export const GET_COUNTRY_STATES_SUCCESS = 'GET_COUNTRY_STATES_SUCCESS';
export const GET_COUNTRY_STATES_FAILURE = 'GET_COUNTRY_STATES_FAILURE';

export const GET_INTEGRATION_STATUS_REQUEST = 'GET_INTEGRATION_STATUS_REQUEST';
export const GET_INTEGRATION_STATUS_SUCCESS = 'GET_INTEGRATION_STATUS_SUCCESS';
export const GET_INTEGRATION_STATUS_FAILURE = 'GET_INTEGRATION_STATUS_FAILURE';
export const RESET_INTEGRATION_STATUS = 'RESET_INTEGRATION_STATUS';

export const GET_ACCOUNTING_SOFTWARE_REQUEST = 'GET_ACCOUNTING_SOFTWARE_REQUEST';
export const GET_ACCOUNTING_SOFTWARE_SUCCESS = 'GET_ACCOUNTING_SOFTWARE_SUCCESS';
export const RESET_ACCOUNTING_SOFTWARE = 'RESET_ACCOUNTING_SOFTWARE';

export const GET_CONNECT_DATA_SOURCE_REQUEST = 'GET_CONNECT_DATA_SOURCE_REQUEST';
export const GET_CONNECT_DATA_SOURCE_SUCCESS = 'GET_CONNECT_DATA_SOURCE_SUCCESS';
export const GET_CONNECT_DATA_SOURCE_FAILURE = 'GET_CONNECT_DATA_SOURCE_FAILURE';

export const GET_STORED_LIMIT_REQUEST = 'GET_STORED_LIMIT_REQUEST';
export const GET_STORED_LIMIT_SUCCESS = 'GET_STORED_LIMIT_SUCCESS';
export const GET_IS_TRADE_RELATION_ELIGIBLE_SUCCESS = 'GET_IS_TRADE_RELATION_ELIGIBLE_SUCCESS';

export const GET_OCROLUS_REQUIRED_MONTHS_REQUEST = 'GET_OCROLUS_REQUIRED_MONTHS_REQUEST';
export const GET_OCROLUS_REQUIRED_MONTHS_SUCCESS = 'GET_OCROLUS_REQUIRED_MONTHS_SUCCESS';
export const GET_OCROLUS_REQUIRED_MONTHS_FAILURE = 'GET_OCROLUS_REQUIRED_MONTHS_FAILURE';
export const RESET_OCROLUS_REQUIRED_MONTHS = 'RESET_OCROLUS_REQUIRED_MONTHS';
