import _get from 'lodash.get';
import PropTypes from 'prop-types';

const ChevronIcon = (props) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z"
        fill={_get(colors, '0', 'currentColor')}
      />
    </svg>
  );
};

ChevronIcon.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ChevronIcon;
