const DocumentsFile = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V7.50931C20 6.71366 19.6839 5.95061 19.1213 5.388L16.612 2.87868C16.0494 2.31607 15.2863 2 14.4907 2H7C5.34315 2 4 3.34315 4 5L4 19Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 16.5H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20 7.5H16.5C15.3954 7.5 14.5 6.60457 14.5 5.5V2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentsFile;
