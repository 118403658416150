export const routes = {
  root: {
    path: '/',
  },
  addCompanyInformation: {
    path: '/add-company-info',
    search: {
      path: '/add-company-info/search',
    },
    select: {
      path: '/add-company-info/select',
    },
    alreadyRegistered: {
      path: '/add-company-info/already-registered',
    },
  },
  logout: {
    path: '/logout',
  },
  roleSelection: {
    path: '/role-selection',
  },
  systemAdministrator: {
    path: '/system-administrator',
  },
  dashboard: {
    path: '/dashboard',
  },
  invoices: {
    path: '/invoices',
    invoicesAsBuyer: {
      path: '/invoices/of-suppliers',
    },
    invoiceDetails: {
      path: '/invoices/:id',
    },
  },
  deals: {
    path: '/deals',
    dealsAsBuyer: {
      path: '/deals/of-suppliers',
    },
    dealDetails: {
      path: '/deals/:dealId',
    },
    fromDocuSign: {
      success: {
        path: '/thank-you-for-signing-the-document',
      },
      cancel: {
        path: '/you-will-sign-the-document-later',
      },
      declined: {
        path: '/you-declined-signing-the-document',
      },
    },
  },
  suppliers: {
    path: '/suppliers',
    supplierDetails: {
      path: '/suppliers/:id',
      invoices: {
        path: '/suppliers/:id/invoices',
      },
      deals: {
        path: '/suppliers/:id/deals',
      },
      information: {
        path: '/suppliers/:id/information',
      },
    },
  },
  buyers: {
    path: '/buyers',
    buyerDetails: {
      path: '/buyers/:id',
      invoices: {
        path: '/buyers/:id/invoices',
      },
      deals: {
        path: '/buyers/:id/deals',
      },
      information: {
        path: '/buyers/:id/information',
      },
    },
  },
  auth: {
    path: '/auth',
    login: {
      path: '/auth/login',
    },
    googleHandler: {
      path: '/auth/google-handler',
    },
    signUp: {
      path: '/auth/sign-up',
      socialAccountActivation: {
        path: '/auth/sign-up/social-account-activation',
      },
      accountNotCreated: {
        path: '/auth/account-not-created',
      },
    },
    forgotPassword: {
      path: '/auth/forgot-password-new',
      emailConfirmation: {
        path: '/auth/forgot-password-new/email-confirmation',
      },
      changePassword: {
        path: '/auth/forgot-password-new/change-password',
      },
      passwordWasSet: {
        path: '/auth/forgot-password-new/password-was-set',
      },
    },
    emailConfirmation: {
      path: '/auth/sign-up/email-confirmation',
    },
    acceptInvitation: {
      path: '/auth/accept-invitation',
    },
  },
  acceptInvitationExternal: {
    path: '/accept-invitation',
  },
  supplierFlow: {
    path: '/create-deal',
    addYourCompany: {
      path: '/create-deal/add-company-info',
    },
    yourCompanyAlreadyRegistered: {
      path: '/create-deal/add-company-info/company-already-registred/:countryName?/companyId/:companyId/duns/:duns',
    },
    selectYourCompany: {
      path: '/create-deal/add-company-info/search/:countryCode',
    },
    addYourBuyer: {
      path: '/create-deal/add-buyer',
    },
    selectYourBuyer: {
      path: '/create-deal/add-buyer/search/:countryCode',
    },
    buyerList: {
      path: '/create-deal/add-buyer/buyers-list',
    },
    financeDocuments: {
      path: '/create-deal/finance-docs',
    },
    addInvoiceRoot: {
      path: '/create-deal/finance-docs/add-invoice',
    },
    addInvoice: {
      path: '/create-deal/finance-docs/add-invoice/tr/:trId',
    },
    anotherInvoice: {
      path: '/create-deal/finance-docs/add-invoice/add-another-invoice/tr/:trId',
    },
    uploadInvoicesSuccess: {
      path: '/create-deal/finance-docs/add-invoice/upload-invoices-success/tr/:trId',
    },
    dealOffer: {
      path: '/create-deal/finance-docs/deal-offer/tr/:trId',
    },
    supplierSignatory: {
      path: '/create-deal/finance-docs/add-supplier-signatory/tr/:trId',
    },
    fillYourBankDetails: {
      path: '/create-deal/finance-docs/add-bank-details/tr/:trId',
    },
    buyerAddAuthorisedSignatory: {
      path: '/create-deal/finance-docs/buyer-authorised-signatory/tr/:trId',
    },
    reviewAndSubmission: {
      path: '/create-deal/review-and-submission',
    },
    reviewDeal: {
      path: '/create-deal/review-and-submission/review-deal/tr/:trId',
    },
    dealSummary: {
      path: '/create-deal/review-and-submission/summary/:dealId',
    },
    dealCard: {
      path: '/deal/:dealId',
    },
  },
  buyerFlow: {
    path: '/create-buyer-deal',
    addYourCompany: {
      path: `/create-buyer-deal/add-company-info`,
    },
    yourCompanyAlreadyRegistered: {
      path: `/create-buyer-deal/add-company-info/company-already-registred/:countryName?/companyId/:companyId/duns/:duns`,
    },
    selectYourCompany: {
      path: `/create-buyer-deal/add-company-info/search/:countryCode`,
    },
    buyerSignatory: {
      path: `/create-buyer-deal/add-buyer-authorised-signatory`,
    },
    addYourSupplier: {
      path: `/create-buyer-deal/add-supplier`,
    },
    selectYourSupplier: {
      path: `/create-buyer-deal/add-supplier/search/:countryCode`,
    },
    suppliersList: {
      path: `/create-buyer-deal/add-supplier/suppliers-list`,
    },
    financeDocuments: {
      path: `/create-buyer-deal/finance-docs`,
    },
    invitationFlow: {
      path: `/create-buyer-deal/invitation-flow`,
    },
    inviteOrContinue: {
      path: `/create-buyer-deal/invitation-flow/invite-or-continue/tr/:trId`,
    },
    inviteSupplier: {
      path: `/create-buyer-deal/invitation-flow/invite-supplier/tr/:trId`,
    },
    supplierInvitation: {
      path: `/create-buyer-deal/invitation-flow/supplier-invitation/:invitationId/tr/:trId`,
    },
    addInvoiceRoot: {
      path: `/create-buyer-deal/finance-docs/add-invoice`,
    },
    addInvoice: {
      path: `/create-buyer-deal/finance-docs/add-invoice/tr/:trId`,
    },
    anotherInvoice: {
      path: `/create-buyer-deal/finance-docs/add-invoice/add-another-invoice/tr/:trId`,
    },
    uploadInvoicesSuccess: {
      path: `/create-buyer-deal/finance-docs/add-invoice/upload-invoices-success/tr/:trId`,
    },
    dealOffer: {
      path: `/create-buyer-deal/finance-docs/deal-offer/tr/:trId`,
    },

    supplierSignatory: {
      path: `/create-buyer-deal/finance-docs/supplier-authorised-signatory/tr/:trId`,
    },
    fillYourBankDetails: {
      path: `/create-buyer-deal/finance-docs/add-bank-details/tr/:trId`,
    },
    reviewAndSubmission: {
      path: `/create-buyer-deal/review-and-submission`,
    },
    reviewDeal: {
      path: `/create-buyer-deal/review-and-submission/review-deal/tr/:trId`,
    },
    dealSummary: {
      path: `/create-buyer-deal/review-and-submission/summary/:dealId`,
    },
    dealCard: {
      path: '/deals/of-suppliers?id=:dealId&sidebar=dealDetail',
    },
  },
} as const;
