import { createBrowserHistory } from 'history';

import { IStennLocationState } from './types';

/**
 * please, avoid usage of this imperative history singleton
 * use default hook useNavigate instead
 * @deprecated
 */
export const history = createBrowserHistory<IStennLocationState>();
