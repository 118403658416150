import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { OptionalValue } from '@app/components-new/OptionalValue';
import { TextSkeleton } from '@app/components-new/TextSkeleton';
import { calculateTypographyHeight } from '@app/components-new/TextSkeleton/helpers/calculateTypographyHeight';
import { IInfoBlockProps } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/CompanyInformation/InfoBlock/types';
import { Stack, useTheme } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

export const InfoBlock: FC<IInfoBlockProps> = ({ titleId, value, loadingState }) => {
  const theme = useTheme();
  return (
    <Stack gap={0.25}>
      <Typography.Overline color={(t) => t.palette.text.secondary}>
        <FormattedMessage id={titleId} />
      </Typography.Overline>
      <TextSkeleton
        status={loadingState}
        style={{
          height: calculateTypographyHeight(theme.typography.body2SemiBold),
        }}
      >
        <Typography.Body2SemiBold>
          <OptionalValue text={value} />
        </Typography.Body2SemiBold>
      </TextSkeleton>
    </Stack>
  );
};
