import { hasStrCodeProperty } from '@app/core/utilities';

export const getNumericErrorCode = (preparedErrorData: { strCode: string; code?: number }) => {
  if (hasStrCodeProperty(preparedErrorData) && preparedErrorData.strCode.length) {
    return Number(preparedErrorData.strCode.split('_').pop());
  }

  // fallback mode for safety reasons
  if (
    preparedErrorData &&
    typeof preparedErrorData === 'object' &&
    // eslint-disable-next-line no-prototype-builtins
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    preparedErrorData.hasOwnProperty('code')
  ) {
    return Number(preparedErrorData.code);
  }

  return NaN;
};
