import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { NOTIFICATIONS_REQUESTS_KEYS, NOTIFICATION_CODES } from '@app/core/constants';

import { INotification, INotificationsInfoResponse, INotificationsResponse, NotificationStatus } from './types';

const markAllReadRequest = () =>
  ApiProvider.apiQueryRequest(`${BASE_API_URLS.notifications}/api/v1.2/Notifications/ReadAll`, {
    method: 'PUT',
  });

const deleteAllRequest = () =>
  ApiProvider.apiQueryRequest(`${BASE_API_URLS.notifications}/api/v1.2/Notifications/DeleteAll`, { method: 'DELETE' });

const readCurrentNotificationRequest = (notification: INotification, callback: () => void) =>
  ApiProvider.apiQueryRequest(`${BASE_API_URLS.notifications}/api/v1.2/Notifications/Read/${notification.id}`, {
    method: 'PUT',
  }).then(() => {
    callback();
  });

const newRequestOptions = {
  method: 'POST',
  body: JSON.stringify(NOTIFICATION_CODES),
};

const notificationsRequest = ({ queryKey }: { queryKey: QueryKey }) => {
  const take = queryKey[1];
  const skip = queryKey[2];
  const status = queryKey[3];

  return ApiProvider.apiQueryRequest<INotificationsResponse[]>(
    `${BASE_API_URLS.notifications}/api/v1.2/Notifications/GetNotifications?status=${status}&take=${take}&skip=${skip}`,
    newRequestOptions
  );
};
const notificationsCountRequest = () =>
  ApiProvider.apiQueryRequest<INotificationsInfoResponse>(
    `${BASE_API_URLS.notifications}/api/v1.2/Notifications/NotificationsInfo`,
    newRequestOptions,
    { keepPreviousData: false, refetchOnMount: true, enabled: true }
  );

const useNotificationsCountRequest = () =>
  useQuery(NOTIFICATIONS_REQUESTS_KEYS.getCounts, notificationsCountRequest, {
    keepPreviousData: false,
    refetchOnMount: true,
    enabled: true,
  });

const useNewNotificationsRequest = (take: number, skip: number) =>
  useQuery(
    [NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications, take, skip, NotificationStatus.NEW],
    notificationsRequest,
    {
      keepPreviousData: false,
      refetchOnMount: true,
      enabled: true,
      cacheTime: 0,
    }
  );

const useReadNotificationsRequest = (enabled: boolean, take: number, skip: number) =>
  useQuery(
    [NOTIFICATIONS_REQUESTS_KEYS.getReadNotifications, take, skip, NotificationStatus.READ],
    notificationsRequest,
    { keepPreviousData: false, enabled }
  );

const useReadCurrentNotificationMutation = (callback: (notification: INotification) => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (notification: INotification) => readCurrentNotificationRequest(notification, () => callback(notification)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getCounts, {
          refetchInactive: false,
          refetchActive: false,
        });
        queryClient.setQueryData(
          NOTIFICATIONS_REQUESTS_KEYS.getCounts,
          // @ts-expect-error TODO: fix types
          (old: { newCount: number; readCount: number }) => ({
            newCount: Math.max(0, old.newCount - 1),
            readCount: old.readCount + 1,
          })
        );
        queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications);
        queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getReadNotifications);
      },
    }
  );
};

const useReadAllMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(markAllReadRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getCounts, {
        refetchInactive: false,
        refetchActive: false,
      });
      queryClient.setQueryData(
        NOTIFICATIONS_REQUESTS_KEYS.getCounts,
        // @ts-expect-error TODO: fix types
        (old: { newCount: number; readCount: number }) => ({
          newCount: 0,
          readCount: old.readCount + old.newCount,
        })
      );
      queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications);
      queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getReadNotifications);
    },
  });
};

const useDeleteAllMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAllRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(NOTIFICATIONS_REQUESTS_KEYS.getCounts, {
        refetchInactive: false,
        refetchActive: false,
      });
      queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications);
      queryClient.removeQueries(NOTIFICATIONS_REQUESTS_KEYS.getReadNotifications);

      queryClient.setQueryData(NOTIFICATIONS_REQUESTS_KEYS.getCounts, {
        newCount: 0,
        readCount: 0,
      });
      queryClient.setQueryData(NOTIFICATIONS_REQUESTS_KEYS.getNewNotifications, []);
      queryClient.setQueryData(NOTIFICATIONS_REQUESTS_KEYS.getReadNotifications, []);
    },
  });
};

export {
  useNewNotificationsRequest,
  useReadNotificationsRequest,
  useNotificationsCountRequest,
  useReadCurrentNotificationMutation,
  useReadAllMutation,
  useDeleteAllMutation,
};
