import { defineMessages } from 'react-intl';

const scope = 'constants.universalMessages';

export const universalMessages = defineMessages({
  weCantFindAnyCompany: {
    id: `${scope}.weCantFindAnyCompany`,
    defaultMessage: "We can't find any companies with a similar name in our database",
  },
  pleaseProvideAdditionalInformation: {
    id: `${scope}.pleaseProvideAdditionalInformation`,
    defaultMessage:
      'Please provide additional information to speed up the deal review process. These fields are optional and will be used for company verification."',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  english: {
    id: `${scope}.english`,
    defaultMessage: 'English',
  },
  chinese: {
    id: `${scope}.chinese`,
    defaultMessage: 'Chinese',
  },
  unifiedSocialCreditCode: {
    id: `${scope}.unifiedSocialCreditCode`,
    defaultMessage: 'Unified social credit code',
  },
  addYourDocuments: {
    id: `${scope}.addYourDocuments`,
    defaultMessage: 'Add your documents',
  },
  companyInformation: {
    id: `${scope}.companyInformation`,
    defaultMessage: 'Company Information',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount',
  },
  fees: {
    id: `${scope}.fees`,
    defaultMessage: 'Fees',
  },
  firstPayment: {
    id: `${scope}.firstPayment`,
    defaultMessage: 'First payment',
  },
  insuranceCharges: {
    id: `${scope}.insuranceCharges`,
    defaultMessage: 'Insurance charges',
  },
  latePaymentFees: {
    id: `${scope}.latePaymentFees`,
    defaultMessage: 'Late payment fees',
  },
  youReceive: {
    id: `${scope}.youReceive`,
    defaultMessage: 'You receive',
  },
  supplierReceive: {
    id: `${scope}.supplierReceive`,
    defaultMessage: 'Supplier receive',
  },
  acceptInvitation: {
    id: `${scope}.acceptInvitation`,
    defaultMessage: 'Accept invitation',
  },
  createInvitation: {
    id: `${scope}.createInvitation`,
    defaultMessage: 'Create an Invitation',
  },
  preview: {
    id: `${scope}.preview`,
    defaultMessage: 'Preview',
  },
  overdue: {
    id: `${scope}.overdue`,
    defaultMessage: 'Overdue',
  },
  somethingWentWrong: {
    id: `${scope}.somethingWentWrong`,
    defaultMessage: 'Something went wrong',
  },
  invoiceValue: {
    id: `${scope}.invoiceValue`,
    defaultMessage: 'Invoice value',
  },
  invoiceCurrency: {
    id: `${scope}.invoiceCurrency`,
    defaultMessage: 'Invoice currency',
  },
  currencyOfAccount: {
    id: `${scope}.currencyOfAccount`,
    defaultMessage: 'Currency of account',
  },
  unknown: {
    id: `${scope}.unknown`,
    defaultMessage: 'Unknown',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  fieldIsRequired: {
    id: `${scope}.fieldIsRequired`,
    defaultMessage: 'The field is required',
  },
  supportingDocumentsRequired: {
    id: `${scope}.supportingDocumentsRequired`,
    defaultMessage: 'Supporting documents are required',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  retry: {
    id: `${scope}.retry`,
    defaultMessage: 'Retry',
  },
  resendEmail: {
    id: `${scope}.resendEmail`,
    defaultMessage: 'Resend Email',
  },
  feesCalculator: {
    id: `${scope}.feesCalculator`,
    defaultMessage: 'fees calculator',
  },
  addDocuments: {
    id: `${scope}.addDocuments`,
    defaultMessage: 'Add documents',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  contacts: {
    id: `${scope}.contacts`,
    defaultMessage: 'Contacts',
  },
  contactsWithName: {
    id: `${scope}.contactsWithName`,
    defaultMessage: 'Contacts {name}',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  gotIt: {
    id: `${scope}.gotIt`,
    defaultMessage: 'Got it',
  },
  stay: {
    id: `${scope}.stay`,
    defaultMessage: 'Stay',
  },
  leave: {
    id: `${scope}.leave`,
    defaultMessage: 'Leave',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  uploadLater: {
    id: `${scope}.uploadLater`,
    defaultMessage: 'Upload later',
  },
  companyHasBeenDeclined: {
    id: `${scope}.companyHasBeenDeclined`,
    defaultMessage:
      'Your company has been declined as a Supplier by Stenn. Please choose another action or <a>contact Stenn to get clarification.</a>',
  },
  invoices: {
    id: `${scope}.invoices`,
    defaultMessage: 'Invoices',
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Suppliers',
  },
  deals: {
    id: `${scope}.deals`,
    defaultMessage: 'Deals',
  },
  deal: {
    id: `${scope}.deal`,
    defaultMessage: 'Deal',
  },
  yourCompany: {
    id: `${scope}.yourCompany`,
    defaultMessage: 'Your company',
  },
  yourBuyer: {
    id: `${scope}.yourBuyer`,
    defaultMessage: 'Your buyer',
  },
  authorisedSignatories: {
    id: `${scope}.authorisedSignatories`,
    defaultMessage: 'Authorised signatories',
  },
  buyersSignatories: {
    id: `${scope}.buyersSignatories`,
    defaultMessage: 'Buyer’s signatories',
  },
  bankDetailsFlowDescription: {
    id: `${scope}.bankDetailsFlowDescription`,
    defaultMessage: 'Bank details provided',
  },
  addYourBuyerSelectedFlow: {
    id: `${scope}.addYourBuyerSelectedFlow`,
    defaultMessage: '{buyerName}, {availableLimit}',
  },
  addYourBuyerInProgressFlowDescription: {
    id: `${scope}.addYourBuyerInProgressFlowDescription`,
    defaultMessage: '{firstBuyerName} and {remainingBuyersCount} more',
  },
  uploadInvoicesFlowDescription: {
    id: `${scope}.uploadInvoicesFlowDescription`,
    defaultMessage: '{invoicesCount} invoices for {totalFaceValue}',
  },
  dealSummaryTitle: {
    id: `${scope}.dealSummaryTitle`,
    defaultMessage: 'Deal summary',
  },
  getFinanced: {
    id: `${scope}.getFinanced`,
    defaultMessage: 'Get Financed',
  },
  dueDate: {
    id: `${scope}.dueDate`,
    defaultMessage: 'Due date',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  signer: {
    id: `${scope}.signer`,
    defaultMessage: 'Signer',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage: 'Contact support',
  },
  cannotAddMoreBuyers: {
    id: `${scope}.cannotAddMoreBuyers`,
    defaultMessage:
      'You cannot add more buyers until one of your deals is financed by Stenn. Or contact our support service.',
  },
  sorryYouCannotAddMoreBuyers: {
    id: `${scope}.sorryYouCannotAddMoreBuyers`,
    defaultMessage: 'Sorry, you cannot add more buyers',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Log in',
  },
  loginSolidSpelling: {
    id: `${scope}.loginSolidSpelling`,
    defaultMessage: 'Login',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign Up',
  },
  loginOrSignup: {
    id: `${scope}.loginOrSignup`,
    defaultMessage: 'Log in / Sign up',
  },
  somethingWentWrongSupportTeamAlreadyNotified: {
    id: `${scope}.somethingWentWrongSupportTeamAlreadyNotified`,
    defaultMessage:
      'Something went wrong. Our support team has already been notified. Please try again in a few seconds.',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  applyOnlineToStartFinanceInvoices: {
    id: `${scope}.applyOnlineToStartFinanceInvoices`,
    defaultMessage: 'Apply online to start finance your invoices with Stenn',
  },
  approved: {
    id: `${scope}.approved`,
    defaultMessage: 'Approved',
  },
  declined: {
    id: `${scope}.declined`,
    defaultMessage: 'Declined',
  },
  decline: {
    id: `${scope}.decline`,
    defaultMessage: 'Decline',
  },
  approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Approve',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  repaid: {
    id: `${scope}.repaid`,
    defaultMessage: 'Repaid',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  partnershipCodeOptional: {
    id: `${scope}.partnershipCodeOptional`,
    defaultMessage: 'Trusted partnership code (optional)',
  },
  surname: {
    id: `${scope}.surname`,
    defaultMessage: 'Surname',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  locality: {
    id: `${scope}.locality`,
    defaultMessage: 'Location (city, town, etc.)',
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Location',
  },
  yourName: {
    id: `${scope}.yourName`,
    defaultMessage: 'Your name',
  },
  yourSurname: {
    id: `${scope}.yourSurname`,
    defaultMessage: 'Your surname',
  },
  yourEmail: {
    id: `${scope}.yourEmail`,
    defaultMessage: 'Your e-mail',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  clearCode: {
    id: `${scope}.clearCode`,
    defaultMessage: 'Clear code',
  },
  dunsOptional: {
    id: `${scope}.dunsOptional`,
    defaultMessage: 'DUNS (Optional)',
  },
  duns: {
    id: `${scope}.duns`,
    defaultMessage: 'DUNS',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  legalAddress: {
    id: `${scope}.legalAddress`,
    defaultMessage: 'Legal address',
  },
  stateProvince: {
    id: `${scope}.stateProvince`,
    defaultMessage: 'State / Province',
  },
  postalZipCode: {
    id: `${scope}.postalZipCode`,
    defaultMessage: 'Postal / ZIP code',
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'URL',
  },
  supplierInvitation: {
    id: `${scope}.supplierInvitation`,
    defaultMessage: 'Supplier invitation',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'Ok',
  },
  iReadAndAcceptTerms: {
    id: `${scope}.iReadAndAcceptTerms`,
    defaultMessage: 'I have read and accepted the <terms>Terms of Use</terms> and <policy>Privacy Policy</policy>',
  },
  changeUser: {
    id: `${scope}.changeUser`,
    defaultMessage: 'Change the user',
  },
  submittingInvoicesToObtainFunding: {
    id: `${scope}.submittingInvoicesToObtainFunding`,
    defaultMessage: 'Submitting invoices to Stenn to obtain funding',
  },
  leaveThePage: {
    id: `${scope}.leaveThePage`,
    defaultMessage: 'Leave the page? It will not be saved.',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone number',
  },
  phoneNumberOptional: {
    id: `${scope}.phoneNumberOptional`,
    defaultMessage: 'Phone number (Optional)',
  },
  buyer: {
    id: `${scope}.buyer`,
    defaultMessage: 'Buyer',
  },
  allBuyers: {
    id: `${scope}.allBuyers`,
    defaultMessage: 'All buyers',
  },
  financed: {
    id: `${scope}.financed`,
    defaultMessage: 'Financed',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  invoiceNumber: {
    id: `${scope}.invoiceNumber`,
    defaultMessage: 'Invoice number',
  },
  financingDate: {
    id: `${scope}.financingDate`,
    defaultMessage: 'Financing date',
  },
  dealNumber: {
    id: `${scope}.dealNumber`,
    defaultMessage: 'Deal Number',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To',
  },
  createdOn: {
    id: `${scope}.createdOn`,
    defaultMessage: 'Created on',
  },
  on: {
    id: `${scope}.on`,
    defaultMessage: 'On',
  },
  off: {
    id: `${scope}.off`,
    defaultMessage: 'Off',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending',
  },
  allSuppliers: {
    id: `${scope}.allSuppliers`,
    defaultMessage: 'All suppliers',
  },
  usedUpLimitPleaseIncreaseLimitOrReplaceInvoicesInLastDeal: {
    id: `${scope}.usedUpLimitPleaseIncreaseLimitOrReplaceInvoicesInLastDeal`,
    defaultMessage:
      'The limit will be available after the buyer pays the financed invoices. If you would like to get funding for other invoices, please replace the invoices in the last deal or ask to increase the limit by sending a request to the Support Team',
  },
  usedUpLimitPleaseIncreaseLimit: {
    id: `${scope}.usedUpLimitPleaseIncreaseLimit`,
    defaultMessage:
      'The limit will be available after the buyer pays the financed invoices. If you would like to get funding for other invoices, please ask for an increase of the limit by sending a request to the Support Team.',
  },
  overdueDays: {
    id: `${scope}.overdueDays`,
    defaultMessage: 'Number of days if the buyer overdues the payment',
  },
  sign: {
    id: `${scope}.sign`,
    defaultMessage: 'Sign',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  createNewDeal: {
    id: `${scope}.createNewDeal`,
    defaultMessage: 'Create new deal',
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: 'Learn more',
  },
  newClient: {
    id: `${scope}.newClient`,
    defaultMessage: 'New client',
  },
  addNewBuyer: {
    id: `${scope}.addNewBuyer`,
    defaultMessage: 'Add new buyer',
  },
  uploadMoreDocuments: {
    id: `${scope}.uploadMoreDocuments`,
    defaultMessage: 'Upload more documents',
  },
  uploadSupportingDocuments: {
    id: `${scope}.uploadSupportingDocuments`,
    defaultMessage: 'Upload supporting documents',
  },
  filterSuppliers: {
    id: `${scope}.filterSuppliers`,
    defaultMessage: 'Filter suppliers',
  },
  noSuppliersFound: {
    id: `${scope}.noSuppliersFound`,
    defaultMessage: 'No suppliers found...',
  },
  noBuyersFound: {
    id: `${scope}.noBuyersFound`,
    defaultMessage: 'No buyers found...',
  },
  noInvoicesFound: {
    id: `${scope}.noInvoicesFound`,
    defaultMessage: 'No invoices found...',
  },
  noDealsFound: {
    id: `${scope}.noDealsFound`,
    defaultMessage: 'No deals found...',
  },
  sendInvitationEmail: {
    id: `${scope}.sendInvitationEmail`,
    defaultMessage: 'Send invitation by email',
  },
  copyReferralLink: {
    id: `${scope}.copyReferralLink`,
    defaultMessage: 'Copy referral link',
  },
  youHaveAnyQuestions: {
    id: `${scope}.youHaveAnyQuestions`,
    defaultMessage: 'Do you have any questions?',
  },
  chooseAnotherBuyer: {
    id: `${scope}.chooseAnotherBuyer`,
    defaultMessage: 'Choose another buyer',
  },
  submitInvoices: {
    id: `${scope}.submitInvoices`,
    defaultMessage: 'Submit invoices',
  },
  requestLimit: {
    id: `${scope}.requestLimit`,
    defaultMessage: 'Request limit',
  },
  buyers: {
    id: `${scope}.buyers`,
    defaultMessage: 'Buyers',
  },
  myBuyers: {
    id: `${scope}.myBuyers`,
    defaultMessage: 'My buyers',
  },
  issueDate: {
    id: `${scope}.issueDate`,
    defaultMessage: 'Issue Date',
  },
  daysLeft: {
    id: `${scope}.daysLeft`,
    defaultMessage: 'Days Left',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  additionalInfoRequired: {
    id: `${scope}.additionalInfoRequired`,
    defaultMessage: 'Additional info required',
  },
  totalInvoicesValue: {
    id: `${scope}.totalInvoicesValue`,
    defaultMessage: 'Total invoices value',
  },
  addNewContact: {
    id: `${scope}.addNewContact`,
    defaultMessage: 'Add new contact',
  },
  available: {
    id: `${scope}.available`,
    defaultMessage: 'Available',
  },
  notAvailable: {
    id: `${scope}.notAvailable`,
    defaultMessage: 'Not available',
  },
  totalInvoiceFaceValue: {
    id: `${scope}.totalInvoiceFaceValue`,
    defaultMessage: 'Total invoice face value',
  },
  uploadInvoices: {
    id: `${scope}.uploadInvoices`,
    defaultMessage: 'Upload invoices',
  },
  bankDetails: {
    id: `${scope}.bankDetails`,
    defaultMessage: 'Bank details',
  },
  authorisedSignatoryBuyers: {
    id: `${scope}.authorisedSignatoryBuyers`,
    defaultMessage: 'Buyerʹs authorised signatory',
  },
  addBuyerGetLimits: {
    id: `${scope}.addBuyerGetLimits`,
    defaultMessage: 'Add your buyer and get limits',
  },
  introduceCompany: {
    id: `${scope}.introduceCompany`,
    defaultMessage: 'Introduce your company',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  position: {
    id: `${scope}.position`,
    defaultMessage: 'Position',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  resendInvitation: {
    id: `${scope}.resendInvitation`,
    defaultMessage: 'Resend invitation',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile',
  },
  invited: {
    id: `${scope}.invited`,
    defaultMessage: 'Invited',
  },
  invitationTool: {
    id: `${scope}.invitationTool`,
    defaultMessage: 'Invitation Tool',
  },
  unableWorkWithBuyer: {
    id: `${scope}.unableWorkWithBuyer`,
    defaultMessage: 'At this time, Stenn is unable to work with Buyers located in {country}.',
  },
  ineligibleTradeRelationDescription: {
    id: `${scope}.ineligibleTradeRelationDescription`,
    defaultMessage:
      'At this time, Stenn is unable to finance Trade Relation between Supplier located in {supplierCountry} and Buyer located in {buyerCountry}',
  },
  dealSummary: {
    id: `${scope}.dealSummary`,
    defaultMessage: 'Deal summary',
  },
  dealOffer: {
    id: `${scope}.dealOffer`,
    defaultMessage: 'Deal offer',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log out',
  },
  lang: {
    id: `${scope}.lang`,
    defaultMessage: 'Language',
  },
  introducer: {
    id: `${scope}.introducer`,
    defaultMessage: 'Introducer',
  },
  applyNow: {
    id: `${scope}.applyNow`,
    defaultMessage: 'Apply now',
  },
  main: {
    id: `${scope}.main`,
    defaultMessage: 'Main',
  },
  addBankAccountDetails: {
    id: `${scope}.addBankAccountDetails`,
    defaultMessage: 'Add bank account details',
  },
  beneficiaryAccountNumber: {
    id: `${scope}.beneficiaryAccountNumber`,
    defaultMessage: 'Beneficiary account number {buttonInfo}',
  },
  swift: {
    id: `${scope}.swift`,
    defaultMessage: 'SWIFT',
  },
  beneficiaryName: {
    id: `${scope}.beneficiaryName`,
    defaultMessage: 'Beneficiary name {buttonInfo}',
  },
  beneficiaryNameText: {
    id: `${scope}.beneficiaryNameText`,
    defaultMessage: 'Beneficiary name',
  },
  firstBeneficiaryAddress: {
    id: `${scope}.firstBeneficiaryAddress`,
    defaultMessage: 'Beneficiary address 1 {buttonInfo}',
  },
  firstBeneficiaryAddressText: {
    id: `${scope}.firstBeneficiaryAddressText`,
    defaultMessage: 'Beneficiary address 1',
  },
  optionalSecondBeneficiaryAddress: {
    id: `${scope}.optionalSecondBeneficiaryAddress`,
    defaultMessage: 'Beneficiary address 2 (Optional)',
  },
  secondBeneficiaryAddress: {
    id: `${scope}.secondBeneficiaryAddress`,
    defaultMessage: 'Beneficiary address 2',
  },
  beneficiaryDetails: {
    id: `${scope}.beneficiaryDetails`,
    defaultMessage: 'Beneficiary details',
  },
  bankName: {
    id: `${scope}.bankName`,
    defaultMessage: 'Bank name',
  },
  addInformationAboutBuyer: {
    id: `${scope}.addInformationAboutBuyer`,
    defaultMessage: 'Add your buyer info',
  },
  itHelpIdentifyBuyer: {
    id: `${scope}.itHelpIdentifyBuyer`,
    defaultMessage: 'It will help us to identify your buyer and set up a credit limit',
  },
  yourBuyersCompanyName: {
    id: `${scope}.yourBuyersCompanyName`,
    defaultMessage: "Your buyer's company name",
  },
  yourSuppliersCompanyName: {
    id: `${scope}.yourSuppliersCompanyName`,
    defaultMessage: "Your supplier's company name",
  },
  unableEstimateCreditLimit: {
    id: `${scope}.unableEstimateCreditLimit`,
    defaultMessage: 'We are unable to estimate the credit limit for {companyName}',
  },
  addAnotherBuyer: {
    id: `${scope}.addAnotherBuyer`,
    defaultMessage: 'Add another buyer',
  },
  foundedCompanies: {
    id: `${scope}.foundedCompanies`,
    defaultMessage:
      'We found {companiesCount, plural, =0{0 companies} one{# company} other{# companies}} with the similar name.',
  },
  searchingCompanies: {
    id: `${scope}.searchingCompanies`,
    defaultMessage: 'Searching for companies...',
  },
  notSelected: {
    id: `${scope}.notSelected`,
    defaultMessage: 'Not Selected',
  },
  selectYourBuyer: {
    id: `${scope}.selectYourBuyer`,
    defaultMessage: 'Select your buyer',
  },
  selectYourSupplier: {
    id: `${scope}.selectYourSupplier`,
    defaultMessage: 'Select your supplier',
  },
  buyerNotListed: {
    id: `${scope}.buyerNotListed`,
    defaultMessage: 'My buyer is not listed',
  },
  couldNotFindCompany: {
    id: `${scope}.couldNotFindCompany`,
    defaultMessage: 'We could not find {companyName} in our databases',
  },
  checkBuyerInformation: {
    id: `${scope}.checkBuyerInformation`,
    defaultMessage: 'Please check the information for the buyer or add another buyer.',
  },
  addYourCompanyInfo: {
    id: `${scope}.addYourCompanyInfo`,
    defaultMessage: 'Add your company info',
  },
  addYourBuyerCompanyInfo: {
    id: `${scope}.addYourBuyerCompanyInfo`,
    defaultMessage: "Add your buyer's company info",
  },
  thisInformationWillHelpUs: {
    id: `${scope}.thisInformationWillHelpUs`,
    defaultMessage:
      'This information will help us to understand your business, so we can make an offer suitable for your needs.',
  },
  itWillHelpUsUnderstandYourBusiness: {
    id: `${scope}.itWillHelpUsUnderstandYourBusiness`,
    defaultMessage: 'It will help us understand your business, so we can make an offer that suits your needs',
  },
  itHelpUnderstandBusiness: {
    id: `${scope}.itHelpUnderstandBusiness`,
    defaultMessage: 'It will help us to understand your business, so we can make an offer that suits your needs',
  },
  yourCompanyName: {
    id: `${scope}.yourCompanyName`,
    defaultMessage: 'Your company name',
  },
  yourRegisteredCompanyName: {
    id: `${scope}.yourRegisteredCompanyName`,
    defaultMessage: 'Your registered company name',
  },
  yourRegisteredCompanyNameEnglish: {
    id: `${scope}.yourRegisteredCompanyNameEnglish`,
    defaultMessage: 'Your registered company name (English)',
  },
  registeredCompanyName: {
    id: `${scope}.registeredCompanyName`,
    defaultMessage: 'Registered company name',
  },
  registeredCompanyNameEnglish: {
    id: `${scope}.registeredCompanyNameEnglish`,
    defaultMessage: 'Registered company name (English)',
  },
  registeredCompanyNameChinese: {
    id: `${scope}.registeredCompanyNameChinese`,
    defaultMessage: 'Registered company name (Chinese)',
  },
  selectYourCompany: {
    id: `${scope}.selectYourCompany`,
    defaultMessage: 'Select your company',
  },
  companyNotListed: {
    id: `${scope}.companyNotListed`,
    defaultMessage: 'Company not listed',
  },
  unableIdentifyCompany: {
    id: `${scope}.unableIdentifyCompany`,
    defaultMessage: 'We are unable to identify your company',
  },
  unableIdentifyCompanyDescription: {
    id: `${scope}.unableIdentifyCompanyDescription`,
    defaultMessage:
      'Please make sure you entered correct details of your company. Submit your company if all the details are correct and we will contact you as soon as we managed this issue.',
  },
  unableInviteSuppliers: {
    id: `${scope}.unableInviteSuppliers`,
    defaultMessage: 'Sorry, but you cannot invite suppliers',
  },
  unableInviteSuppliersDescription: {
    id: `${scope}.unableInviteSuppliersDescription`,
    defaultMessage:
      "You cannot invite suppliers, but still can invite other buyers and participate in Stenn's referral program.",
  },
  submitCompanyIfDetailsCorrect: {
    id: `${scope}.submitCompanyIfDetailsCorrect`,
    defaultMessage: 'Submit your company.',
  },
  makeSureEnteredDetails: {
    id: `${scope}.makeSureEnteredDetails`,
    defaultMessage:
      'Please make sure you entered correct details of your company. Submit your company if all the details are correct and we will contact you as soon as we managed this issue.',
  },
  invoice: {
    id: `${scope}.invoice`,
    defaultMessage: 'Invoice',
  },
  transportDocument: {
    id: `${scope}.transportDocument`,
    defaultMessage: 'Transport document',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  verifyAuthorisedSignatoryInformation: {
    id: `${scope}.verifyAuthorisedSignatoryInformation`,
    defaultMessage:
      'Please add and verify personal information for the authorised signatory of your trading partner. {hint}',
  },
  verifyBuyersAuthorisedSignatoryInformation: {
    id: `${scope}.verifyBuyersAuthorisedSignatoryInformation`,
    defaultMessage: 'Please add and verify personal information for the authorised signatory of your trading partner',
  },
  foundBuyersAuthorisedSignatoryInformation: {
    id: `${scope}.foundBuyersAuthorisedSignatoryInformation`,
    defaultMessage:
      'We’ve found a person who is assumingly authorised to sign documents on behalf of your trading partner.',
  },
  confirmAuthorisedSignatoryInformation: {
    id: `${scope}.confirmAuthorisedSignatoryInformation`,
    defaultMessage:
      'Please verify personal information for the authorised signatory of your trading partner. Click <b>Next</b> to confirm. {hint}',
  },
  contactBuyerForSigningDocuments: {
    id: `${scope}.contactBuyerForSigningDocuments`,
    defaultMessage: 'Stenn will contact your buyer only for signing documents',
  },
  someoneAuthorisedSignDocuments: {
    id: `${scope}.someoneAuthorisedSignDocuments`,
    defaultMessage:
      'If you believe someone else is authorised to sign documents on behalf of your trading partner, please contact our support team.',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'Support',
  },
  signAgreements: {
    id: `${scope}.signAgreements`,
    defaultMessage: 'Sign agreements with Stenn',
  },
  youCanSignAgreementsWithEDS: {
    id: `${scope}.youCanSignAgreementsWithEDS`,
    defaultMessage: 'You can sign agreements with Electronic Digital Signature',
  },
  signDocuments: {
    id: `${scope}.signDocuments`,
    defaultMessage: 'Sign documents',
  },
  sixDigitCode: {
    id: `${scope}.sixDigitCode`,
    defaultMessage: '6-digit code',
  },
  reviewDealParameters: {
    id: `${scope}.reviewDealParameters`,
    defaultMessage: 'Please review your deal parameters.',
  },
  dealUnderReview: {
    id: `${scope}.dealUnderReview`,
    defaultMessage: 'Your deal is under review',
  },
  youHaveSubmittedDeal: {
    id: `${scope}.youHaveSubmittedDeal`,
    defaultMessage:
      'You have submitted the deal to us on {date}. We will review the deal and will contact you via your email {email} within 48 hours.',
  },
  submitDeal: {
    id: `${scope}.submitDeal`,
    defaultMessage: 'Submit deal',
  },
  dealSubmitted: {
    id: `${scope}.dealSubmitted`,
    defaultMessage: 'Your deal is submitted',
  },
  reviewYourDealAndComeBack: {
    id: `${scope}.reviewYourDealAndComeBack`,
    defaultMessage: 'Thank you for finishing the onboarding. We will review your deal and contact you soon.',
  },
  visitDashboard: {
    id: `${scope}.visitDashboard`,
    defaultMessage: 'Visit dashboard',
  },
  goToDashboard: {
    id: `${scope}.goToDashboard`,
    defaultMessage: 'Go to Dashboard',
  },
  exploreYourPersonalDashboard: {
    id: `${scope}.exploreYourPersonalDashboard`,
    defaultMessage: 'You can explore your personal dashboard while your deal is under review',
  },
  addInvoice: {
    id: `${scope}.addInvoice`,
    defaultMessage: 'Add invoice',
  },
  theInvoiceNumber: {
    id: `${scope}.theInvoiceNumber`,
    defaultMessage: 'The invoice number',
  },
  invoiceValueUSD: {
    id: `${scope}.invoiceValueUSD`,
    defaultMessage: 'Invoice value (USD) {buttonInfo}',
  },
  chooseWhatYouSellToCustomer: {
    id: `${scope}.chooseWhatYouSellToCustomer`,
    defaultMessage: 'Please choose what you sell to the customer?',
  },
  facebook: {
    id: `${scope}.facebook`,
    defaultMessage: 'Facebook',
  },
  goods: {
    id: `${scope}.goods`,
    defaultMessage: 'Goods',
  },
  services: {
    id: `${scope}.services`,
    defaultMessage: 'Services',
  },
  uploadDocuments: {
    id: `${scope}.uploadDocuments`,
    defaultMessage: 'Upload documents',
  },
  uploadInvoiceAndTransportDocuments: {
    id: `${scope}.uploadInvoiceAndTransportDocuments`,
    defaultMessage:
      'Please upload invoice and transport documents supporting the deal, such as Bill of lading, Air waybill, CMR documents etc.',
  },
  availableRemainingLimit: {
    id: `${scope}.availableRemainingLimit`,
    defaultMessage: 'The available limit remaining is {availableLimit} USD',
  },
  selectBuyerToSubmitInvoices: {
    id: `${scope}.selectBuyerToSubmitInvoices`,
    defaultMessage: 'Select your buyer to submit invoices',
  },
  invoiceAdded: {
    id: `${scope}.invoiceAdded`,
    defaultMessage: 'Invoice added',
  },
  yourInvoiceAddedSuccessfully: {
    id: `${scope}.yourInvoiceAddedSuccessfully`,
    defaultMessage: 'Your invoice was added successfully. You can add another one or proceed to the deal summary',
  },
  limitSuccessfullyRequested: {
    id: `${scope}.limitSuccessfullyRequested`,
    defaultMessage: 'Your limit request has been sent',
  },
  addAnotherInvoice: {
    id: `${scope}.addAnotherInvoice`,
    defaultMessage: 'Add another invoice',
  },
  proceedDealSummary: {
    id: `${scope}.proceedDealSummary`,
    defaultMessage: 'Proceed to deal summary',
  },
  website: {
    id: `${scope}.website`,
    defaultMessage: 'Website',
  },
  supplierWebsite: {
    id: `${scope}.supplierWebsite`,
    defaultMessage: 'Supplier’s website',
  },
  optionalField: {
    id: `${scope}.optionalField`,
    defaultMessage: '(Optional)',
  },
  comments: {
    id: `${scope}.comments`,
    defaultMessage: 'Comments',
  },
  authorisedSignatory: {
    id: `${scope}.authorisedSignatory`,
    defaultMessage: 'Authorised signatory',
  },
  verifyAuthorisedSignatoryToSignDocuments: {
    id: `${scope}.verifyAuthorisedSignatoryToSignDocuments`,
    defaultMessage:
      "Please provide the following information about the person who is authorised to sign documents on behalf of your company. Stenn will send the documents directly to this signatory's email inbox.",
  },
  verifyAuthorisedSignatory: {
    id: `${scope}.verifyAuthorisedSignatory`,
    defaultMessage:
      'Please verify that information below is correct and you are authorised to seek credit on behalf of your company. {hint}',
  },
  iAmAuthorisedSignatoryCheckbox: {
    id: `${scope}.iAmAuthorisedSignatoryCheckbox`,
    defaultMessage: 'I am an authorised signatory',
  },
  yourCompanyWebsite: {
    id: `${scope}.yourCompanyWebsite`,
    defaultMessage: 'Your company website',
  },
  addUser: {
    id: `${scope}.addUser`,
    defaultMessage: 'Add user',
  },
  addPersonAsEmployee: {
    id: `${scope}.addPersonAsEmployee`,
    defaultMessage:
      'If you are not an authorised signatory, please add the person who is the signatory as an employee in your profile. They can then sign up and complete this step.',
  },
  createUserProfile: {
    id: `${scope}.createUserProfile`,
    defaultMessage: 'Create user profile',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  contactSuccessfullyAdded: {
    id: `${scope}.contactSuccessfullyAdded`,
    defaultMessage: 'Your contact was successfully added',
  },
  uploadedDocuments: {
    id: `${scope}.uploadedDocuments`,
    defaultMessage: 'Uploaded documents',
  },
  editInvoice: {
    id: `${scope}.editInvoice`,
    defaultMessage: 'Invoice editing',
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: 'Copy Link',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  whoYouWantInvite: {
    id: `${scope}.whoYouWantInvite`,
    defaultMessage: 'Who do you want to invite?',
  },
  createInvitationForYourClient: {
    id: `${scope}.createInvitationForYourClient`,
    defaultMessage:
      'Create an invitation for your client with automatically generated personalised link for easy registration.',
  },
  clientName: {
    id: `${scope}.clientName`,
    defaultMessage: "Client's name",
  },
  clientSurname: {
    id: `${scope}.clientSurname`,
    defaultMessage: "Client's surname",
  },
  clientEmail: {
    id: `${scope}.clientEmail`,
    defaultMessage: "Client's email",
  },
  leaveThisPage: {
    id: `${scope}.leaveThisPage`,
    defaultMessage: 'Leave this page? Your progress will not be saved',
  },
  checkYourRefereeDetails: {
    id: `${scope}.checkYourRefereeDetails`,
    defaultMessage: 'Check your referee details',
  },
  youWillGetReferralLink: {
    id: `${scope}.youWillGetReferralLink`,
    defaultMessage: 'You will get the referral link on the next step.',
  },
  linkToInvitationCopied: {
    id: `${scope}.linkToInvitationCopied`,
    defaultMessage: 'A link to the invitation was copied to the clipboard ',
  },
  linkToPSPCopied: {
    id: `${scope}.linkToPSPCopied`,
    defaultMessage: 'A link to the Personal Sales Page was copied to the clipboard',
  },
  linkToRepsCertificateCopied: {
    id: `${scope}.linkToRepsCertificateCopied`,
    defaultMessage: 'A link to the Representative Certificate was copied to the clipboard',
  },
  weSentInvitationToEmail: {
    id: `${scope}.weSentInvitationToEmail`,
    defaultMessage: 'We have sent the invitation to {email}',
  },
  yourUniqueInvitation: {
    id: `${scope}.yourUniqueInvitation`,
    defaultMessage: 'Here is your unique invitation.',
  },
  pleaseCopyLinkBelow: {
    id: `${scope}.pleaseCopyLinkBelow`,
    defaultMessage:
      'Please copy the link below and send it directly to your supplier using your way of communication. This unique link will redirect your supplier to welcome page (please see the preview on the left).',
  },
  sendInvitationYourReferee: {
    id: `${scope}.sendInvitationYourReferee`,
    defaultMessage: 'Or send the invitation to your referee by email from Stenn',
  },
  sendDocuments: {
    id: `${scope}.sendDocuments`,
    defaultMessage: 'Send documents',
  },
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Please enter phone number in international format',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  addingInvoices: {
    id: `${scope}.addingInvoices`,
    defaultMessage: 'Adding Invoices',
  },
  pleaseAddInvoices: {
    id: `${scope}.pleaseAddInvoices`,
    defaultMessage: 'Please add one or several invoices to proceed',
  },
  dealType: {
    id: `${scope}.dealType`,
    defaultMessage: 'Deal Type',
  },
  invoiceProductType: {
    id: `${scope}.invoiceProductType`,
    defaultMessage: 'Type',
  },
  goodsDealTypeDescription: {
    id: `${scope}.goodsDealTypeDescription`,
    defaultMessage: 'You sell goods to your buyer. You have to attach an invoice and transport document to this deal',
  },
  servicesDealTypeDescription: {
    id: `${scope}.servicesDealTypeDescription`,
    defaultMessage: 'You provide services to your buyer. You have to attach an invoice file to this deal',
  },
  unableWorkWithCompanyAsSupplier: {
    id: `${scope}.unableWorkWithCompanyAsSupplier`,
    defaultMessage:
      'At this time, Stenn is unable to work with Suppliers located in {countryName} due to regulatory restrictions. We are continuously reviewing regulations and adding geographies we are able to finance. If there are any changes, our team will reach out immediately with an update',
  },
  unableWorkWithCompanyAsBuyer: {
    id: `${scope}.unableWorkWithCompanyAsBuyer`,
    defaultMessage:
      'At this time, Stenn is unable to work with Buyers located in {countryName} due to regulatory restrictions. We are continuously reviewing regulations and adding geographies we are able to finance. If there are any changes, our team will reach out immediately with an update',
  },
  weAreUnableWorkInYourCountry: {
    id: `${scope}.weAreUnableWorkInYourCountry`,
    defaultMessage: 'We are unable to work in your country',
  },
  weAreUnableWorkWithBuyersLocatedIn: {
    id: `${scope}.weAreUnableWorkWithBuyersLocatedIn`,
    defaultMessage: 'We are unable to work with buyers, located in {countryName}',
  },
  addAnotherBuyerFromDifferentCountry: {
    id: `${scope}.addAnotherBuyerFromDifferentCountry`,
    defaultMessage: 'You may add another buyer from a different country',
  },
  buyerHasntApprovedFromCreditPerspective: {
    id: `${scope}.buyerHasntApprovedFromCreditPerspective`,
    defaultMessage: 'This Buyer hasn’t been approved by Stenn from a credit perspective. Please choose another Buyer.',
  },
  youHaveExceededYourLimit: {
    id: `${scope}.youHaveExceededYourLimit`,
    defaultMessage:
      'You have exceeded your limit for adding companies to your account. All other buyers should now be added manually. Please contact support if you want to add another buyer company. You can also explore other functions of the website by visiting the Dashboard.',
  },
  stennUnableOfferYouDomesticFinancing: {
    id: `${scope}.stennUnableOfferYouDomesticFinancing`,
    defaultMessage:
      'At this time, Stenn is unable to offer you domestic financing in {countryName} due to restrictions in local legislation. We are continuously reviewing regulations and adding geographies we are able to finance domestically. If there are any changes, our team will reach out immediately with an update',
  },
  hasBuyerInListOfAdded: {
    id: `${scope}.hasBuyerInListOfAdded`,
    defaultMessage: 'The buyer has been already added',
  },
  uploadInvoicesWithNewDealType: {
    id: `${scope}.uploadInvoicesWithNewDealType`,
    defaultMessage:
      'You have uploaded invoices previously. If you choose this buyer and deal type, you will have to upload new invoices. All previously uploaded invoices will be available after submitting the current deal',
  },
  steps: {
    id: `${scope}.steps`,
    defaultMessage: 'Steps',
  },
  resetAll: {
    id: `${scope}.resetAll`,
    defaultMessage: 'Reset all',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  youHaveUploadedYourBankStatements: {
    id: `${scope}.youHaveUploadedYourBankStatements`,
    defaultMessage:
      '<div>You have uploaded your bank statements, which are currently under review.</div> <div>You will receive an email notification once the check is finished, and you will be able to add your suppliers</div>',
  },
  pleaseUploadNonEmptyFiles: {
    id: `${scope}.pleaseUploadNonEmptyFiles`,
    defaultMessage: 'Please upload non-empty files',
  },
  enterBankDetailsForPaymentPurposes: {
    id: `${scope}.enterBankDetailsForPaymentPurposes`,
    defaultMessage: 'Please enter your bank account details for payment purposes',
  },
  notListed: {
    id: `${scope}.notListed`,
    defaultMessage: 'Not listed',
  },
  stennCanProvideCreditLimit: {
    id: `${scope}.stennCanProvideCreditLimit`,
    defaultMessage: 'Stenn can provide a credit limit for {companyName} up to {creditLimit}',
  },
  blockUser: {
    id: `${scope}.blockUser`,
    defaultMessage: 'Block user',
  },
  unblockUser: {
    id: `${scope}.unblockUser`,
    defaultMessage: 'Unblock user',
  },
  userEmail: {
    id: `${scope}.userEmail`,
    defaultMessage: 'User email',
  },
  of: {
    id: `${scope}.of`,
    defaultMessage: 'of',
  },
  myInfo: {
    id: `${scope}.myInfo`,
    defaultMessage: 'My info',
  },
  joiningDate: {
    id: `${scope}.joiningDate`,
    defaultMessage: 'Joining date',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  requesting: {
    id: `${scope}.requesting`,
    defaultMessage: 'Requesting',
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'Month',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  qty: {
    id: `${scope}.qty`,
    defaultMessage: 'Qty',
  },
  January: {
    id: `${scope}.january`,
    defaultMessage: 'January',
  },
  February: {
    id: `${scope}.february`,
    defaultMessage: 'February',
  },
  March: {
    id: `${scope}.march`,
    defaultMessage: 'March',
  },
  April: {
    id: `${scope}.april`,
    defaultMessage: 'April',
  },
  May: {
    id: `${scope}.may`,
    defaultMessage: 'May',
  },
  June: {
    id: `${scope}.june`,
    defaultMessage: 'June',
  },
  July: {
    id: `${scope}.july`,
    defaultMessage: 'July',
  },
  August: {
    id: `${scope}.august`,
    defaultMessage: 'August',
  },
  September: {
    id: `${scope}.september`,
    defaultMessage: 'September',
  },
  October: {
    id: `${scope}.october`,
    defaultMessage: 'October',
  },
  November: {
    id: `${scope}.november`,
    defaultMessage: 'November',
  },
  December: {
    id: `${scope}.december`,
    defaultMessage: 'December',
  },
  myCompanyIsNotListed: {
    id: `${scope}.myCompanyIsNotListed`,
    defaultMessage: 'My company is not listed',
  },
  changeYourCompanyInfo: {
    id: `${scope}.changeYourCompanyInfo`,
    defaultMessage: 'Change your company info',
  },
  submitRequest: {
    id: `${scope}.submitRequest`,
    defaultMessage: 'Submit Request',
  },
  requestSubmitted: {
    id: `${scope}.requestSubmitted`,
    defaultMessage: 'Request submitted',
  },
  cancelRequest: {
    id: `${scope}.cancelRequest`,
    defaultMessage: 'Cancel request',
  },
  dontHaveAnAccount: {
    id: `${scope}.dontHaveAnAccount`,
    defaultMessage: "Don't have an account?",
  },
  orLoginWith: {
    id: `${scope}.orLoginWith`,
    defaultMessage: 'Or log in with',
  },
  enterYourEmail: {
    id: `${scope}.enterYourEmail`,
    defaultMessage: 'Enter your email address to reset your password:',
  },
  welcomeBack: {
    id: `${scope}.welcomeBack`,
    defaultMessage: 'Welcome back!',
  },
  resendCode: {
    id: `${scope}.resendCode`,
    defaultMessage: 'Resend the code',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot password?',
  },
  limit: {
    id: `${scope}.limit`,
    defaultMessage: 'Limit',
  },
  getCompaniesList: {
    id: `${scope}.getCompaniesList`,
    defaultMessage: 'Get compaines list',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'New password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm new password',
  },
  invalidCode: {
    id: `${scope}.invalidCode`,
    defaultMessage: 'Invalid code',
  },
  dontHaveRegisteredCompany: {
    id: `${scope}.dontHaveRegisteredCompany`,
    defaultMessage: 'Don’t have a registered company?',
  },
  iDontHaveARegisteredCompany: {
    id: `${scope}.iDontHaveARegisteredCompany`,
    defaultMessage: 'I don’t have a registered company',
  },
  imAnIndividual: {
    id: `${scope}.imAnIndividual`,
    defaultMessage: 'No, I’m an individual and I don’t have a registered company',
  },
  stennForIndividuals: {
    id: `${scope}.stennForIndividuals`,
    defaultMessage: 'Stenn for individuals',
  },
  thisSectionIsFor: {
    id: `${scope}.thisSectionIsFor`,
    defaultMessage:
      'This section is for {flowName} with registered companies. Private individuals can make money with Stenn by introducing new clients. If you wish to be a Supplier, you will need to register a company.',
  },
  becomeIntroducer: {
    id: `${scope}.becomeIntroducer`,
    defaultMessage: 'Become an Introducer',
  },
  requestWillBeCanceled: {
    id: `${scope}.requestWillBeCanceled`,
    defaultMessage:
      'If you proceed, your current request will be cancelled. Are you sure you want to cancel your request?',
  },
  lost: {
    id: `${scope}.lost`,
    defaultMessage: 'Lost',
  },
  stenn: {
    id: `${scope}.stenn`,
    defaultMessage: 'Stenn International',
  },
  stennAddress: {
    id: `${scope}.stennAddress`,
    defaultMessage: '12th floor, The Bower, 207-211 Old Street',
  },
  stennAddressLineTwo: {
    id: `${scope}.stennAddressLineTwo`,
    defaultMessage: 'London, EC1V 9NR, United Kingdom',
  },
  stennPhone: {
    id: `${scope}.stennPhone`,
    defaultMessage: '+44 (0) 20 3735 9080',
  },
  stennEmail: {
    id: `${scope}.stennEmail`,
    defaultMessage: 'welcome@stenn.com',
  },
  stennRegistered: {
    id: `${scope}.stennRegistered`,
    defaultMessage: 'Registered in England and Wales.',
  },
  stennCompanyNumber: {
    id: `${scope}.stennCompanyNumber`,
    defaultMessage: 'Company number 09488169.',
  },
  stennVat: {
    id: `${scope}.stennVat`,
    defaultMessage: 'VAT number GB301 5476 35.',
  },
  loading: {
    id: `${scope}.loading`,
  },
  noClientsFound: {
    id: `${scope}.noClientsFound`,
    defaultMessage: 'No clients found.',
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: 'Share',
  },
  passwordMustContain: {
    id: `${scope}.passwordMustContain`,
    defaultMessage: 'Password must contain:',
  },
  noLoginAttemptsLeft: {
    id: `${scope}.noLoginAttemptsLeft`,
    defaultMessage: 'No login attempts left',
  },
  safeDataInformation: {
    id: `${scope}.safeDataInformation`,
    defaultMessage: 'We keep your data safe and confidential according to our <a>Privacy Policy</a>',
  },
  limitCalculationIsPending: {
    id: `${scope}.limitCalculationIsPending`,
    defaultMessage: 'Limit calculation is pending',
  },
  noCompaniesWithSimilarName: {
    id: `${scope}.noCompaniesWithSimilarName`,
    defaultMessage:
      'No companies with a similar name were found. Please ensure that the provided data is correct and try again.',
  },
  asASupplier: {
    id: `${scope}.asASupplier`,
    defaultMessage: 'As a Supplier',
  },
  asABuyer: {
    id: `${scope}.asABuyer`,
    defaultMessage: 'As a Buyer',
  },
  editButton: {
    id: `${scope}.editButton`,
    defaultMessage: 'Edit',
  },
  removeButton: {
    id: `${scope}.removeButton`,
    defaultMessage: 'Remove',
  },
  backToDashboard: {
    id: `${scope}.backToDashboard`,
    defaultMessage: 'Back to Dashboard',
  },
});

export default universalMessages;
