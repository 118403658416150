export const SET_SYSTEM_ADMINISTRATOR_ATTACH_REQUEST = 'SET_SYSTEM_ADMINISTRATOR_ATTACH_REQUEST';
export const SET_SYSTEM_ADMINISTRATOR_ATTACH_SUCCESS = 'SET_SYSTEM_ADMINISTRATOR_ATTACH_SUCCESS';
export const SET_SYSTEM_ADMINISTRATOR_ATTACH_FAILURE = 'SET_SYSTEM_ADMINISTRATOR_ATTACH_FAILURE';

export const SET_SYSTEM_ADMINISTRATOR_INVITE_REQUEST = 'SET_SYSTEM_ADMINISTRATOR_INVITE_REQUEST';
export const SET_SYSTEM_ADMINISTRATOR_INVITE_SUCCESS = 'SET_SYSTEM_ADMINISTRATOR_INVITE_SUCCESS';
export const SET_SYSTEM_ADMINISTRATOR_INVITE_FAILURE = 'SET_SYSTEM_ADMINISTRATOR_INVITE_FAILURE';

export const SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_REQUEST = 'SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_REQUEST';
export const SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_SUCCESS = 'SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_SUCCESS';
export const SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_FAILURE = 'SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_FAILURE';

export const SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_REQUEST = 'SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_REQUEST';
export const SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_SUCCESS = 'SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_SUCCESS';
export const SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_FAILURE = 'SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_FAILURE';

export const IS_ADMIN_REQUEST = 'IS_ADMIN_REQUEST';
export const IS_ADMIN_SUCCESS = 'IS_ADMIN_SUCCESS';
export const IS_ADMIN_FAILURE = 'IS_ADMIN_FAILURE';

export const CREATE_EMAIL_CONFIRMATION_LINK_REQUEST = 'CREATE_EMAIL_CONFIRMATION_LINK_REQUEST';
export const CREATE_EMAIL_CONFIRMATION_LINK_SUCCESS = 'CREATE_EMAIL_CONFIRMATION_LINK_SUCCESS';
export const CREATE_EMAIL_CONFIRMATION_LINK_FAILURE = 'CREATE_EMAIL_CONFIRMATION_LINK_FAILURE';

export const CREATE_CHANGE_PASSWORD_EMAIL_LINK_REQUEST = 'CREATE_CHANGE_PASSWORD_EMAIL_LINK_REQUEST';
export const CREATE_CHANGE_PASSWORD_EMAIL_LINK_SUCCESS = 'CREATE_CHANGE_PASSWORD_EMAIL_LINK_SUCCESS';
export const CREATE_CHANGE_PASSWORD_EMAIL_LINK_FAILURE = 'CREATE_CHANGE_PASSWORD_EMAIL_LINK_FAILURE';

export const ADD_MAX_REQUEST_LIMITS_COUNT_REQUEST = 'ADD_MAX_REQUEST_LIMITS_COUNT_REQUEST';
export const ADD_MAX_REQUEST_LIMITS_COUNT_SUCCESS = 'ADD_MAX_REQUEST_LIMITS_COUNT_SUCCESS';
export const ADD_MAX_REQUEST_LIMITS_COUNT_FAILURE = 'ADD_MAX_REQUEST_LIMITS_COUNT_FAILURE';

export const REMOVE_LINK_IN_COMPANY_PERSON_LINK_REQUEST = 'REMOVE_LINK_IN_COMPANY_PERSON_LINK_REQUEST';
export const REMOVE_LINK_IN_COMPANY_PERSON_LINK_SUCCESS = 'REMOVE_LINK_IN_COMPANY_PERSON_LINK_SUCCESS';
export const REMOVE_LINK_IN_COMPANY_PERSON_LINK_FAILURE = 'REMOVE_LINK_IN_COMPANY_PERSON_LINK_FAILURE';

export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILURE = 'GET_USER_ROLES_FAILURE';

export const ADD_USER_ROLES_REQUEST = 'ADD_USER_ROLES_REQUEST';
export const ADD_USER_ROLES_SUCCESS = 'ADD_USER_ROLES_SUCCESS';
export const ADD_USER_ROLES_FAILURE = 'ADD_USER_ROLES_FAILURE';

export const REMOVE_USER_ROLES_REQUEST = 'REMOVE_USER_ROLES_REQUEST';
export const REMOVE_USER_ROLES_SUCCESS = 'REMOVE_USER_ROLES_SUCCESS';
export const REMOVE_USER_ROLES_FAILURE = 'REMOVE_USER_ROLES_FAILURE';

export const STOP_IMPERSONATE_WITHOUT_TARGET_REQUEST = 'STOP_IMPERSONATE_WITHOUT_TARGET_REQUEST';
export const STOP_IMPERSONATE_WITHOUT_TARGET_SUCCESS = 'STOP_IMPERSONATE_WITHOUT_TARGET_SUCCESS';
export const STOP_IMPERSONATE_WITHOUT_TARGET_FAILURE = 'STOP_IMPERSONATE_WITHOUT_TARGET_FAILURE';
