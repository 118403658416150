import { FC, useMemo } from 'react';

import { useMutation } from 'react-query';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { flagsMap } from '@app/components-new/Internationalization/LanguageSelectorWrapper/constants/flags';
import { setBrowserLanguageRequest } from '@app/components-new/Internationalization/LanguageSelectorWrapper/mutations/setBrowserLanguageRequest';
import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import { LANGUAGES, TLang } from '@app/core/internationalization';
import { useAuth } from '@stenngroup/auth0-sdk';
import { LanguageSelect, SelectChangeEvent } from '@stenngroup/ui-kit';

export const LanguageSelectorWrapper: FC = () => {
  const { isAuthenticated } = useAuth();
  const { langId, setLangId } = useLangContext();
  const langOptions = useMemo(
    () =>
      LANGUAGES.map((lang) => ({
        value: lang.id,
        label: lang.name,
        flag: flagsMap[lang.id],
      })),
    []
  );

  const setBrowserLanguageMutation = useMutation(setBrowserLanguageRequest);

  const configuredFetch = useConfiguredFetch();

  const handleLanguageChange = (event: SelectChangeEvent<string>): void => {
    setLangId(event.target.value as TLang);
    if (isAuthenticated) {
      const languageCode = LANGUAGES.find((lang) => lang.id === event.target.value)?.languageCode as string;
      setBrowserLanguageMutation.mutate({ languageCode, configuredFetch });
    }
  };
  return (
    <LanguageSelect
      sx={{
        width: '100% !important',
        backgroundColor: ({ palette }) => `${palette.background.level1} !important`,
        color: ({ palette }) => `${palette.text.primary} !important`,
        '& .MuiSelect-icon': {
          color: ({ palette }) => `${palette.action.active} !important`,
        },
      }}
      options={langOptions}
      value={langId}
      onChange={handleLanguageChange}
    />
  );
};
