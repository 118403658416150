import { ReactNode, useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';

import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';

import { loadTranslations } from './translations';

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
const checkIntlInit = async (setTranslationMessages: Function, locale: string) => {
  if (!window.Intl) {
    await import('intl');
    await Promise.all([
      // @ts-expect-error TODO: fix types
      import('intl/locale-data/jsonp/en.js'),
      // @ts-expect-error TODO: fix types
      import('intl/locale-data/jsonp/es.js'),
      // @ts-expect-error TODO: fix types
      import('intl/locale-data/jsonp/zh-Hans.js'),
    ]);
    const translations = await loadTranslations(locale);
    setTranslationMessages(translations.default);
  } else {
    const translations = await loadTranslations(locale);
    setTranslationMessages(translations.default);
  }
};

export const IntlWrapperV2 = ({ children }: { children: ReactNode }) => {
  const { langId } = useLangContext();
  const [translationMessages, setTranslationMessages] = useState(null);

  useEffect(() => {
    checkIntlInit(setTranslationMessages, langId);
  }, [langId]);

  if (!translationMessages) {
    return null;
  }

  return (
    <IntlProvider locale={langId} messages={translationMessages}>
      {children}
    </IntlProvider>
  );
};
