import PropTypes from 'prop-types';

const InfoOutlined = (props: any) => {
  const { colors, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g clipPath="url(#clip0_9797_188614)">
        <circle cx="12" cy="12" r="9" stroke="#2196F3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.889 15.8886H13.4549"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1769 15.8891V11.167H10.8992"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1113 7.83094C12.1113 7.98435 11.9869 8.10871 11.8335 8.10871C11.6801 8.10871 11.5557 7.98435 11.5557 7.83094C11.5557 7.67753 11.6801 7.55316 11.8335 7.55316"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8334 7.5524C11.9868 7.5524 12.1112 7.67677 12.1112 7.83018"
          stroke="#2196F3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9797_188614">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

InfoOutlined.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default InfoOutlined;
