import { FC, useCallback, useMemo, useState } from 'react';

import { transitionsMap } from '@app/components-new/FlowTransitions/constants/transitionsMap';
import { FlowTransitionsContext } from '@app/components-new/FlowTransitions/context/FlowTransitionsContext';
import {
  EFlowStep,
  IFlowState,
  IFlowTransitionsContext,
  IStartFlowParams,
  IStepParams,
} from '@app/components-new/FlowTransitions/context/types';
import { assert } from '@app/helpers/assert';

const FLOW_DEFAULT_STATE: IFlowState = {
  trId: '',
  flowType: null,
  tradeRole: null,
  myCompanyId: null,
  counterpartyCompanyId: null,
};

const FLOW_DEFAULT_STEP_STATE: Record<EFlowStep, unknown> = {
  [EFlowStep.TRADE_ROLE]: null,
  [EFlowStep.SEARCH_TRADE_PARTNER]: null,
  [EFlowStep.SELECT_NEW_TRADE_PARTNER]: null,
  [EFlowStep.REQUEST_LIMIT]: null,
  [EFlowStep.TRADE_PARTNER_CREATED]: null,
  [EFlowStep.SELECT_TRADE_PARTNER]: null,
  [EFlowStep.UPLOAD_INVOICE]: null,
  [EFlowStep.INVOICES_LIST]: null,
  [EFlowStep.ADD_COMPANY_SIGNATORY]: null,
  [EFlowStep.ADD_TRADE_PARTNER_SIGNATORY]: null,
  [EFlowStep.ADD_BANK_DETAILS]: null,
  [EFlowStep.REVIEW_DEAL]: null,
  [EFlowStep.DEAL_SUBMITTED]: null,
  [EFlowStep.INVITE_TRADE_PARTNER]: null,
  [EFlowStep.TRADE_PARTNER_INVITED]: null,
};

type TDirection = 'forward' | 'backward';

export const FlowTransitionsContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [flowState, setFlowState] = useState<IFlowState>(FLOW_DEFAULT_STATE);

  const [stepsState, setStepsState] = useState<Record<EFlowStep, unknown>>(FLOW_DEFAULT_STEP_STATE);

  const [currentStep, setCurrentStep] = useState<EFlowStep | null>(null);
  const [history, setHistory] = useState<EFlowStep[]>([]);
  const [direction, setDirection] = useState<TDirection>('forward');
  const [isOpen, setIsOpen] = useState(false);

  const transitions = useMemo(() => {
    return transitionsMap;
  }, []);

  const appendToHistory = (step: EFlowStep) => {
    setHistory((prev) => Array.from(new Set([...prev, step])));
  };

  const handleNextStep = useCallback(
    (params?: IStepParams): void => {
      const { stepState, newFlowState } = params ?? {};
      assert(currentStep, 'currentStep is not defined');
      const nextStep = transitions[currentStep].next?.(flowState);
      if (!nextStep) {
        return;
      }
      assert(currentStep, 'currentStep is not defined');
      setStepsState((prev) => ({ ...prev, [currentStep]: stepState ?? null }));
      setFlowState((prev) => ({ ...prev, ...newFlowState }));
      setCurrentStep(nextStep);
      appendToHistory(currentStep);
      setDirection('forward');
    },
    [currentStep, flowState, transitions]
  );

  const handlePrevStep = useCallback(
    (params?: IStepParams): void => {
      const { newFlowState } = params ?? {};
      const prevStep = history.length > 0 ? history[history.length - 1] : null;
      if (!prevStep) {
        return;
      }
      const prevStepTransition = transitions[prevStep];
      if (!prevStepTransition) {
        return;
      }
      setHistory((prev) => prev.slice(0, prev.length - 1));
      setFlowState((prev) => ({ ...prev, ...newFlowState }));
      setCurrentStep(prevStepTransition.step);
      setDirection('backward');
    },
    [transitions, history]
  );

  const goTo = useCallback(
    (step: EFlowStep, direction: TDirection = 'forward', params?: IStepParams) => {
      const { stepState } = params ?? {};
      assert(currentStep, 'currentStep is not defined');
      setCurrentStep(step);
      setDirection(direction);
      if (stepState) {
        setStepsState((prev) => ({ ...prev, [currentStep]: stepState ?? null }));
      }
      appendToHistory(currentStep);
    },
    [currentStep]
  );

  const startFlow = useCallback((params: IStartFlowParams) => {
    setFlowState(params.initialState);
    setIsOpen(true);
    setCurrentStep(params.initialStep);
  }, []);

  const canGoBack = history.length > 0;

  const getFlowState = useCallback(() => {
    return flowState;
  }, [flowState]);

  const getStateByStep = useCallback(
    (step: EFlowStep) => {
      return stepsState[step];
    },
    [stepsState]
  );

  const reset = useCallback(() => {
    setCurrentStep(null);
    setFlowState(FLOW_DEFAULT_STATE);
    setHistory([]);
    setStepsState(FLOW_DEFAULT_STEP_STATE);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const contextValue: IFlowTransitionsContext = useMemo(() => {
    return {
      startFlow,
      direction,
      currentStep,
      history,
      handleNextStep,
      handlePrevStep,
      canGoBack,
      goTo,
      getFlowState,
      isOpen,
      close,
      reset,
      getStateByStep: <T,>(step: EFlowStep) => getStateByStep(step) as T,
    };
  }, [
    startFlow,
    direction,
    currentStep,
    history,
    handleNextStep,
    handlePrevStep,
    canGoBack,
    goTo,
    getFlowState,
    isOpen,
    close,
    getStateByStep,
    reset,
  ]);

  return <FlowTransitionsContext.Provider value={contextValue}>{children}</FlowTransitionsContext.Provider>;
};
