/* eslint-disable no-param-reassign */
import produce from 'immer';

import * as appActionTypes from '../actionTypes/systemAdministrator';

const systemAdministratorInitialState = {
  systemAdministratorAttach: { loading: false, success: false },
  systemAdministratorInvite: { loading: false, link: '', success: false },
  impersonateState: { loading: false, success: false },
  stopImpersonateState: { loading: false, success: false },
  userGetRolesOperationState: { loading: false, data: [], success: false },
  userAddRolesOperationState: {
    loading: false,
    addRole: false,
    success: false,
  },
  userRemoveRolesOperationState: {
    loading: false,
    removeRole: false,
    success: false,
  },
  removeLinkCompanyPersonLinkStore: { loading: false, success: false },
  addMaxRequestLimitCountState: {
    loading: false,
    limitCount: '',
    success: false,
  },
  createChangePasswordEmailLinkState: {
    loading: false,
    link: '',
    success: false,
  },
  createEmailConfirmationLink: { loading: false, link: '', success: false },
  isAdmin: { loading: false, adminAccess: null, success: false },
  stopImpersonateWithoutTargetState: { loading: false, success: false },
};

export const systemAdminReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case appActionTypes.STOP_IMPERSONATE_WITHOUT_TARGET_REQUEST:
        draft.stopImpersonateWithoutTargetState = {
          ...systemAdministratorInitialState.stopImpersonateWithoutTargetState,
          loading: true,
        };
        break;

      case appActionTypes.STOP_IMPERSONATE_WITHOUT_TARGET_SUCCESS:
        draft.stopImpersonateWithoutTargetState = {
          ...systemAdministratorInitialState.stopImpersonateWithoutTargetState,
          loading: false,
          success: true,
        };
        break;

      case appActionTypes.STOP_IMPERSONATE_WITHOUT_TARGET_FAILURE:
        draft.stopImpersonateWithoutTargetState = {
          ...systemAdministratorInitialState.stopImpersonateWithoutTargetState,
          loading: false,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_REQUEST:
        draft.impersonateState = {
          ...systemAdministratorInitialState.impersonateState,
          loading: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_SUCCESS:
        draft.impersonateState = {
          ...systemAdministratorInitialState.impersonateState,
          loading: false,
          success: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_IMPERSONATE_FAILURE:
        draft.impersonateState = {
          ...systemAdministratorInitialState.impersonateState,
          loading: false,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_REQUEST:
        draft.stopImpersonateState = {
          ...systemAdministratorInitialState.stopImpersonateState,
          loading: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_SUCCESS:
        draft.stopImpersonateState = {
          ...systemAdministratorInitialState.stopImpersonateState,
          loading: false,
          success: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_STOP_IMPERSONATE_FAILURE:
        draft.stopImpersonateState = {
          ...systemAdministratorInitialState.stopImpersonateState,
          loading: false,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_ATTACH_REQUEST:
        draft.systemAdministratorAttach = {
          ...systemAdministratorInitialState.systemAdministratorAttach,
          loading: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_ATTACH_SUCCESS:
        draft.systemAdministratorAttach = {
          ...systemAdministratorInitialState.systemAdministratorAttach,
          loading: false,
          success: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_ATTACH_FAILURE:
        draft.systemAdministratorAttach = {
          ...systemAdministratorInitialState.systemAdministratorAttach,
          loading: false,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_INVITE_REQUEST:
        draft.systemAdministratorInvite = {
          ...systemAdministratorInitialState.systemAdministratorInvite,
          loading: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_INVITE_SUCCESS:
        draft.systemAdministratorInvite = {
          ...systemAdministratorInitialState.systemAdministratorInvite,
          loading: false,
          link: action.payload,
          success: true,
        };
        break;

      case appActionTypes.SET_SYSTEM_ADMINISTRATOR_INVITE_FAILURE:
        draft.systemAdministratorInvite = {
          ...systemAdministratorInitialState.systemAdministratorInvite,
          loading: false,
        };
        break;

      case appActionTypes.GET_USER_ROLES_SUCCESS:
        draft.userGetRolesOperationState = {
          ...systemAdministratorInitialState.userGetRolesOperationState,
          success: true,
          data: action.payload,
        };
        draft.userAddRolesOperationState = {
          ...systemAdministratorInitialState.userAddRolesOperationState,
          success: false,
        };
        draft.userRemoveRolesOperationState = {
          ...systemAdministratorInitialState.userRemoveRolesOperationState,
          success: false,
        };
        break;

      case appActionTypes.GET_USER_ROLES_REQUEST:
        draft.userGetRolesOperationState = {
          ...systemAdministratorInitialState.userGetRolesOperationState,
          loading: true,
        };
        break;

      case appActionTypes.GET_USER_ROLES_FAILURE:
        draft.userGetRolesOperationState = {
          ...systemAdministratorInitialState.userGetRolesOperationState,
          loading: false,
        };
        break;

      case appActionTypes.ADD_USER_ROLES_SUCCESS:
        draft.userAddRolesOperationState = {
          ...systemAdministratorInitialState.userAddRolesOperationState,
          addRole: true,
          success: true,
        };
        draft.userRemoveRolesOperationState = {
          ...systemAdministratorInitialState.userRemoveRolesOperationState,
          success: false,
        };
        draft.userGetRolesOperationState = {
          ...systemAdministratorInitialState.userGetRolesOperationState,
          success: false,
        };
        break;

      case appActionTypes.ADD_USER_ROLES_REQUEST:
        draft.userAddRolesOperationState = {
          ...systemAdministratorInitialState.userAddRolesOperationState,
          loading: true,
        };
        break;

      case appActionTypes.ADD_USER_ROLES_FAILURE:
        draft.userAddRolesOperationState = {
          ...systemAdministratorInitialState.userAddRolesOperationState,
          loading: false,
        };
        break;

      case appActionTypes.REMOVE_USER_ROLES_REQUEST:
        draft.userRemoveRolesOperationState = {
          ...systemAdministratorInitialState.userRemoveRolesOperationState,
          loading: true,
        };
        break;

      case appActionTypes.REMOVE_USER_ROLES_SUCCESS:
        draft.userRemoveRolesOperationState = {
          ...systemAdministratorInitialState.userRemoveRolesOperationState,
          removeRole: true,
          success: true,
        };
        draft.userGetRolesOperationState = {
          ...systemAdministratorInitialState.userGetRolesOperationState,
          success: false,
        };
        draft.userAddRolesOperationState = {
          ...systemAdministratorInitialState.userAddRolesOperationState,
          success: false,
        };
        break;

      case appActionTypes.REMOVE_USER_ROLES_FAILURE:
        draft.userRemoveRolesOperationState = {
          ...systemAdministratorInitialState.userRemoveRolesOperationState,
          loading: false,
        };
        break;

      case appActionTypes.REMOVE_LINK_IN_COMPANY_PERSON_LINK_REQUEST:
        draft.removeLinkCompanyPersonLinkStore = {
          ...systemAdministratorInitialState.removeLinkCompanyPersonLinkStore,
          loading: true,
        };
        break;

      case appActionTypes.REMOVE_LINK_IN_COMPANY_PERSON_LINK_SUCCESS:
        draft.removeLinkCompanyPersonLinkStore = {
          ...systemAdministratorInitialState.removeLinkCompanyPersonLinkStore,
          loading: false,
          success: true,
        };
        break;

      case appActionTypes.REMOVE_LINK_IN_COMPANY_PERSON_LINK_FAILURE:
        draft.removeLinkCompanyPersonLinkStore = {
          ...systemAdministratorInitialState.removeLinkCompanyPersonLinkStore,
          loading: false,
        };
        break;

      case appActionTypes.ADD_MAX_REQUEST_LIMITS_COUNT_REQUEST:
        draft.addMaxRequestLimitCountState = {
          ...systemAdministratorInitialState.addMaxRequestLimitCountState,
          loading: true,
        };
        break;

      case appActionTypes.ADD_MAX_REQUEST_LIMITS_COUNT_SUCCESS:
        draft.addMaxRequestLimitCountState = {
          ...systemAdministratorInitialState.addMaxRequestLimitCountState,
          loading: false,
          success: true,
          limitCount: action.payload,
        };
        break;

      case appActionTypes.ADD_MAX_REQUEST_LIMITS_COUNT_FAILURE:
        draft.addMaxRequestLimitCountState = {
          ...systemAdministratorInitialState.addMaxRequestLimitCountState,
          loading: false,
        };
        break;

      case appActionTypes.CREATE_CHANGE_PASSWORD_EMAIL_LINK_REQUEST:
        draft.createChangePasswordEmailLinkState = {
          ...systemAdministratorInitialState.createChangePasswordEmailLinkState,
          loading: true,
        };
        break;

      case appActionTypes.CREATE_CHANGE_PASSWORD_EMAIL_LINK_SUCCESS:
        draft.createChangePasswordEmailLinkState = {
          ...systemAdministratorInitialState.createChangePasswordEmailLinkState,
          loading: false,
          link: action.payload,
          success: true,
        };
        break;

      case appActionTypes.CREATE_CHANGE_PASSWORD_EMAIL_LINK_FAILURE:
        draft.createChangePasswordEmailLinkState = {
          ...systemAdministratorInitialState.createChangePasswordEmailLinkState,
          loading: false,
        };
        break;

      case appActionTypes.CREATE_EMAIL_CONFIRMATION_LINK_REQUEST:
        draft.createEmailConfirmationLink = {
          ...systemAdministratorInitialState.createEmailConfirmationLink,
          loading: true,
        };
        break;

      case appActionTypes.CREATE_EMAIL_CONFIRMATION_LINK_SUCCESS:
        draft.createEmailConfirmationLink = {
          ...systemAdministratorInitialState.createEmailConfirmationLink,
          loading: false,
          link: action.payload,
          success: true,
        };
        break;

      case appActionTypes.CREATE_EMAIL_CONFIRMATION_LINK_FAILURE:
        draft.createEmailConfirmationLink = {
          ...systemAdministratorInitialState.createEmailConfirmationLink,
          loading: false,
        };
        break;

      case appActionTypes.IS_ADMIN_REQUEST:
        draft.isAdmin = {
          ...systemAdministratorInitialState.isAdmin,
          loading: true,
        };
        break;

      case appActionTypes.IS_ADMIN_SUCCESS:
        draft.isAdmin = {
          ...systemAdministratorInitialState.isAdmin,
          loading: false,
          success: true,
          adminAccess: action.payload,
        };
        break;

      case appActionTypes.IS_ADMIN_FAILURE:
        draft.isAdmin = {
          ...systemAdministratorInitialState.isAdmin,
          loading: false,
        };
        break;

      default:
        break;
    }
  },
  { ...systemAdministratorInitialState }
);
