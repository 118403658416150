import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { useGetProfileV2 } from '@app/api/profile/useGetProfileV2';
import { CompanyStatus } from '@app/components-new/CompanyStatus';
import { FinanceGraph } from '@app/components-new/FinanceGraph';
import { FinanceStats } from '@app/components-new/FinanceStats';
import { RequestLimitText } from '@app/components-new/RequestLimitText';
import { TEST_IDS } from '@app/core/constants';
import { showBuyerFinanceData } from '@app/helpers/showBuyerFinanceData';
import { getCompanyFinanceDataRequest } from '@app/layouts/DashboardLayout/Header/PersonalSpace/queries/getCompanyFinanceDataRequest';
import { AddUserDialogContent } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/AddUserDialogContent';
import { CompanyInformation } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/CompanyInformation';
import { Team } from '@app/layouts/DashboardLayout/Header/PersonalSpace/sections/Team';
import { IPersonalSpaceProps } from '@app/layouts/DashboardLayout/Header/PersonalSpace/types';
import { ESupplierState } from '@app/pages/Suppliers/SuppliersPage/types';
import { ERequestKey } from '@app/types/request';
import { Divider, Stack } from '@mui/material';
import { Button, Dialog, Tabs, Typography } from '@stenngroup/ui-kit';

const TABS = [
  {
    label: <FormattedMessage id="constants.universalMessages.team" />,
    value: 'team',
  },
  {
    label: <FormattedMessage id="constants.universalMessages.companyInformation" />,
    value: 'companyInformation',
  },
] as const;

type TTabValue = (typeof TABS)[number]['value'];

const ComponentMap: Record<TTabValue, FC> = {
  team: () => <Team />,
  companyInformation: () => <CompanyInformation />,
};

export const PersonalSpace: FC<IPersonalSpaceProps> = () => {
  const [currentTab, setCurrentTab] = useState<TTabValue>('team');
  const { data: profile } = useGetProfileV2();

  const configuredFetch = useConfiguredFetch();
  const { data: financeData, status: loadingState } = useQuery(
    [ERequestKey.PersonalSpaceFinanceData],
    () => getCompanyFinanceDataRequest({ configuredFetch, payload: { hasBuyerRole: !!profile?.hasBuyerRole } }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const handleChange = (_e: React.SyntheticEvent<Element, Event>, newValue: TTabValue): void => {
    setCurrentTab(newValue);
  };

  const Component = ComponentMap[currentTab];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const supplierStatus = profile?.clientsSupplierStatus ?? ESupplierState.Unknown;

  return (
    <>
      <Stack gap={2} py={1} data-testid={TEST_IDS.personalSpace}>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack gap={1}>
              <Typography.Body1SemiBold data-testid={TEST_IDS.personalSpaceCompanyName}>
                {profile?.companyName}
              </Typography.Body1SemiBold>
              {financeData ? (
                <Stack direction="row" gap={1.5} alignItems="center">
                  <CompanyStatus status={financeData.buyerClientsStatus} />
                  <Stack alignItems="center" direction="row" gap={0.5}>
                    <RequestLimitText status={financeData.buyerClientsStatus} value={financeData.fullLimit} />
                  </Stack>
                </Stack>
              ) : (
                <CompanyStatus status={supplierStatus} />
              )}
            </Stack>
            <Stack>
              <Button
                size="small"
                variant="tertiary"
                color="primary"
                onClick={() => setIsModalOpen(true)}
                data-testid={TEST_IDS.personalSpaceAddUserButton}
              >
                <FormattedMessage id="constants.universalMessages.addUser" />
              </Button>
            </Stack>
          </Stack>
          {financeData && showBuyerFinanceData(financeData.buyerClientsStatus) && (
            <Stack gap={1.5}>
              <FinanceGraph
                loadingState={loadingState}
                used={financeData.invoiceOutstanding}
                available={financeData.availableLimit}
                reserved={financeData.reservedLimit}
                overdue={financeData.amtPastDueDate}
              />
              <FinanceStats
                loadingState={loadingState}
                used={financeData.invoiceOutstanding}
                available={financeData.availableLimit}
                reserved={financeData.reservedLimit}
                overdue={financeData.amtPastDueDate}
                currencyCode="USD"
              />
            </Stack>
          )}
        </Stack>
        <Divider />
        <Stack gap={2}>
          <Tabs tabs={TABS} value={currentTab} onChange={handleChange} />
          <Stack>
            <Component />
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        fullWidth
        size="medium"
        data-testid={TEST_IDS.personalSpaceAddUserDialog}
      >
        <AddUserDialogContent onClose={() => setIsModalOpen(false)} />
      </Dialog>
    </>
  );
};
