import { backendErrorMessages } from '@app/core/internationalization';

export const getLocalTranslatedMessageId = (errorCode: number | string) => {
  const messageId = `backendError_${errorCode}`;

  if (backendErrorMessages[messageId as keyof typeof backendErrorMessages]) {
    return messageId;
  }

  return '';
};
