import { truncateText } from '@app/utils/truncateText';

interface IAddress {
  street?: string | null;
  state?: string | null;
  city?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
}

export const formatAddress = (address: IAddress) => {
  return `${truncateText(address?.street ?? '', 20)} ${address?.postalCode ?? ''} ${address?.state ?? ''} ${
    address?.city ?? ''
  } ${address?.countryCode ?? ''}`;
};
