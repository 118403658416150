export const BASE_API_URLS = {
  operations: window._STENN_.API_OPERATIONS_PATH,
  onboarding: window._STENN_.API_ONBOARDING_PATH,
  pushSender: window._STENN_.API_PUSHSENDER_PATH,
  logger: window._STENN_.API_LOGGER_PATH,
  notifications: window._STENN_.API_ONBOARDING_PATH,
  clientsServiceWeb: window._STENN_.API_CLIENTS_SERVICE_WEB,
};

export const STENN_APP_HEADER = 'stenn-app-2';
