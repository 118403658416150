import { LOCAL_STORAGE_KEYS } from '@app/core/constants';

import { BaseStorageService, IStorage } from './BaseStorageService';

const storage: IStorage = {
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  get: (key: string) => localStorage.getItem(key),
  has: (key: string) => localStorage.getItem(key) !== null,
  delete: (key) => {
    localStorage.removeItem(key);
  },
  getAll: () => localStorage,
};

class LocalStorageServiceBase extends BaseStorageService {
  clear = () => {
    localStorage.clear();
  };

  getEntries = (prefix = '') => {
    const entries = [];

    for (const key of Object.keys(localStorage)) {
      if (key?.startsWith(prefix)) {
        entries.push({ key, value: localStorage.getItem(key) });
      }
    }

    return entries;
  };
}

const privateKeys = Object.values(LOCAL_STORAGE_KEYS.private);

export const LocalStorageService = new LocalStorageServiceBase(storage, privateKeys);
