import { useQuery } from 'react-query';

import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { EDealType } from '@app/core/constants';
import { getApiQuery } from '@app/core/utilities';

type TCurrenciesDto = {
  description: string;
  isoNumericCode: number;
  symbol: string;
  isoName: string;
};

type TAvailableCurrenciesProps = {
  tradeRelationId?: string;
  isEnabled?: boolean;
  invoiceProductType?: EDealType;
  cacheTime?: number;
};

enum EAvailableCurrencyRequestKey {
  GetCurrency = 'getCurrency',
}

const getAvailableCurrenciesRequest = (queryParams: { tradeRelationId?: string; invoiceProductType?: EDealType }) => {
  const query = getApiQuery(queryParams);
  return ApiProvider.apiQueryRequest<TCurrenciesDto[]>(`${BASE_API_URLS.onboarding}/api/v1.2/Currencies?${query}`, {
    method: 'GET',
  });
};
export const useAvailableCurrencies = ({
  invoiceProductType,
  isEnabled,
  tradeRelationId,
  cacheTime,
}: TAvailableCurrenciesProps) =>
  useQuery(
    EAvailableCurrencyRequestKey.GetCurrency,
    () =>
      getAvailableCurrenciesRequest({
        tradeRelationId,
        invoiceProductType,
      }),
    { refetchOnMount: true, enabled: isEnabled, cacheTime }
  );
