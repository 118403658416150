// initSentry must be imported before any other imports
// eslint-disable-next-line import/order
import './initSentry';
import { initAnalyticScripts } from '@app/core/analytics';
import { NovaApp } from '@app/novaApp';
import ReactDOM from 'react-dom/client';

import { configureApiProvider } from './configuration/ApiProvider.config';
import { configureAuthentication } from './configuration/Authentication.config';

const MOUNT_NODE = document.getElementById('app');

initAnalyticScripts();
configureApiProvider();

configureAuthentication();

const root = ReactDOM.createRoot(MOUNT_NODE);

root.render(<NovaApp />);
