import { IntlFormatters } from 'react-intl';

import { searchCompanyBaseSchema } from '@app/components-new/SearchCompanyForm/baseSchema';
import { ISearchCompanyForm } from '@app/components-new/SearchCompanyForm/types';
import { isDUNSNumber } from '@stenngroup/utilities';
import * as yup from 'yup';

export const searchCompanySchema = (formatMessage: IntlFormatters['formatMessage']) =>
  searchCompanyBaseSchema(formatMessage).shape<Record<keyof Pick<ISearchCompanyForm, 'duns'>, yup.AnySchema>>({
    duns: yup
      .string()
      .test(
        'duns',
        formatMessage({ id: 'components.NotListedCompany.dunsNumberMustBeNumerical' }),
        (value) => !value || isDUNSNumber(value)
      )
      .optional(),
  });
